import { DialogProps } from './Dialog';
import Box from '@mui/material/Box';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import React from 'react';
import Typography from '@mui/material/Typography';

export interface DialogTitleProps extends MuiDialogTitleProps {
  children?: React.ReactNode;
  onClose?: DialogProps['onClose'];
  disableTypography?: boolean;
}

export const DialogTitle = ({ children, onClose, disableTypography, ...rest }: DialogTitleProps) => {
  return (
    <MuiDialogTitle
      {...rest}
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'space-between',
        ...rest.sx,
      }}
    >
      {disableTypography ? (
        children
      ) : (
        <Typography component={Box} fontWeight="600" paddingTop="12px" variant="h5">
          {children}
        </Typography>
      )}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={(e) => onClose(e, 'closeButtonClick')}
          size="large"
          sx={{ right: '-10px' }}
        >
          <CloseLineIcon color="black" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
