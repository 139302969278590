import * as Types from '../types.generated.js';

export type ProviderFragment = {
  __typename?: 'ProviderType';
  firstName: string;
  lastName: string;
  npi: string;
  emailAddress?: string | null;
  faxNumber?: string | null;
  id: string;
  phoneNumber?: string | null;
  smsNumber?: string | null;
};

export const ProviderFragmentDoc = `
    fragment ProviderFragment on ProviderType {
  firstName
  lastName
  npi
  emailAddress
  faxNumber
  id
  npi
  phoneNumber
  smsNumber
}
    `;
