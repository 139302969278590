import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetWaiversQueryVariables = Types.Exact<{
  onlyActive?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  state?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetWaiversQuery = {
  __typename?: 'Query';
  waivers?: {
    __typename?: 'WaiverTypeConnection';
    edges: Array<{
      __typename?: 'WaiverTypeEdge';
      node?: { __typename?: 'WaiverType'; id: string; name: string } | null;
    } | null>;
  } | null;
};

export const GetWaiversDocument = `
    query getWaivers($onlyActive: Boolean, $state: String, $orderBy: String) {
  waivers(onlyActive: $onlyActive, state: $state, orderBy: $orderBy) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export const useGetWaiversQuery = <TData = GetWaiversQuery, TError = unknown>(
  variables?: GetWaiversQueryVariables,
  options?: Omit<UseQueryOptions<GetWaiversQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetWaiversQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetWaiversQuery, TError, TData>({
    queryKey: variables === undefined ? ['getWaivers'] : ['getWaivers', variables],
    queryFn: fetcher<GetWaiversQuery, GetWaiversQueryVariables>(GetWaiversDocument, variables),
    ...options,
  });
};

useGetWaiversQuery.getKey = (variables?: GetWaiversQueryVariables) =>
  variables === undefined ? ['getWaivers'] : ['getWaivers', variables];

export const useInfiniteGetWaiversQuery = <TData = InfiniteData<GetWaiversQuery>, TError = unknown>(
  variables: GetWaiversQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetWaiversQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetWaiversQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetWaiversQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined ? ['getWaivers.infinite'] : ['getWaivers.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetWaiversQuery, GetWaiversQueryVariables>(GetWaiversDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetWaiversQuery.getKey = (variables?: GetWaiversQueryVariables) =>
  variables === undefined ? ['getWaivers.infinite'] : ['getWaivers.infinite', variables];

useGetWaiversQuery.fetcher = (variables?: GetWaiversQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetWaiversQuery, GetWaiversQueryVariables>(GetWaiversDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWaiversQuery((req, res, ctx) => {
 *   const { onlyActive, state, orderBy } = req.variables;
 *   return res(
 *     ctx.data({ waivers })
 *   )
 * })
 */
export const mockGetWaiversQuery = (
  resolver: Parameters<typeof graphql.query<GetWaiversQuery, GetWaiversQueryVariables>>[1],
) => graphql.query<GetWaiversQuery, GetWaiversQueryVariables>('getWaivers', resolver);
