import { getFormattedPhoneForDisplay } from '../utils/phoneUtils';
import { useNpiSearchQuery } from '../api-clients/falcon-api/graphql/queries/npiSearch.generated';
import { useSnackbar } from '../components/Snackbar/SnackbarContext';
import { useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export interface NpiProvider {
  id: string;
  fax: string;
  firstName: string;
  lastName: string;
  npi: string;
  phoneNumber: string;
}

export const useValidateNpi = () => {
  const { showSnackbarNotification } = useSnackbar();
  const [isValidatingNpi, setIsValidatingNpi] = useState(false);
  const [isNpiValid, setIsNpiValid] = useState(false);

  const validateNpi = async (
    npi: string,
    onProviderUpdate: (provider: NpiProvider) => void,
    showInputLengthErrorPopup = true,
  ) => {
    try {
      setIsNpiValid(false);

      const variables = {
        npiNumber: npi,
        firstName: '',
        lastName: '',
      };

      if (!npi) {
        return;
      }

      if (npi.length !== 10) {
        if (showInputLengthErrorPopup) {
          Swal.fire({
            title: 'Invalid NPI Number',
            text: 'The NPI must be 10 digits long',
            icon: 'warning',
            confirmButtonColor: '#00ae86',
            confirmButtonText: 'Ok',
            showCancelButton: false,
          });
        }
        return;
      }

      Swal.fire({
        allowOutsideClick: false,
        title: 'Validating NPI Number...',
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      setIsValidatingNpi(true);

      const result = await useNpiSearchQuery.fetcher(variables)();

      const record = result.npiSearch?.results?.[0];
      setIsValidatingNpi(false);

      Swal.close();

      if (!record) {
        throw new Error('Could not find a NPI record associated with the provided number.');
      }

      setIsNpiValid(true);

      const { firstName, lastName, phone, providerId, fax } = record;

      onProviderUpdate({
        npi,
        firstName: firstName || '',
        lastName: lastName || '',
        phoneNumber: phone ? getFormattedPhoneForDisplay(phone) : '',
        id: providerId || '',
        fax: fax ? getFormattedPhoneForDisplay(fax) : '',
      });
    } catch (err) {
      if (err instanceof Error) {
        setIsNpiValid(false);
        setIsValidatingNpi(false);
        Swal.close();

        showSnackbarNotification({ title: 'NPI validation error.', subtitle: err.message, type: 'error' });

        throw err;
      }
    }
  };

  return {
    isValidatingNpi,
    isNpiValid,
    validateNpi,
  };
};
