import { AgreementStatusEnum } from '../../api-clients/falcon-api/graphql/types.generated';
import { BannerRouteTabs } from '../../components/BannerRouteTabs';
import { generatePath } from 'react-router-dom';
import { OrgSelect } from '../../components/OrgSelect/OrgSelect';
import { PATHS } from '../../router/paths';
import { ReactSVG } from 'react-svg';
import { theme } from '@tomorrow/ui/theme';
import { useCurrentOrgUser } from '../../router/useCurrentOrgUser';
import { useEhrContext } from '../../hooks/useEhrContext';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useOrgIdParam } from '../../hooks/useOrgIdParam';
import BannerIconContainer from '../../components/BannerIconContainer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import NewOrderButton from '../../components/NewOrderButton/NewOrderButton';
import PracticeIconSVG from '../../assets/practice-icon.svg';
import qs from 'qs';

const PracticeBanner = () => {
  const flags = useFeatureFlags();
  const orgId = useOrgIdParam() ?? '';
  const ehrContext = useEhrContext();

  const tabs = [
    { label: 'Orders', path: generatePath(PATHS.PRACTICE_ORDERS, { orgId }) },
    ...(flags.rp_patients_screen
      ? [{ label: 'Patients', path: generatePath(PATHS.PRACTICE_PATIENTS, { orgId }) }]
      : []),
    { label: 'My Practice', path: generatePath(PATHS.PRACTICE_MANAGEMENT, { orgId }) },
  ];

  return !ehrContext.isSuccess || ehrContext.data ? null : (
    <Box bgcolor={theme.colors.white} pt="20px" width="100%">
      <Container>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <BannerIconContainer>
                  <ReactSVG src={PracticeIconSVG} />
                </BannerIconContainer>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                  <OrgSelect />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Box alignItems="center" display="flex" height="100%" justifyContent="flex-end">
              <NewPracticeOrderButton />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" marginBottom="3px">
              <BannerRouteTabs tabs={tabs} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const getNeedsAgreementStatus = (agreementStatus?: AgreementStatusEnum) => {
  if (!agreementStatus) return false;
  return [
    AgreementStatusEnum.NewOrgNeedsAgreement,
    AgreementStatusEnum.ExistingOrgNeedsAgreement,
    AgreementStatusEnum.ExistingOrgNeedsAgreementNewUser,
  ].includes(agreementStatus);
};

export function NewPracticeOrderButton({ patientId }: { patientId?: string }) {
  const orgId = useOrgIdParam() ?? '';
  const orgUser = useCurrentOrgUser();
  const needsAgreementSigned = getNeedsAgreementStatus(orgUser?.org?.agreementStatus);

  if (!orgId) {
    return null;
  }

  const linkToSignBAAScreen =
    generatePath(PATHS.PRACTICE_BAA, { orgId }) + qs.stringify({ patientId }, { addQueryPrefix: true });

  const newOrderPath =
    generatePath(PATHS.PRACTICE_ORDERS_INTAKE_NEW, { orgId, step: null }) +
    qs.stringify({ patientId }, { addQueryPrefix: true });

  return <NewOrderButton to={needsAgreementSigned ? linkToSignBAAScreen : newOrderPath} />;
}

export default PracticeBanner;
