import { LINKS } from '../router/links';
import { logAction } from '../utils/logging';
import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

export function NpiRegistryLink(props: TypographyProps) {
  return (
    <Typography {...props} color="textSecondary" gutterBottom>
      {"Don't know the provider's NPI? "}
      <Link
        fontWeight="600"
        href={LINKS.NPI_LOOKUP_LINK}
        onClick={() => logAction('Open NPI Registry')}
        rel="noopener noreferrer"
        target="_blank"
      >
        Search the NPI registry
      </Link>
    </Typography>
  );
}
