import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';

// selectedOrderId is example value for now but not used (POC)
export interface LabelStepFormValues {
  selectedOrderId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function toDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): LabelStepFormValues {
  return {
    selectedOrderId: undefined,
  };
}
