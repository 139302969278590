import { lazily } from 'react-lazily';
import { NewReferralButton } from '../../components/CareCoordinators/CareCoordinatorBanner';
import { OrderDrawer } from '../../components/OrderDrawer/OrderDrawer';
import { OrderDrawerSkeleton } from '../../components/OrderDrawer/OrderDrawerSkeleton';
import { retry } from '../../router/retry';
import { Suspense, useCallback } from 'react';
import { useAddViewIdToHttpHeaders } from '../../hooks/useAddViewIdToHttpHeaders';
import { useSearchParams } from 'react-router-dom';

const { CareCoordinatorOrderDrawer } = lazily(() =>
  retry(() => import('../../components/CareCoordinators/CareCoordinatorOrderDrawer/CareCoordinatorOrderDrawer')),
);
const { PatientDrawerLazy } = lazily(() => retry(() => import('../../components/PatientDrawer/PatientDrawerLazy')));

export function CareCoordinatorOmniDrawer() {
  useAddViewIdToHttpHeaders();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = useCallback(
    function handleClose() {
      searchParams.delete('commentId');
      searchParams.delete('orderId');
      searchParams.delete('patientId');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const content = (() => {
    const orderId = searchParams.get('orderId');
    const patientId = searchParams.get('patientId');

    if (orderId) return <CareCoordinatorOrderDrawer key={orderId} onClose={handleClose} orderId={orderId} />;
    if (patientId)
      return (
        <PatientDrawerLazy
          key={patientId}
          newOrderButton={<NewReferralButton patientId={patientId} />}
          onClose={handleClose}
          patientId={patientId}
        />
      );

    return null;
  })();

  return (
    <OrderDrawer.Root extendedWidth onClose={handleClose} open={!!content} resizable>
      <Suspense fallback={<OrderDrawerSkeleton onClose={handleClose} />}>{content}</Suspense>
    </OrderDrawer.Root>
  );
}
