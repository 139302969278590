// Providers a wrapper around localStorage/sessionStorage so that calling code
// can avoid repeating which storage, the value's key, and JSON serialization.
export function wrapStorage<Value>(storage: Storage, key: string) {
  return {
    getJSON(): Value | null {
      const value = storage.getItem(key);
      return value !== null ? JSON.parse(value) : null;
    },
    setJSON(value: Value | null) {
      if (value !== null) storage.setItem(key, JSON.stringify(value));
      else storage.removeItem(key);
    },
  };
}
