import { Location, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useRef } from 'react';

const PreviousLocationContext = React.createContext<Location | undefined>({
  key: '',
  pathname: '',
  search: '',
  hash: '',
  state: {},
});

export interface PreviousLocationProviderProps {
  children: React.ReactNode;
}

const PreviousLocationProvider = ({ children }: PreviousLocationProviderProps) => {
  const location = useLocation();
  const prevLocationRef = useRef<Location>();

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  return (
    <PreviousLocationContext.Provider value={prevLocationRef.current}>{children}</PreviousLocationContext.Provider>
  );
};

export default PreviousLocationProvider;

export { PreviousLocationContext };

export const usePreviousLocation = () => useContext(PreviousLocationContext);
