import {
  CreateOrgPatientMutationVariables,
  useCreateOrgPatientMutation,
} from '../../api-clients/falcon-api/graphql/mutations/createOrgPatient.generated';
import { formatDate } from '../../utils/dateUtils';
import {
  IntakePatientFragment,
  IntakePatientSlimFragment,
} from '../../api-clients/falcon-api/graphql/fragments/IntakePatientFragment.generated';
import { SexEnum } from '../../api-clients/falcon-api/graphql/types.generated';
import {
  UpdateOrgPatientMutationVariables,
  useUpdateOrgPatientMutation,
} from '../../api-clients/falcon-api/graphql/mutations/updateOrgPatient.generated';
import { useQueryClient } from '@tanstack/react-query';

export type IntakePatient = IntakePatientFragment | IntakePatientSlimFragment;

export interface PatientFormValues {
  patient: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: Date | null;
    emailAddress: string;
    sex: SexEnum | null;
    homePhoneNumber: string;
    height: string;
    weight: string;
    languagePreferenceCode: string;
  };
  guardian: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  } | null;
  shipping: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
  };
  insurance: {
    primary: {
      carrierId: string;
      planId: string;
      policyNumber: string;
    };
    secondary: {
      carrierId: string;
      planId: string;
      policyNumber: string;
    };
    waiverId: string;
  };
}

export function useCreateOrgPatient() {
  const queryClient = useQueryClient();
  const create = useCreateOrgPatientMutation({
    meta: {
      errorMessage: 'Failed to create patient',
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey: ['getIntakeOrgPatients'] });
      queryClient.invalidateQueries({ queryKey: ['getOrgPatients'] });
      queryClient.invalidateQueries({ queryKey: ['getSingleOrgPatient'] });
    },
  });

  return {
    async mutateAsync(values: PatientFormValues, orgId: string): Promise<IntakePatient | null> {
      if (!values.patient.dateOfBirth) {
        return null;
      }

      const { createOrgPatient: result } = await create.mutateAsync({
        input: {
          patient: {
            ...toCommonOrgPatientInput(values),
            dateOfBirth: formatDate(values.patient.dateOfBirth, 'date'),
          },
          orgId,
        },
      });
      return !result?.error ? (result?.patient ?? null) : null;
    },
    isLoading: create.isPending,
    error:
      (create.error ? 'Something went wrong while creating the patient' : undefined) ??
      create.data?.createOrgPatient?.error?.message,
  };
}

export function useUpdateOrgPatient() {
  const queryClient = useQueryClient();
  const update = useUpdateOrgPatientMutation({
    meta: {
      errorMessage: 'Failed to save org patient',
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey: ['getIntakeOrgPatients'] });
      queryClient.invalidateQueries({ queryKey: ['getOrgPatients'] });
      queryClient.invalidateQueries({ queryKey: ['getSingleOrgPatient'] });
    },
  });

  return {
    async mutateAsync(values: PatientFormValues, orgId: string, patientId: string): Promise<IntakePatient | null> {
      const { updateOrgPatient: result } = await update.mutateAsync({
        input: {
          ...toCommonOrgPatientInput(values),
          patientId,
          orgId,
        },
      });
      return !result?.error ? (result?.patient ?? null) : null;
    },
    isLoading: update.isPending,
    error:
      (update.error ? 'Something went wrong while editing the patient' : undefined) ??
      update.data?.updateOrgPatient?.error?.message,
  };
}

type CommonOrgPatientInputFields = Pick<
  CreateOrgPatientMutationVariables['input']['patient'],
  Extract<keyof CreateOrgPatientMutationVariables['input']['patient'], keyof UpdateOrgPatientMutationVariables['input']>
>;

export function toCommonOrgPatientInput(values: PatientFormValues): CommonOrgPatientInputFields {
  return {
    firstName: values.patient.firstName,
    middleName: values.patient.middleName,
    lastName: values.patient.lastName,
    emailAddress: values.patient.emailAddress,
    sex: values.patient.sex ?? SexEnum.Unknown,
    homePhoneNumber: values.patient.homePhoneNumber ?? '',
    heightInInches: values.patient.height ? Number(values.patient.height) : null,
    weightInPounds: values.patient.weight ? Number(values.patient.weight) : null,
    languagePreference: {
      code: values.patient.languagePreferenceCode,
    },
    guardian: values.guardian,
    address: {
      addressLine1: values.shipping.addressLine1,
      addressLine2: values.shipping.addressLine2,
      city: values.shipping.city,
      state: values.shipping.state,
      postalCode: values.shipping.postalCode,
    },
    primaryInsurance:
      values.insurance.primary.carrierId && values.insurance.primary.policyNumber
        ? {
            insurerId: getInsurerId(values.insurance.primary),
            policyNumber: values.insurance.primary.policyNumber.toUpperCase(),
          }
        : null,
    secondaryInsurance:
      values.insurance.secondary.carrierId && values.insurance.secondary.policyNumber
        ? {
            insurerId: getInsurerId(values.insurance.secondary),
            policyNumber: values.insurance.secondary.policyNumber.toUpperCase(),
          }
        : null,
    waiverId: values.insurance.waiverId ? values.insurance.waiverId : null,
  };
}

export function getInsurerId(insurance: PatientFormValues['insurance']['primary']) {
  // While plan is optional, we need logic to fall back to carrierId. If plan is
  // made required, as intended, then this logic won't be necessary.
  return insurance.planId ? insurance.planId : insurance.carrierId;
}
