import { forwardRef, useEffect, useState } from 'react';
import { logError } from '../utils/logging';
import { useEhrContext } from '../hooks/useEhrContext';
import { useSnackbar } from './Snackbar/SnackbarContext';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Cookie from 'js-cookie';
import FormControl from '@mui/material/FormControl';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import TextField from '@mui/material/TextField';
import useDebounce from '../hooks/useDebounce';

export interface SmartyStreetsPrediction {
  streetLine: string;
  secondary?: string;
  city: string;
  state: string;
  zipcode: string;
  index?: number;
}

interface AddressAutocompleteProps {
  id?: string;
  size?: 'small' | 'medium';
  required?: boolean;
  addressValue: string;
  setAddressValue: (value: string) => void;
  onSelect: (selectedPrediction: SmartyStreetsPrediction | null) => void;
  errorText?: string;
  width?: string;
  placeholder?: string;
  onFocus: () => void;
  onBlur: () => void;
  initialAddressValue?: SmartyStreetsPrediction;
  disabled?: boolean;
}

export const AddressAutocomplete = forwardRef<HTMLElement, AddressAutocompleteProps>(function AddressAutocomplete(
  {
    id,
    onSelect,
    addressValue,
    setAddressValue,
    onBlur,
    onFocus,
    errorText,
    placeholder,
    initialAddressValue,
    disabled,
    required,
    size = 'small',
  },
  ref,
) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialAddressValue || null);
  const { showSnackbarNotification } = useSnackbar();
  const ehrContext = useEhrContext();
  const isEhrMode = !ehrContext.isLoading && !!ehrContext.data;
  const [isDebouncing, setIsDebouncing] = useState(false);
  const websiteKey = import.meta.env.REACT_APP_SMARTY_STREETS_PUBLIC_KEY;
  const credentials = new SmartyStreetsSDK.core.SharedCredentials(websiteKey);

  const [predictions, setPredictions] = useState<SmartyStreetsPrediction[]>([]);
  const loading = open && isDebouncing;

  const [autoCompleteInstance] = useState(SmartyStreetsSDK.core.buildClient.usAutocompletePro(credentials));

  const debounceCallback = (value: boolean) => {
    if (isDebouncing !== value && !isEhrMode) setIsDebouncing(value);
  };

  const debouncedAddressValue = useDebounce(addressValue, 500, debounceCallback);

  const onInputChange = (value: string) => {
    if (!value) {
      setPredictions([]);
    }

    setAddressValue(value);
  };

  useEffect(() => {
    const isDeployPreviewEnv = import.meta.env.REACT_APP_ENV === 'preview';
    const isTestEnv = !!Cookie.get('client-is-playwright');
    // We don't want to make requests to SmartyStreets in EHR mode, deploy preview, or test environments
    // because we don't want to use up our free lookups in those automated environments.
    const isQueryableEnv = !(isEhrMode || isDeployPreviewEnv || isTestEnv);

    if (debouncedAddressValue && isQueryableEnv) {
      const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(debouncedAddressValue);
      // @ts-ignore this has probably never worked. Investigate what original dev was trying to do
      lookup.aggresive = true;
      lookup.maxResults = 5;

      autoCompleteInstance
        .send(lookup)
        .then((result) => {
          const mappedPredictions = result.result.map((suggestion, index) => ({
            ...suggestion,
            index,
          }));
          setPredictions(mappedPredictions);
        })
        .catch((err: Error) => {
          showSnackbarNotification({
            type: 'warning',
            title: 'Unable to auto-suggest addresses.',
            subtitle: 'Please enter the address manually to proceed.',
          });

          logError(err);
        });
    }
  }, [debouncedAddressValue, autoCompleteInstance, showSnackbarNotification, isEhrMode]);

  return (
    <FormControl error={Boolean(errorText)} fullWidth required={required}>
      <Autocomplete
        defaultValue={selectedOption}
        disabled={disabled}
        filterOptions={(option) => option}
        freeSolo
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          return option.streetLine;
        }}
        id={id}
        isOptionEqualToValue={(option, value) => option.index === value.index}
        loading={loading}
        onBlur={onBlur}
        onChange={(_event, value) => {
          if (typeof value === 'string') {
            return;
          }

          setSelectedOption(value);
          onSelect(value);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onFocus={onFocus}
        onOpen={() => {
          setOpen(true);
        }}
        open={open && (Boolean(predictions.length) || loading)}
        options={predictions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorText}
            helperText={errorText}
            id={id ? `${id}-input` : undefined}
            inputProps={{
              ...params.inputProps,
              'aria-label': placeholder,
              autoComplete: 'chrome-off',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <AddressAutocompleteEndAdornment addressValue={addressValue} loading={loading} params={params} />
              ),
            }}
            inputRef={ref}
            onChange={(event) => {
              onInputChange(event.target.value);
            }}
            placeholder={placeholder}
            required={required}
            size={size || 'medium'}
            value={addressValue}
          />
        )}
        renderOption={(props, { streetLine, secondary, zipcode, city, state, index }) => (
          <li
            {...props}
            key={index}
          >{`${streetLine}, ${secondary ? `${secondary},` : ''} ${zipcode} ${city}, ${state}`}</li>
        )}
        size={size}
        value={selectedOption}
      />
    </FormControl>
  );
});

type AddressAutocompleteEndAdornmentProps = {
  params: AutocompleteRenderInputParams;
  loading: boolean;
  addressValue: string;
};

const AddressAutocompleteEndAdornment = ({ loading, params, addressValue }: AddressAutocompleteEndAdornmentProps) => {
  if (loading) {
    return <CircularProgress color="inherit" size={20} />;
  }
  return addressValue && params?.InputProps?.endAdornment ? params.InputProps.endAdornment : null;
};
