export const normalizePhoneNumber = (value: string) => value.replace(/[^\d]/g, '');

export const getFormattedPhoneForDisplay = (str: string) => {
  if (!str) return '';

  let phoneNumber = str;

  phoneNumber = normalizePhoneNumber(phoneNumber);

  // If phone number is longer than 10 digits, take the last 10 digits
  if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.slice(-10);
  }

  if (!phoneNumber.match(/^[0-9]{10}$/)) return str;

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export const formatPhoneNumber = (str: string) => {
  if (!str) return '';

  if (!str.match(/^[0-9]{10}$/)) return str;

  return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6)}`;
};

const validRawPattern = /[2-9]{1}\d{2}[2-9]{1}\d{2}\d{4}/;
const validDisplayPattern = /\([2-9]{1}\d{2}\) [2-9]{1}\d{2}-\d{4}/;

// TODO: update e2e tests to use valid areaCodes
export const validAreaCodes = new Set([
  // https://www.twilio.com/docs/glossary/north-american-area-codes#area-codes-and-the-north-american-numbering-plan
  // https://www.nationalnanpa.com/enas/geoAreaCodeNumberReport.do
  // 200s
  201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216, 217, 218, 219, 220, 223, 224, 225, 226,
  228, 229, 231, 234, 236, 239, 240, 242, 246, 248, 249, 250, 251, 252, 253, 254, 256, 260, 262, 263, 264, 267, 268,
  269, 270, 272, 274, 276, 279, 281, 283, 284, 289,
  // 300s
  301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 323, 325, 326,
  329, 330, 331, 332, 334, 336, 337, 339, 340, 341, 343, 345, 346, 347, 350, 351, 352, 354, 360, 361, 363, 364, 365,
  367, 368, 369, 380, 385, 386,
  // 400s
  401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 412, 413, 414, 415, 416, 417, 418, 419, 423, 424, 425, 428, 430,
  431, 432, 434, 435, 437, 438, 440, 441, 442, 443, 445, 447, 448, 450, 458, 463, 464, 468, 469, 470, 472, 473, 474,
  475, 478, 479, 480, 484,
  // 500s
  501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 512, 513, 514, 515, 516, 517, 518, 519, 520, 530, 531, 534, 539,
  540, 541, 548, 551, 557, 559, 561, 562, 563, 564, 567, 570, 571, 572, 573, 574, 575, 579, 580, 581, 582, 584, 585,
  586, 587,
  // 600s
  601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 612, 613, 614, 615, 616, 617, 618, 619, 620, 623, 626, 628, 629,
  630, 631, 636, 639, 640, 641, 646, 647, 649, 650, 651, 656, 657, 658, 659, 660, 661, 662, 664, 667, 669, 670, 671,
  672, 678, 680, 681, 682, 683, 684, 689,
  // 700s
  701, 702, 703, 704, 705, 706, 707, 708, 709, 712, 713, 714, 715, 716, 717, 718, 719, 720, 721, 724, 725, 726, 727,
  728, 731, 732, 734, 737, 740, 742, 743, 747, 753, 754, 757, 758, 760, 762, 763, 765, 767, 769, 770, 771, 772, 773,
  774, 775, 778, 779, 780, 781, 782, 784, 785, 786, 787,
  // 800s
  801, 802, 803, 804, 805, 806, 807, 808, 809, 810, 812, 813, 814, 815, 816, 817, 818, 819, 820, 825, 826, 828, 829,
  830, 831, 832, 835, 838, 839, 840, 843, 845, 847, 848, 849, 850, 854, 856, 857, 858, 859, 860, 861, 862, 863, 864,
  865, 867, 868, 869, 870, 872, 873, 876, 878,
  // 900s
  901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 912, 913, 914, 915, 916, 917, 918, 919, 920, 925, 928, 929, 930,
  931, 934, 936, 937, 938, 939, 940, 941, 943, 945, 947, 948, 949, 951, 952, 954, 956, 959, 970, 971, 972, 973, 978,
  979, 980, 983, 984, 985, 986, 989,
  // https://www.nationalnanpa.com/enas/nonGeoNpaServiceReport.do
  500, 521, 522, 523, 524, 525, 526, 527, 528, 529, 533, 544, 566, 577, 588, 600, 622, 633, 700, 710, 800, 833, 844,
  855, 866, 877, 888, 900,
]);

// We use `(777) 777-7777` as an escape hatch for not sending SMS as part of the
// request provider signature flow during testing
if (import.meta.env.REACT_APP_ENV !== 'production') validAreaCodes.add(777);

export function validatePhone(label: string) {
  return (value: string) => {
    if (!value) return undefined;

    const isValidRawPattern = validRawPattern.test(value);
    const isValidDisplayPattern = validDisplayPattern.test(value);

    if (!isValidRawPattern && !isValidDisplayPattern)
      return `${label} must be 10 digits long and not start with 0 or 1`;

    const areaCode = Number(isValidRawPattern ? value.substring(0, 3) : value.substring(1, 4));

    if (!validAreaCodes.has(areaCode)) return `${label} must have a valid area code`;

    return undefined;
  };
}

export function getHomeAndMobilePhoneNumbers({
  homePhoneNumber,
  mobilePhoneNumber,
}: {
  homePhoneNumber?: string | null | undefined;
  mobilePhoneNumber?: string | null | undefined;
}) {
  const numbers: Array<string> = [];
  if (homePhoneNumber) {
    numbers.push(`${formatPhoneNumber(homePhoneNumber)}(h)`);
  }
  if (mobilePhoneNumber) {
    numbers.push(`${formatPhoneNumber(mobilePhoneNumber)}(m)`);
  }

  return numbers.length ? numbers.join(',') : '-';
}
