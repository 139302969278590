import { Colors } from './colors';

const theme = {
  colors: {
    background: Colors.orangeWhite,
    backgroundAlt: Colors.cararra,
    backgroundLight: Colors.cararraLight,
    backgroundInv: Colors.creamBrulee,
    backgroundDark: Colors.bigStone,
    black: Colors.mirage,
    border: Colors.zumthor,
    midGrayText: Colors.zumthor,
    darkGrayBackground: Colors.altGray,
    borderAlt: Colors.funBlue,
    borderFocus: Colors.bigStone,
    borderError: Colors.red,
    buttonPrimaryFill: Colors.neonGreen,
    buttonPrimaryText: Colors.white,
    buttonSecondaryFill: Colors.waikawaGray,
    buttonTertiaryFill: Colors.eggSour,
    buttonDisabledFill: Colors.neonGreen,
    secondaryButtonDisabledFill: Colors.baliHai,
    buttonDisabledText: Colors.geyser,
    buttonNeonGreenFill: Colors.neonGreen,
    buttonHoverGreen: Colors.darkGreen,
    buttonNavyFill: Colors.navy,
    selectBorder: Colors.daintree,
    darkText: Colors.daintree,
    darkBackground: Colors.daintree,
    colorPrimary: Colors.daintree,
    colorSecondary: Colors.kournikova,
    headerNavigationFill: Colors.daintree,
    inputBorderError: Colors.red,
    inputBorderPositive: Colors.jungleMist,
    inputFill: Colors.white,
    inputFillDisabled: Colors.white,
    inputFillError: Colors.white,
    inputTextDisabled: Colors.mirage,
    linkActive: Colors.funBlue,
    linkHover: Colors.funBlue,
    linkText: Colors.funBlue,
    linkVisited: Colors.funBlue,
    mono100: Colors.white,
    mono200: Colors.iron,
    mono400: Colors.paleSky,
    mono500: Colors.athensGray,
    mono550: Colors.lightGray,
    mono600: Colors.grayChateau,
    mono700: Colors.casper,
    mono800: Colors.paleSky,
    mono1000: Colors.black,
    mono1100: Colors.gray,
    mono1200: Colors.elephantColor,
    mono1300: Colors.stormGray,
    mono1400: Colors.fogColor,
    mono1500: Colors.nevada,
    monoDarkGray: Colors.monoDarkGray,
    negative: Colors.red,
    negative100: Colors.outrageousOrange,
    positive: Colors.jungleMist,
    positive500: Colors.gulfStream,
    positive700: Colors.shadowGreen,
    positive800: Colors.darkOrange,
    positive900: Colors.darkGreen,
    primary: Colors.white,
    warning: Colors.kournikova,
    white: Colors.white,
    textFading: Colors.paleSky,
    textUnderlineBackground: Colors.funBlue,
    sliderArrowBackground: Colors.funBlue,
    cardLinkText: Colors.funBlue,
    fadingOrageBackground: Colors.fadingOrange,
    whiteOrangeBackground: Colors.orangeWhite,
    filterPrimaryColor: Colors.pantone,
    filterSecondaryColor: Colors.daintree,
    filterHoverPrimaryColor: Colors.geyser,
    filterHoverSecondaryColor: Colors.astronaut,
    productCardButtonBackground: Colors.mystic,
    carouselHoverColor: Colors.cello,
    mysticIconBackground: Colors.mystic,
    darkPrimaryButtonColor: Colors.lightCello,
    errorBannerBackground: Colors.peachSchnapps,
    lightGrayBackground: Colors.lightGray,
    tabNavBorder: Colors.neonGreen,
    skyLightBackground: Colors.skyLight,
    headerButtonsBorder: Colors.neonGreen,
    graphFillColor: Colors.hippieBlue,
    darkNavyBackground: Colors.blueDianne,
    secondaryBoxShadow: Colors.transparentBlack,
    tertiaryBoxShadow: Colors.transparentBlackSecondary,
    primaryButtonActiveFill: Colors.mountainMeadow,
    neonGreenText: Colors.neonGreen,
    darkGreen: Colors.cuttySark,
    transparentGreen: Colors.swansDown,
    green: Colors.green,
    clinicalTag: Colors.casal,
    clinicalTagBackGround: Colors.casalSecondary,
    errorRed: Colors.red,
    porcelain: Colors.porcelain,
    activeLinkColor: Colors.tropicalRainForest,
    desertStorm: Colors.desertStorm,
    imageTabsBorderPrimary: Colors.casal,
    imageTabsBorderSecondary: Colors.fadingGray,
    imageTabsBorderHoover: Colors.fadingCascal,
    colorPrimaryLight: Colors.daintreeLight,
    colorPrimaryMedium: Colors.daintreeMedium,
    borderLight: Colors.zumthorLight,
    borderLighter: Colors.zumthorLighter,
    darkGray: Colors.darkGray,
    recommendationBorder: Colors.daintreeBorder,
    warningRed: Colors.warningRed,
    fadingGray: Colors.nevada,
    osloGrayText: Colors.osloGray,
    stormBackground: Colors.lighterStorm,
    formsGreen: Colors.formsGreen,
    borderSelected: Colors.borderSelected,
    borderDeselected: Colors.borderDeselected,
    lightGreyText: Colors.lightGreyText,
    grayContainer: Colors.grayContainer,
    newBackground: Colors.newBackground,
    lighterGreyText: Colors.lighterGreyText,
    footerBackground: Colors.daintree,
    intakeDivider: Colors.intakeDivider,
    textPlaceholder: Colors.textPlaceholder,
    internalUseWarning: Colors.internalUseWarning,
    muiFocusedBorder: Colors.muiFocusedBorder,
    muiError: Colors.muiError,
    disabledInput: Colors.disabledInput,
    disabledInputText: Colors.disabledInputText,
    inputBorder: Colors.inputBorder,
    muiGreen: Colors.muiGreen,
    disabledRadio: Colors.disabledRadio,
    label: Colors.label,
    lightViolet: Colors.lightViolet,
    lightDuskBlue: Colors.lightDuskBlue,
    dullGray: Colors.dullGray,
    gold: Colors.gold,
    primaryHover: Colors.primaryHover,
    secondaryHover: Colors.secondaryHover,
    primaryErrorHover: Colors.primaryErrorHover,
    secondaryErrorHover: Colors.secondaryErrorHover,
    userCircle: Colors.userCircle,
  } as const,
};

interface TomorrowTheme {
  colors: typeof theme.colors;
}

export default theme as TomorrowTheme;
