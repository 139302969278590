import { SxProps, Theme } from '@mui/material';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadLineIcon from 'remixicon-react/DownloadLineIcon';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useRef, useState } from 'react';

interface TablePaginationProviderProps {
  count: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  page: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[] | undefined;
  onDataDownload?: () => void;
}

export function TablePaginationProvider(props: TablePaginationProviderProps) {
  const { onDataDownload, count, onChangePage, onChangeRowsPerPage, page, rowsPerPage, rowsPerPageOptions } = props;
  const paginationMeasurmentRef = useRef<HTMLSpanElement | null>(null);
  const [paginationFieldWidth, setPaginationFieldWidth] = useState(0);
  const [pageFieldValue, setPageFieldValue] = useState((page || 0) + 1);
  const pageTotals = Math.ceil(count / (rowsPerPage || 1)) || 1;

  useEffect(() => {
    const target: HTMLSpanElement | null = paginationMeasurmentRef.current;
    if (target !== null) {
      target.style.display = 'inline';
      setPaginationFieldWidth(target.offsetWidth);
      target.style.display = 'none';
    }

    setPageFieldValue((page || 0) + 1);
  }, [page]);

  const handlePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!isNaN(Number(value))) {
      setPageFieldValue(Number(value));
    }
  };

  const handlePageFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onChangePage) {
      onChangePage(e, pageFieldValue - 1);
    }
  };

  const labelDisplayedRows = () => (
    <>
      <Box
        component="input"
        onBlur={handlePageFieldBlur}
        onChange={handlePageChange}
        sx={{ width: `${paginationFieldWidth}px`, ...paginationInputStyles }}
        value={pageFieldValue}
      />
      {`of ${pageTotals}`}
    </>
  );

  const handleNextPage = (e: unknown) => {
    if (onChangePage) {
      onChangePage(e, page + 1);
    }
  };

  const handlePrevPage = (e: unknown) => {
    if (onChangePage) {
      onChangePage(e, page - 1);
    }
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(e);
    }
  };

  return (
    <Box
      sx={{
        background: 'rgba(240, 241, 237, 0.5);',
        padding: '3px',
      }}
    >
      <Grid container direction="row">
        <Grid item sm={5} xs={6}>
          {onDataDownload && (
            <Button
              onClick={onDataDownload}
              startIcon={<DownloadLineIcon size={14} />}
              sx={{
                border: 'none',
                borderRadius: 'unset',
                background: 'transparent',
                width: 'auto',
                margin: '0 10px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10px',
                letterSpacing: '0.04em',
                textTransform: 'uppercase',
                color: '#667B85',
              }}
            >{`${count} RESULTS`}</Button>
          )}
        </Grid>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          item
          justifyContent="center"
          sm={2}
          sx={gridStyles}
          xs={6}
        >
          <IconButton disabled={page === 0} onClick={handlePrevPage} size="small">
            <ArrowLeftSLineIcon size={20} />
          </IconButton>
          {labelDisplayedRows()}
          <IconButton disabled={page === pageTotals - 1} onClick={handleNextPage} size="small">
            <ArrowRightSLineIcon size={20} />
          </IconButton>
        </Grid>
        <Grid
          alignContent="flex-end"
          alignItems="center"
          container
          item
          justifyContent="flex-end"
          sm={5}
          sx={gridStyles}
          xs={12}
        >
          {rowsPerPageOptions && rowsPerPage && (
            <>
              <Box component="span" margin="10px 6px 10px 10px">
                Rows per page
              </Box>
              <Box
                component="select"
                onChange={handleRowsPerPageChange}
                sx={{ width: 'max-content', maxWidth: 'max-content', ...paginationInputStyles }}
              >
                {rowsPerPageOptions?.map((el, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={`rows-per-page-${index}`} value={el}>
                    {el}
                  </option>
                ))}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <span ref={paginationMeasurmentRef} style={{ padding: '5px', display: 'none', width: 'max-content' }}>
        {(page || 0) + 1}
      </span>
    </Box>
  );
}

const gridStyles: SxProps<Theme> = {
  display: 'flex',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
  color: '#667B85',
  whiteSpace: 'nowrap',
  justifyContent: (theme) => (theme.breakpoints.down('sm') ? 'flex-end' : undefined),
};

const paginationInputStyles: SxProps = {
  margin: '0 5px',
  background: '#FFFFFF',
  border: '1px solid rgba(0, 35, 52, 0.15)',
  boxSizing: 'border-box',
  borderRadius: '3px',
  maxWidth: '40px',
};
