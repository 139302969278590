import * as Types from '../types.generated.js';

import { IntakePatientSlimFragmentDoc } from '../fragments/IntakePatientFragment.generated.js';
import {
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
} from '../fragments/PatientFragment.generated.js';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type UpdateOrgPatientMutationVariables = Types.Exact<{
  input: Types.UpdateOrgPatientInput;
}>;

export type UpdateOrgPatientMutation = {
  __typename?: 'Mutation';
  updateOrgPatient?: {
    __typename?: 'UpdateOrgPatientMutation';
    patient?: {
      __typename?: 'PatientSlimType';
      id: string;
      emailAddress?: string | null;
      dateOfBirth?: string | null;
      fullName?: string | null;
      firstName: string;
      middleName: string;
      lastName: string;
      sex?: Types.SexEnum | null;
      heightInInches?: number | null;
      weightInPounds?: number | null;
      homePhoneNumber?: string | null;
      shippingAddress?: {
        __typename?: 'AddressType';
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        id: string;
      } | null;
      guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
      languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
      primaryCareProvider?: {
        __typename?: 'ProviderType';
        id: string;
        firstName: string;
        lastName: string;
        npi: string;
      } | null;
      primaryCareProviderContact?: {
        __typename?: 'ContactType';
        id: string;
        emailAddress?: string | null;
        phoneNumber?: string | null;
        smsNumber?: string | null;
        faxNumber?: string | null;
      } | null;
      patientWaiver?: {
        __typename?: 'PatientWaiverType';
        waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
      } | null;
      primaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategory?: string | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
      secondaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategory?: string | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
      tertiaryInsurance?: {
        __typename?: 'PatientInsuranceType';
        status: Types.AppPatientInsuranceStatusChoices;
        policyNumber: string;
        insurer: {
          __typename?: 'InsurerType';
          id: string;
          insurerCategory?: string | null;
          name: string;
          display: string;
          requiresTitleXix: boolean;
          parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
        };
      } | null;
    } | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const UpdateOrgPatientDocument = `
    mutation updateOrgPatient($input: UpdateOrgPatientInput!) {
  updateOrgPatient(input: $input) {
    patient {
      ...IntakePatientSlimFragment
    }
    error {
      id
      type
      message
    }
  }
}
    ${IntakePatientSlimFragmentDoc}
${PatientSlimFragmentDoc}
${PatientSlimSansAddressFragmentDoc}
${PatientInsuranceFragmentDoc}
${AddressFragmentDoc}`;

export const useUpdateOrgPatientMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateOrgPatientMutation, TError, UpdateOrgPatientMutationVariables, TContext>,
) => {
  return useMutation<UpdateOrgPatientMutation, TError, UpdateOrgPatientMutationVariables, TContext>({
    mutationKey: ['updateOrgPatient'],
    mutationFn: (variables?: UpdateOrgPatientMutationVariables) =>
      fetcher<UpdateOrgPatientMutation, UpdateOrgPatientMutationVariables>(UpdateOrgPatientDocument, variables)(),
    ...options,
  });
};

useUpdateOrgPatientMutation.getKey = () => ['updateOrgPatient'];

useUpdateOrgPatientMutation.fetcher = (
  variables: UpdateOrgPatientMutationVariables,
  options?: RequestInit['headers'],
) => fetcher<UpdateOrgPatientMutation, UpdateOrgPatientMutationVariables>(UpdateOrgPatientDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateOrgPatientMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateOrgPatient })
 *   )
 * })
 */
export const mockUpdateOrgPatientMutation = (
  resolver: Parameters<typeof graphql.mutation<UpdateOrgPatientMutation, UpdateOrgPatientMutationVariables>>[1],
) => graphql.mutation<UpdateOrgPatientMutation, UpdateOrgPatientMutationVariables>('updateOrgPatient', resolver);
