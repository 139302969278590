import { DiagnosisCode } from '../../../../components/DiagnosisCodeAutocomplete';
import { HcpcsCode } from '../../../../components/HcpcsCodeAutocomplete';
import { SupplierFaxIntakeFormRadioOption, SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';

export interface OrderStepFormValues {
  selectedFormOption: SupplierFaxIntakeFormRadioOption;
  selectedOrderId?: string;
  newOrderFormValues: {
    referralDate: Date | null;
    categoryId: string | null;
    subcategoryId: string | null;
    provider: {
      id?: string;
      npi: string | null;
      firstName?: string;
      lastName?: string;
      phoneNumber?: string;
      fax?: string;
    };
    diagnoses: {
      resultId: string | null;
      dxCode: DiagnosisCode | null;
    }[];
    lineItems: {
      resultId: string | null;
      hcpcs: HcpcsCode | null;
      quantity: string | null;
    }[];
  };
}

export function toDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): OrderStepFormValues {
  return {
    selectedFormOption: null,
    selectedOrderId: undefined,
    newOrderFormValues: {
      referralDate: orderSeed.extractionResultsSet?.referralDateCorrected
        ? new Date(orderSeed.extractionResultsSet.referralDateCorrected)
        : null,
      categoryId: null,
      subcategoryId: orderSeed.extractionResultsSet?.subcategoryIdCorrected || null,
      provider: {
        npi: orderSeed.extractionResultsSet?.providerNpiCorrected || null,
      },
      diagnoses:
        orderSeed?.extractionResultsSet?.dxCodes?.flatMap((item) =>
          item.diagnosisCodeCorrected !== null
            ? [
                {
                  resultId: item.id,
                  dxCode: {
                    code: item.diagnosisCodeCorrected ?? '',
                    description: item.diagnosisCodeCorrected ?? '',
                    id: item.diagnosisCodeCorrected ?? '',
                  },
                },
              ]
            : [],
        ) || [],
      lineItems:
        orderSeed.extractionResultsSet?.lineItems.flatMap((item) =>
          item.hcpcsCodeCorrected !== null
            ? [
                {
                  resultId: item.id,
                  hcpcs: { code: item.hcpcsCodeCorrected ?? '', id: item.hcpcsCodeCorrected ?? '' },
                  quantity: item.quantityCorrected ? String(item.quantityCorrected) : '',
                },
              ]
            : [],
        ) || [],
    },
  };
}
