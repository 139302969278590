import { ColorsV2 } from '@tomorrow/ui/theme';
import { intervalToDuration, milliseconds } from 'date-fns';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import useInterval from '../../../../hooks/useInterval';

const calculateFriendlyTimeRemaining = (timestamp) => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: new Date(),
    end: timestamp,
  });

  if (!hours && !minutes) {
    return `${seconds}s`;
  }

  return `${hours ? `${hours}h` : ''}${minutes ? ` ${minutes}m` : ''}`;
};

type OrderExpirationLabelProps = {
  timestamp: Date;
  expiredContent?: React.ReactNode;
  hideIcon?: boolean;
};

const OrderExpirationLabel = ({
  timestamp,
  hideIcon = false,
  expiredContent = 'Expired',
}: OrderExpirationLabelProps) => {
  const [friendlyTimeRemaining, setFriendlyTimeRemaining] = useState(() => calculateFriendlyTimeRemaining(timestamp));

  useInterval(() => setFriendlyTimeRemaining(calculateFriendlyTimeRemaining(timestamp)), milliseconds({ seconds: 1 }));

  return (
    <Box alignItems="center" color={ColorsV2.red} display="flex">
      {hideIcon ? null : <TimeLineIcon size={15} />}
      <Box component="span" marginLeft="5px">
        {timestamp <= new Date() ? expiredContent : friendlyTimeRemaining}
      </Box>
    </Box>
  );
};

export default OrderExpirationLabel;
