import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type SendFaxbackCoversheetMutationVariables = Types.Exact<{
  input: Types.FaxbackCoversheetInput;
}>;

export type SendFaxbackCoversheetMutation = {
  __typename?: 'Mutation';
  sendFaxbackCoversheet?: {
    __typename?: 'SendFaxbackCoversheetMutation';
    pdfUrl?: string | null;
    transmission?: { __typename?: 'FaxTransmissionType'; createdAt: string; updatedAt: string; id: string } | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; message: string } | null;
  } | null;
};

export const SendFaxbackCoversheetDocument = `
    mutation sendFaxbackCoversheet($input: FaxbackCoversheetInput!) {
  sendFaxbackCoversheet(inputData: $input) {
    transmission {
      createdAt
      updatedAt
      id
    }
    pdfUrl
    error {
      id
      message
    }
  }
}
    `;

export const useSendFaxbackCoversheetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SendFaxbackCoversheetMutation, TError, SendFaxbackCoversheetMutationVariables, TContext>,
) => {
  return useMutation<SendFaxbackCoversheetMutation, TError, SendFaxbackCoversheetMutationVariables, TContext>({
    mutationKey: ['sendFaxbackCoversheet'],
    mutationFn: (variables?: SendFaxbackCoversheetMutationVariables) =>
      fetcher<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
        SendFaxbackCoversheetDocument,
        variables,
      )(),
    ...options,
  });
};

useSendFaxbackCoversheetMutation.getKey = () => ['sendFaxbackCoversheet'];

useSendFaxbackCoversheetMutation.fetcher = (
  variables: SendFaxbackCoversheetMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
    SendFaxbackCoversheetDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendFaxbackCoversheetMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ sendFaxbackCoversheet })
 *   )
 * })
 */
export const mockSendFaxbackCoversheetMutation = (
  resolver: Parameters<
    typeof graphql.mutation<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>
  >[1],
) =>
  graphql.mutation<SendFaxbackCoversheetMutation, SendFaxbackCoversheetMutationVariables>(
    'sendFaxbackCoversheet',
    resolver,
  );
