import { getOrderDisplayStatusProps } from './OrderStatusBadge.helpers';
import { OrderDisplayStatus } from '../../api-clients/falcon-api/hooks/useOrderStatuses';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import StatusBadge from './StatusBadge';
import Typography from '@mui/material/Typography';

export const OrderStatusBadge = React.forwardRef(
  (
    {
      label,
      status,
      subLabel,
      sx,
      ...rest
    }: {
      label?: React.ReactNode;
      status: OrderDisplayStatus | null | undefined;
      subLabel?: string;
      sx?: SxProps;
    },
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    const display = status ? getOrderDisplayStatusProps(status) : undefined;

    return (
      <Box ref={ref} component="span" sx={sx} {...rest}>
        <StatusBadge color={display?.badge?.color} icon={display?.badge?.icon} label={label || status} />
        {subLabel && (
          <Box paddingTop={1}>
            <Typography>
              <Box color={display?.subLabelColor ?? display?.badge?.color} component="span">
                {subLabel}
              </Box>
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);
