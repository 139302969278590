import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetLanguagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetLanguagesQuery = {
  __typename?: 'Query';
  languages?: Array<{ __typename?: 'LanguageType'; name: string; code: string } | null> | null;
};

export const GetLanguagesDocument = `
    query getLanguages {
  languages {
    name
    code
  }
}
    `;

export const useGetLanguagesQuery = <TData = GetLanguagesQuery, TError = unknown>(
  variables?: GetLanguagesQueryVariables,
  options?: Omit<UseQueryOptions<GetLanguagesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetLanguagesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetLanguagesQuery, TError, TData>({
    queryKey: variables === undefined ? ['getLanguages'] : ['getLanguages', variables],
    queryFn: fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, variables),
    ...options,
  });
};

useGetLanguagesQuery.getKey = (variables?: GetLanguagesQueryVariables) =>
  variables === undefined ? ['getLanguages'] : ['getLanguages', variables];

export const useInfiniteGetLanguagesQuery = <TData = InfiniteData<GetLanguagesQuery>, TError = unknown>(
  variables: GetLanguagesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetLanguagesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetLanguagesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetLanguagesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined ? ['getLanguages.infinite'] : ['getLanguages.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetLanguagesQuery.getKey = (variables?: GetLanguagesQueryVariables) =>
  variables === undefined ? ['getLanguages.infinite'] : ['getLanguages.infinite', variables];

useGetLanguagesQuery.fetcher = (variables?: GetLanguagesQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLanguagesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ languages })
 *   )
 * })
 */
export const mockGetLanguagesQuery = (
  resolver: Parameters<typeof graphql.query<GetLanguagesQuery, GetLanguagesQueryVariables>>[1],
) => graphql.query<GetLanguagesQuery, GetLanguagesQueryVariables>('getLanguages', resolver);
