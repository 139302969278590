import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@tomorrow/ui';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { IntakePatient } from './PatientModal/PatientForm.helpers';
import { PatientDetailCard } from './PatientDetailCard';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

export const PatientCarousel = ({
  patients,
  selectedPatientId,
  onPatientSelect,
  disabled,
}: {
  patients: IntakePatient[];
  selectedPatientId?: string;
  onPatientSelect: (patient: IntakePatient | null) => void;
  disabled: boolean;
}) => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [selectedCarouselSlideIndex, setSelectedCarouselSlideIndex] = useState<number>(0);

  const onCarouselSlideSelect = useCallback(() => {
    if (!carouselApi) {
      return;
    }

    const selectedIndex = carouselApi.selectedScrollSnap();

    setSelectedCarouselSlideIndex(selectedIndex);
    onPatientSelect(patients[selectedIndex] ?? null);
  }, [carouselApi, onPatientSelect, patients]);

  const onCarouselSlideClick = (index: number) => () => {
    if (!carouselApi) return;

    carouselApi.scrollTo(index);
  };

  useEffect(() => {
    if (!carouselApi) {
      return undefined;
    }

    carouselApi.on('select', onCarouselSlideSelect);

    return () => {
      carouselApi?.off('select', onCarouselSlideSelect);
    };
  }, [carouselApi, onCarouselSlideSelect]);

  useEffect(() => {
    if (!carouselApi) return;

    carouselApi.reInit({ startIndex: patients.findIndex((patient) => patient.id === selectedPatientId) ?? 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselApi]);

  useEffect(() => {
    if (!disabled) {
      onCarouselSlideSelect();
    }
  }, [disabled, onCarouselSlideSelect]);

  return (
    <Carousel disabled={disabled} setApi={setCarouselApi}>
      <CarouselContent>
        {patients.map((patient, index) => {
          const selected = selectedCarouselSlideIndex === index && !disabled;

          return (
            <CarouselItem key={patient.id} sx={{ flexBasis: 'auto' }}>
              <Box
                component="button"
                disabled={disabled}
                onClick={onCarouselSlideClick(index)}
                sx={{
                  width: '100%',
                  bgcolor: 'inherit',
                  pointerEvents: disabled ? 'none' : 'auto',
                  color: 'inherit',
                  border: 'none',
                  padding: '0',
                  outline: 'inherit',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  textAlign: 'inherit',
                  lineHeight: 'inherit',
                }}
                type="button"
              >
                <Box
                  sx={{
                    maxWidth: '380px',
                    borderRadius: 'inherit',
                    border: selected ? `1px solid ${ColorsV2.green}` : '1px solid transparent',
                    bgcolor: selected ? '#F7FFFC' : '#F8F8F8',
                    transition: 'border-color 0.5s, background-color 0.3s',
                    opacity: disabled ? 0.5 : 1,
                  }}
                >
                  <PatientDetailCard compact={false} patient={patient} sx={{ padding: '20px' }} />
                </Box>
              </Box>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext sx={{ right: 0 }} />
      <CarouselDots />
    </Carousel>
  );
};
