import { Outlet, useOutletContext } from 'react-router-dom';
import { useOrgIdParam } from '../hooks/useOrgIdParam';
import { useUserLocalStorage } from '../hooks/useUserStorage';

export function OrgLayout() {
  const context = useOutletContext();
  const orgId = useOrgIdParam() ?? '';

  useSyncOrgIdToUserLocalStorage(orgId);

  return <Outlet context={context} />;
}

function useSyncOrgIdToUserLocalStorage(orgId: string) {
  const { data: userLocalStorageData, update: updateUserLocalStorageData } = useUserLocalStorage();
  const lastUsedOrgId = userLocalStorageData?.last_used_org_id;

  if (updateUserLocalStorageData && orgId && orgId !== lastUsedOrgId) {
    updateUserLocalStorageData({ last_used_org_id: orgId });
  }
}
