import { logError } from '../utils/logging';

export function retry<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 500): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          logError(error, { fn });
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
