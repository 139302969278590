import { SupplierIntakeFaxOrderSeed } from '../SupplierFaxOrderWizard.utils';

export interface PatientStepFormValues {
  selectedFormOption: SupplierIntakeFaxOrderSeed['selectedPatientFormOption'];
  patient: SupplierIntakeFaxOrderSeed['selectedPatient'];
}

export function toDefaultFormValues(orderSeed: SupplierIntakeFaxOrderSeed): PatientStepFormValues {
  return {
    selectedFormOption: orderSeed.selectedPatientFormOption ?? null,
    patient: orderSeed.selectedPatient ?? null,
  };
}
