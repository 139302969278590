import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner() {
  return (
    <Box display="block" height="84px" width="84px">
      <CircularProgress size="100%" />
    </Box>
  );
}
