import { AppOrderSeedPrimaryExtractorChoices } from '../../api-clients/falcon-api/graphql/types.generated';
import { logAction } from '../../utils/logging';
import { useCallback, useEffect, useRef } from 'react';
import { useUserLocalStorage } from '../../hooks/useUserStorage';
import { v4 as uuidv4 } from 'uuid';

export type StepNavActionType = 'wizard_load' | 'next' | 'prev' | 'jump';
export type ExitNavActionType = 'exit' | 'end' | 'reject';

export const logFaxWizardAction = (
  name: string,
  payload: {
    stepPath: string;
    documentExtractionJobId: string;
    navActionType: StepNavActionType | ExitNavActionType | null;
    wizardMode: string;
    extractor: AppOrderSeedPrimaryExtractorChoices | undefined;
    traceId: string;
    meta?: { [key: string]: unknown };
  },
) => logAction(name, payload);

export function useTrackStepNavigation(
  documentExtractionJobId: string,
  wizardMode: string,
  extractor: AppOrderSeedPrimaryExtractorChoices | undefined,
  stepPath: string,
) {
  const {
    data: userStorageData,
    isLoading: isUserStorageDataLoading,
    update: updateUserStorageData,
  } = useUserLocalStorage();

  const traceIdRef = useRef(uuidv4());

  if (documentExtractionJobId && userStorageData) {
    if (userStorageData.fax_order_references && documentExtractionJobId in userStorageData.fax_order_references) {
      traceIdRef.current = userStorageData.fax_order_references[documentExtractionJobId];
    } else {
      updateUserStorageData?.({
        fax_order_references: {
          ...userStorageData.fax_order_references,
          [documentExtractionJobId]: traceIdRef.current,
        },
      });
    }
  }

  const startDateRef = useRef(new Date());
  const navActionTypeRef = useRef<StepNavActionType | ExitNavActionType | null>('wizard_load');
  const stepPathRef = useRef(stepPath);
  const documentExtractionJobIdRef = useRef(documentExtractionJobId);
  const isUserStorageDataLoadingRef = useRef(isUserStorageDataLoading);
  stepPathRef.current = stepPath;
  isUserStorageDataLoadingRef.current = isUserStorageDataLoading;
  documentExtractionJobIdRef.current = documentExtractionJobId;

  useEffect(
    function trackNavigationOnMount() {
      if (isUserStorageDataLoadingRef.current) throw new Error('Ensure user storage data is loaded before tracking');
      const navActionType = navActionTypeRef.current;

      logFaxWizardAction(`fax_order_intake_step_entry`, {
        stepPath,
        documentExtractionJobId: documentExtractionJobIdRef.current,
        navActionType,
        wizardMode,
        extractor,
        traceId: traceIdRef.current,
      });

      startDateRef.current = new Date();
      navActionTypeRef.current = null;
    },
    [extractor, stepPath, wizardMode],
  );

  useEffect(
    function trackNavigationOnUnmount() {
      return function trackNavigationOnUnmount() {
        if (isUserStorageDataLoadingRef.current) throw new Error('Ensure user storage data is loaded before tracking');

        const navActionType = navActionTypeRef.current;
        const stepPath = stepPathRef.current;

        logFaxWizardAction(`fax_order_intake_exit`, {
          stepPath,
          documentExtractionJobId: documentExtractionJobIdRef.current,
          navActionType,
          wizardMode,
          extractor,
          traceId: traceIdRef.current,
        });

        startDateRef.current = new Date();
        navActionTypeRef.current = null;
      };
    },
    [extractor, wizardMode],
  );

  return {
    setLogNavActionTypeState: useCallback(function setLogNavActionTypeState(
      navActionType: StepNavActionType | ExitNavActionType,
    ) {
      navActionTypeRef.current = navActionType;
    }, []),
    traceId: traceIdRef.current,
  };
}
