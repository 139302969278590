import { BoxProps, darken, SxProps } from '@mui/material';
import { ColorsV2 } from '../theme';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
  disabled?: boolean;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  disabled: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<HTMLDivElement, BoxProps & CarouselProps>(
  ({ orientation = 'horizontal', opts, setApi, plugins, disabled, sx, children, ...props }, ref) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    useEffect(() => {
      if (disabled && api) {
        api?.reInit({ watchDrag: false });
      } else {
        api?.reInit({ watchDrag: true });
      }
    }, [api, disabled]);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return undefined;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          disabled: !!disabled,
        }}
      >
        <Box
          ref={ref}
          aria-roledescription="carousel"
          onKeyDownCapture={handleKeyDown}
          role="region"
          sx={{
            position: 'relative',
            ...sx,
          }}
          {...props}
        >
          {children}
        </Box>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<HTMLDivElement, BoxProps & { wrapperSx?: SxProps }>(
  ({ wrapperSx, sx, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel();

    return (
      <Box
        ref={carouselRef}
        sx={{
          overflow: 'hidden',
          ...wrapperSx,
        }}
      >
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: orientation === 'horizontal' ? 'row' : 'column',
            ...sx,
          }}
          {...props}
        />
      </Box>
    );
  },
);
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<HTMLDivElement, BoxProps>(({ sx, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      aria-roledescription="slide"
      role="group"
      sx={{
        minWidth: 0,
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: '100%',
        ...sx,
      }}
      {...props}
    />
  );
});
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof IconButton>>(
  ({ sx, size = 'small', ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev, disabled } = useCarousel();

    return (
      <Fade in={canScrollPrev && !disabled}>
        <Tooltip disableInteractive title="Previous">
          <Box
            component="span"
            sx={{
              position: 'absolute',
              left: orientation === 'horizontal' ? '-45px' : '50%',
              top: orientation === 'horizontal' ? '50%' : '-45px',
              transform: orientation === 'horizontal' ? 'translateY(-50%)' : 'translateX(-50%) rotate(90deg)',
              ...sx,
            }}
          >
            <StyledIconButton ref={ref} onClick={scrollPrev} size={size} {...props}>
              <ArrowLeftSLineIcon />
              <span style={visuallyHidden}>Previous slide</span>
            </StyledIconButton>
          </Box>
        </Tooltip>
      </Fade>
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof IconButton>>(
  ({ sx, size = 'small', ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext, disabled } = useCarousel();

    return (
      <Fade in={canScrollNext && !disabled}>
        <Tooltip disableInteractive title="Next">
          <Box
            component="span"
            sx={{
              position: 'absolute',
              right: orientation === 'horizontal' ? '-45px' : undefined,
              top: orientation === 'horizontal' ? '50%' : undefined,
              bottom: orientation === 'horizontal' ? undefined : '-45px',
              left: orientation === 'horizontal' ? undefined : '50%',
              transform: orientation === 'horizontal' ? 'translateY(-50%)' : 'translateX(-50%) rotate(90deg)',
              ...sx,
            }}
          >
            <StyledIconButton ref={ref} onClick={scrollNext} size={size} {...props}>
              <ArrowRightSLineIcon />
              <span style={visuallyHidden}>Next slide</span>
            </StyledIconButton>
          </Box>
        </Tooltip>
      </Fade>
    );
  },
);

CarouselNext.displayName = 'CarouselNext';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  border: '1px solid hsla(200, 11%, 64%, 0.5)',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: darken(theme.palette.common.white, 0.05),
  },
  '&:disabled': {
    boxShadow: 'none',
    color: 'rgba(0, 35, 52, 0.3)',
    border: 'none',
  },
  background: 'white',
}));

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

const useDotButton = (api: CarouselApi): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!api) return;
      api.scrollTo(index);
    },
    [api],
  );

  const onInit = useCallback((api: CarouselApi) => {
    if (!api) return;

    setScrollSnaps(api.scrollSnapList());
  }, []);

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) return;

    setSelectedIndex(api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!api) return;

    onInit(api);
    onSelect(api);
    api.on('reInit', onInit);
    api.on('reInit', onSelect);
    api.on('select', onSelect);
  }, [api, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

const DotButton = ({
  onClick,
  selected,
  index,
  disabled,
}: {
  onClick: () => void;
  selected: boolean;
  index: number;
  disabled?: boolean;
}) => {
  return (
    <Box
      component="button"
      disabled={disabled}
      onClick={onClick}
      sx={{
        opacity: disabled ? 0.5 : 1,
        width: '24px',
        height: '24px',
        color: 'inherit',
        border: 'none',
        padding: '0',
        bgcolor: 'transparent',
        outline: 'inherit',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus-within': {
          outline: '1px solid',
          outlineColor: ColorsV2.green,
        },
      }}
      type="button"
    >
      <Box
        sx={{
          width: '5px',
          height: '5px',
          bgcolor: selected ? ColorsV2.green : ColorsV2.gray_medium,
          borderRadius: '100%',
        }}
      />
      <span style={visuallyHidden}>Go to slide {index}</span>
    </Box>
  );
};

const CarouselDots = React.forwardRef<HTMLDivElement, BoxProps>(({ sx, ...props }, ref) => {
  const { api, disabled } = useCarousel();

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api);
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 1,
        width: '100%',
        justifyContent: 'center',
        ...sx,
      }}
      {...props}
    >
      {scrollSnaps.map((_, index) => (
        <DotButton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          disabled={disabled}
          index={index}
          onClick={() => onDotButtonClick(index)}
          selected={selectedIndex === index}
        />
      ))}
    </Box>
  );
});

CarouselDots.displayName = 'CarouselDots';

export { type CarouselApi, Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext, CarouselDots };
