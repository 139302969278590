import { Navigate } from 'react-router-dom';
import { PATHS } from './paths';
import { useEhrContext } from '../hooks/useEhrContext';
import React from 'react';

export function EhrMode({ normal, patient }: { patient?: React.ReactNode; normal?: React.ReactNode }) {
  const ehrContext = useEhrContext();

  if (ehrContext.isLoading) return null;

  const isPatientContext = !!ehrContext.data?.patientId;

  return isPatientContext ? (
    <>{patient ?? <Navigate replace to={PATHS.NOT_FOUND} />}</>
  ) : (
    <>{normal ?? <Navigate replace to={PATHS.NOT_FOUND} />}</>
  );
}
