import { DatePickerProps } from '@tomorrow/ui';
import { lazily } from 'react-lazily';
import { retry } from '../../router/retry';
import React, { Suspense } from 'react';

const { DatePicker: DatePickerLazy } = lazily(() => retry(() => import('@tomorrow/ui')));

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  return (
    <Suspense fallback={null}>
      <DatePickerLazy inputRef={ref} {...props} />
    </Suspense>
  );
});

export default DatePicker;
