import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function EhrUnauthorized() {
  return (
    <Box alignItems="center" display="flex" flexGrow={1} py="20px" textAlign="center">
      <Container sx={{ height: '100%' }}>
        <Typography gutterBottom variant="h1">
          🚧 Under construction 🚧
        </Typography>

        <Typography gutterBottom variant="h5">
          We’re testing our Epic integration with just one practice to start.
        </Typography>

        <Typography variant="h6">
          We will expand to your practice soon. In the meantime, return to{' '}
          <Link href="https://tomorrowhealth.com/login" target="_blank">
            tomorrowhealth.com/login
          </Link>{' '}
          in a new browser tab to continue ordering DME supplies. Thanks!
        </Typography>
      </Container>
    </Box>
  );
}
