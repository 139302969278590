import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type OrgRevokeDocumentExtractionJobMutationVariables = Types.Exact<{
  input: Types.OrgRevokeDocumentExtractionJobInput;
}>;

export type OrgRevokeDocumentExtractionJobMutation = {
  __typename?: 'Mutation';
  orgRevokeDocumentExtractionJob?: {
    __typename?: 'OrgRevokeDocumentExtractionJobMutation';
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const OrgRevokeDocumentExtractionJobDocument = `
    mutation orgRevokeDocumentExtractionJob($input: OrgRevokeDocumentExtractionJobInput!) {
  orgRevokeDocumentExtractionJob(inputData: $input) {
    error {
      id
      type
      message
    }
  }
}
    `;

export const useOrgRevokeDocumentExtractionJobMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OrgRevokeDocumentExtractionJobMutation,
    TError,
    OrgRevokeDocumentExtractionJobMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    OrgRevokeDocumentExtractionJobMutation,
    TError,
    OrgRevokeDocumentExtractionJobMutationVariables,
    TContext
  >({
    mutationKey: ['orgRevokeDocumentExtractionJob'],
    mutationFn: (variables?: OrgRevokeDocumentExtractionJobMutationVariables) =>
      fetcher<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
        OrgRevokeDocumentExtractionJobDocument,
        variables,
      )(),
    ...options,
  });
};

useOrgRevokeDocumentExtractionJobMutation.getKey = () => ['orgRevokeDocumentExtractionJob'];

useOrgRevokeDocumentExtractionJobMutation.fetcher = (
  variables: OrgRevokeDocumentExtractionJobMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
    OrgRevokeDocumentExtractionJobDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOrgRevokeDocumentExtractionJobMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ orgRevokeDocumentExtractionJob })
 *   )
 * })
 */
export const mockOrgRevokeDocumentExtractionJobMutation = (
  resolver: Parameters<
    typeof graphql.mutation<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>
  >[1],
) =>
  graphql.mutation<OrgRevokeDocumentExtractionJobMutation, OrgRevokeDocumentExtractionJobMutationVariables>(
    'orgRevokeDocumentExtractionJob',
    resolver,
  );
