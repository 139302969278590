import { IntakePatient } from '../../components/PatientModal/PatientForm.helpers';

export function formatInsurance(ins: IntakePatient['primaryInsurance']) {
  if (!ins) return '-';

  const planName = ins.insurer?.name;
  const carrierName = ins.insurer?.parent?.name;
  const carrierPrefix = carrierName && carrierName !== planName ? `${carrierName} - ` : '';
  return `${carrierPrefix}${planName} (#${ins.policyNumber})`;
}
