export const Colors = {
  black: '#000000',
  funBlue: '#2C497F',
  iron: '#C8CACD',
  jungleMist: '#B2D0D3',
  kournikova: '#FFCC55',
  mirage: '#002334',
  orangeWhite: '#FFFAEE',
  outrageousOrange: '#FF6E40',
  fadingOrange: '#FFEBBB',
  paleSky: '#6E7480',
  zumthor: '#99A8AF',
  white: '#FFFFFF',
  shadowGreen: '#98C0C4',
  gulfStream: '#7EB0B5',
  newGreen: '#EEFAF5',
  newGray: '#F8F8F8',
  athensGray: '#EAEDF2',
  geyser: '#D5DBE5',
  baliHai: '#8092B2',
  astronaut: '#253D6A',
  waikawaGray: '#566D99',
  grayChateau: '#9FA3AA',
  casper: '#ABB6CC',
  creamBrulee: '#FFE099',
  eggSour: '#FFF5DD',
  bigStone: '#0E182B',
  mystic: '#E5EFF0',
  cello: '#1F345B',
  lightCello: '#223962',
  peachSchnapps: '#FFE2D9',
  nevada: '#6B767B',

  // rebranding colors
  darkOrange: '#FF7E5C',
  neonGreen: '#43D6AF',
  green: 'rgba(0, 168, 124, 1)',
  darkGreen: '#00AE86',
  lightGray: '#ECEEE8',
  darkGray: '#E6E7E5',
  contrastGray: '#EBECE8',
  altGray: '#F7F8F9',
  navy: '#91ACD9',
  skyLight: '#D7DFF0',
  gray: '#6D7C86',
  elephantColor: 'rgba(12, 37, 54, 0.05)',
  pantone: '#E7E9E7',
  daintree: '#002638',
  daintreeLight: 'rgba(0, 38, 56, 0.1)',
  daintreeMedium: 'rgba(0, 38, 56, 0.5)',
  daintreeBorder: 'rgba(0, 38, 56, 0.25)',
  cararra: '#ECEEE8',
  cararraLight: '#F0F1ED',
  stormGray: ' #6E7480',
  fogColor: 'rgba(77, 151, 140, 0.15)',
  hippieBlue: '#4D978C',
  blueDianne: '#264756',
  transparentBlack: 'rgba(0, 0, 0, 0.15)',
  transparentBlackSecondary: 'rgba(0, 0, 0, 0.25)',
  mountainMeadow: '#19B591',
  cuttySark: '#58867D',
  swansDown: '#DCEFE8',
  casal: '#2E685C',
  casalSecondary: 'rgba(46, 104, 92, 0.1)',
  red: '#E31106',
  warningRed: '#EA2A20',
  warningRedSecondary: '#f45454',
  porcelain: '#E6EAEC',
  tropicalRainForest: '#006A5B',
  desertStorm: '#F8F8F7',
  lighterStorm: '#F7F8F6',
  fadingGray: 'rgba(46, 104, 92, 0.3)',
  fadingCascal: 'rgba(46, 104, 92, 0.7)',
  zumthorLight: 'rgba(153,168,175,0.5)',
  zumthorLighter: 'rgba(153,168,175,0.15)',
  osloGray: '#808D93',
  formsGreen: '#00A87C',
  borderSelected: '#50DCB7',
  borderDeselected: '#AFBBBF',
  lightGreyText: '#7F8C92',
  monoDarkGray: '#7F8C92',
  grayContainer: '#E9EAE6',
  newBackground: '#F1F2ED',
  lighterGreyText: '#758186',
  intakeDivider: '#D7DCD9',
  textPlaceholder: '#80939B',
  internalUseWarning: '#F56842',
  greyMedium: '#99A7AE',
  muiFocusedBorder: '#667B85',
  muiError: '#F45555',
  disabledInput: 'rgba(230, 233, 235, 0.6)',
  disabledInputText: '#99A7AE',
  inputBorder: 'rgba(0, 35, 52, 0.2)',
  muiGreen: '#09B175',
  disabledRadio: '#DADDDE',
  label: '#002334',
  lightViolet: '#7E54F3',
  lightDuskBlue: '#4570DF',
  dullGray: '#667B85',
  gold: '#E1AA57',
  yellow: '#FFF174',
  primaryHover: '#29d1a4',
  secondaryHover: '#5D7179',
  primaryErrorHover: '#f12727',
  secondaryErrorHover: '#c00c0c',
  userCircle: '#F38460',
  grayLight: '#E6E9EB',
} as const;

export const UserAvatarColors = {
  blue: '#6CABF3',
  darkBlue: '#6A77EB',
  gold: '#C6AE81',
  gray: '#AEAEAE',
  green: '#7CD674',
  orange: '#F38460',
  pink: '#EB6AB7',
  purple: '#B473F4',
  darkPurple: '#754DE7',
  red: '#FE6E6E',
  seaGreen: '#67D3AC',
  skyBlue: '#60D9F3',
  yellow: '#E4C65A',
} as const;

export const ColorsV2 = {
  white: '#ffffff',
  navy: '#002334',
  gray_dark: '#5D7179',
  gray_medium: '#99A7AE',
  gray_light: '#E6E9EB',
  cream: '#F0F1ED',
  green: '#09B175',
  neon_green: '#50DCB7',
  red: '#F45555',
  mustard: '#E1AA57',
  blue: '#4570DF',
  purple: '#754DE7',
} as const;
