/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  AppOrderReferralChannelChoices,
  AppOrderReferralSourceChoices,
  AppOrderSourceChoices,
  AppOrderSupplierSuggestionSourceChoices,
  OmniCreateOrderInput,
} from '../../../../api-clients/falcon-api/graphql/types.generated';
import {
  assignOrderSeedDetails,
  getOrderSeedFromResponse,
  SupplierIntakeFaxOrderSeed,
} from '../SupplierFaxOrderWizard.utils';
import { ColorsV2 } from '@tomorrow/ui/theme';
import {
  DateInput,
  DiagnosisInput,
  HcpcsInput,
  NpiInput,
  NumberInput,
  ProductSubcategoryInput,
} from '../../../../components/FormInputs';
import { DocumentFieldId } from './DocumentStep.utils';
import {
  GetOrderSeedPotentialOrderMatchesQuery,
  PotentialOrderMatchFragment,
  useGetOrderSeedPotentialOrderMatchesQuery,
} from '../../../../api-clients/falcon-api/graphql/queries/getOrderSeedPotentialOrderMatches.generated';
import { InputSubSection } from '../components/InputSection';
import { milliseconds } from 'date-fns';
import { NpiRegistryLink } from '../../../../components/NpiRegistryLink';
import { OrderStepFormValues, toDefaultFormValues } from './OrderStep.utils';
import { OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import { ProviderDetailCard } from '../../../../components/ProviderDetailCard';
import { SupplierOrderCarousel } from '../../../../components/SupplierOrderCarousel';
import { SupplierOrderSearch } from '../components/SupplierOrderSearch';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useController, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useCreateOmniOrder, useUpdateOmniOrder } from '../../../IntakeOrderWizard/common/omniApi';
import { useCurrentOrgUser } from '../../../../router/useCurrentOrgUser';
import { useIntakeSubcategories } from '../../../../api-clients/falcon-api/hooks/useIntakeSubcategories';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export type OrderStepForm = UseFormReturn<OrderStepFormValues>;

type HighlightFieldCb<T extends string = any> = OrderWizardStepComponentProps<
  SupplierIntakeFaxOrderSeed,
  T
>['onHighlightField'];

export const OrderStep = forwardRef<
  StepRef,
  OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed, DocumentFieldId>
>(function OrderStep({ orderSeed, onOrderProduce, onNextStep, onOrderSubmit, onHighlightField }, ref) {
  const currentOrgUser = useCurrentOrgUser();
  const supplierId = currentOrgUser.org.supplier?.id ?? '';

  const form = useForm<OrderStepFormValues>({
    defaultValues: toDefaultFormValues(orderSeed),
  });

  const createOmniOrderMutation = useCreateOmniOrder();
  const updateOmniOrderMutation = useUpdateOmniOrder();

  const saveOrder = useCallback(
    async function saveOrder(values: OrderStepFormValues, onSuccess: (orderSeed: SupplierIntakeFaxOrderSeed) => void) {
      if (!orderSeed.id) throw new Error('Missing order seed id');

      let updatedOrderSeed = orderSeed;

      if (values.selectedFormOption === 'new') {
        try {
          const response = await createOmniOrderMutation.mutateAsync({
            input: toCreateOmniOrderInput(supplierId, orderSeed, values),
          });

          updatedOrderSeed = onOrderProduce((draft) =>
            Object.assign(draft, {
              ...orderSeed,
              order: { id: response.order?.order?.id ?? '' },
            }),
          );
        } catch (error) {
          if (error instanceof Error) {
            form.setError('selectedFormOption', {
              message: error.message,
            });

            return;
          }
        }
      } else {
        const response = await updateOmniOrderMutation.mutateAsync({
          input: {
            orderId: values.selectedOrderId ?? '',
            orderSeedId: orderSeed.id,
          },
        });

        const responseOrderSeed = getOrderSeedFromResponse(response);
        updatedOrderSeed = onOrderProduce((draft) => assignOrderSeedDetails(draft, responseOrderSeed));
      }

      onSuccess(updatedOrderSeed);
    },
    [createOmniOrderMutation, form, onOrderProduce, orderSeed, supplierId, updateOmniOrderMutation],
  );

  const handleFormSubmit = useCallback(async () => {
    await form.handleSubmit(async (values) => {
      onOrderSubmit();
      await saveOrder(values, onNextStep);
    })();
  }, [form, onNextStep, onOrderSubmit, saveOrder]);

  useImperativeHandle(
    ref,
    () => ({
      triggerFormSubmit: handleFormSubmit,
    }),
    [handleFormSubmit],
  );

  return (
    <Box bgcolor={ColorsV2.white} display="flex" flexGrow="1" minWidth={0}>
      <Box flexGrow="1" minWidth={0}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <Stack flexGrow="1" paddingY="20px" spacing={2}>
            <Stack spacing={2}>
              <Stack paddingX="40px" spacing={2}>
                <Typography fontWeight="600" variant="h5">
                  Assign document to an order
                </Typography>
                <Divider />
              </Stack>

              <Box paddingLeft="40px">
                <OrderInput form={form} onHighlightField={onHighlightField} orderSeed={orderSeed} />
              </Box>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Box>
  );
});

const OrderInput = ({
  orderSeed,
  form,
  onHighlightField,
}: {
  orderSeed: SupplierIntakeFaxOrderSeed;
  form: OrderStepForm;
  onHighlightField: HighlightFieldCb;
}) => {
  const potentialOrderMatchesQuery = useGetOrderSeedPotentialOrderMatchesQuery(
    { orgId: orderSeed.orgId, orderSeedId: orderSeed.id ?? '', patientId: orderSeed.selectedPatient?.id ?? '' },
    {
      enabled: !!orderSeed.orgId && !!orderSeed.id && !!orderSeed.selectedPatient?.id,
      staleTime: milliseconds({ minutes: 5 }),
      meta: {
        errorMessage: 'Failed to load orders.',
      },
      select: useCallback(function transformGetOrderSeedPotentialOrderMatchesQuery(
        query: GetOrderSeedPotentialOrderMatchesQuery,
      ): PotentialOrderMatchFragment[] {
        return query.orderSeedPotentialOrderMatches?.flatMap((order) => (order ? [order] : [])) ?? [];
      }, []),
    },
  );

  const { field: optionField, fieldState: optionFieldState } = useController({
    control: form.control,
    name: 'selectedFormOption',
    rules: {
      required: 'Please select an option',
      deps: ['selectedOrderId'],
    },
  });

  const { field: selectedOrderIdField, fieldState: selectedOrderIdFieldState } = useController({
    control: form.control,
    name: 'selectedOrderId',
    rules: {
      required: optionField.value === 'new' ? false : 'Please select an order',
    },
  });

  const error = optionFieldState.error || selectedOrderIdFieldState.error;

  return (
    <FormControl component="fieldset" error={!!error?.message}>
      <RadioGroup
        {...optionField}
        onChange={(e, value) => {
          selectedOrderIdField.onChange(null);

          optionField.onChange(value);
        }}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          disabled={potentialOrderMatchesQuery.isFetching || (potentialOrderMatchesQuery.data?.length || 0) === 0}
          disableTypography
          label={
            <Box minWidth={0}>
              <Stack paddingBottom={1} paddingTop={1} spacing={1}>
                <Typography
                  color={
                    potentialOrderMatchesQuery.isFetching || (potentialOrderMatchesQuery.data?.length || 0) === 0
                      ? 'text.secondary'
                      : undefined
                  }
                  fontWeight="600"
                  variant="subtitle2"
                >
                  Select order from a potential match
                </Typography>

                {potentialOrderMatchesQuery.isFetching && (
                  <Stack spacing={1}>
                    <Skeleton width="50%">
                      <Typography>Loading...</Typography>
                    </Skeleton>
                    <Stack direction="row" overflow="hidden" spacing={1}>
                      <Skeleton
                        sx={{
                          height: '200px',
                          minWidth: '300px',
                        }}
                        variant="rounded"
                      />
                      <Skeleton
                        sx={{
                          height: '200px',
                          minWidth: '300px',
                        }}
                        variant="rounded"
                      />
                    </Stack>
                  </Stack>
                )}

                {!potentialOrderMatchesQuery.isFetching && (potentialOrderMatchesQuery.data?.length || 0) === 0 && (
                  <Typography>No matches found.</Typography>
                )}

                {!potentialOrderMatchesQuery.isFetching &&
                  potentialOrderMatchesQuery.data &&
                  potentialOrderMatchesQuery.data.length > 0 && (
                    <Stack spacing={1}>
                      <Typography>
                        We have found {potentialOrderMatchesQuery.data?.length} potential{' '}
                        {potentialOrderMatchesQuery.data?.length === 1 ? 'match' : 'matches'}:
                      </Typography>
                      <SupplierOrderCarousel
                        disabled={optionField.value !== 'match'}
                        onOrderSelect={selectedOrderIdField.onChange}
                        orders={potentialOrderMatchesQuery.data}
                        selectedOrderId={selectedOrderIdField.value}
                      />
                    </Stack>
                  )}
              </Stack>
            </Box>
          }
          sx={{ alignItems: 'flex-start' }}
          value="match"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          disableTypography
          label={
            <Box flexGrow="1" minWidth={0}>
              <Stack paddingTop={1} spacing={1}>
                <Typography fontWeight="600" variant="subtitle2">
                  Select order from database
                </Typography>
                {optionField.value === 'database' && (
                  <SupplierOrderSearch
                    onSelect={selectedOrderIdField.onChange}
                    selectedOrderId={selectedOrderIdField.value}
                    selectedPatientId={orderSeed.selectedPatient?.id}
                  />
                )}
              </Stack>
            </Box>
          }
          sx={{
            alignItems: 'flex-start',
          }}
          value="database"
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          disableTypography
          label={
            <Stack flexGrow={1} paddingTop={1} spacing={1}>
              <Typography fontWeight="600" variant="subtitle2">
                Create new order
              </Typography>
              {optionField.value === 'new' && (
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="body2">
                    Some information was extracted from the document.
                  </Typography>

                  <InputContainer highlightFieldId="subcategory_name" onHighlightField={onHighlightField}>
                    <DateInput
                      control={form.control}
                      label="Referral Date"
                      name="newOrderFormValues.referralDate"
                      rules={{
                        required: 'Date is required',
                      }}
                    />
                  </InputContainer>

                  <InputContainer highlightFieldId="subcategory_name" onHighlightField={onHighlightField}>
                    <SubcategoryField form={form} />
                  </InputContainer>

                  <ProviderField form={form} onHighlightField={onHighlightField} />

                  <DiagnosisInputSection form={form} onHighlightField={onHighlightField} />
                  <LineItemInputsSection form={form} onHighlightField={onHighlightField} />
                </Stack>
              )}
            </Stack>
          }
          onClick={(e) => {
            // Prevents an annoying scroll jump upon focusing this input
            // due to the large patient form container
            e.preventDefault();

            selectedOrderIdField.onChange(null);

            optionField.onChange('new');
          }}
          sx={{
            alignItems: 'flex-start',
          }}
          value="new"
        />
      </RadioGroup>
      {!!error?.message && <FormHelperText sx={{ marginTop: 1 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

function SubcategoryField({ form }: { form: OrderStepForm }) {
  const selectedCategoryId = form.watch('newOrderFormValues.categoryId');
  const selectedSubcategoryId = form.watch('newOrderFormValues.subcategoryId');
  const intakeSubcategories = useIntakeSubcategories();

  const selectedSubcategory = useMemo(
    () => intakeSubcategories.data?.find((subcategory) => subcategory.id === selectedSubcategoryId),
    [intakeSubcategories.data, selectedSubcategoryId],
  );

  if (selectedSubcategory?.intakeCategory.id && selectedCategoryId !== selectedSubcategory.intakeCategory.id) {
    form.setValue('newOrderFormValues.categoryId', selectedSubcategory.intakeCategory.id, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  }

  return (
    <ProductSubcategoryInput
      control={form.control}
      label="Subcategory"
      name="newOrderFormValues.subcategoryId"
      rules={{
        required: 'Product subcategory is required',
      }}
    />
  );
}

function ProviderField({
  form,
  onHighlightField,
}: {
  form: OrderStepForm;
  onHighlightField: HighlightFieldCb<DocumentFieldId>;
}) {
  const provider = form.watch('newOrderFormValues.provider');
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const handleOnHighlightField = (highlightFieldId: DocumentFieldId, highlightEnabled: boolean) => () => {
    if (!inputContainerRef.current) return;

    onHighlightField(inputContainerRef.current, highlightFieldId, highlightEnabled);
  };

  useEffect(() => {
    if (!!provider.npi && !provider.id) form.trigger('newOrderFormValues.provider.npi');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={1}>
      <div
        ref={inputContainerRef}
        onMouseEnter={handleOnHighlightField('provider_npi', true)}
        onMouseLeave={handleOnHighlightField('provider_npi', false)}
      >
        <NpiInput
          control={form.control}
          label="Provider NPI Number"
          name="newOrderFormValues.provider.npi"
          onProviderChange={(provider) => {
            form.resetField('newOrderFormValues.provider', {
              keepDirty: false,
              defaultValue: provider || undefined,
            });
          }}
        />
      </div>
      <NpiRegistryLink onClick={(e) => e.stopPropagation()} />
      {provider.id && (
        <ProviderDetailCard
          provider={{
            npi: provider.npi ?? undefined,
            firstName: provider.firstName,
            lastName: provider.lastName,
            phoneNumber: provider.phoneNumber,
            faxNumber: provider.fax,
            emailAddress: undefined,
          }}
          sx={{
            bgcolor: '#F8F8F8',
          }}
        />
      )}
    </Stack>
  );
}

function DiagnosisInputSection({
  form,
  onHighlightField,
}: {
  form: OrderStepForm;
  onHighlightField: HighlightFieldCb<DocumentFieldId>;
}) {
  const [parentRef] = useAutoAnimate();
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'newOrderFormValues.diagnoses',
    keyName: 'keyId',
  });

  return (
    <InputSubSection
      label="Diagnoses"
      rightComponent={
        <Tooltip disableInteractive title="Add Item">
          <Button
            onClick={() =>
              append({
                resultId: null,
                dxCode: null,
              })
            }
            size="small"
          >
            <AddLineIcon />
          </Button>
        </Tooltip>
      }
    >
      <Box ref={parentRef} display="flex" flexDirection="column" gap={1}>
        {fields.map((item, index) => {
          return (
            <Stack key={item.keyId} spacing={1}>
              <Stack flexGrow="1" spacing={1}>
                <InputContainer
                  highlightFieldId={`diagnosis_code_${index + 1}` as DocumentFieldId}
                  onHighlightField={onHighlightField}
                >
                  <Box alignItems="center" display="flex" gap={1} justifyContent="space-between">
                    <Typography>DX code {index + 1}</Typography>
                    <Box width="50%">
                      <DiagnosisInput
                        control={form.control}
                        name={`newOrderFormValues.diagnoses.${index}.dxCode`}
                        rules={{ required: true }}
                      />
                    </Box>
                  </Box>
                </InputContainer>
              </Stack>
              <Box alignSelf="flex-end">
                <Tooltip disableInteractive title="Remove">
                  <span>
                    <IconButton disabled={fields.length === 0} onClick={() => remove(index)}>
                      <DeleteBinLineIcon size="18px" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Stack>
          );
        })}
      </Box>
    </InputSubSection>
  );
}

function LineItemInputsSection({
  form,
  onHighlightField,
}: {
  form: OrderStepForm;
  onHighlightField: HighlightFieldCb<DocumentFieldId>;
}) {
  const [parentRef] = useAutoAnimate();
  const { fields, append, remove } = useFieldArray({
    name: 'newOrderFormValues.lineItems',
    control: form.control,
    keyName: 'keyId',
  });

  return (
    <InputSubSection
      label="Line Items"
      rightComponent={
        <Tooltip disableInteractive title="Add Item">
          <Button onClick={() => append({ resultId: null, hcpcs: null, quantity: null })} size="small">
            <AddLineIcon />
          </Button>
        </Tooltip>
      }
    >
      <Box ref={parentRef} display="flex" flexDirection="column" gap={1}>
        {fields.map((item, index) => {
          return (
            <div key={item.keyId}>
              {index > 0 && (
                <Box marginBottom={2} paddingLeft={3}>
                  <Divider />
                </Box>
              )}
              <Stack flexGrow="1" spacing={1}>
                <InputContainer
                  highlightFieldId={`line_item_${index + 1}_hcpcs` as DocumentFieldId}
                  onHighlightField={onHighlightField}
                >
                  <Box alignItems="center" display="flex" gap={1} justifyContent="space-between">
                    <Typography>HCPCS</Typography>
                    <Box width="50%">
                      <HcpcsInput
                        control={form.control}
                        name={`newOrderFormValues.lineItems.${index}.hcpcs`}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                  </Box>
                </InputContainer>
                <InputContainer
                  highlightFieldId={`line_item_${index + 1}_quantity` as DocumentFieldId}
                  onHighlightField={onHighlightField}
                >
                  <Box alignItems="center" display="flex" gap={1} justifyContent="space-between">
                    <Typography>Quantity</Typography>
                    <Box width="50%">
                      <NumberInput
                        control={form.control}
                        name={`newOrderFormValues.lineItems.${index}.quantity`}
                        rules={{ required: 'Quantity required' }}
                      />
                    </Box>
                  </Box>
                </InputContainer>
                <Box alignSelf="flex-end">
                  <Tooltip disableInteractive title="Remove">
                    <span>
                      <IconButton disabled={fields.length === 0} onClick={() => remove(index)}>
                        <DeleteBinLineIcon size="18px" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Stack>
            </div>
          );
        })}
      </Box>
    </InputSubSection>
  );
}

interface InputContainerProps<T extends string> extends React.PropsWithChildren {
  highlightFieldId: T;
  onHighlightField: HighlightFieldCb<T>;
}

const InputContainer = ({ onHighlightField, highlightFieldId, children }: InputContainerProps<DocumentFieldId>) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const handleOnHighlightField = (highlightEnabled: boolean) => () => {
    if (!inputContainerRef.current) return;

    onHighlightField(inputContainerRef.current, highlightFieldId, highlightEnabled);
  };

  return (
    <div
      ref={inputContainerRef}
      onMouseEnter={handleOnHighlightField(true)}
      onMouseLeave={handleOnHighlightField(false)}
    >
      {children}
    </div>
  );
};

function toCreateOmniOrderInput(
  supplierId: string,
  orderSeed: SupplierIntakeFaxOrderSeed,
  formValues: OrderStepFormValues,
): OmniCreateOrderInput {
  if (!orderSeed.selectedPatient) throw new Error('Missing selected patient');

  const referringProviderId = formValues.newOrderFormValues.provider.id;

  return {
    orderSeedId: orderSeed.id,
    patientId: orderSeed.selectedPatient?.id,
    source: AppOrderSourceChoices.App,
    categoryId: formValues.newOrderFormValues.categoryId,
    subcategoryId: formValues.newOrderFormValues.subcategoryId,
    referralInput: {
      referringProviderId,
      referralSource: AppOrderReferralSourceChoices.Provider,
      referralChannel: AppOrderReferralChannelChoices.Fax,
    },
    prescriptionInput: {
      orderLineItems: formValues.newOrderFormValues.lineItems.flatMap((lineItem) =>
        lineItem.hcpcs
          ? [
              {
                hcpcsCode: lineItem.hcpcs.code,
                quantity: lineItem.quantity ? Number(lineItem.quantity) : null,
                diagnosisCodes: formValues.newOrderFormValues.diagnoses.flatMap((diagnosis) =>
                  diagnosis.dxCode ? [diagnosis.dxCode.code] : [],
                ),
              },
            ]
          : [],
      ),
      referralDate: orderSeed.extractionResultsSet?.referralDateCorrected,
      referringProviderId,
    },
    supplierPreferenceInput: {
      supplierSuggestions: [
        {
          supplierId,
          source: AppOrderSupplierSuggestionSourceChoices.Provider,
          taken: true,
          note: 'Supplier created via fax automation',
        },
      ],
    },
    submitForReview: true,
  };
}
