import { DialogTitle } from '../../../../../components/Dialog';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

interface OrderActionDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
}

const OrderActionDialog = ({ onClose, children, ...props }: OrderActionDialogProps) => {
  const theme = useTheme();
  const isXsWidth = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={isXsWidth} fullWidth onClose={onClose} {...props}>
      <DialogTitle onClose={onClose}></DialogTitle>
      <DialogContent
        sx={{
          '&:first-of-type': {
            paddingTop: 0,
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default OrderActionDialog;
