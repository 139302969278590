import { Navigate } from 'react-router-dom';
import { PATHS } from './paths';
import { useContentfulFeatureFlags, useFeatureFlags } from '../hooks/useFeatureFlags';

export function FeatureFlagged({
  enabled,
  flag,
}: {
  enabled: React.ReactNode;
  flag: keyof ReturnType<typeof useFeatureFlags>;
}) {
  const contentful = useContentfulFeatureFlags();
  const flags = useFeatureFlags();

  if (contentful.isLoading) return null;
  return flags[flag] ? <>{enabled}</> : <Navigate replace to={PATHS.NOT_FOUND} />;
}
