import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetDocumentS3UrlQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
  token?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetDocumentS3UrlQuery = {
  __typename?: 'Query';
  documentS3Url?:
    | { __typename: 'DocumentS3UrlResult'; url?: string | null }
    | { __typename: 'ErrorType'; message: string }
    | null;
};

export const GetDocumentS3UrlDocument = `
    query getDocumentS3Url($documentId: ID!, $token: String) {
  documentS3Url(documentId: $documentId, token: $token) {
    __typename
    ... on DocumentS3UrlResult {
      url
    }
    ... on ErrorType {
      message
    }
  }
}
    `;

export const useGetDocumentS3UrlQuery = <TData = GetDocumentS3UrlQuery, TError = unknown>(
  variables: GetDocumentS3UrlQueryVariables,
  options?: Omit<UseQueryOptions<GetDocumentS3UrlQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetDocumentS3UrlQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDocumentS3UrlQuery, TError, TData>({
    queryKey: ['getDocumentS3Url', variables],
    queryFn: fetcher<GetDocumentS3UrlQuery, GetDocumentS3UrlQueryVariables>(GetDocumentS3UrlDocument, variables),
    ...options,
  });
};

useGetDocumentS3UrlQuery.getKey = (variables: GetDocumentS3UrlQueryVariables) => ['getDocumentS3Url', variables];

export const useInfiniteGetDocumentS3UrlQuery = <TData = InfiniteData<GetDocumentS3UrlQuery>, TError = unknown>(
  variables: GetDocumentS3UrlQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetDocumentS3UrlQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetDocumentS3UrlQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetDocumentS3UrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getDocumentS3Url.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetDocumentS3UrlQuery, GetDocumentS3UrlQueryVariables>(GetDocumentS3UrlDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetDocumentS3UrlQuery.getKey = (variables: GetDocumentS3UrlQueryVariables) => [
  'getDocumentS3Url.infinite',
  variables,
];

useGetDocumentS3UrlQuery.fetcher = (variables: GetDocumentS3UrlQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetDocumentS3UrlQuery, GetDocumentS3UrlQueryVariables>(GetDocumentS3UrlDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDocumentS3UrlQuery((req, res, ctx) => {
 *   const { documentId, token } = req.variables;
 *   return res(
 *     ctx.data({ documentS3Url })
 *   )
 * })
 */
export const mockGetDocumentS3UrlQuery = (
  resolver: Parameters<typeof graphql.query<GetDocumentS3UrlQuery, GetDocumentS3UrlQueryVariables>>[1],
) => graphql.query<GetDocumentS3UrlQuery, GetDocumentS3UrlQueryVariables>('getDocumentS3Url', resolver);
