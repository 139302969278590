import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type PreviewFaxbackCoversheetMutationVariables = Types.Exact<{
  input: Types.FaxbackCoversheetInput;
}>;

export type PreviewFaxbackCoversheetMutation = {
  __typename?: 'Mutation';
  previewFaxbackCoversheet?: {
    __typename?: 'PreviewFaxbackCoversheetMutation';
    pdfData?: string | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const PreviewFaxbackCoversheetDocument = `
    mutation previewFaxbackCoversheet($input: FaxbackCoversheetInput!) {
  previewFaxbackCoversheet(inputData: $input) {
    pdfData
    error {
      id
      type
      message
    }
  }
}
    `;

export const usePreviewFaxbackCoversheetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PreviewFaxbackCoversheetMutation,
    TError,
    PreviewFaxbackCoversheetMutationVariables,
    TContext
  >,
) => {
  return useMutation<PreviewFaxbackCoversheetMutation, TError, PreviewFaxbackCoversheetMutationVariables, TContext>({
    mutationKey: ['previewFaxbackCoversheet'],
    mutationFn: (variables?: PreviewFaxbackCoversheetMutationVariables) =>
      fetcher<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
        PreviewFaxbackCoversheetDocument,
        variables,
      )(),
    ...options,
  });
};

usePreviewFaxbackCoversheetMutation.getKey = () => ['previewFaxbackCoversheet'];

usePreviewFaxbackCoversheetMutation.fetcher = (
  variables: PreviewFaxbackCoversheetMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
    PreviewFaxbackCoversheetDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewFaxbackCoversheetMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ previewFaxbackCoversheet })
 *   )
 * })
 */
export const mockPreviewFaxbackCoversheetMutation = (
  resolver: Parameters<
    typeof graphql.mutation<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>
  >[1],
) =>
  graphql.mutation<PreviewFaxbackCoversheetMutation, PreviewFaxbackCoversheetMutationVariables>(
    'previewFaxbackCoversheet',
    resolver,
  );
