import { EhrMode } from '../../router/EhrMode';
import { FeatureFlagged } from '../../router/FeatureFlagged';
import { IntakeFaxOrderWizardStep } from '../IntakeFaxOrderWizard/IntakeFaxOrderWizard';
import { Layout } from '../../layouts/Layout';
import { lazily } from 'react-lazily';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Navigate, Route } from 'react-router-dom';
import { NavigateToFirstOrgByType } from '../../router/NavigateToFirstOrgByType';
import { OrgTypes } from '../../api-clients/falcon-api/graphql/types.generated';
import { OrgUserContainer } from '../../router/OrgUserContainer';
import { PATHS } from '../../router/paths';
import { PracticeOmniDrawer } from './PracticeOmniDrawer';
import { retry } from '../../router/retry';
import { SupplierFaxOrderWizard } from '../IntakeFaxOrderWizard/supplier/SupplierFaxOrderWizard';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const { PracticeHeader } = lazily(() => retry(() => import('./PracticeHeader')));
const { PracticeSignAgreement } = lazily(() => retry(() => import('./PracticeSignAgreement')));
const { default: PracticeOrderSuccess } = lazily(() => retry(() => import('./PracticeOrderSuccess')));
const { default: PracticeSettings } = lazily(() => retry(() => import('./PracticeSettings/PracticeSettings')));
const { default: PracticeEhrPatientOrdersScreen } = lazily(() =>
  retry(() => import('./patients/EhrPatientOrders/EhrPatientOrdersScreen')),
);
const { default: PracticePatientOrdersScreen } = lazily(() =>
  retry(() => import('./patients/PatientOrders/PatientOrdersScreen')),
);
const { PracticeOrderWizard } = lazily(() => retry(() => import('../IntakeOrderWizard/RPIntake/PracticeOrderWizard')));
const { default: PracticeOrderScreen } = lazily(() => retry(() => import('./PracticeOrderList')));
const { default: PracticeManagementScreen } = lazily(() => retry(() => import('./PracticeManagementScreen')));
const { PatientList } = lazily(() => retry(() => import('../../components/PatientList/PatientList')));
const { default: PracticeNotificationPreferences } = lazily(() =>
  retry(() => import('./PracticeSettings/NotificationPreferences/NotificationPreferences')),
);
const { IntakeOrderWizardStep } = lazily(() => retry(() => import('../IntakeOrderWizard/IntakeOrderWizard')));

export const practiceRoutes = (
  <Route path="practices/*">
    <Route element={<NavigateToFirstOrgByType orgType={OrgTypes.Practice} />} index />

    <Route element={<OrgUserContainer orgType={OrgTypes.Practice} />} path=":orgId/*">
      <Route element={<Layout />}>
        <Route element={<PracticeOrderWizard />} path="orders/:orderId/intake/*">
          <Route element={<IntakeOrderWizardStep />} path=":step" />
          <Route element={<Navigate replace to="patient" />} index />
        </Route>
      </Route>

      <Route element={<Layout drawer={<PracticeOmniDrawer />} header={<PracticeHeader banner />} />}>
        <Route
          element={
            <EhrMode normal={<Navigate replace to="orders" />} patient={<Navigate replace to="patients/ehr" />} />
          }
          index
        />
        <Route element={<EhrMode normal={<PracticeOrderScreen />} />} path="orders" />
        <Route element={<FeatureFlagged enabled={<ModalLayout />} flag="rp_payor_qualifications_fax_view" />}>
          <Route
            element={
              <SupplierFaxOrderWizard
                onExitRedirectPath={PATHS.PRACTICE_ORDERS}
                onFinishRedirectPath={PATHS.PRACTICE_ORDERS}
              />
            }
            path="orders/faxes/:orderSeedId/intake/qualifications_view_only/:documentId/*"
          >
            <Route element={<IntakeFaxOrderWizardStep />} path=":step" />
            <Route element={<Navigate replace to="document" />} index />
          </Route>
        </Route>
        <Route
          element={
            <EhrMode
              normal={
                <Container>
                  <Box py={2}>
                    <PracticeManagementScreen />
                  </Box>
                </Container>
              }
            />
          }
          path="practice-management"
        />
        <Route
          element={<FeatureFlagged enabled={<EhrMode normal={<PatientList />} />} flag="rp_patients_screen" />}
          path="patients"
        />
      </Route>

      <Route element={<Layout drawer={<PracticeOmniDrawer />} header={<PracticeHeader />} />}>
        <Route element={<PracticeSignAgreement />} path="baa" />
        <Route element={<PracticeOrderSuccess />} path="orders/:orderId/success" />
        <Route element={<EhrMode patient={<PracticeEhrPatientOrdersScreen />} />} path="patients/ehr" />
        <Route element={<EhrMode normal={<PracticePatientOrdersScreen />} />} path="patients/:patientId" />
        <Route element={<PracticeSettings />} path="settings/*">
          <Route element={<PracticeNotificationPreferences />} path="notifications" />
          <Route element={<EhrMode normal={<PracticeManagementScreen />} />} path="practice-management" />
          <Route element={<Navigate replace to="notifications" />} index />
        </Route>

        <Route element={<Navigate replace to={PATHS.NOT_FOUND} />} path="*" />
      </Route>
    </Route>
    <Route element={<Navigate replace to=".." />} path="*" />
  </Route>
);
