import {
  AddressLine1Input,
  AddressStateInput,
  EmailAddressInput,
  GenderInput,
  Input,
  PhoneNumberInput,
} from '../FormInputs';
import { Checkbox } from '../Checkbox';
import { formatDate, parseDate } from '../../utils/dateUtils';
import { getFormattedPhoneForDisplay } from '../../utils/phoneUtils';
import { getInsurerId, IntakePatient, PatientFormValues } from './PatientForm.helpers';
import {
  GetPatientInsuranceDetailsQuery,
  GetPatientInsuranceDetailsQueryVariables,
  useGetPatientInsuranceDetailsQuery,
} from '../../api-clients/falcon-api/graphql/queries/getPatientInsuranceDetails.generated';
import {
  InsurancePrimaryCarrierInput,
  InsurancePrimaryMemberIdInput,
  InsurancePrimaryPlanInput,
  InsuranceSecondaryCarrierInput,
  InsuranceSecondaryMemberIdInput,
  InsuranceSecondaryPlanInput,
  InsuranceWaiverInput,
  PatientDateOfBirthInput,
  PatientHeightInput,
  PatientLanguagePreferenceInput,
  PatientWeightInput,
  useWaivers,
} from './PatientInputs';
import { isValid } from 'date-fns';
import { logAction } from '../../utils/logging';
import { Separator } from '../Separator/Separator';
import { useController, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { useInsurers } from '../../api-clients/falcon-api/hooks/useInsurers';
import { useQuery } from '@tanstack/react-query';
import { useVocab } from '../../hooks/useVocab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ErrorInfo from '../ErrorInfo/ErrorInfo';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useDebounce from '../../hooks/useDebounce';

export type FormMode = 'create' | 'update';

type Form = UseFormReturn<PatientFormValues>;

export type PatientFormRef = {
  triggerSubmit: () => void;
};

interface PatientFormProps {
  children?: React.ReactNode;
  defaultValues: PatientFormValues;
  formMode: FormMode;
  onSubmit: (values: PatientFormValues) => Promise<void> | undefined;
  submitState: { isLoading: boolean; error: string | undefined };
  isIntakeFlow?: boolean;
  fieldDescriptions?: {
    primaryInsurance?: {
      carrier?: React.ReactNode;
      plan?: React.ReactNode;
      policyNumber?: React.ReactNode;
    };
    secondaryInsurance?: {
      carrier?: React.ReactNode;
      plan?: React.ReactNode;
      policyNumber?: React.ReactNode;
    };
  };
}

export const PatientForm = React.forwardRef<PatientFormRef, PatientFormProps>(
  ({ children, formMode, defaultValues, onSubmit, submitState, isIntakeFlow, fieldDescriptions }, ref) => {
    const form = useForm<PatientFormValues>({
      defaultValues: {
        ...defaultValues,
        guardian: defaultValues.guardian ?? {
          firstName: '',
          lastName: '',
          phoneNumber: '',
        },
      },
    });
    const [coverageTab, setCoverageTab] = useState<'primary' | 'secondary' | 'waiver'>('primary');
    const coverageError = useCoverageValidation(form);
    const [isGuardianInputsEnabled, setIsGuardianInputsEnabled] = useState(() => !!defaultValues.guardian);
    const vocab = useVocab();

    useInsuranceWaiverSync(form);

    const handleOnFormSubmit = useCallback(
      () =>
        form.handleSubmit((formValues) =>
          onSubmit({ ...formValues, guardian: isGuardianInputsEnabled ? formValues.guardian : null }),
        )(),
      [form, isGuardianInputsEnabled, onSubmit],
    );

    useImperativeHandle(
      ref,
      () => ({
        triggerSubmit: handleOnFormSubmit,
      }),
      [handleOnFormSubmit],
    );

    return (
      <form onSubmit={handleOnFormSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography fontWeight="600">1. {vocab.Patient} Info:</Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              control={form.control}
              disabled={formMode === 'update'}
              label="First name"
              name="patient.firstName"
              rules={{ required: 'First name is required' }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              control={form.control}
              disabled={formMode === 'update'}
              label="Middle name"
              name="patient.middleName"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input
              control={form.control}
              disabled={formMode === 'update'}
              label="Last name"
              name="patient.lastName"
              rules={{ required: 'Last name is required' }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PatientDateOfBirthInput
              control={form.control}
              disabled={formMode === 'update'}
              label="Date of birth"
              name="patient.dateOfBirth"
              rules={{ required: 'Date of birth is required' }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <EmailAddressInput
              control={form.control}
              disabled={formMode === 'update'}
              label="Email"
              name="patient.emailAddress"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <GenderInput
              control={form.control}
              label="Gender"
              name="patient.sex"
              rules={{ required: 'Gender is required' }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              control={form.control}
              label="Phone"
              name="patient.homePhoneNumber"
              rules={{
                required: 'Phone is required',
              }}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <PatientHeightInput control={form.control} label="Height (in)" name="patient.height" />
          </Grid>

          <Grid item md={3} xs={6}>
            <PatientWeightInput control={form.control} label="Weight (lbs)" name="patient.weight" />
          </Grid>

          <Grid item xs={6}>
            <PatientLanguagePreferenceInput
              control={form.control}
              label="Preferred language"
              name="patient.languagePreferenceCode"
              rules={{
                required: 'Language is required',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGuardianInputsEnabled}
                  onChange={() => setIsGuardianInputsEnabled((prev) => !prev)}
                />
              }
              label={`The ${vocab.patient} has a Legally Authorized representative (LAR) / guardian`}
              onClick={(e) => e.stopPropagation()}
            />
          </Grid>

          {isGuardianInputsEnabled && (
            <>
              <Grid item md={6} xs={12}>
                <Input
                  control={form.control}
                  label="LAR first name"
                  name="guardian.firstName"
                  rules={{ required: 'First name is required' }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Input
                  control={form.control}
                  label="LAR last name"
                  name="guardian.lastName"
                  rules={{ required: 'Last name is required' }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <PhoneNumberInput
                  control={form.control}
                  label="LAR phone"
                  name="guardian.phoneNumber"
                  rules={{ required: 'Phone is required' }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Separator margin="20px 0" />
          </Grid>

          <Grid item xs={12}>
            <Typography fontWeight="600">2. Shipping Address:</Typography>
          </Grid>

          <Grid item xs={12}>
            <AddressLine1Input
              control={form.control}
              label="Street address"
              name="shipping.addressLine1"
              onAddressChange={(address) =>
                form.setValue(
                  'shipping',
                  {
                    addressLine1: address?.streetLine ?? '',
                    addressLine2: '',
                    city: address?.city ?? '',
                    state: address?.state ?? '',
                    postalCode: address?.zipcode ?? '',
                  },
                  { shouldDirty: true },
                )
              }
              rules={{ required: 'Street address is required' }}
              transformInitialAddressValue={(address) => ({
                streetLine: address.addressLine1,
                secondary: address.addressLine2,
                city: address.city,
                state: address.state,
                zipcode: address.postalCode,
              })}
              watchFieldName="shipping"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Input control={form.control} label="Apt/Suite" name="shipping.addressLine2" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input control={form.control} label="City" name="shipping.city" rules={{ required: 'City is required' }} />
          </Grid>

          <Grid item md={6} xs={12}>
            <AddressStateInput
              control={form.control}
              label="State"
              name="shipping.state"
              rules={{ required: 'State is required' }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              control={form.control}
              label="ZIP Code"
              name="shipping.postalCode"
              rules={{ required: 'ZIP Code is required' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Separator margin="20px 0" />
          </Grid>

          <Grid item xs={12}>
            <Typography fontWeight="600">3. Coverage info:</Typography>
          </Grid>

          <Grid item xs={12}>
            <Tabs onChange={(_evt, value) => setCoverageTab(value)} value={coverageTab} variant="scrollable">
              <Tab label="Primary Insurance" value="primary" />
              <Tab label="Secondary Insurance" value="secondary" />
              <Tab label="Waiver" value="waiver" />
            </Tabs>
          </Grid>

          {(() => {
            switch (coverageTab) {
              case 'primary':
                return (
                  <>
                    <Grid item xs={12}>
                      <InsurancePrimaryCarrierInput
                        control={form.control}
                        error={coverageError}
                        name="insurance.primary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.primaryInsurance?.carrier && (
                      <Grid item xs={12}>
                        {fieldDescriptions.primaryInsurance.carrier}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <InsurancePrimaryPlanInput
                        control={form.control}
                        name="insurance.primary.planId"
                        watchFieldName="insurance.primary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.primaryInsurance?.plan && (
                      <Grid item xs={12}>
                        {fieldDescriptions.primaryInsurance.plan}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <InsurancePrimaryMemberIdInput
                        control={form.control}
                        name="insurance.primary.policyNumber"
                        watchFieldName="insurance.primary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.primaryInsurance?.policyNumber && (
                      <Grid item xs={12}>
                        {fieldDescriptions.primaryInsurance.policyNumber}
                      </Grid>
                    )}
                  </>
                );

              case 'secondary':
                return (
                  <>
                    <Grid item xs={12}>
                      <InsuranceSecondaryCarrierInput
                        control={form.control}
                        error={coverageError}
                        name="insurance.secondary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.secondaryInsurance?.carrier && (
                      <Grid item xs={12}>
                        {fieldDescriptions.secondaryInsurance.carrier}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <InsuranceSecondaryPlanInput
                        control={form.control}
                        name="insurance.secondary.planId"
                        watchFieldName="insurance.secondary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.secondaryInsurance?.plan && (
                      <Grid item xs={12}>
                        {fieldDescriptions.secondaryInsurance.plan}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <InsuranceSecondaryMemberIdInput
                        control={form.control}
                        name="insurance.secondary.policyNumber"
                        watchFieldName="insurance.secondary.carrierId"
                      />
                    </Grid>

                    {fieldDescriptions?.secondaryInsurance?.policyNumber && (
                      <Grid item xs={12}>
                        {fieldDescriptions.secondaryInsurance.policyNumber}
                      </Grid>
                    )}
                  </>
                );

              case 'waiver':
                return (
                  <>
                    <Grid item xs={12}>
                      <InsuranceWaiverInput
                        control={form.control}
                        error={coverageError}
                        name="insurance.waiverId"
                        watchFieldName="shipping.state"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InsuranceWaiverAlert form={form} isIntakeFlow={isIntakeFlow} />
                    </Grid>
                  </>
                );

              default:
                return undefined;
            }
          })()}

          {/* Always rendered so that the slow data fetch happens ASAP */}
          <Grid item xs={12}>
            <PrimaryInsuranceVerificationAlert form={form} visible={coverageTab === 'primary'} />
            <SecondaryInsuranceVerificationAlert form={form} visible={coverageTab === 'secondary'} />
          </Grid>

          {submitState.error ? (
            <Grid item xs={12}>
              <ErrorInfo errorText={submitState.error} margin="0 0 20px " />
            </Grid>
          ) : null}

          {children ? (
            <Grid item xs={12}>
              {children}
            </Grid>
          ) : null}
        </Grid>
      </form>
    );
  },
);

function PrimaryInsuranceVerificationAlert({ form, visible }: { form: Form; visible: boolean }) {
  const patient = useWatch({ control: form.control, name: 'patient' });
  const insurance = useWatch({ control: form.control, name: 'insurance.primary' });
  return <InsuranceVerificationAlert insurance={insurance} patient={patient} type="primary" visible={visible} />;
}

function SecondaryInsuranceVerificationAlert({ form, visible }: { form: Form; visible: boolean }) {
  const patient = useWatch({ control: form.control, name: 'patient' });
  const insurance = useWatch({ control: form.control, name: 'insurance.secondary' });
  return <InsuranceVerificationAlert insurance={insurance} patient={patient} type="secondary" visible={visible} />;
}

function InsuranceVerificationAlert({
  insurance,
  patient,
  type,
  visible,
}: {
  insurance: PatientFormValues['insurance']['primary'];
  patient: PatientFormValues['patient'];
  visible: boolean;
  type: 'primary' | 'secondary';
}) {
  const vars: GetPatientInsuranceDetailsQueryVariables = {
    firstName: useDebounce(patient.firstName, 200),
    lastName: useDebounce(patient.lastName, 200),
    dateOfBirth: useDebounce(
      patient.dateOfBirth && isValid(patient.dateOfBirth) ? (formatDate(patient.dateOfBirth, 'date') ?? '') : '',
      200,
    ),
    policyNumber: useDebounce(insurance.policyNumber, 200),
    insurerId: getInsurerId(insurance),
  };

  const { data: insurers } = useInsurers();
  const carrier = insurers?.find((c) => c.id === insurance.carrierId);

  const verify = useQuery({
    async queryFn(context) {
      const data = await useGetPatientInsuranceDetailsQuery.fetcher(vars)(context);
      logAction(`patient-modal.insurance-verification.result.${transformInsuranceDetails(data)}`, {
        insurerId: getInsurerId(insurance),
        type,
      });

      return data;
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: useGetPatientInsuranceDetailsQuery.getKey(vars),
    enabled: !!vars.firstName && !!vars.lastName && !!vars.dateOfBirth && !!vars.policyNumber && !!vars.insurerId,
    select: transformInsuranceDetails,
    meta: {
      errorMessage: 'Failed to verify insurance.',
    },
  });

  if (!visible || !carrier?.eligibilityShowErrors) return null;

  if (verify.isInitialLoading)
    return (
      <Alert severity="info">
        <AlertTitle>Verifying insurance details...</AlertTitle>
      </Alert>
    );

  switch (verify.data) {
    case 'verified':
      return (
        <Alert severity="success">
          <AlertTitle>Automatic insurance verification succeeded.</AlertTitle>
        </Alert>
      );

    case 'not-verified-error':
    case 'not-verified':
      return (
        <Alert severity="error">
          <AlertTitle>Automatic insurance verification failed.</AlertTitle>
          Please double-check insurance details entered above before saving changes. We will also manually verify upon
          order submission.
        </Alert>
      );
    default:
      return null;
  }
}
function transformInsuranceDetails(query: GetPatientInsuranceDetailsQuery) {
  const details = query.patientInsuranceDetails;
  if (details?.verified) return 'verified';
  if (details?.showErrors) return 'not-verified-error';
  return 'not-verified';
}

function useCoverageValidation(form: Form) {
  const validateCoverage = useController({
    control: form.control,
    name: 'insurance',
    rules: {
      validate(insurance) {
        return !insurance.primary.carrierId && !insurance.secondary.carrierId && !insurance.waiverId
          ? 'At least one of primary, secondary, or waiver is required.'
          : undefined;
      },
    },
  });
  return validateCoverage.fieldState.error?.message;
}

function useInsuranceWaiverSync(form: Form) {
  const state = useWatch({ control: form.control, name: 'shipping.state' });
  const waiverId = useWatch({ control: form.control, name: 'insurance.waiverId' });

  const options = useWaivers({ state });
  const optionExists = options.data?.waivers?.edges?.some((edge) => edge?.node?.id === waiverId);

  useEffect(() => {
    if (options.isSuccess && !optionExists) form.setValue('insurance.waiverId', '');
  }, [options.isSuccess, optionExists, form]);
}

function InsuranceWaiverAlert({ form, isIntakeFlow }: { form: Form; isIntakeFlow?: boolean }) {
  const vocab = useVocab();
  const state = useWatch({ control: form.control, name: 'shipping.state' });
  const options = useWaivers({ state });

  if (!state) {
    return <Alert severity="info">Available waiver programs are based on the {vocab.patient}&apos;s state.</Alert>;
  }

  if (!options.data) return null;

  if (!options.data.waivers?.edges?.length) {
    return <Alert severity="warning">There are currently no waiver programs available for the state of {state}</Alert>;
  }

  if (isIntakeFlow)
    return (
      <Alert severity="info">
        Additional waiver and coverage details can be provided in the <b>Notes</b> field on the <b>Review &amp; Send</b>{' '}
        step.
      </Alert>
    );

  return null;
}

type PatientFormValuesSource = Omit<IntakePatient, 'id' | 'shippingAddress'> & {
  shippingAddress?: Omit<NonNullable<IntakePatient['shippingAddress']>, 'id'> | null;
};
export function toDefaultPatientFormValues(patient: PatientFormValuesSource | null | undefined): PatientFormValues {
  return {
    patient: {
      firstName: patient?.firstName ?? '',
      middleName: patient?.middleName ?? '',
      lastName: patient?.lastName ?? '',
      dateOfBirth: patient?.dateOfBirth ? parseDate(patient.dateOfBirth) : null,
      emailAddress: patient?.emailAddress ?? '',
      sex: patient?.sex ?? null,
      homePhoneNumber: getFormattedPhoneForDisplay(patient?.homePhoneNumber ?? ''),
      height: patient?.heightInInches ? String(patient.heightInInches) : '',
      weight: patient?.weightInPounds ? String(patient.weightInPounds) : '',
      languagePreferenceCode: patient?.languagePreference?.code ?? 'en',
    },
    guardian: patient?.guardian
      ? {
          firstName: patient?.guardian?.firstName ?? '',
          lastName: patient?.guardian?.lastName ?? '',
          phoneNumber: getFormattedPhoneForDisplay(patient?.guardian?.phoneNumber ?? ''),
        }
      : null,
    shipping: {
      addressLine1: patient?.shippingAddress?.addressLine1 ?? '',
      addressLine2: patient?.shippingAddress?.addressLine2 ?? '',
      city: patient?.shippingAddress?.city ?? '',
      state: patient?.shippingAddress?.state ?? '',
      postalCode: patient?.shippingAddress?.postalCode ?? '',
    },
    insurance: {
      primary: {
        carrierId: patient?.primaryInsurance?.insurer?.parent?.id ?? '',
        planId: patient?.primaryInsurance?.insurer?.id ?? '',
        policyNumber: patient?.primaryInsurance?.policyNumber ?? '',
      },
      secondary: {
        carrierId: patient?.secondaryInsurance?.insurer?.parent?.id ?? '',
        planId: patient?.secondaryInsurance?.insurer?.id ?? '',
        policyNumber: patient?.secondaryInsurance?.policyNumber ?? '',
      },
      waiverId: patient?.patientWaiver?.waiver?.id ?? '',
    },
  };
}
