import { logAction } from '../../utils/logging';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Spinner from './Spinner';

type SpinnerScreenProps = {
  fullHeight?: boolean;
};

function SpinnerScreen({ fullHeight }: SpinnerScreenProps) {
  useEffect(function logHowLongSpinnerIsShown() {
    const start = new Date();
    return () => {
      const end = new Date();
      const duration = end.valueOf() - start.valueOf();
      logAction('Spinner', { duration }, { excludeMixpanel: true });
    };
  }, []);

  return (
    <Box alignItems="center" display="flex" height={fullHeight ? '100vh' : '100%'} justifyContent="center" width="100%">
      <Spinner />
    </Box>
  );
}

export default SpinnerScreen;
