import CheckedDisabledIcon from '../assets/checkbox-disabled-checked.svg?react';
import CheckedErrorIcon from '../assets/checkbox-error-checked.svg?react';
import CheckedIcon from '../assets/checkbox-checked.svg?react';
import DisabledIcon from '../assets/checkbox-disabled.svg?react';
import ErrorIcon from '../assets/checkbox-error.svg?react';
import IndeterminateIcon from '../assets/checkbox-indeterminate.svg?react';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import UncheckedIcon from '../assets/checkbox-unchecked.svg?react';

type CheckboxProps = {
  error?: boolean;
} & MuiCheckboxProps;

const getCheckedIcon = (error: boolean, disabled: boolean) => {
  if (error) {
    return <CheckedErrorIcon />;
  }
  if (disabled) {
    return <CheckedDisabledIcon />;
  }
  return <CheckedIcon />;
};

const getUncheckedIcon = (error: boolean, disabled: boolean) => {
  if (error) {
    return <ErrorIcon />;
  }
  if (disabled) {
    return <DisabledIcon />;
  }
  return <UncheckedIcon />;
};

export const Checkbox = ({ error = false, disabled = false, ...rest }: CheckboxProps) => {
  return (
    <MuiCheckbox
      {...rest}
      checkedIcon={getCheckedIcon(error, disabled)}
      color="primary"
      disabled={disabled}
      icon={getUncheckedIcon(error, disabled)}
      indeterminateIcon={<IndeterminateIcon />}
    />
  );
};
