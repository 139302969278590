import { cn } from '../lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { InputBase, inputBaseVariants } from './InputBase';
import { Label } from './Label';
import React from 'react';

const inputVariants = cva('peer placeholder:text-transparent focus:placeholder:text-muted-foreground', {
  variants: {
    variant: {
      default: '',
      destructive: '',
    },
    size: {
      small: 'pb-2 pt-5',
      medium: 'pb-2 pt-5',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'medium',
  },
}) satisfies typeof inputBaseVariants;

export interface InputProps extends React.ComponentPropsWithoutRef<typeof InputBase> {
  label?: string;
}

const Input = React.forwardRef<React.ElementRef<typeof InputBase>, InputProps>(
  ({ id, label, className, size, variant, placeholder = ' ', ...props }, ref) => {
    const input: React.ReactElement = label ? (
      <div className="relative">
        <InputBase
          ref={ref}
          className={cn(inputVariants({ variant, size }), className)}
          id={id}
          placeholder={placeholder}
          size={size}
          variant={variant}
          {...props}
        />
        <FloatingLabel htmlFor={id} size={size} variant={variant}>
          {label}
        </FloatingLabel>
      </div>
    ) : (
      <InputBase
        ref={ref}
        className={className}
        id={id}
        placeholder={placeholder}
        size={size}
        variant={variant}
        {...props}
      />
    );

    return input;
  },
);

Input.displayName = 'Input';

const floatingLabelVariants = cva(
  'peer-focus:secondary peer-focus:dark:secondary pointer-events-none absolute z-10 origin-[0] scale-75 bg-transparent text-sm text-gray-500 duration-300 peer-placeholder-shown:scale-100 peer-focus:scale-75',
  {
    variants: {
      variant: {
        default: '',
        destructive: '',
      },
      size: {
        small:
          'start-[13px] top-1.5 -translate-y-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-0 peer-focus:translate-y-0.5',
        medium:
          'start-[17px] top-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-2.5 peer-focus:top-0 peer-focus:translate-y-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
  },
) satisfies typeof inputBaseVariants;

export interface FloatingLabelProps
  extends React.ComponentPropsWithoutRef<typeof Label>,
    VariantProps<typeof inputBaseVariants> {}

const FloatingLabel = React.forwardRef<React.ElementRef<typeof Label>, FloatingLabelProps>(
  ({ className, ...props }, ref) => {
    return (
      <Label
        ref={ref}
        className={cn(floatingLabelVariants({ variant: props.variant, size: props.size }), className)}
        {...props}
      />
    );
  },
);

FloatingLabel.displayName = 'FloatingLabel';

export { Input };
