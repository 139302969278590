import { AppInsurerInsurerCategoryDirectChoices, OrgTypes } from '../api-clients/falcon-api/graphql/types.generated';
import { findOrgUser, useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { useMemo } from 'react';
import { useOrgIdParam } from './useOrgIdParam';

export function useVocab() {
  const { data: user } = useCurrentUser();
  const orgId = useOrgIdParam() ?? '';

  return useMemo(() => {
    const orgUser = findOrgUser(user, { orgId });
    const orgType = orgUser?.org?.orgTypeEnum;
    const cc = orgType === OrgTypes.CareCoordination;
    const shp = orgUser?.org.insurerCategoryEnum === AppInsurerInsurerCategoryDirectChoices.Shp;

    return {
      patient: cc ? 'member' : ' patient',
      Patient: cc ? 'Member' : 'Patient',
      order: cc ? 'referral' : 'order',
      Order: cc ? 'Referral' : 'Order',
      CareCoordinator: shp ? 'Service Coordinator' : 'Care Coordinator',
    } as const;
  }, [orgId, user]);
}
