import { Link as RouterLink } from 'react-router-dom';
import { SkipToMainContent } from '@tomorrow/ui';
import { theme } from '@tomorrow/ui/theme';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import BirdLogo from '../../assets/bird-logo-dark.svg?react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FullBirdLogo from '../../assets/updated-logo.svg?react';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export function GeneralHeader({ homeLink, rightContent }: { homeLink: string; rightContent?: React.ReactNode }) {
  const [isProdAlertBannerVisible, setIsProdAlertBannerVisible] = useState(
    () => import.meta.env.REACT_APP_ENV !== 'production',
  );

  function handleClose() {
    setIsProdAlertBannerVisible(false);
  }

  return (
    <AppBar position="static" sx={{ borderBottom: '1px solid rgba(0, 38, 56, 0.1)' }}>
      <SkipToMainContent />
      <Box color={theme.colors.black}>
        <Container>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '66px',
              whiteSpace: 'nowrap',
            }}
          >
            <Logo homeLink={homeLink} />
            {isProdAlertBannerVisible && (
              <Box onClick={handleClose} sx={{ cursor: 'pointer', overflow: 'auto', flexGrow: 1, display: 'flex' }}>
                <NonProdAlert onClose={handleClose} />
              </Box>
            )}
            <Box flexGrow="1" />
            {rightContent}
          </Stack>
        </Container>
      </Box>
    </AppBar>
  );
}

function Logo({ homeLink }: { homeLink: string }) {
  return (
    <RouterLink to={homeLink}>
      <Hidden smUp>
        <Box display="flex" marginLeft="-10px">
          <BirdLogo height="61px" width="61px" />
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box display="flex">
          <FullBirdLogo height="45px" width="132px" />
        </Box>
      </Hidden>
    </RouterLink>
  );
}

function NonProdAlert({ onClose }: { onClose?: () => void }) {
  // if env is QA, label it as demo here.
  const envLabel = import.meta.env.REACT_APP_ENV === 'qa' ? 'demo' : import.meta.env.REACT_APP_ENV;
  const label = `${envLabel} env`;

  return (
    <Alert onClose={onClose} severity="warning" title={label}>
      <Typography>
        <Box component="span" fontWeight="600">
          {label}
        </Box>
      </Typography>
    </Alert>
  );
}
