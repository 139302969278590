import { Outlet, useOutletContext } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import React, { forwardRef, Suspense } from 'react';
import Slide from '@mui/material/Slide';
import SpinnerScreen from '../components/Spinner/SpinnerScreen';
import type { TransitionProps } from '@mui/material/transitions';

export function ModalLayout() {
  const context = useOutletContext();

  return (
    <Suspense fallback={<SpinnerScreen fullHeight />}>
      <Dialog fullScreen={true} open TransitionComponent={Transition}>
        <Outlet context={context} />
      </Dialog>
    </Suspense>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide ref={ref} direction="up" mountOnEnter unmountOnExit {...props} />;
});
