import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreviousLocation } from '../contexts/PreviousLocationContext';
import { viewIds } from '../hooks/useAddViewIdToHttpHeaders';
import mixpanel from 'mixpanel-browser';

export const isMixPanelEnabled = !!import.meta.env.REACT_APP_MIXPANEL_TOKEN;
export const isChameleonEnabled = !!import.meta.env.REACT_APP_CHAMELEON_KEY;

export function logError(error: unknown, extra?: Record<string, unknown>) {
  // eslint-disable-next-line no-console
  if (import.meta.env.REACT_APP_ENV === 'development') console.error(error);
  Sentry.captureException(error, { extra });
  datadogRum.addError(error, extra);
}

export function logWarning(message: string, extra?: Record<string, unknown>) {
  Sentry.captureMessage(message, { level: 'warning', extra });
}

type LogActionConfig = { excludeMixpanel?: boolean };
export type LogActionExtra = { [key: string]: unknown } & { [key in keyof LogActionConfig]: never };

export function logAction(message: string, extra?: LogActionExtra, config?: LogActionConfig) {
  const allExtra = { viewIds: viewIds.join(','), ...extra };
  datadogRum.addAction(message, allExtra);

  if (isMixPanelEnabled && !config?.excludeMixpanel) {
    mixpanel.track(message, allExtra);
  }
}

export function setOrgForLogging(
  org: NonNullable<NonNullable<ReturnType<typeof useCurrentUser>['data']>['orgUsers']>[number]['org'],
) {
  if (!org) return;

  datadogRum.setUserProperty('orgId', org.id);
  datadogRum.setUserProperty('orgName', org.name);
  datadogRum.setUserProperty('orgType', org.orgTypeEnum);
  datadogRum.setUserProperty('practiceId', org.practice?.id);

  Sentry.setContext('org', {
    orgId: org.id,
    orgName: org.name,
    orgType: org.orgTypeEnum,
    practiceId: org.practice?.id,
  });

  if (mixpanel?.people) {
    mixpanel.people.set({
      orgId: org.id,
      orgName: org.name,
      orgType: org.orgTypeEnum,
      practiceId: org.practice?.id,
    });
  }
}

// TODO: Refactor as a Context Provider that accepts a list of routes to track navigation for
export const useTrackRouteNavigation = () => {
  const previousLocationRef = useRef(usePreviousLocation());
  const locationRef = useRef(useLocation());

  useEffect(function trackNavigation() {
    const location = locationRef.current;
    const previousLocation = previousLocationRef.current;

    logAction('Navigation', {
      prevLocation: previousLocation,
      currentLocation: location,
    });
  }, []);
};
