import { useEffect, useRef } from 'react';

/**
 * Call function in `useEffect` when value changes, but not when component mounts
 * @param fn callback function to be invoked when any element's value in `dependencies` updates
 * @param dependencies
 * @see https://mantine.dev/hooks/use-did-update/
 */
const useDidUpdate = (fn: React.EffectCallback, dependencies?: any[]) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return fn();
    }

    mounted.current = true;
    return undefined;
    // TODO: Fix lint error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useDidUpdate;
