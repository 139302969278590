import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetSupplierOrdersQueryVariables = Types.Exact<{
  orgId: Types.Scalars['UUID']['input'];
  supplierId: Types.Scalars['ID']['input'];
  patientId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  category?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  assignedBefore?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  assignedAfter?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  serviceLevels?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusIn?: Types.InputMaybe<Types.Scalars['String']['input']>;
  patientInsurers?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderByAssignedAt?: Types.InputMaybe<Types.BaseCustomOrdering>;
  orderByEstimatedDeliveryDate?: Types.InputMaybe<Types.BaseCustomOrdering>;
  orderByLocationName?: Types.InputMaybe<Types.BaseCustomOrdering>;
  submitterOrSigners?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.InputMaybe<Types.SupplyStatus>> | Types.InputMaybe<Types.SupplyStatus>>;
  locationId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  estimatedDeliveryAfter?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  estimatedDeliveryBefore?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;

export type GetSupplierOrdersQuery = {
  __typename?: 'Query';
  supplierOrders?: {
    __typename?: 'SupplierOrderTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'SupplierOrderTypeEdge';
      node?: {
        __typename?: 'SupplierOrderType';
        id: string;
        friendlyId?: string | null;
        createdAt: string;
        dischargeAt?: string | null;
        referralSource?: Types.AppOrderReferralSourceChoices | null;
        mostRecentAssignmentForSupplier?: {
          __typename?: 'OrderAssignmentType';
          estimatedDeliveryAt?: string | null;
          dueAt?: string | null;
          createdAt: string;
          trackingNumberSummary: {
            __typename?: 'OrderAssignmentTrackingNumberSummaryType';
            hasFailedTrackingNumber: boolean;
            hasTrackingNumber: boolean;
          };
          location?: { __typename?: 'NetworkSupplierLocationType'; id: string; name: string } | null;
        } | null;
        patient: {
          __typename?: 'PatientType';
          fullName?: string | null;
          primaryInsurance?: { __typename?: 'PatientInsuranceType'; policyNumber: string } | null;
          secondaryInsurance?: { __typename?: 'PatientInsuranceType'; policyNumber: string } | null;
        };
        intakeCategory?: { __typename?: 'IntakeCategoryType'; name: string } | null;
        intakeSubcategory?: { __typename?: 'IntakeSubcategoryType'; name: string } | null;
        referringProvider?: { __typename?: 'ProviderType'; firstName: string; lastName: string } | null;
        supplyEventTimeline?: Array<{
          __typename?: 'OrderTimelineEntryType';
          statusHistory?: { __typename?: 'OrderStatusHistorySlimType'; supplyStatus?: string | null } | null;
        } | null> | null;
        oldestUnreadComment?: { __typename?: 'CommentType'; id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type SupplierOrderFragment = {
  __typename?: 'SupplierOrderType';
  id: string;
  friendlyId?: string | null;
  createdAt: string;
  dischargeAt?: string | null;
  referralSource?: Types.AppOrderReferralSourceChoices | null;
  mostRecentAssignmentForSupplier?: {
    __typename?: 'OrderAssignmentType';
    estimatedDeliveryAt?: string | null;
    dueAt?: string | null;
    createdAt: string;
    trackingNumberSummary: {
      __typename?: 'OrderAssignmentTrackingNumberSummaryType';
      hasFailedTrackingNumber: boolean;
      hasTrackingNumber: boolean;
    };
    location?: { __typename?: 'NetworkSupplierLocationType'; id: string; name: string } | null;
  } | null;
  patient: {
    __typename?: 'PatientType';
    fullName?: string | null;
    primaryInsurance?: { __typename?: 'PatientInsuranceType'; policyNumber: string } | null;
    secondaryInsurance?: { __typename?: 'PatientInsuranceType'; policyNumber: string } | null;
  };
  intakeCategory?: { __typename?: 'IntakeCategoryType'; name: string } | null;
  intakeSubcategory?: { __typename?: 'IntakeSubcategoryType'; name: string } | null;
  referringProvider?: { __typename?: 'ProviderType'; firstName: string; lastName: string } | null;
  supplyEventTimeline?: Array<{
    __typename?: 'OrderTimelineEntryType';
    statusHistory?: { __typename?: 'OrderStatusHistorySlimType'; supplyStatus?: string | null } | null;
  } | null> | null;
  oldestUnreadComment?: { __typename?: 'CommentType'; id: string } | null;
};

export const SupplierOrderFragmentDoc = `
    fragment SupplierOrderFragment on SupplierOrderType {
  id
  friendlyId
  createdAt
  dischargeAt
  mostRecentAssignmentForSupplier(supplierId: $supplierId) {
    estimatedDeliveryAt
  }
  patient {
    fullName
    primaryInsurance {
      policyNumber
    }
    secondaryInsurance {
      policyNumber
    }
  }
  intakeCategory {
    name
  }
  intakeSubcategory {
    name
  }
  referringProvider {
    firstName
    lastName
  }
  referralSource
  supplyEventTimeline(supplierId: $supplierId) {
    statusHistory {
      supplyStatus
    }
  }
  oldestUnreadComment(orgId: $orgId) {
    id
  }
  mostRecentAssignmentForSupplier(supplierId: $supplierId) {
    dueAt
    createdAt
    trackingNumberSummary {
      hasFailedTrackingNumber
      hasTrackingNumber
    }
    location {
      id
      name
    }
  }
}
    `;
export const GetSupplierOrdersDocument = `
    query getSupplierOrders($orgId: UUID!, $supplierId: ID!, $patientId: UUID, $offset: Int, $first: Int, $last: Int, $category: UUID, $assignedBefore: DateTime, $assignedAfter: DateTime, $searchText: String, $serviceLevels: String, $statusIn: String, $patientInsurers: [String!], $orderBy: String, $orderByAssignedAt: BaseCustomOrdering, $orderByEstimatedDeliveryDate: BaseCustomOrdering, $orderByLocationName: BaseCustomOrdering, $submitterOrSigners: String, $statuses: [SupplyStatus], $locationId: String, $estimatedDeliveryAfter: DateTime, $estimatedDeliveryBefore: DateTime) {
  supplierOrders(
    supplierId: $supplierId
    patientId: $patientId
    offset: $offset
    first: $first
    last: $last
    category: $category
    assignedBefore: $assignedBefore
    assignedAfter: $assignedAfter
    searchText: $searchText
    serviceLevels: $serviceLevels
    statusIn: $statusIn
    patientInsurers: $patientInsurers
    statuses: $statuses
    locationId: $locationId
    orderByAssignedAt: $orderByAssignedAt
    orderByEstimatedDeliveryDate: $orderByEstimatedDeliveryDate
    orderByLocationName: $orderByLocationName
    orderBy: $orderBy
    submitterOrSigners: $submitterOrSigners
    estimatedDeliveryAfter: $estimatedDeliveryAfter
    estimatedDeliveryBefore: $estimatedDeliveryBefore
  ) {
    totalCount
    edges {
      node {
        ...SupplierOrderFragment
      }
    }
  }
}
    ${SupplierOrderFragmentDoc}`;

export const useGetSupplierOrdersQuery = <TData = GetSupplierOrdersQuery, TError = unknown>(
  variables: GetSupplierOrdersQueryVariables,
  options?: Omit<UseQueryOptions<GetSupplierOrdersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetSupplierOrdersQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetSupplierOrdersQuery, TError, TData>({
    queryKey: ['getSupplierOrders', variables],
    queryFn: fetcher<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>(GetSupplierOrdersDocument, variables),
    ...options,
  });
};

useGetSupplierOrdersQuery.getKey = (variables: GetSupplierOrdersQueryVariables) => ['getSupplierOrders', variables];

export const useInfiniteGetSupplierOrdersQuery = <TData = InfiniteData<GetSupplierOrdersQuery>, TError = unknown>(
  variables: GetSupplierOrdersQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetSupplierOrdersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetSupplierOrdersQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetSupplierOrdersQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getSupplierOrders.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>(GetSupplierOrdersDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetSupplierOrdersQuery.getKey = (variables: GetSupplierOrdersQueryVariables) => [
  'getSupplierOrders.infinite',
  variables,
];

useGetSupplierOrdersQuery.fetcher = (variables: GetSupplierOrdersQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>(GetSupplierOrdersDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetSupplierOrdersQuery((req, res, ctx) => {
 *   const { orgId, supplierId, patientId, offset, first, last, category, assignedBefore, assignedAfter, searchText, serviceLevels, statusIn, patientInsurers, orderBy, orderByAssignedAt, orderByEstimatedDeliveryDate, orderByLocationName, submitterOrSigners, statuses, locationId, estimatedDeliveryAfter, estimatedDeliveryBefore } = req.variables;
 *   return res(
 *     ctx.data({ supplierOrders })
 *   )
 * })
 */
export const mockGetSupplierOrdersQuery = (
  resolver: Parameters<typeof graphql.query<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>>[1],
) => graphql.query<GetSupplierOrdersQuery, GetSupplierOrdersQueryVariables>('getSupplierOrders', resolver);
