import * as Types from '../types.generated.js';

import {
  FaxOrderSeedFragmentDoc,
  ExtractionResultsFragmentDoc,
  ExtractionCoordinatesFragmentDoc,
} from '../queries/getFaxOrderSeed.generated.js';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type UpdateOrderSeedMutationVariables = Types.Exact<{
  input: Types.UpdateOrderSeedInput;
}>;

export type UpdateOrderSeedMutation = {
  __typename?: 'Mutation';
  updateOrderSeed?: {
    __typename?: 'UpdateOrderSeedMutation';
    orderSeed?: {
      __typename?: 'OrderSeedType';
      id: string;
      status: Types.AppOrderSeedStatusChoices;
      primaryExtractor: Types.AppOrderSeedPrimaryExtractorChoices;
      extractionResultsSet?: {
        __typename?: 'ExtractionResultsSetType';
        id: string;
        synthpopClinicalSummary?: string | null;
        orderFieldRequirements: string;
        patientFirstNameRaw?: string | null;
        patientFirstNameCorrected?: string | null;
        patientFirstNameConfidence?: Types.AppExtractionResultsSetPatientFirstNameConfidenceChoices | null;
        patientMiddleNameRaw?: string | null;
        patientMiddleNameCorrected?: string | null;
        patientMiddleNameConfidence?: Types.AppExtractionResultsSetPatientMiddleNameConfidenceChoices | null;
        patientLastNameRaw?: string | null;
        patientLastNameCorrected?: string | null;
        patientLastNameConfidence?: Types.AppExtractionResultsSetPatientLastNameConfidenceChoices | null;
        patientDateOfBirthRaw?: string | null;
        patientDateOfBirthCorrected?: string | null;
        patientDateOfBirthConfidence?: Types.AppExtractionResultsSetPatientDateOfBirthConfidenceChoices | null;
        patientSexRaw?: string | null;
        patientSexCorrected?: string | null;
        patientSexConfidence?: Types.AppExtractionResultsSetPatientSexConfidenceChoices | null;
        patientEmailAddressRaw?: string | null;
        patientEmailAddressCorrected?: string | null;
        patientEmailAddressConfidence?: Types.AppExtractionResultsSetPatientEmailAddressConfidenceChoices | null;
        patientHeightInInchesRaw?: number | null;
        patientHeightInInchesCorrected?: number | null;
        patientHeightInInchesConfidence?: Types.AppExtractionResultsSetPatientHeightInInchesConfidenceChoices | null;
        patientWeightInPoundsRaw?: number | null;
        patientWeightInPoundsCorrected?: number | null;
        patientWeightInPoundsConfidence?: Types.AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices | null;
        patientCellPhoneRaw?: string | null;
        patientCellPhoneCorrected?: string | null;
        patientCellPhoneConfidence?: Types.AppExtractionResultsSetPatientCellPhoneConfidenceChoices | null;
        patientHomePhoneRaw?: string | null;
        patientHomePhoneCorrected?: string | null;
        patientHomePhoneConfidence?: Types.AppExtractionResultsSetPatientHomePhoneConfidenceChoices | null;
        providerFirstNameRaw?: string | null;
        providerFirstNameCorrected?: string | null;
        providerFirstNameConfidence?: Types.AppExtractionResultsSetProviderFirstNameConfidenceChoices | null;
        providerLastNameRaw?: string | null;
        providerLastNameCorrected?: string | null;
        providerLastNameConfidence?: Types.AppExtractionResultsSetProviderLastNameConfidenceChoices | null;
        providerNpiRaw?: string | null;
        providerNpiCorrected?: string | null;
        providerNpiConfidence?: Types.AppExtractionResultsSetProviderNpiConfidenceChoices | null;
        supplierNameRaw?: string | null;
        supplierNameCorrected?: string | null;
        supplierNameConfidence?: Types.AppExtractionResultsSetSupplierNameConfidenceChoices | null;
        referralDateRaw?: string | null;
        referralDateCorrected?: string | null;
        referralDateConfidence?: Types.AppExtractionResultsSetReferralDateConfidenceChoices | null;
        subcategoryNameRaw?: string | null;
        subcategoryNameCorrected?: string | null;
        subcategoryNameConfidence?: Types.AppExtractionResultsSetSubcategoryNameConfidenceChoices | null;
        subcategoryIdRaw?: string | null;
        subcategoryIdCorrected?: string | null;
        bloodGasStudyRaw?: string | null;
        bloodGasStudyCorrected?: string | null;
        bloodGasStudyConfidence?: Types.AppExtractionResultsSetBloodGasStudyConfidenceChoices | null;
        sleepStudyRaw?: string | null;
        sleepStudyCorrected?: string | null;
        sleepStudyConfidence?: Types.AppExtractionResultsSetSleepStudyConfidenceChoices | null;
        chartNotesRaw?: string | null;
        chartNotesCorrected?: string | null;
        chartNotesConfidence?: Types.AppExtractionResultsSetChartNotesConfidenceChoices | null;
        coverSheetRaw?: string | null;
        coverSheetCorrected?: string | null;
        coverSheetConfidence?: Types.AppExtractionResultsSetCoverSheetConfidenceChoices | null;
        providerSignaturePresentRaw?: boolean | null;
        providerSignaturePresentCorrected?: boolean | null;
        providerSignaturePresentConfidence?: Types.AppExtractionResultsSetProviderSignaturePresentConfidenceChoices | null;
        prescriptionRaw?: string | null;
        prescriptionCorrected?: string | null;
        prescriptionConfidence?: Types.AppExtractionResultsSetPrescriptionConfidenceChoices | null;
        patientAhiRaw?: number | null;
        patientAhiCorrected?: number | null;
        patientAhiConfidence?: Types.AppExtractionResultsSetPatientAhiConfidenceChoices | null;
        patientTotalSleepTimeRaw?: number | null;
        patientTotalSleepTimeCorrected?: number | null;
        patientTotalSleepTimeConfidence?: Types.AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices | null;
        patientReiRaw?: number | null;
        patientReiCorrected?: number | null;
        patientReiConfidence?: Types.AppExtractionResultsSetPatientReiConfidenceChoices | null;
        patientRdiRaw?: number | null;
        patientRdiCorrected?: number | null;
        patientRdiConfidence?: Types.AppExtractionResultsSetPatientRdiConfidenceChoices | null;
        patientNeckSizeRaw?: string | null;
        patientNeckSizeCorrected?: string | null;
        patientNeckSizeConfidence?: Types.AppExtractionResultsSetPatientNeckSizeConfidenceChoices | null;
        patientNeckCircumferenceInInchesRaw?: number | null;
        patientNeckCircumferenceInInchesCorrected?: number | null;
        patientNeckCircumferenceInInchesConfidence?: Types.AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices | null;
        patientLastSeenDateRaw?: string | null;
        patientLastSeenDateCorrected?: string | null;
        patientLastSeenDateConfidence?: Types.AppExtractionResultsSetPatientLastSeenDateConfidenceChoices | null;
        patientHypopneaScoreRaw?: number | null;
        patientHypopneaScoreCorrected?: number | null;
        patientHypopneaScoreConfidence?: Types.AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices | null;
        patientEssRaw?: number | null;
        patientEssCorrected?: number | null;
        patientEssConfidence?: Types.AppExtractionResultsSetPatientEssConfidenceChoices | null;
        patientBpRaw?: string | null;
        patientBpCorrected?: string | null;
        patientBpConfidence?: Types.AppExtractionResultsSetPatientBpConfidenceChoices | null;
        patientBmiRaw?: number | null;
        patientBmiCorrected?: number | null;
        patientBmiConfidence?: Types.AppExtractionResultsSetPatientBmiConfidenceChoices | null;
        normalizedCoordinates?: {
          __typename?: 'CoordinatesSetType';
          patientFirstName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientMiddleName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientLastName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientSex?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientCellPhone?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientDateOfBirth?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientEmailAddress?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientHomePhone?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientHeightInInches?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientWeightInPounds?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          patientHomeAddress?: {
            __typename?: 'CoordinatesAddressResultType';
            address1?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            address2?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            city?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            postalCode?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            state?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null;
          patientShippingAddress?: {
            __typename?: 'CoordinatesAddressResultType';
            address1?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            address2?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            city?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            postalCode?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            state?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null;
          patientPrimaryInsurance?: {
            __typename?: 'CoordinatesInsuranceType';
            groupNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            insurerName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            planName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            policyNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null;
          patientSecondaryInsurance?: {
            __typename?: 'CoordinatesInsuranceType';
            groupNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            insurerName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            planName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            policyNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null;
          patientTertiaryInsurance?: {
            __typename?: 'CoordinatesInsuranceType';
            groupNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            insurerName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            planName?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            policyNumber?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null;
          referringProviderFirstName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          referringProviderLastName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          referringProviderNpi?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          orderReferralDate?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          orderSubcategoryName?: {
            __typename?: 'LocationInfoType';
            page?: number | null;
            boundingBox?: {
              __typename?: 'BoundingBoxType';
              height?: number | null;
              left?: number | null;
              top?: number | null;
              width?: number | null;
            } | null;
          } | null;
          lineItems?: Array<{
            __typename?: 'CoordinatesLineItemType';
            hcpcsCode?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
            quantity?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null> | null;
          dxCodes?: Array<{
            __typename?: 'CoordinatesDiagnosisType';
            diagnosisCode?: {
              __typename?: 'LocationInfoType';
              page?: number | null;
              boundingBox?: {
                __typename?: 'BoundingBoxType';
                height?: number | null;
                left?: number | null;
                top?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        homeAddress: {
          __typename?: 'ExtractionAddressResultType';
          address1Raw?: string | null;
          address1Corrected?: string | null;
          address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
          address2Raw?: string | null;
          address2Corrected?: string | null;
          address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
          cityRaw?: string | null;
          cityCorrected?: string | null;
          cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
          stateRaw?: string | null;
          stateCorrected?: string | null;
          stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
          postalCodeRaw?: string | null;
          postalCodeCorrected?: string | null;
          postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
        };
        shippingAddress: {
          __typename?: 'ExtractionAddressResultType';
          address1Raw?: string | null;
          address1Corrected?: string | null;
          address1Confidence?: Types.AppExtractionAddressResultAddress1ConfidenceChoices | null;
          address2Raw?: string | null;
          address2Corrected?: string | null;
          address2Confidence?: Types.AppExtractionAddressResultAddress2ConfidenceChoices | null;
          cityRaw?: string | null;
          cityCorrected?: string | null;
          cityConfidence?: Types.AppExtractionAddressResultCityConfidenceChoices | null;
          stateRaw?: string | null;
          stateCorrected?: string | null;
          stateConfidence?: Types.AppExtractionAddressResultStateConfidenceChoices | null;
          postalCodeRaw?: string | null;
          postalCodeCorrected?: string | null;
          postalCodeConfidence?: Types.AppExtractionAddressResultPostalCodeConfidenceChoices | null;
        };
        primaryInsurance: {
          __typename?: 'ExtractionInsuranceResultType';
          insurerNameRaw?: string | null;
          insurerNameCorrected?: string | null;
          insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
          planNameRaw?: string | null;
          planNameCorrected?: string | null;
          planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
          groupNumberRaw?: string | null;
          groupNumberCorrected?: string | null;
          groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
          policyNumberRaw?: string | null;
          policyNumberCorrected?: string | null;
          policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
        };
        secondaryInsurance: {
          __typename?: 'ExtractionInsuranceResultType';
          insurerNameRaw?: string | null;
          insurerNameCorrected?: string | null;
          insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
          planNameRaw?: string | null;
          planNameCorrected?: string | null;
          planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
          groupNumberRaw?: string | null;
          groupNumberCorrected?: string | null;
          groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
          policyNumberRaw?: string | null;
          policyNumberCorrected?: string | null;
          policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
        };
        tertiaryInsurance: {
          __typename?: 'ExtractionInsuranceResultType';
          insurerNameRaw?: string | null;
          insurerNameCorrected?: string | null;
          insurerNameConfidence?: Types.AppExtractionInsuranceResultInsurerNameConfidenceChoices | null;
          planNameRaw?: string | null;
          planNameCorrected?: string | null;
          planNameConfidence?: Types.AppExtractionInsuranceResultPlanNameConfidenceChoices | null;
          groupNumberRaw?: string | null;
          groupNumberCorrected?: string | null;
          groupNumberConfidence?: Types.AppExtractionInsuranceResultGroupNumberConfidenceChoices | null;
          policyNumberRaw?: string | null;
          policyNumberCorrected?: string | null;
          policyNumberConfidence?: Types.AppExtractionInsuranceResultPolicyNumberConfidenceChoices | null;
        };
        dxCodes: Array<{
          __typename?: 'ExtractionDiagnosisResultType';
          id: string;
          diagnosisCodeRaw?: string | null;
          diagnosisCodeCorrected?: string | null;
          diagnosisCodeConfidence?: Types.AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices | null;
        }>;
        lineItems: Array<{
          __typename?: 'ExtractionLineItemResultType';
          id: string;
          quantityRaw?: number | null;
          quantityCorrected?: number | null;
          quantityConfidence?: Types.AppExtractionLineItemResultQuantityConfidenceChoices | null;
          hcpcsCodeRaw?: string | null;
          hcpcsCodeCorrected?: string | null;
          hcpcsCodeConfidence?: Types.AppExtractionLineItemResultHcpcsCodeConfidenceChoices | null;
          lengthOfNeedRaw?: string | null;
          lengthOfNeedCorrected?: string | null;
          lengthOfNeedConfidence?: Types.AppExtractionLineItemResultLengthOfNeedConfidenceChoices | null;
          productNameRaw?: string | null;
          productNameCorrected?: string | null;
          productNameConfidence?: Types.AppExtractionLineItemResultProductNameConfidenceChoices | null;
          brandNameRaw?: string | null;
          brandNameCorrected?: string | null;
          brandNameConfidence?: Types.AppExtractionLineItemResultBrandNameConfidenceChoices | null;
          frequencyRaw?: string | null;
          frequencyCorrected?: string | null;
          frequencyConfidence?: Types.AppExtractionLineItemResultFrequencyConfidenceChoices | null;
          cptCodeRaw?: string | null;
          cptCodeCorrected?: string | null;
          cptCodeConfidence?: Types.AppExtractionLineItemResultCptCodeConfidenceChoices | null;
        }>;
      } | null;
      documentExtractionJob: {
        __typename?: 'DocumentExtractionJobType';
        id: string;
        senderFaxNumber?: string | null;
        searchableDocument?: { __typename?: 'DocumentType'; id: string } | null;
        document: { __typename?: 'DocumentType'; id: string };
      };
      order?: { __typename?: 'OrderType'; id: string } | null;
    } | null;
    error?: {
      __typename?: 'ErrorType';
      id?: string | null;
      type?: string | null;
      message: string;
      lang?: string | null;
    } | null;
  } | null;
};

export const UpdateOrderSeedDocument = `
    mutation updateOrderSeed($input: UpdateOrderSeedInput!) {
  updateOrderSeed(inputData: $input) {
    orderSeed {
      ...FaxOrderSeedFragment
    }
    error {
      id
      type
      message
      lang
    }
  }
}
    ${FaxOrderSeedFragmentDoc}
${ExtractionResultsFragmentDoc}
${ExtractionCoordinatesFragmentDoc}`;

export const useUpdateOrderSeedMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateOrderSeedMutation, TError, UpdateOrderSeedMutationVariables, TContext>,
) => {
  return useMutation<UpdateOrderSeedMutation, TError, UpdateOrderSeedMutationVariables, TContext>({
    mutationKey: ['updateOrderSeed'],
    mutationFn: (variables?: UpdateOrderSeedMutationVariables) =>
      fetcher<UpdateOrderSeedMutation, UpdateOrderSeedMutationVariables>(UpdateOrderSeedDocument, variables)(),
    ...options,
  });
};

useUpdateOrderSeedMutation.getKey = () => ['updateOrderSeed'];

useUpdateOrderSeedMutation.fetcher = (variables: UpdateOrderSeedMutationVariables, options?: RequestInit['headers']) =>
  fetcher<UpdateOrderSeedMutation, UpdateOrderSeedMutationVariables>(UpdateOrderSeedDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateOrderSeedMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateOrderSeed })
 *   )
 * })
 */
export const mockUpdateOrderSeedMutation = (
  resolver: Parameters<typeof graphql.mutation<UpdateOrderSeedMutation, UpdateOrderSeedMutationVariables>>[1],
) => graphql.mutation<UpdateOrderSeedMutation, UpdateOrderSeedMutationVariables>('updateOrderSeed', resolver);
