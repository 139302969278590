import { muiThemeBreakpoints } from './breakpoints';
import { ThemeOptions } from '@mui/material';

const typography: ThemeOptions['typography'] = {
  fontFamily: '"Inter", sans-serif',
  h1: {
    fontFamily: 'Freight Text Pro, sans-serif',
    fontWeight: 'normal',
    letterSpacing: '-1px',
    [muiThemeBreakpoints.breakpoints.up('xs')]: {
      fontSize: '44px',
      lineHeight: '44px',
    },
    [muiThemeBreakpoints.breakpoints.up('sm')]: {
      fontSize: '54px',
      lineHeight: '54px',
    },
    [muiThemeBreakpoints.breakpoints.up('md')]: {
      fontSize: '64px',
      lineHeight: '64px',
    },
  },
  h2: {
    fontFamily: 'Freight Text Pro, sans-serif',
    fontWeight: 'normal',
    letterSpacing: '-0.7px',
    [muiThemeBreakpoints.breakpoints.up('xs')]: {
      fontSize: '34px',
      lineHeight: '34px',
    },
    [muiThemeBreakpoints.breakpoints.up('sm')]: {
      fontSize: '40px',
      lineHeight: '40px',
    },
    [muiThemeBreakpoints.breakpoints.up('md')]: {
      fontSize: '50px',
      lineHeight: '50px',
    },
  },
  h3: {
    fontFamily: 'Freight Text Pro, sans-serif',
    fontWeight: 'normal',
    letterSpacing: '-0.5px',
    [muiThemeBreakpoints.breakpoints.up('xs')]: {
      fontSize: '26px',
      lineHeight: '29px',
    },
    [muiThemeBreakpoints.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '35px',
    },
    [muiThemeBreakpoints.breakpoints.up('md')]: {
      fontSize: '36px',
      lineHeight: '40px',
    },
  },
  h4: {
    fontFamily: 'Freight Text Pro, sans-serif',
    fontWeight: 'normal',
    letterSpacing: '-0.4px',
    [muiThemeBreakpoints.breakpoints.up('xs')]: {
      fontSize: '22px',
      lineHeight: '24px',
    },
    [muiThemeBreakpoints.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '26px',
    },
    [muiThemeBreakpoints.breakpoints.up('md')]: {
      fontSize: '28px',
      lineHeight: '31px',
    },
  },
  h5: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: '29px',
  },
  h6: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '26px',
  },
  body1: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '19px',
  },
  body2: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '17px',
  },
  caption: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '14.5px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '23px',
  },
  subtitle2: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
};

export default typography;
