import { CloseButton } from '../CloseButton';
import { Separator as InternalSeparator, Line } from '../Separator/Separator';
import { OrderDrawer } from './OrderDrawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const FAKE_TABLE_CELL_DATA = [...Array(1).keys()];

type OrderDrawerSkeletonProps = {
  onClose: () => void;
};

export const OrderDrawerSkeleton = ({ onClose }: OrderDrawerSkeletonProps) => (
  <>
    <Header>
      <Grid container justifyContent="space-between">
        <Skeleton height="25px" width="35%" />
        <CloseButton onClick={onClose} />
      </Grid>
      <Skeleton width="40%" />
      <Skeleton width="25%" />
    </Header>
    <Separator />
    <Container>
      <Grid item xs={12}>
        {/* // TODO: MUI currently does not yet support container queries as of 03/2023. This is a workaround to allow the order drawer to be flexible width. */}
        {/* https://github.com/mui/material-ui/issues/25189#issuecomment-1321236185 */}
        <Grid container spacing={2.5} sx={{ containerType: 'inline-size' }}>
          <Grid
            item
            sm
            sx={(th) => ({
              [th.breakpoints.down(650).replace('@media', '@container')]: {
                flexBasis: '100%',
              },
            })}
            xs={12}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                {FAKE_TABLE_CELL_DATA.map((rowId) => (
                  <Section key={rowId} title={<Skeleton height="25px" width={'50%'} />}>
                    <Skeleton height="25px" width={'40%'} />
                    <OrderDrawer.ContentSectionMeta>
                      <Skeleton height="130px" width={'70%'} />
                    </OrderDrawer.ContentSectionMeta>
                  </Section>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Section title={<Skeleton height="25px" width={'50%'} />}>
                  <Skeleton height="25px" width={'40%'} />
                  <OrderDrawer.ContentSectionMeta>
                    <Skeleton height="130px" width={'70%'} />
                  </OrderDrawer.ContentSectionMeta>
                </Section>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm xs={12}>
            <Section title={<Skeleton height="25px" width={'50%'} />}>
              <Skeleton height="25px" width={'40%'} />
              <OrderDrawer.ContentSectionMeta>
                <Skeleton height="130px" width={'70%'} />
              </OrderDrawer.ContentSectionMeta>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </>
);

const Container = ({ children }: { children: React.ReactNode }) => {
  return <Box padding="20px 15px 20px 15px">{children}</Box>;
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container direction="column" padding="20px 30px 20px 30px">
      {children}
    </Grid>
  );
};

const Separator = () => (
  <InternalSeparator margin="0" width="100%">
    <Line height="1px" />
  </InternalSeparator>
);

enum VariationColor {
  highlighted = 'rgba(244, 85, 85, 0.06)',
  dull = 'rgba(230, 233, 235, 0.3)',
}

const Section = (props: {
  title?: React.ReactNode;
  variation?: keyof typeof VariationColor;
  spaced?: boolean;
  children: React.ReactNode;
}) => {
  const { title, children } = props;

  return (
    <Box
      bgcolor={props.variation ? VariationColor[props.variation] : undefined}
      margin={props.spaced ? '15px 0 15px 0' : ''}
      padding="15px"
    >
      <Grid container>
        <Grid item xs={3}>
          <Title>{title}</Title>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

const Title = ({ children }: { children?: React.ReactNode }) => {
  if (typeof children === 'string') {
    return (
      <Typography
        sx={{
          color: 'text.secondary',
          display: 'block',
          wordWrap: 'break-word',
          maxWidth: '100px',
          paddingTop: '2px',
          fontWeight: '600',
        }}
        variant="caption"
      >
        {`${children}:`}
      </Typography>
    );
  }

  return <>{children}</>;
};
