import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type CreateExtractionFeedbackMutationVariables = Types.Exact<{
  input: Types.ExtractionFeedbackInput;
}>;

export type CreateExtractionFeedbackMutation = {
  __typename?: 'Mutation';
  createExtractionFeedbackMutation?: {
    __typename?: 'CreateExtractionFeedbackMutation';
    error?: { __typename?: 'ErrorType'; id?: string | null; type?: string | null; message: string } | null;
  } | null;
};

export const CreateExtractionFeedbackMutationDocument = `
    mutation createExtractionFeedbackMutation($input: ExtractionFeedbackInput!) {
  createExtractionFeedbackMutation(inputData: $input) {
    error {
      id
      type
      message
    }
  }
}
    `;

export const useCreateExtractionFeedbackMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateExtractionFeedbackMutation,
    TError,
    CreateExtractionFeedbackMutationVariables,
    TContext
  >,
) => {
  return useMutation<CreateExtractionFeedbackMutation, TError, CreateExtractionFeedbackMutationVariables, TContext>({
    mutationKey: ['createExtractionFeedbackMutation'],
    mutationFn: (variables?: CreateExtractionFeedbackMutationVariables) =>
      fetcher<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
        CreateExtractionFeedbackMutationDocument,
        variables,
      )(),
    ...options,
  });
};

useCreateExtractionFeedbackMutation.getKey = () => ['createExtractionFeedbackMutation'];

useCreateExtractionFeedbackMutation.fetcher = (
  variables: CreateExtractionFeedbackMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
    CreateExtractionFeedbackMutationDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateExtractionFeedbackMutationMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createExtractionFeedbackMutation })
 *   )
 * })
 */
export const mockCreateExtractionFeedbackMutationMutation = (
  resolver: Parameters<
    typeof graphql.mutation<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>
  >[1],
) =>
  graphql.mutation<CreateExtractionFeedbackMutation, CreateExtractionFeedbackMutationVariables>(
    'createExtractionFeedbackMutation',
    resolver,
  );
