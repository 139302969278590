import { logError } from '../utils/logging';
import { useCurrentUser, User } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { useEffect, useRef } from 'react';
import { useOrgUserAcceptInviteMutation } from '../api-clients/falcon-api/graphql/mutations/orgUserAcceptInvite.generated';

type OrgUserType = NonNullable<User['orgUsers']>[number];

function useAcceptInvite(orgUser: OrgUserType | undefined) {
  const user = useCurrentUser();
  const orgId = orgUser?.org?.id;

  const orgUserAcceptInvite = useOrgUserAcceptInviteMutation({
    onError: logError, // log but don't show
    onSuccess(data) {
      if (data.orgUserAcceptInvite?.ok) user.refetch();
    },
  });

  // To avoid useEffect dependency array - infinite loop
  const acceptRef = useRef(orgUserAcceptInvite);
  acceptRef.current = orgUserAcceptInvite;

  useEffect(() => {
    if (orgId && !orgUser.acceptedInviteAt) {
      acceptRef.current.mutateAsync({ orgId });
    }
  }, [orgId, orgUser?.acceptedInviteAt]);
}

export default useAcceptInvite;
