import { GetUserQuery, useGetUserQuery } from '../graphql/queries/getUser.generated';
import { OrgTypes } from '../graphql/types.generated';
import { useAuth } from '../../../contexts/AuthContext';

export type User = NonNullable<ReturnType<typeof transformGetUserQuery>>;
export type OrgUserBase = NonNullable<User['orgUsers']>[0];
export type OrgUser = OrgUserBase & { org: NonNullable<OrgUserBase['org']> };
export type UserOrg = NonNullable<OrgUser['org']>;

export function useCurrentUser() {
  const auth = useAuth();

  return useGetUserQuery(
    {},
    {
      enabled: auth.status === 'authenticated',
      staleTime: Infinity,
      meta: {
        errorMessage: 'Something went wrong getting your user information.  Please try again later.',
      },
      select: transformGetUserQuery,
    },
  );
}

function transformGetUserQuery({ user }: GetUserQuery) {
  return user;
}

export function findOrgUser(
  user: Partial<NonNullable<ReturnType<typeof useCurrentUser>['data']>> | null | undefined,
  spec: { orgId?: string; orgTypeEnum?: OrgTypes; practiceId?: string; supplierId?: string },
) {
  const result = user?.orgUsers?.find((ou) => {
    if (!ou.org) return false;
    if (spec.orgId && ou.org.id !== spec.orgId) return false;
    if (spec.orgTypeEnum && ou.org.orgTypeEnum !== spec.orgTypeEnum) return false;
    if (spec.practiceId && ou.org.practice?.id !== spec.practiceId) return false;
    if (spec.supplierId && ou?.org.supplier?.id !== spec.supplierId) return false;
    return true;
  });
  return result as OrgUser | undefined;
}
