export enum LINKS {
  CARE_ADVOCATES_NUM = 'tel: +18442174952',
  CARE_ADVOCATES_SUPPORT = 'https://home.tomorrowhealth.com/support',
  CARE_COORDINATOR_RESOURCE_CENTER = 'https://resources.tomorrowhealth.com/tomorrow-health-service-coordinator-resource-center',
  COHERE_LOGIN = 'https://login.coherehealth.com/',
  CONTACT_NUM = 'tel: +18444024344',
  GET_UPDATES = 'https://tomorrowhealth.typeform.com/to/fwWy8C',
  INTAKE_SUBMISSION_SUCCESS = 'https://home.tomorrowhealth.com/patient-referrals-submission-success',
  NPI_LOOKUP_LINK = 'https://npiregistry.cms.hhs.gov/',
  PARTNERSHIP_MAIL_TO = 'mailto: partnerships@tomorrowhealth.com',
  PATIENT_BILL = 'https://support.tomorrowhealth.com/en_us/patient-bill-of-rights-and-responsibilities-SJvJm1MKw',
  SIGNUP = 'https://resources.tomorrowhealth.com/sign-up',
  PRACTICE_FEEDBACK_SURVEY_DEC_2022 = 'https://survey.hsforms.com/1xhhDuUEKT7SpCFyuC3qTXA5jhss',
  PRACTICE_ORDER_COMPLETE_ANON = 'https://home.tomorrowhealth.com/order-complete',
  PRACTICE_RESOURCE_CENTER = 'https://resources.tomorrowhealth.com/tomorrow-health-referring-provider-resources',
  PRACTICE_RESOURCE_CENTER_SAVING_CHART_NOTES = 'https://resources.tomorrowhealth.com/tomorrow-health-referring-provider-resources#Chartnotes',
  PRIVACY_NOTICE = 'https://support.tomorrowhealth.com/en_us/hipaa-privacy-notice-HJOJm1GFw',
  PRIVACY_POLICY = 'https://support.tomorrowhealth.com/en_us/privacy-policy-HkS17yGYP',
  PRODUCTION_HOMEPAGE = 'https://home.tomorrowhealth.com/',
  SOCIAL_FB = 'https://www.facebook.com/tomorrowhealth1/',
  SOCIAL_IG = 'https://www.instagram.com/tomorrowhealth',
  SOCIAL_LN = ' https://www.linkedin.com/company/tomorrowhealth/',
  SOCIAL_TW = 'https://twitter.com/tomorrowhealth1',
  STAR_PLUS_REQUIREMENTS = 'https://www.hhs.texas.gov/sites/default/files/documents/laws-regulations/forms/2067/2067.pdf',
  SUPPLIER_FEEDBACK_SURVEY_JANUARY_2023 = 'https://survey.hsforms.com/1aiRuZeuLRDCF9DRcL_77zQ5jhss',
  SUPPLIER_MEMBER_IN_RETAIL_FORM = 'https://9307612.fs1.hubspotusercontent-na1.net/hubfs/9307612/DME%20Resources/Member%20in%20Retail%20Medical%20Equip%20Location%20(1).pdf',
  SUPPLIER_ORDER_COMPLETION_MANUAL = 'https://9307612.fs1.hubspotusercontent-na1.net/hubfs/9307612/DME%20Resources/Tomorrow%20Health%20%7C%20Order%20Completion%20Workflow.pdf',
  SUPPLIER_RESOURCE_CENTER = 'https://resources.tomorrowhealth.com/tomorrow-health-dme-resources',
  SUPPLIER_SLA_MANUAL = 'https://9307612.fs1.hubspotusercontent-na1.net/hubfs/9307612/DME%20Resources/Tomorrow%20Health%20%7C%20Service%20Level%20Agreements%20(SLAs).pdf',
  SUPPLIER_STANDARDS = 'https://support.tomorrowhealth.com/en_us/dmepos-supplier-standards-By8yQkfKv',
  SUPPLIER_USER_MANUAL = 'https://9307612.fs1.hubspotusercontent-na1.net/hubfs/9307612/DME%20Resources/Tomorrow%20Health%20%7C%20DME%20User%20Manual.pdf',
  SUPPORT = 'https://support.tomorrowhealth.com/',
  SUPPORT_EMAIL = 'support@tomorrowhealth.com',
  SUPPORT_NEW_REQUEST = 'https://support.tomorrowhealth.com/hc/en-us/requests/new',
  SUPPORT_RETURN_POLICY = 'https://support.tomorrowhealth.com/hc/en-us/articles/360048670251',
}
