import { formatISO, parseISO } from 'date-fns';

type DateAPIRepresentation = NonNullable<Parameters<typeof formatISO>[1]>['representation'];
export function formatDate(date: Date, representation?: DateAPIRepresentation): string;
export function formatDate(date: null | undefined, representation?: DateAPIRepresentation): null;
export function formatDate(date: Date | null | undefined, representation?: DateAPIRepresentation): string | null;
export function formatDate(date: Date | null | undefined, representation: DateAPIRepresentation = 'complete') {
  return date ? formatISO(date, { representation }) : null;
}

export function parseDate(date: string): Date;
export function parseDate(date: null | undefined): null;
export function parseDate(date: string | null | undefined): Date | null;
export function parseDate(date: string | null | undefined) {
  return date !== null && date !== undefined ? parseISO(date) : null;
}
