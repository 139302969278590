import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export interface ClipboardButtonProps {
  value: string;
  /**
   * Optional cb handler upon clipboard copy
   */
  onClipboardCopy?: (clipboardValue: string, error?: Error) => void;
}

export const ClipboardButton = ({ value, onClipboardCopy }: ClipboardButtonProps) => {
  const [, copy] = useCopyToClipboard();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <div title={tooltipOpen ? undefined : 'Copy to clipboard'}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={tooltipOpen}
          placement="bottom-end"
          title="Copied to clipboard"
        >
          <IconButton
            onClick={async () => {
              if (value) {
                try {
                  await copy(value);
                  onClipboardCopy?.(value);
                  handleTooltipOpen();
                } catch (error) {
                  if (error instanceof Error) {
                    onClipboardCopy?.(value, error);
                  }
                }
              }
            }}
            size="small"
          >
            <FileCopyLineIcon color="#99A7AE" size={13} />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
};

export default ClipboardButton;
