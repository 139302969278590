import { DialogTitle } from './DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import React, { forwardRef } from 'react';
import Slide from '@mui/material/Slide';

export const DialogTransition = Slide;

export interface DialogButton {
  fullWidth?: boolean;
  onClick: () => void;
  text: string;
  isLoading?: boolean;
  isSecondary?: boolean;
  isWarning?: boolean;
  disabled?: boolean;
}

type DialogCloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export type DialogOnCloseHandlerFunc = (event: unknown, reason: DialogCloseReason) => void;

export interface DialogProps extends MuiDialogProps {
  buttons?: DialogButton[];
  dividers?: boolean;
  padding?: string;
  buttonsAlign?: 'left' | 'right';
  dialogTitle?: React.ReactNode;
  onClose?: DialogOnCloseHandlerFunc;
  isSubDialog?: boolean;
}

export const Dialog = forwardRef((props: DialogProps, ref: React.Ref<unknown>) => {
  const {
    buttons = [],
    dividers = true,
    dialogTitle,
    children,
    isSubDialog,
    className,
    padding,
    buttonsAlign,
    ...rest
  } = props;

  return (
    <MuiDialog
      className={className}
      fullWidth
      keepMounted
      sx={isSubDialog ? { zIndex: '1500' } : undefined}
      TransitionComponent={Slide}
      {...rest}
    >
      {dialogTitle ? <DialogTitle onClose={props.onClose}>{dialogTitle}</DialogTitle> : null}

      {dividers && dialogTitle ? (
        <Box paddingLeft="40px" paddingRight="40px" role="separator">
          <Box borderTop="1px solid #E6E9EB" />
        </Box>
      ) : null}

      <DialogContent
        ref={ref}
        dividers={dividers}
        sx={{
          borderTop: 'none',
          padding,
        }}
      >
        {children}
      </DialogContent>
      {buttons.length ? (
        <DialogActions
          sx={{
            justifyContent: buttonsAlign === 'left' ? 'flex-start' : 'flex-end',
          }}
        >
          {buttons.map((button) => (
            <Button
              key={button.text}
              color={button.isWarning ? 'warning' : undefined}
              disabled={button.disabled || button.isLoading}
              fullWidth={button.fullWidth}
              onClick={button.onClick}
              variant={button.isSecondary ? 'secondary' : 'primary'}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
});
