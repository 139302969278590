import * as Types from '../types.generated.js';

export type PatientFragment = {
  __typename?: 'PatientType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  shippingAddress?: {
    __typename?: 'AddressType';
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    id: string;
  } | null;
};

export type PatientSlimFragment = {
  __typename?: 'PatientSlimType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  fullName?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  shippingAddress?: {
    __typename?: 'AddressType';
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
    id: string;
  } | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
};

export type PatientSlimSansAddressFragment = {
  __typename?: 'PatientSlimType';
  id: string;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
  fullName?: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  sex?: Types.SexEnum | null;
  heightInInches?: number | null;
  weightInPounds?: number | null;
  homePhoneNumber?: string | null;
  guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
  languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
  primaryCareProvider?: {
    __typename?: 'ProviderType';
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
  } | null;
  primaryCareProviderContact?: {
    __typename?: 'ContactType';
    id: string;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    smsNumber?: string | null;
    faxNumber?: string | null;
  } | null;
  patientWaiver?: {
    __typename?: 'PatientWaiverType';
    waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
  } | null;
  primaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  secondaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
  tertiaryInsurance?: {
    __typename?: 'PatientInsuranceType';
    status: Types.AppPatientInsuranceStatusChoices;
    policyNumber: string;
    insurer: {
      __typename?: 'InsurerType';
      id: string;
      insurerCategory?: string | null;
      name: string;
      display: string;
      requiresTitleXix: boolean;
      parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
    };
  } | null;
};

export type PatientInsuranceFragment = {
  __typename?: 'PatientInsuranceType';
  status: Types.AppPatientInsuranceStatusChoices;
  policyNumber: string;
  insurer: {
    __typename?: 'InsurerType';
    id: string;
    insurerCategory?: string | null;
    name: string;
    display: string;
    requiresTitleXix: boolean;
    parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
  };
};

export type AddressFragment = {
  __typename?: 'AddressType';
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  id: string;
};

export const PatientInsuranceFragmentDoc = `
    fragment PatientInsuranceFragment on PatientInsuranceType {
  status
  insurer {
    id
    insurerCategory
    name
    display
    requiresTitleXix
    parent {
      id
      name
    }
  }
  policyNumber
}
    `;
export const AddressFragmentDoc = `
    fragment AddressFragment on AddressType {
  addressLine1
  addressLine2
  city
  state
  postalCode
  id
}
    `;
export const PatientFragmentDoc = `
    fragment PatientFragment on PatientType {
  id
  emailAddress
  dateOfBirth
  firstName
  middleName
  lastName
  sex
  heightInInches
  weightInPounds
  homePhoneNumber
  mobilePhoneNumber
  patientWaiver {
    waiver {
      id
      name
    }
  }
  guardian {
    firstName
    lastName
    phoneNumber
  }
  languagePreference {
    code
    name
  }
  primaryCareProvider {
    id
    firstName
    lastName
    npi
  }
  primaryCareProviderContact {
    id
    emailAddress
    phoneNumber
    smsNumber
    faxNumber
  }
  primaryInsurance {
    ...PatientInsuranceFragment
  }
  secondaryInsurance {
    ...PatientInsuranceFragment
  }
  tertiaryInsurance {
    ...PatientInsuranceFragment
  }
  shippingAddress {
    ...AddressFragment
  }
}
    `;
export const PatientSlimSansAddressFragmentDoc = `
    fragment PatientSlimSansAddressFragment on PatientSlimType {
  id
  emailAddress
  dateOfBirth
  fullName
  firstName
  middleName
  lastName
  sex
  heightInInches
  weightInPounds
  homePhoneNumber
  guardian {
    firstName
    lastName
    phoneNumber
  }
  languagePreference {
    code
    name
  }
  primaryCareProvider {
    id
    firstName
    lastName
    npi
  }
  primaryCareProviderContact {
    id
    emailAddress
    phoneNumber
    smsNumber
    faxNumber
  }
  patientWaiver {
    waiver {
      id
      name
    }
  }
  primaryInsurance {
    ...PatientInsuranceFragment
  }
  secondaryInsurance {
    ...PatientInsuranceFragment
  }
  tertiaryInsurance {
    ...PatientInsuranceFragment
  }
}
    `;
export const PatientSlimFragmentDoc = `
    fragment PatientSlimFragment on PatientSlimType {
  ...PatientSlimSansAddressFragment
  shippingAddress {
    ...AddressFragment
  }
}
    `;
