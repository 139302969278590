import { cn } from '../lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

const inputBaseVariants = cva(
  'flex w-full rounded-md border border-solid border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
      },
      size: {
        small: 'h-11 p-3 text-sm',
        medium: 'h-[52px] p-4 text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
  },
);

export interface InputBaseProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputBaseVariants> {}

const InputBase = React.forwardRef<HTMLInputElement, InputBaseProps>(({ className, variant, size, ...props }, ref) => {
  return <input ref={ref} className={cn(inputBaseVariants({ variant, size }), className)} {...props} />;
});

InputBase.displayName = 'InputBase';

export { InputBase, inputBaseVariants };
