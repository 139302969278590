import { CurrentOrgUserContext } from './useCurrentOrgUser';
import { findOrgUser, useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { getDefaultPageForUser } from './index';
import { Helmet } from 'react-helmet';
import { logAction, setOrgForLogging } from '../utils/logging';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { OrgTypes } from '../api-clients/falcon-api/graphql/types.generated';
import { PATHS } from './paths';
import { useEffect } from 'react';
import { useOrgIdParam } from '../hooks/useOrgIdParam';
import { useUserLocalStorage } from '../hooks/useUserStorage';
import SpinnerScreen from '../components/Spinner/SpinnerScreen';
import useAcceptInvite from '../hooks/useAcceptInvite';

export function OrgUserContainer({ orgType }: { orgType: OrgTypes }) {
  const { data: user } = useCurrentUser();
  const orgId = useOrgIdParam() ?? '';

  const orgUser = findOrgUser(user, { orgId, orgTypeEnum: orgType });
  useAcceptInvite(orgUser);
  useEffect(() => setOrgForLogging(orgUser?.org), [orgUser?.org]);

  if (!user) return <SpinnerScreen fullHeight />;
  if (!orgUser) return <FallbackToDifferentOrgId orgType={orgType} />;

  return (
    <>
      <Helmet>
        <title>{orgUser.org?.name}</title>
      </Helmet>

      <CurrentOrgUserContext.Provider value={orgUser}>
        <Outlet />
      </CurrentOrgUserContext.Provider>
    </>
  );
}

function FallbackToDifferentOrgId({ orgType }: { orgType: OrgTypes }) {
  const { data: user } = useCurrentUser();
  const { data: userStorageData } = useUserLocalStorage();
  const lastUsedOrgId = userStorageData?.last_used_org_id;
  const location = useLocation();
  const orgId = useOrgIdParam() ?? '';

  const fallbackOrgUser =
    findOrgUser(user, { supplierId: orgId /* supplierId to orgId migration */ }) ??
    findOrgUser(user, { practiceId: orgId /* practiceId to orgId migration */ }) ??
    findOrgUser(user, { orgTypeEnum: orgType /* fallback to first org */ });

  const fallbackOrgId = fallbackOrgUser?.org?.id;

  const to = fallbackOrgId
    ? { pathname: location.pathname.replace(orgId, fallbackOrgId), search: location.search }
    : (getDefaultPageForUser({ user, orgId: lastUsedOrgId }) ?? PATHS.UNAUTHORIZED);

  useEffect(() => logAction('OrgId fallback', { orgType, orgId, fallbackOrgId }), [fallbackOrgId, orgId, orgType]);
  return <Navigate replace to={to} />;
}
