import { FaxOrderSeedFragment } from '../../../api-clients/falcon-api/graphql/queries/getFaxOrderSeed.generated';
import { IntakePatient } from '../../../components/PatientModal/PatientForm.helpers';
import { UpdateOrderSeedMutation } from '../../../api-clients/falcon-api/graphql/mutations/updateOrderSeed.generated';

export type SupplierFaxIntakeFormRadioOption = 'match' | 'database' | 'new' | null;
export interface SupplierIntakeFaxOrderSeed extends Partial<FaxOrderSeedFragment> {
  showFaxBackPreviewPdf: boolean;
  faxBackPreviewPdfUrl: ArrayBuffer | string | null;
  pdfLoading: boolean;
  orgId: string;
  completedAutoQualificationsReviewStep: boolean;
  pageLabels: {
    data: {
      id: string;
      label: string;
    } | null;
    page: number;
  }[];
  selectedPatientFormOption?: SupplierFaxIntakeFormRadioOption;
  selectedPatient?: IntakePatient | null;
}

export function getOrderSeedFromResponse(response: UpdateOrderSeedMutation) {
  const orderSeed = response.updateOrderSeed?.orderSeed;
  if (response.updateOrderSeed?.error?.message) throw new Error(response.updateOrderSeed.error.message);
  if (!orderSeed) throw new Error('Missing order in response');
  return orderSeed;
}

export function assignOrderSeedDetails(draft: SupplierIntakeFaxOrderSeed, orderSeed: FaxOrderSeedFragment) {
  Object.assign(draft, {
    ...orderSeed,
  } satisfies Partial<SupplierIntakeFaxOrderSeed>);
}
