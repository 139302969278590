import { lazily } from 'react-lazily';
import { OrderDrawer } from '../../components/OrderDrawer/OrderDrawer';
import { OrderDrawerSkeleton } from '../../components/OrderDrawer/OrderDrawerSkeleton';
import { retry } from '../../router/retry';
import { Suspense, useCallback } from 'react';
import { useAddViewIdToHttpHeaders } from '../../hooks/useAddViewIdToHttpHeaders';
import { useSearchParams } from 'react-router-dom';

const { SupplierOrderDrawer } = lazily(() =>
  retry(() => import('./components/SupplierOrderDrawer/SupplierOrderDrawer')),
);
const { PriorAuthDrawer } = lazily(() => retry(() => import('./components/PriorAuth/PriorAuthDrawer')));

export function SupplierOmniDrawer() {
  useAddViewIdToHttpHeaders();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = useCallback(
    function handleClose() {
      searchParams.delete('commentId');
      searchParams.delete('orderId');
      searchParams.delete('requestId');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const content = (() => {
    const orderId = searchParams.get('orderId');
    const requestId = searchParams.get('requestId');

    if (orderId) return <SupplierOrderDrawer key={orderId} onClose={handleClose} orderId={orderId} />;
    if (requestId) return <PriorAuthDrawer key={requestId} onClose={handleClose} requestId={requestId} />;

    return null;
  })();

  return (
    <OrderDrawer.Root extendedWidth onClose={handleClose} open={!!content} resizable>
      <Suspense fallback={<OrderDrawerSkeleton onClose={handleClose} />}>{content}</Suspense>
    </OrderDrawer.Root>
  );
}
