import { ColorsV2 as colors } from './colors';
import { components } from './components';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { green } from '@mui/material/colors';
import breakpoints from './breakpoints';
import typography from './typography';
import './components.types';

const themeObject: ThemeOptions = {
  typography,
  breakpoints,
  palette: {
    mode: 'light',
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.navy,
    },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.navy,
      secondary: colors.gray_dark,
      disabled: colors.gray_medium,
    },
    // border: {
    //   main: colors.border,
    // },
    background: {
      default: colors.cream,
      paper: colors.white,
    },
    success: {
      main: green[500],
      light: green[300],
      dark: green[700],
    },

    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    // custom: {
    //   light: '#ffa726',
    //   main: '#f57c00',
    //   dark: '#ef6c00',
    //   contrastText: 'rgba(0, 0, 0, 0.87)',
    // },

    // TODO: Configure the rest of the "action" colors
    // action: {
    //   active: 'rgba(0, 0, 0, 0.54)',
    //   hover: 'rgba(0, 0, 0, 0.04)',
    //   hoverOpacity: 0.04,
    //   selected: 'rgba(0, 0, 0, 0.08)',
    //   selectedOpacity: 0.08,
    //   disabled: Tokens.ColorPaletteNeutral200,
    //   disabledBackground: 'rgba(0, 0, 0, 0.12)',
    //   disabledOpacity: 0.38,
    //   focus: 'rgba(0, 0, 0, 0.12)',
    //   focusOpacity: 0.12,
    //   activatedOpacity: 0.12,
    // },
  },
  components,
};

export const muiTheme = createTheme(themeObject);

// Needed for MUI compatibility with Tailwind CSS
// https://mui.com/material-ui/integrations/interoperability/#tailwind-css
export const createMuiThemeWithRoot = (rootElement: HTMLElement) =>
  createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiPopover: {
          defaultProps: {
            container: rootElement,
          },
        },
        MuiPopper: {
          defaultProps: {
            container: rootElement,
          },
        },
        MuiDialog: {
          defaultProps: {
            container: rootElement,
          },
        },
        MuiModal: {
          defaultProps: {
            container: rootElement,
          },
        },
      },
    }),
  );
