import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetPatientInsuranceDetailsQueryVariables = Types.Exact<{
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  dateOfBirth: Types.Scalars['Date']['input'];
  policyNumber: Types.Scalars['String']['input'];
  insurerId: Types.Scalars['ID']['input'];
}>;

export type GetPatientInsuranceDetailsQuery = {
  __typename?: 'Query';
  patientInsuranceDetails?: {
    __typename?: 'PatientInsuranceVerificationType';
    verified?: boolean | null;
    showErrors?: boolean | null;
    insuranceInformation?: {
      __typename?: 'PatientInsuranceInformationType';
      patientGroupNumber?: string | null;
      patientInsuranceType?: string | null;
    } | null;
  } | null;
};

export const GetPatientInsuranceDetailsDocument = `
    query getPatientInsuranceDetails($firstName: String!, $lastName: String!, $dateOfBirth: Date!, $policyNumber: String!, $insurerId: ID!) {
  patientInsuranceDetails(
    firstName: $firstName
    lastName: $lastName
    dateOfBirth: $dateOfBirth
    policyNumber: $policyNumber
    insurerId: $insurerId
  ) {
    verified
    showErrors
    insuranceInformation {
      patientGroupNumber
      patientInsuranceType
    }
  }
}
    `;

export const useGetPatientInsuranceDetailsQuery = <TData = GetPatientInsuranceDetailsQuery, TError = unknown>(
  variables: GetPatientInsuranceDetailsQueryVariables,
  options?: Omit<UseQueryOptions<GetPatientInsuranceDetailsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetPatientInsuranceDetailsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetPatientInsuranceDetailsQuery, TError, TData>({
    queryKey: ['getPatientInsuranceDetails', variables],
    queryFn: fetcher<GetPatientInsuranceDetailsQuery, GetPatientInsuranceDetailsQueryVariables>(
      GetPatientInsuranceDetailsDocument,
      variables,
    ),
    ...options,
  });
};

useGetPatientInsuranceDetailsQuery.getKey = (variables: GetPatientInsuranceDetailsQueryVariables) => [
  'getPatientInsuranceDetails',
  variables,
];

export const useInfiniteGetPatientInsuranceDetailsQuery = <
  TData = InfiniteData<GetPatientInsuranceDetailsQuery>,
  TError = unknown,
>(
  variables: GetPatientInsuranceDetailsQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetPatientInsuranceDetailsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetPatientInsuranceDetailsQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetPatientInsuranceDetailsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getPatientInsuranceDetails.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetPatientInsuranceDetailsQuery, GetPatientInsuranceDetailsQueryVariables>(
            GetPatientInsuranceDetailsDocument,
            { ...variables, ...(metaData.pageParam ?? {}) },
          )(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetPatientInsuranceDetailsQuery.getKey = (variables: GetPatientInsuranceDetailsQueryVariables) => [
  'getPatientInsuranceDetails.infinite',
  variables,
];

useGetPatientInsuranceDetailsQuery.fetcher = (
  variables: GetPatientInsuranceDetailsQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetPatientInsuranceDetailsQuery, GetPatientInsuranceDetailsQueryVariables>(
    GetPatientInsuranceDetailsDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientInsuranceDetailsQuery((req, res, ctx) => {
 *   const { firstName, lastName, dateOfBirth, policyNumber, insurerId } = req.variables;
 *   return res(
 *     ctx.data({ patientInsuranceDetails })
 *   )
 * })
 */
export const mockGetPatientInsuranceDetailsQuery = (
  resolver: Parameters<
    typeof graphql.query<GetPatientInsuranceDetailsQuery, GetPatientInsuranceDetailsQueryVariables>
  >[1],
) =>
  graphql.query<GetPatientInsuranceDetailsQuery, GetPatientInsuranceDetailsQueryVariables>(
    'getPatientInsuranceDetails',
    resolver,
  );
