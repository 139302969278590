import { GetStatusMappingsQuery, useGetStatusMappingsQuery } from '../graphql/queries/getStatusMappings.generated';
import { logError } from '../../../utils/logging';
import { milliseconds } from 'date-fns';
import { OrgTypes } from '../graphql/types.generated';
import { useMemo } from 'react';

const orderDisplayStatuses = {
  Accepted: true,
  'Appointment Required': true,
  'Awaiting Appointment': true,
  Cancelled: true,
  Delivered: true,
  'Draft Order': true,
  'Draft Referral': true,
  'Matching To Supplier': true,
  'More Info Needed': true,
  'Needs Signature': true,
  'Order Under Review': true,
  'Partially Delivered': true,
  'Partially delivered': true,
  'Pending Acceptance': true,
  'Pending acceptance': true,
  'Referral Under Review': true,
  Rejected: true,
  'Sent To Supplier': true,
  Shipped: true,
  'Supplier Working On Order': true,
  'Supplier Working On Referral': true,
} as const;

export type OrderDisplayStatus = keyof typeof orderDisplayStatuses;

// Compensating for the fact that OrderDisplayStatus is not an enum from falcon-api:
export function toOrderDisplayStatus(status: string | null | undefined) {
  if (status !== null && status !== undefined && !orderDisplayStatuses[status]) {
    logError(`Invalid OrderDisplayStatus: '${status}'`);
  }
  return status as OrderDisplayStatus | null | undefined;
}

export function useOrderStatuses(orgType: OrgTypes) {
  return useGetStatusMappingsQuery(
    { orgType },
    {
      staleTime: milliseconds({ hours: 10 }),
      meta: {
        errorMessage: 'Failed to load statuses.',
      },
      select: transformGetStatusMappingsQuery,
    },
  );
}

function transformGetStatusMappingsQuery(query: GetStatusMappingsQuery) {
  return query.statusMappingByOrgType?.flatMap((st) =>
    st?.displayStatus && st?.orderStatuses?.length
      ? [
          {
            displayStatus: st.displayStatus as OrderDisplayStatus,
            orderStatuses: st.orderStatuses.flatMap((os) => (os ? [os] : [])),
          },
        ]
      : [],
  );
}

// create a Map to lookup displayStatus by orderStatus
export function useStatusDisplayMap(orgType: OrgTypes) {
  const { data: statuses } = useOrderStatuses(orgType);

  return useMemo(
    () => new Map(statuses?.flatMap((status) => status.orderStatuses.map((os) => [os, status.displayStatus]))),
    [statuses],
  );
}
