import { DmeOrderForTable } from '../hooks/useSupplierOrders';
import { OrderStatusBadge } from '../../../components/StatusBadge/OrderStatusBadge';
import { OrderTrackingStatusBadge } from '@tomorrow/ui';
import { parseDate } from '../../../utils/dateUtils';
import Box from '@mui/material/Box';
import OrderExpirationLabel from './OrderExpirationLabel/OrderExpirationLabel';
import Tooltip from '@mui/material/Tooltip';

export const SupplierOrderListOrderStatus = ({ order }: { order: DmeOrderForTable }) => {
  const orderStatus = order.displayStatus;

  if (!orderStatus) {
    return null;
  }

  switch (orderStatus) {
    case 'Pending acceptance': {
      const dueAtTimestamp = order.mostRecentAssignmentForSupplier?.dueAt;

      return (
        <Tooltip
          enterTouchDelay={0}
          placement="top"
          title={dueAtTimestamp ? <OrderExpirationLabel timestamp={parseDate(dueAtTimestamp)} /> : ''}
        >
          <OrderStatusBadge status={orderStatus} />
        </Tooltip>
      );
    }

    default:
      return (
        <Box alignItems="center" display="flex">
          <OrderStatusBadge status={orderStatus} />
          {order.trackingNumberSummary?.hasTrackingNumber && (
            <Box display="flex" marginLeft="3px">
              <OrderTrackingStatusBadge failedDelivery={!!order.trackingNumberSummary.hasFailedTrackingNumber} />
            </Box>
          )}
        </Box>
      );
  }
};
