import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../contexts/AuthContext';
import { useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { useMemo } from 'react';
import { wrapStorage } from '../utils/wrapStorage';

const userKeyPrefix = '_th_user';

const userStorageHookFactory =
  <T = object>(storage: Storage) =>
  () => {
    const { data: user } = useCurrentUser();
    const authStatus = useAuth().status;

    const queryClient = useQueryClient();

    const userId = user?.id;
    const userKeySuffix = authStatus === 'anonymous' ? 'anon' : userId;

    const userStorage = useMemo(
      () => wrapStorage<Partial<T>>(storage, `${userKeyPrefix}:${userKeySuffix}`),
      [userKeySuffix],
    );

    const { data, isLoading } = useQuery({
      enabled: !!userId || authStatus === 'anonymous',
      queryKey: [userKeyPrefix, userKeySuffix],
      queryFn: () => ({
        data: userStorage?.getJSON(),
        update: (value: Partial<T>) => {
          userStorage?.setJSON({ ...userStorage?.getJSON(), ...value });
          queryClient.refetchQueries({ queryKey: [userKeyPrefix] });
        },
      }),
      placeholderData: keepPreviousData,
    });

    return {
      isLoading,
      data: data?.data,
      update: data?.update,
    };
  };

export const useUserLocalStorage = userStorageHookFactory<{
  has_user_dismissed_onboarding_modal: boolean;
  last_used_org_id: string;
  order_references: Record<string, string>;
  fax_order_references: Record<string, string>;
}>(window.localStorage);
