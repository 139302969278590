import { createContext, useContext } from 'react';
import { findOrgUser } from '../api-clients/falcon-api/hooks/useCurrentUser';

type CurrentOrgUserContextProps = NonNullable<ReturnType<typeof findOrgUser>>;

export const CurrentOrgUserContext = createContext<CurrentOrgUserContextProps | null>(null);

export function useCurrentOrgUser() {
  const context = useContext(CurrentOrgUserContext);

  if (!context) {
    throw new Error('useCurrentOrgUser must be used within a <OrgUserContainer />');
  }

  return context;
}
