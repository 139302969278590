import { Accordion } from '@tomorrow/ui';
import { ColorsV2, muiTheme } from '@tomorrow/ui/theme';
import { logAction } from '../../utils/logging';
import { styled } from '@mui/material/styles';
import { SxProps, Theme, useMediaQuery } from '@mui/material';
import { useStorage } from '../../hooks/useStorage';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import Box, { BoxProps } from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface OrderDrawerProps extends DrawerProps {
  extendedWidth?: boolean;
  resizable?: boolean;
  leftContentSlot?: React.ReactNode;
}

const minResizeWidth = 450;
const defaultWidthValue = 580;
const extendedWidthValue = 1050;

const RootContainer = (props: OrderDrawerProps) => (
  // reset component state when extendedWidth changes
  // reference: https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
  <Root {...props} key={props.extendedWidth ? 'true' : 'false'} />
);

// TODO (AC-290) Take in a headerContent prop and render close button rather than requiring consumers to do these things
const Root = ({ extendedWidth, resizable, leftContentSlot, children, ...rest }: OrderDrawerProps) => {
  const orderDrawerWidthStorage = useStorage<number>(window.localStorage, 'order-drawer-width');
  const [dragging, setDragging] = useState(false);
  const isXsWidth = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [newWidth, setNewWidth] = useState(() =>
    extendedWidth ? orderDrawerWidthStorage.getJSON() || extendedWidthValue : defaultWidthValue,
  );
  const [animated, setAnimated] = useState(false);

  const expanded = newWidth > defaultWidthValue;

  const onExpandButtonClick = useCallback(() => {
    let timeoutId: NodeJS.Timeout;

    return () => {
      clearTimeout(timeoutId);

      logAction('Order Drawer - Expand Button Clicked', {
        expanded: !expanded,
      });

      setAnimated(true);

      timeoutId = setTimeout(() => {
        setAnimated(false);
      }, 1500);

      const newWidth = expanded ? defaultWidthValue : extendedWidthValue;
      setNewWidth(newWidth);
      orderDrawerWidthStorage.setJSON(newWidth);
    };
  }, [expanded, orderDrawerWidthStorage]);

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault();
    setDragging(true);
  }, []);

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!dragging) {
        return;
      }

      const offsetRight = getOffsetRightWidth(e.clientX);

      if (offsetRight !== undefined) {
        setNewWidth(offsetRight);
      }
    },
    [dragging],
  );

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault();
    setDragging(true);
  }, []);

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!dragging) return;

      const offsetRight = getOffsetRightWidth(e.changedTouches[0].clientX);

      if (offsetRight !== undefined) {
        setNewWidth(offsetRight);
      }
    },
    [dragging],
  );

  const handleDragEnd = useCallback(() => {
    if (!dragging) return;

    orderDrawerWidthStorage.setJSON(newWidth);

    setDragging(false);

    logAction('Order Drawer - Resized (dragged)', {
      expanded,
    });
  }, [dragging, expanded, newWidth, orderDrawerWidthStorage]);

  useEffect(() => {
    if (resizable) {
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchend', handleDragEnd);
    }

    return () => {
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [handleDragEnd, resizable]);

  const expandIcon = expanded ? <ArrowRightSLineIcon size={18} /> : <ArrowLeftSLineIcon size={18} />;

  return (
    <Drawer
      anchor="right"
      onMouseMove={handleMouseMove}
      onMouseUp={handleDragEnd}
      onTouchEnd={handleDragEnd}
      onTouchMove={handleTouchMove}
      PaperProps={{
        sx: {
          cursor: dragging ? 'ew-resize' : undefined,
          width: isXsWidth ? '100%' : '95%',
          maxWidth: isXsWidth ? '100%' : newWidth,
          overflowY: 'visible',
          transition: animated
            ? 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s !important'
            : undefined,
        },
      }}
      {...rest}
    >
      {!isXsWidth && resizable && (
        <Dragger isDragging={dragging} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
          <Tooltip arrow enterTouchDelay={0} placement="top" title={expanded ? 'Collapse' : 'Expand'}>
            <IconButton
              aria-label={expanded ? 'Collapse' : 'Expand'}
              color="secondary"
              onClick={onExpandButtonClick()}
              size="small"
              sx={{
                border: '1px solid',
                borderColor: ColorsV2.gray_light,
                backgroundColor: ColorsV2.white,
                '&:hover': {
                  backgroundColor: ColorsV2.gray_light,
                },
                position: 'absolute',
                zIndex: 101,
                left: 10,
                top: 30,
              }}
            >
              {expandIcon}
            </IconButton>
          </Tooltip>
          {leftContentSlot}
        </Dragger>
      )}
      <InnerContainer>{children}</InnerContainer>
    </Drawer>
  );
};

interface DraggerProps extends BoxProps {
  isDragging: boolean;
  children: React.ReactNode;
}

const Dragger = React.memo(({ isDragging, onMouseDown, onTouchStart, children, ...props }: DraggerProps) => {
  return (
    <Box
      {...props}
      sx={{
        position: 'absolute',
        right: '100%',
        top: 0,
        height: '100%',
        ...props.sx,
      }}
    >
      {children}
      <Box
        aria-label="Resize"
        component="button"
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        sx={{
          border: 'none',
          background: 'none',
          width: '24px',
          right: '-1px',
          zIndex: 100,
          cursor: 'ew-resize',
          height: '100%',
          position: 'relative',

          '&:active': {
            '& > div': {
              backgroundColor: ColorsV2.blue,
              width: '3px',
            },
          },

          '&:focus': {
            outline: 'none',
            '& > div': {
              backgroundColor: ColorsV2.blue,
              width: '3px',
            },
          },

          '&:hover': {
            '& > div': {
              backgroundColor: ColorsV2.blue,
              width: '3px',
            },
          },
        }}
        tabIndex={-1}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            opacity: 0.75,
            pointerEvents: 'none',
            transitionProperty: 'background-color, width',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-in-out',
            backgroundColor: ColorsV2.gray_medium,
            width: '1px',
            boxShadow: isDragging ? '3px 0px 3px rgba(0, 0, 0, .2)' : undefined,
          }}
        />
      </Box>
    </Box>
  );
});

const InnerContainer = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  wordWrap: 'break-word',
});

const getOffsetRightWidth = (clientX: number) => {
  const offsetRight = document.body.offsetWidth - (clientX - document.body.offsetLeft);
  const maxResizeWidth = document.body.offsetWidth - 100;

  if (offsetRight > minResizeWidth && offsetRight < maxResizeWidth) {
    return offsetRight;
  }

  if (offsetRight <= minResizeWidth) {
    return minResizeWidth;
  }

  if (offsetRight >= maxResizeWidth) {
    return maxResizeWidth;
  }

  return undefined;
};

interface SectionProps extends BoxProps {
  headerContent: React.ReactNode;
  headerDivider?: boolean;
  defaultExpanded?: boolean;
  sx?: SxProps;
  variant?: 'text' | 'accordion';
}

const Section = ({
  headerContent,
  headerDivider,
  defaultExpanded = true,
  sx,
  variant = 'text',
  children,
}: SectionProps) => {
  const header =
    typeof headerContent === 'string' ? (
      <Typography fontWeight="600" variant="subtitle1">
        {headerContent}
      </Typography>
    ) : (
      headerContent
    );

  return (
    <Box bgcolor={ColorsV2.white} boxShadow="0px 2px 5px rgba(0, 0, 0, 0.05)" flexGrow="1" padding="20px" sx={sx}>
      {variant === 'text' || !children ? (
        <>
          {header}
          {children && <Container headerDivider={headerDivider}>{children}</Container>}
        </>
      ) : (
        <Accordion
          defaultExpanded={defaultExpanded}
          fullWidth
          headerContent={header}
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <Container headerDivider={headerDivider}>{children}</Container>
        </Accordion>
      )}
    </Box>
  );
};

const Container = styled('div')(
  ({ theme, headerDivider }: { headerDivider: SectionProps['headerDivider'] } & { theme: Theme }) => ({
    paddingTop: theme.spacing(2.5),
    ...(headerDivider && {
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      marginTop: theme.spacing(2.5),
    }),
  }),
);

const tileStyles: SxProps = {
  wordWrap: 'break-word',
  fontWeight: '600',
  color: 'text.disabled',
};

const VariantColor = {
  default: undefined,
  highlighted: 'rgba(244, 85, 85, 0.06)',
} as const;

const Title = ({ children }: { children?: React.ReactNode }) => {
  if (typeof children === 'string') {
    return (
      <Typography sx={tileStyles} variant="body2">
        {`${children}:`}
      </Typography>
    );
  }

  return <>{children}</>;
};

const ContentSection = ({
  headerTitle,
  children,
  variant = 'default',
  ...props
}: {
  headerTitle?: React.ReactNode;
  children?: React.ReactNode;
  variant?: keyof typeof VariantColor;
}) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: VariantColor[variant],
          ...(variant === 'highlighted' && { mx: '-20px', p: '20px' }),
        }}
        {...props}
      >
        <Grid container spacing={1} wrap="nowrap">
          <Grid item xs={4}>
            <Title>{headerTitle}</Title>
          </Grid>
          <Grid item xs zeroMinWidth>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const ContentSectionMeta = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '6px',

  '&:last-of-type': {
    marginBottom: '0',
  },
});

export const OrderDrawer = {
  Root: RootContainer,
  Section,
  ContentSection,
  ContentSectionMeta,
};
