import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { forwardRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimeField as MuiTimeField, TimeFieldProps as MuiTimeFieldProps } from '@mui/x-date-pickers/TimeField';
import { TextFieldProps } from '@mui/material';

export interface TimeFieldProps extends Omit<MuiTimeFieldProps<Date>, 'renderInput' | 'inputFormat' | 'mask'> {
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  name?: TextFieldProps['name'];
  required?: TextFieldProps['required'];
  onBlur?: TextFieldProps['onBlur'];
  size?: TextFieldProps['size'];
}

export const TimeField = forwardRef<HTMLInputElement, TimeFieldProps>(
  ({ error, name, onBlur, helperText, required, size, ...rest }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiTimeField
          inputRef={ref}
          slotProps={{
            textField: {
              name,
              error,
              required,
              size,
              helperText,
              onBlur,
            },
          }}
          {...rest}
        />
      </LocalizationProvider>
    );
  },
);

TimeField.displayName = 'TimeField';

export default TimeField;
