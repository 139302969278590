import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const dateFormats = {
  date: new Intl.DateTimeFormat(undefined, { dateStyle: 'medium' }),
  dateAndTime: new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' }),
} as const;

export function displayDate(date: Date, format?: Intl.DateTimeFormat): string;
export function displayDate(date: null | undefined, format?: Intl.DateTimeFormat): null;
export function displayDate(date: Date | null | undefined, format?: Intl.DateTimeFormat): string | null;
export function displayDate(date: Date | null | undefined, format: Intl.DateTimeFormat = dateFormats.date) {
  return date ? format.format(date) : null;
}
