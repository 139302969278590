import { GetLanguagesQuery, useGetLanguagesQuery } from '../graphql/queries/getLanguages.generated';
import { keepPreviousData } from '@tanstack/react-query';

export function useLanguages() {
  return useGetLanguagesQuery(
    {},
    {
      staleTime: Infinity,
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to load languages.',
      },
      select: transformGetLanguagesQuery,
    },
  );
}

function transformGetLanguagesQuery({ languages }: GetLanguagesQuery) {
  return sortLanguagesByPriorityValues(
    languages?.flatMap((langs) => {
      if (!langs?.code) return [];

      return [{ value: langs.code, label: langs.name }];
    }) ?? [],
    ['en', 'es'],
  );
}

// places languages with given prioritized value codes at the top of the list
function sortLanguagesByPriorityValues(arr: { label: string; value: string }[], priorityValues: string[]) {
  const priorityMap = new Map(priorityValues.map((value, index) => [value, index]));

  return arr.sort(function comparePriority(a, b) {
    const priorityA = priorityMap.get(a.value);
    const priorityB = priorityMap.get(b.value);

    // If both items have defined priorities, compare them
    if (priorityA !== undefined && priorityB !== undefined) {
      return priorityA - priorityB;
    }

    // If one item has a defined priority and the other doesn't, prioritize the defined one
    if (priorityA !== undefined) {
      return -1;
    }
    if (priorityB !== undefined) {
      return 1;
    }

    // If neither item has a defined priority, maintain their original order
    return 0;
  });
}
