import * as Types from '../types.generated.js';

import { IntakePatientFragmentDoc } from '../fragments/IntakePatientFragment.generated.js';
import {
  PatientFragmentDoc,
  PatientInsuranceFragmentDoc,
  AddressFragmentDoc,
  PatientSlimFragmentDoc,
  PatientSlimSansAddressFragmentDoc,
} from '../fragments/PatientFragment.generated.js';
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetOrderSeedPotentialPatientMatchesQueryVariables = Types.Exact<{
  orderSeedId: Types.Scalars['UUID']['input'];
  orgId: Types.Scalars['UUID']['input'];
}>;

export type GetOrderSeedPotentialPatientMatchesQuery = {
  __typename?: 'Query';
  orderSeedPotentialPatientMatches?: Array<{
    __typename?: 'PatientType';
    id: string;
    emailAddress?: string | null;
    dateOfBirth?: string | null;
    firstName: string;
    middleName: string;
    lastName: string;
    sex?: Types.SexEnum | null;
    heightInInches?: number | null;
    weightInPounds?: number | null;
    homePhoneNumber?: string | null;
    mobilePhoneNumber?: string | null;
    patientWaiver?: {
      __typename?: 'PatientWaiverType';
      waiver?: { __typename?: 'WaiverType'; id: string; name: string } | null;
    } | null;
    guardian?: { __typename?: 'PersonType'; firstName: string; lastName: string; phoneNumber?: string | null } | null;
    languagePreference?: { __typename?: 'LanguageType'; code: string; name: string } | null;
    primaryCareProvider?: {
      __typename?: 'ProviderType';
      id: string;
      firstName: string;
      lastName: string;
      npi: string;
    } | null;
    primaryCareProviderContact?: {
      __typename?: 'ContactType';
      id: string;
      emailAddress?: string | null;
      phoneNumber?: string | null;
      smsNumber?: string | null;
      faxNumber?: string | null;
    } | null;
    primaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategory?: string | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    secondaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategory?: string | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    tertiaryInsurance?: {
      __typename?: 'PatientInsuranceType';
      status: Types.AppPatientInsuranceStatusChoices;
      policyNumber: string;
      insurer: {
        __typename?: 'InsurerType';
        id: string;
        insurerCategory?: string | null;
        name: string;
        display: string;
        requiresTitleXix: boolean;
        parent?: { __typename?: 'InsurerType'; id: string; name: string } | null;
      };
    } | null;
    shippingAddress?: {
      __typename?: 'AddressType';
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      postalCode: string;
      id: string;
    } | null;
  } | null> | null;
};

export const GetOrderSeedPotentialPatientMatchesDocument = `
    query getOrderSeedPotentialPatientMatches($orderSeedId: UUID!, $orgId: UUID!) {
  orderSeedPotentialPatientMatches(orderSeedId: $orderSeedId, orgId: $orgId) {
    ...IntakePatientFragment
  }
}
    ${IntakePatientFragmentDoc}
${PatientFragmentDoc}
${PatientInsuranceFragmentDoc}
${AddressFragmentDoc}`;

export const useGetOrderSeedPotentialPatientMatchesQuery = <
  TData = GetOrderSeedPotentialPatientMatchesQuery,
  TError = unknown,
>(
  variables: GetOrderSeedPotentialPatientMatchesQueryVariables,
  options?: Omit<UseQueryOptions<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>({
    queryKey: ['getOrderSeedPotentialPatientMatches', variables],
    queryFn: fetcher<GetOrderSeedPotentialPatientMatchesQuery, GetOrderSeedPotentialPatientMatchesQueryVariables>(
      GetOrderSeedPotentialPatientMatchesDocument,
      variables,
    ),
    ...options,
  });
};

useGetOrderSeedPotentialPatientMatchesQuery.getKey = (variables: GetOrderSeedPotentialPatientMatchesQueryVariables) => [
  'getOrderSeedPotentialPatientMatches',
  variables,
];

export const useInfiniteGetOrderSeedPotentialPatientMatchesQuery = <
  TData = InfiniteData<GetOrderSeedPotentialPatientMatchesQuery>,
  TError = unknown,
>(
  variables: GetOrderSeedPotentialPatientMatchesQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetOrderSeedPotentialPatientMatchesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getOrderSeedPotentialPatientMatches.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetOrderSeedPotentialPatientMatchesQuery, GetOrderSeedPotentialPatientMatchesQueryVariables>(
            GetOrderSeedPotentialPatientMatchesDocument,
            { ...variables, ...(metaData.pageParam ?? {}) },
          )(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetOrderSeedPotentialPatientMatchesQuery.getKey = (
  variables: GetOrderSeedPotentialPatientMatchesQueryVariables,
) => ['getOrderSeedPotentialPatientMatches.infinite', variables];

useGetOrderSeedPotentialPatientMatchesQuery.fetcher = (
  variables: GetOrderSeedPotentialPatientMatchesQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetOrderSeedPotentialPatientMatchesQuery, GetOrderSeedPotentialPatientMatchesQueryVariables>(
    GetOrderSeedPotentialPatientMatchesDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOrderSeedPotentialPatientMatchesQuery((req, res, ctx) => {
 *   const { orderSeedId, orgId } = req.variables;
 *   return res(
 *     ctx.data({ orderSeedPotentialPatientMatches })
 *   )
 * })
 */
export const mockGetOrderSeedPotentialPatientMatchesQuery = (
  resolver: Parameters<
    typeof graphql.query<GetOrderSeedPotentialPatientMatchesQuery, GetOrderSeedPotentialPatientMatchesQueryVariables>
  >[1],
) =>
  graphql.query<GetOrderSeedPotentialPatientMatchesQuery, GetOrderSeedPotentialPatientMatchesQueryVariables>(
    'getOrderSeedPotentialPatientMatches',
    resolver,
  );
