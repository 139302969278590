import { Outlet, useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import React, { Suspense } from 'react';
import SpinnerScreen from '../components/Spinner/SpinnerScreen';

export function Layout({ drawer, header }: { drawer?: React.ReactNode; header?: React.ReactNode }) {
  const context = useOutletContext();

  return (
    <>
      <Suspense fallback={null}>{header}</Suspense>

      <Box component="main" display="flex" flexDirection="column" flexGrow="1" id="main" role="main" tabIndex={-1}>
        <Suspense fallback={<SpinnerScreen fullHeight />}>
          <Outlet context={context} />
        </Suspense>
        <Suspense>{drawer}</Suspense>
      </Box>
    </>
  );
}
