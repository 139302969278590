import { WithDirection } from '../api-clients/falcon-api/hooks/useOrgOrderSearch';
import ArrowDropDownFillIcon from 'remixicon-react/ArrowDropDownFillIcon';
import ArrowDropUpFillIcon from 'remixicon-react/ArrowDropUpFillIcon';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Table, { TableProps } from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import visuallyHidden from '@mui/utils/visuallyHidden';

export function OrdersTable(props: TableProps) {
  return (
    <Table
      size="small"
      sx={{
        width: '100%',
        '& thead': {
          '& th': {
            borderTop: `solid 1px rgba(153, 167, 174, 0.4)`,
            borderBottom: `solid 1px rgba(153, 167, 174, 0.4)`,
            borderLeft: `solid rgba(0, 35, 52, 0.06) 1px`,
            borderRight: `solid rgba(0, 35, 52, 0.06) 1px`,
            fontWeight: 'bold',
            fontSize: '10px',
            lineHeight: '145%',
            letterSpacing: '0.04em',
            textTransform: 'uppercase',
            color: '#002334',
          },
        },
        '& tbody': {
          '& tr': {
            cursor: 'pointer',
            '&:focus-within': {
              outline: '1px solid',
            },
          },
          '& td': {
            fontSize: '13px',
            lineHeight: '145%',
            border: `1px solid rgba(0, 35, 52, 0.06)`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'text.secondary',
          },
        },
        ...props.sx,
      }}
      {...props}
    />
  );
}

export interface SortProps<SortableColumn extends string> {
  onSort: (sort: WithDirection<SortableColumn>) => unknown;
  sort: WithDirection<SortableColumn>;
}

export function TableHeadCell<SortableColumn extends string>({
  column,
  children,
  onSort,
  sort,
}: { column?: SortableColumn; children: React.ReactNode } & SortProps<SortableColumn>) {
  if (!column) return <TableCell>{children}</TableCell>;

  const descColumn: WithDirection<SortableColumn> = `-${column}`;
  const direction = (() => {
    if (sort === column) return 'asc';
    if (sort === descColumn) return 'desc';
    return undefined;
  })();

  return (
    <TableCell sortDirection={direction ?? false}>
      <TableSortLabel
        active={sort === column || sort === descColumn}
        direction={direction}
        IconComponent={() => {
          if (sort === column) return <ArrowDropUpFillIcon />;
          if (sort === descColumn) return <ArrowDropDownFillIcon />;
          return null;
        }}
        onClick={() => onSort(sort === column ? descColumn : column)}
      >
        {children}

        {sort === column ? <span style={visuallyHidden}>sorted ascending</span> : null}
        {sort === descColumn ? <span style={visuallyHidden}>sorted descending</span> : null}
      </TableSortLabel>
    </TableCell>
  );
}

export function SkeletonRows({ cellCount, rowCount }: { cellCount: number; rowCount: number }) {
  return (
    <>
      {[...Array(rowCount).keys()].map((row) => (
        <TableRow key={row}>
          {[...Array(cellCount).keys()].map((cell) => (
            <TableCell key={cell}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
