import { useCurrentUser } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { useMemo } from 'react';
import { wrapStorage } from '../utils/wrapStorage';

export function useStorage<Value>(storage: Storage, storageKey: string, uniqueByUser = true) {
  const { data: user } = useCurrentUser();

  const key = uniqueByUser ? `${storageKey}-${user?.id ?? 'anon'}` : storageKey;

  return useMemo(() => wrapStorage<Value>(storage, key), [key, storage]);
}
