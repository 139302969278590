import {
  GetIntakeOrgPatientsQuery,
  useGetIntakeOrgPatientsQuery,
} from '../api-clients/falcon-api/graphql/queries/getIntakeOrgPatients.generated';
import { IntakePatient } from './PatientModal/PatientForm.helpers';
import { keepPreviousData } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import React, { forwardRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import useDebounce from '../hooks/useDebounce';

export interface PatientAutocompleteProps {
  disablePatientSelection?: boolean;
  error: string | undefined;
  orgId: string;
  selectedPatient: IntakePatient | null;
  placeholder?: string;
  setSelectedPatient: (patient: IntakePatient | null) => void;
  id?: string;
  size?: 'small' | 'medium';
}

export const PatientAutocomplete = React.memo(
  forwardRef<HTMLElement, PatientAutocompleteProps>(function PatientAutocomplete(
    { disablePatientSelection, placeholder, error, orgId, id, selectedPatient, setSelectedPatient, size },
    ref,
  ) {
    const [optionsListIsOpen, setOptionsListIsOpen] = useState(false);
    const [patientOptionsSearchValue, setPatientOptionsSearchValue] = useState('');
    const fullName = useDebounce(patientOptionsSearchValue, 200);
    const patientOptions = usePatientOptions(orgId, fullName, !disablePatientSelection);
    const totalPatientCount = useTotalPatientCount(orgId, !disablePatientSelection);
    const noPatientsError =
      totalPatientCount.isSuccess && !totalPatientCount.data && !selectedPatient
        ? 'No patients saved, Please click Add new Patient'
        : '';

    const errorText = error ?? noPatientsError;
    const disabled = disablePatientSelection || !!noPatientsError;

    function handleTextboxChange(input: string) {
      if (!input) setSelectedPatient(null);
      setPatientOptionsSearchValue(input);
    }

    return (
      <FormControl error={!!errorText}>
        <Autocomplete
          disabled={disabled}
          disablePortal
          getOptionLabel={getOptionLabel}
          id={id}
          isOptionEqualToValue={getOptionSelected}
          loading={patientOptions.isFetching}
          onChange={(_e, value) => setSelectedPatient(value)}
          onClose={() => setOptionsListIsOpen(false)}
          onOpen={() => setOptionsListIsOpen(true)}
          open={optionsListIsOpen && !disabled && (!!patientOptions.data?.length || patientOptions.isFetching)}
          options={patientOptions.data ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errorText}
              helperText={errorText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {patientOptions.isFetching ? (
                      <Box position="absolute" right="37px">
                        <CircularProgress color="inherit" size={20} />
                      </Box>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                size,
              }}
              inputRef={ref}
              onChange={(event) => handleTextboxChange(event.target.value)}
              placeholder={placeholder ?? 'Search for a patient'}
            />
          )}
          size={size}
          value={selectedPatient}
        />
      </FormControl>
    );
  }),
);

function getOptionLabel({ firstName = '', lastName = '' }: IntakePatient) {
  return `${firstName} ${lastName}`;
}

function getOptionSelected(option: IntakePatient, patient: IntakePatient) {
  return option.id === patient.id;
}

function useTotalPatientCount(orgId: string, enabled: boolean) {
  return useGetIntakeOrgPatientsQuery(
    { orgId, first: 0, fullName: '' },
    {
      enabled: enabled && !!orgId,
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve total patient count',
      },
      select: transformGetIntakePatientsQueryForCount,
    },
  );
}

function transformGetIntakePatientsQueryForCount(query: GetIntakeOrgPatientsQuery) {
  return query.orgPatients?.totalCount;
}

function usePatientOptions(orgId: string, fullName: string, enabled: boolean) {
  return useGetIntakeOrgPatientsQuery(
    { orgId, first: 10, fullName },
    {
      enabled: enabled && !!orgId && fullName.length > 1,
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve patients',
      },
      select: transformGetIntakePatientsQuery,
    },
  );
}

function transformGetIntakePatientsQuery(query: GetIntakeOrgPatientsQuery): IntakePatient[] {
  return query.orgPatients?.edges?.flatMap((edge) => (edge?.node ? [edge.node] : [])) ?? [];
}
