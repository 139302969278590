import {
  DiagnosisCodeFragment,
  GetDiagnosisCodesQuery,
  useGetDiagnosisCodesQuery,
} from '../api-clients/falcon-api/graphql/queries/getDiagnosisCodes.generated';
import { keepPreviousData } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useDebounce from '../hooks/useDebounce';

export interface DiagnosisCode extends DiagnosisCodeFragment {
  id: DiagnosisCodeFragment['code'];
}

export interface DiagnosisCodeAutocompleteProps {
  error: string | undefined;
  selectedDiagnosisCodes: DiagnosisCode | DiagnosisCode[] | undefined;
  setSelectedDiagnosisCodes: (diagnosisCodes: DiagnosisCode[] | DiagnosisCode | null) => void;
  disabled?: boolean;
  disablePortal?: boolean;
  multiple?: boolean;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  id?: string;
}

const filterOptions = createFilterOptions<DiagnosisCode>({
  stringify: (option) => `${option.code} ${option.description}`,
});

export const DiagnosisCodeAutocomplete = React.forwardRef<HTMLElement, DiagnosisCodeAutocompleteProps>(
  function DiagnosisCodeAutoComplete(
    {
      error,
      selectedDiagnosisCodes,
      multiple = true,
      setSelectedDiagnosisCodes,
      disabled: disabledProp,
      disablePortal = false,
      placeholder,
      required,
      size = 'small',
      id,
    },
    ref,
  ) {
    const [diagnosisCodeOptionsSearchValue, setDiagnosisCodeOptionsSearchValue] = useState('');
    const diagnosisCodeSearchQuery = useDebounce(diagnosisCodeOptionsSearchValue, 300);
    const diagnosisCodeOptions = useDiagnosisCodeOptions(diagnosisCodeSearchQuery);
    const totalDiagnosisCodeCount = useTotalDiagnosisCodeCount();
    const hasValue = Array.isArray(selectedDiagnosisCodes)
      ? selectedDiagnosisCodes.length > 0
      : !!selectedDiagnosisCodes;
    const noDiagnosisCodeError =
      totalDiagnosisCodeCount.isSuccess && !totalDiagnosisCodeCount.data && !hasValue ? 'No diagnosis codes found' : '';

    const errorText = error ?? noDiagnosisCodeError;
    const disabled = disabledProp || !!noDiagnosisCodeError;

    function handleTextboxChange(input: string) {
      setDiagnosisCodeOptionsSearchValue(input);
    }

    return (
      <Autocomplete
        disabled={disabled}
        disablePortal={disablePortal}
        filterOptions={filterOptions}
        filterSelectedOptions
        getOptionLabel={getOptionLabel}
        id={id}
        isOptionEqualToValue={getOptionSelected}
        limitTags={3}
        loading={diagnosisCodeOptions.isFetching}
        multiple={multiple}
        noOptionsText={
          diagnosisCodeOptionsSearchValue ? 'No options found. Try searching for more.' : 'Try searching for options'
        }
        onChange={(_e, value) => setSelectedDiagnosisCodes(value)}
        options={diagnosisCodeOptions.data ?? []}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorText}
            helperText={errorText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {diagnosisCodeOptions.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputRef={ref}
            onChange={(event) => handleTextboxChange(event.target.value)}
            placeholder={placeholder ?? 'Search code or description'}
            required={required}
            size={size}
          />
        )}
        renderOption={getOptionRender}
        renderTags={renderTags}
        size={size}
        value={selectedDiagnosisCodes}
      />
    );
  },
);

function renderTags(value, getTagProps) {
  return value.map((option, index) => (
    <Chip key={option.id} {...getTagProps({ index })} label={getTagRender(option)} />
  ));
}

function getTagRender({ code, description }: DiagnosisCode) {
  return (
    <Box component={Typography} title={`${code} - ${description}`}>
      {code}
    </Box>
  );
}

function getOptionLabel({ code }: DiagnosisCode) {
  return code;
}

function getOptionRender(props, { code, description }: DiagnosisCode) {
  return (
    <li {...props}>
      <Typography fontWeight="600" overflow="hidden" textOverflow="ellipsis">
        {code}

        {!!description && (
          <Typography component="span" title={description} variant="body2">
            {' '}
            - {description}
          </Typography>
        )}
      </Typography>
    </li>
  );
}

function getOptionSelected(option: DiagnosisCode, diagnosisCode: DiagnosisCode) {
  return option.code === diagnosisCode.code;
}

function useTotalDiagnosisCodeCount() {
  return useGetDiagnosisCodesQuery(
    { first: 0 },
    {
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve total diagnosis code count',
      },
      select: transformGetDiagnosisCodesQueryForCount,
    },
  );
}

function transformGetDiagnosisCodesQueryForCount(query: GetDiagnosisCodesQuery) {
  return query.diagnosisCodes?.totalCount;
}

function useDiagnosisCodeOptions(searchText: string) {
  return useGetDiagnosisCodesQuery(
    { first: 10, searchText },
    {
      enabled: searchText.length > 1,
      staleTime: milliseconds({ minutes: 5 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to retrieve diagnosis codes',
      },
      select: transformGetDiagnosisCodesQuery,
    },
  );
}

function transformGetDiagnosisCodesQuery(query: GetDiagnosisCodesQuery): DiagnosisCode[] {
  return (
    query.diagnosisCodes?.edges?.flatMap((edge) => (edge?.node ? [{ ...edge.node, id: edge.node.code }] : [])) ?? []
  );
}
