export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Base64` scalar type represents a base64-encoded String. */
  Base64: { input: string; output: string };
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: string; output: string };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: string; output: string };
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: number; output: number };
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: { input: unknown; output: unknown };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: string; output: string };
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: { input: string; output: string };
};

export type AckAllAppNotificationsMutation = {
  __typename?: 'AckAllAppNotificationsMutation';
  error?: Maybe<ErrorType>;
};

/** Adds a patient to an org. */
export type AddPatientToOrgMutation = {
  __typename?: 'AddPatientToOrgMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type AddSupplierDocuments = {
  __typename?: 'AddSupplierDocuments';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type AddSupplierDocumentsInput = {
  documents: Array<InputMaybe<DocumentInput>>;
  orderId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
  type: SupplyDocumentType;
};

/**
 * If the user exists, add it to org. Otherwise, create a user and add it to the org.
 * It is assumed that supplying a user_id as an input to this mutation means that the
 * caller believes the user already exists. However, if a user_id is not supplied and
 * user object is found with the input email, that existing user object will be used.
 *
 * To specify an org, either org_id or org_type and org_name must be provided.
 */
export type AddUserToOrgMutation = {
  __typename?: 'AddUserToOrgMutation';
  error?: Maybe<ErrorType>;
  orgUser?: Maybe<OrgUserType>;
};

export type AddressType = {
  __typename?: 'AddressType';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  county: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export enum AddressTypeEnum {
  Facility = 'FACILITY',
  Other = 'OTHER',
  Patient = 'PATIENT',
}

export type AdminAddDefectDefinitionMutation = {
  __typename?: 'AdminAddDefectDefinitionMutation';
  error?: Maybe<ErrorType>;
  intakeDefect?: Maybe<IntakeDefectType>;
};

export type AdminAddEhrSystemToOrgMutation = {
  __typename?: 'AdminAddEhrSystemToOrgMutation';
  error?: Maybe<ErrorType>;
};

export type AdminAddHcpcsCodesToSubcategory = {
  __typename?: 'AdminAddHcpcsCodesToSubcategory';
  error?: Maybe<ErrorType>;
  subcategory?: Maybe<IntakeSubcategoryType>;
};

export type AdminAddPatientsToPracticeMutation = {
  __typename?: 'AdminAddPatientsToPracticeMutation';
  error?: Maybe<ErrorType>;
  practice?: Maybe<PracticeType>;
};

export type AdminAssignOwnerMutation = {
  __typename?: 'AdminAssignOwnerMutation';
  error?: Maybe<ErrorType>;
  relation?: Maybe<OrderUserRelationType>;
};

export type AdminAutoRouteOrder = {
  __typename?: 'AdminAutoRouteOrder';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminBulkUpdateIntakeQuestionHcpcsMutation = {
  __typename?: 'AdminBulkUpdateIntakeQuestionHcpcsMutation';
  error?: Maybe<ErrorType>;
  errorList?: Maybe<Array<Maybe<ErrorListItemType>>>;
  intakeQuestionHcpcs?: Maybe<Array<Maybe<IntakeQuestionHcpcsType>>>;
};

/**
 * In contrast to the existing bulk_replace_insurer_coverage function, this
 * mutation forces users to be explicit about insurer coverage additions and removals
 * rather than performing a full replace where the user must pass in the full list of
 * insurers that are covered and omit those that should not be (declarative).
 *
 * The advantage of requiring explicitly defined additions and removals is that it is less
 * susceptible to concurrent user issues. Declarative operations can inadvertently, and silently,
 * overwrite concurrent users changes letting only the last one win.
 */
export type AdminBulkUpdateNetworkSupplierCoveredInsurers = {
  __typename?: 'AdminBulkUpdateNetworkSupplierCoveredInsurers';
  addedInsurerIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  error?: Maybe<ErrorType>;
  networkSupplier?: Maybe<NetworkSupplierType>;
  removedInsurerIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

/**
 * Associate/disassociate product validation rules to/from product question
 * mappings
 */
export type AdminBulkUpdateProductValidationRuleMappingsMutation = {
  __typename?: 'AdminBulkUpdateProductValidationRuleMappingsMutation';
  /** Whether this operation associated or disassociated */
  associate?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  productQuestionMappings?: Maybe<Array<ProductCatalogQuestionValidationRuleInsurerType>>;
};

/**
 * Associate/disassociate clinical validation rules to/from clinical question
 * mappings
 */
export type AdminBulkUpdateValidationRuleMappingsMutation = {
  __typename?: 'AdminBulkUpdateValidationRuleMappingsMutation';
  /** Whether this operation associated or disassociated */
  associate?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  intakeQuestionHcpcs?: Maybe<Array<IntakeQuestionHcpcsType>>;
};

export type AdminCancelNetworkOrder = {
  __typename?: 'AdminCancelNetworkOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export enum AdminCancelNetworkOrderType {
  Restart = 'RESTART',
  Terminal = 'TERMINAL',
}

export type AdminChangeSigningMethodInput = {
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['UUID']['input'];
  signingMethod: AppOrderSignerSigningMethodChoices;
};

export type AdminChangeSigningMethodMutation = {
  __typename?: 'AdminChangeSigningMethodMutation';
  error?: Maybe<ErrorType>;
  success: Scalars['Boolean']['output'];
};

export type AdminChargeInvoiceMutation = {
  __typename?: 'AdminChargeInvoiceMutation';
  invoice?: Maybe<InvoiceType>;
  payment?: Maybe<PaymentType>;
};

export type AdminCheckPatientInsuranceEligibilityMutation = {
  __typename?: 'AdminCheckPatientInsuranceEligibilityMutation';
  error?: Maybe<ErrorType>;
  patientInsurance?: Maybe<PatientInsuranceType>;
};

export type AdminCloneIntakeQuestionMutation = {
  __typename?: 'AdminCloneIntakeQuestionMutation';
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionType>;
};

export type AdminCloneIntakeQuestionVersionMutation = {
  __typename?: 'AdminCloneIntakeQuestionVersionMutation';
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionType>;
};

/**
 * When CR questions are queried, for a given hcpcs, we first search for any
 * mappings associated with the order's primary insurer. If we find no mappings, then
 * we'll traverse the insurer base_entity hierarchy up and perform another search until
 * we find a single mapping.
 *
 * Let's say A0001 is mapped to question1 and question2 for the default insurer (None).
 * We want to add a new question3 to A0001 only for GHP but still have question1 and
 * question2 appear for A0001.
 *
 * It would be insufficient to just add a new mapping between A0001/question3/GHP
 * because the insurer hierarchy traversal logic will start with GHP, find the mapping
 * for question3 then stop. This means that question 1 and 2 will stop appearing!
 *
 * In order to get all three questions to appear, we need to clone the original
 * mappings that would have been resolved through the hierarchy traversal into the GHP
 * insurer, then create the new mapping for the new question.
 * This way when we query for mappings for A0001/GHP, all three questions show up.
 *
 * This mutation is meant to provide the initial cloning of all hierarchy-resolved
 * mappings for a HCPCS into mappings specific for the input insurer.
 */
export type AdminCloneMappingsForInsurerHcpcsMutation = {
  __typename?: 'AdminCloneMappingsForInsurerHcpcsMutation';
  error?: Maybe<ErrorType>;
  intakeQuestionHcpcs?: Maybe<Array<Maybe<IntakeQuestionHcpcsObjectType>>>;
};

export type AdminCompleteNetworkOrder = {
  __typename?: 'AdminCompleteNetworkOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

/**
 * Create a comment on a Box order.
 *
 * If the input includes an original_comment_id, the comment is a reply
 * to the original comment.
 *
 * We do not store the comment in the database, as a successful comment creation
 * will trigger a webhook event which will in turn store the comment in the database.
 */
export type AdminCreateBoxComment = {
  __typename?: 'AdminCreateBoxComment';
  comment?: Maybe<Scalars['String']['output']>;
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminCreateCareCoordination = {
  __typename?: 'AdminCreateCareCoordination';
  careCoordination?: Maybe<CareCoordinationType>;
  error?: Maybe<ErrorType>;
};

export type AdminCreateEhrSystemInput = {
  fhirServerUrl: Scalars['String']['input'];
  /** FHIR version, eg 'r4', default 'unknown' */
  fhirVersion?: InputMaybe<FhirVersionEnum>;
  iss: Scalars['String']['input'];
  jwksUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** List of Org ids to be associated with this EHR system */
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The EHR vendor, e.g 'epic' */
  type: EhrVendorEnum;
};

export type AdminCreateEhrSystemMutation = {
  __typename?: 'AdminCreateEhrSystemMutation';
  ehrSystem?: Maybe<EhrSystemObjectType>;
  error?: Maybe<ErrorType>;
};

export type AdminCreateIntakeQuestionHcpcsMutation = {
  __typename?: 'AdminCreateIntakeQuestionHcpcsMutation';
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionType>;
  invalidList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/**
 * Mutation to create new intake question.
 *
 * Constraints for creating intake questions:
 * 1. If history_version_id is included, question_type input must match the
 *    question_type of the IntakeQuestion with id=history_version_id.
 * 2. If history_version_id matches a question, will create a new version of that
 *    original question. The new IntakeQuestion's history_version_id will be the id
 *    of the original.
 * 3. If history_version_id is included and a matching question for it cannot be
 *    found, will raise an error.
 */
export type AdminCreateIntakeQuestionMutation = {
  __typename?: 'AdminCreateIntakeQuestionMutation';
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionType>;
};

export type AdminCreateNetworkSupplier = {
  __typename?: 'AdminCreateNetworkSupplier';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  networkSupplier?: Maybe<NetworkSupplierType>;
};

export type AdminCreateNetworkSupplierInput = {
  autoAccept?: InputMaybe<Scalars['Boolean']['input']>;
  automaticEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  billingTins?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  intakePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  isProofOfDeliveryRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isThdSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  networkSupplierHcpcs?: InputMaybe<Array<InputMaybe<NetworkSupplierHcpcsInput>>>;
  networkSupplierZipcodes?: InputMaybe<Array<InputMaybe<NetworkSupplierZipcodeInput>>>;
  npi?: InputMaybe<Scalars['String']['input']>;
  opsId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  servicingCapability?: InputMaybe<AppNetworkSupplierServicingCapabilityChoices>;
  sftpUsername?: InputMaybe<Scalars['String']['input']>;
  statewideShip?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateOrUpdateInsurerMutation = {
  __typename?: 'AdminCreateOrUpdateInsurerMutation';
  error?: Maybe<ErrorType>;
  insurer?: Maybe<InsurerType>;
};

export type AdminCreateOrUpdateWaiverMutation = {
  __typename?: 'AdminCreateOrUpdateWaiverMutation';
  error?: Maybe<ErrorType>;
  waiver?: Maybe<WaiverType>;
};

export type AdminCreateOrderDocumentsMutation = {
  __typename?: 'AdminCreateOrderDocumentsMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminCreatePracticeInput = {
  addresses?: InputMaybe<Array<CreateAddressInput>>;
  facilityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreatePracticeMutation = {
  __typename?: 'AdminCreatePracticeMutation';
  error?: Maybe<ErrorType>;
  practice?: Maybe<PracticeType>;
};

export type AdminCreateProductFormVersionMutation = {
  __typename?: 'AdminCreateProductFormVersionMutation';
  error?: Maybe<ErrorType>;
  productFormVersion?: Maybe<ProductFormVersionType>;
};

export type AdminCreateProductMutation = {
  __typename?: 'AdminCreateProductMutation';
  error?: Maybe<ErrorType>;
  product?: Maybe<ProductType>;
};

/**
 * Mutation to create a new product question: either a new
 * ProductCatalogQuestionVersion of an existing ProductCatalogQuestion, or a new
 * ProductCatalogQuestionVersion of a new ProductCatalogQuestion
 *
 * Constraints for creating product question:
 * 1. If product_catalog_question_id is passed in and  matches an existing
 * ProductCatalogQuestion: create a new ProductCatalogQuestionVersion and
 * associate it with the existing ProductCatalogQuestion. The question_type
 * input must match the question_type of the existing ProductCatalogQuestion's
 * active_question_version; otherwise, raise error.
 * 2. If product_catalog_question_id is passed in and doesn't match any existing
 * ProductCatalogQuestion: raise error.
 * 3. If product_catalog_question_id is not passed in: (1) create a new
 * ProductCatalogQuestion and (2) create a new ProductCatalogQuestionVersion and
 * associate it with the newly created ProductCatalogQuestion
 */
export type AdminCreateProductQuestionVersionMutation = {
  __typename?: 'AdminCreateProductQuestionVersionMutation';
  error?: Maybe<ErrorType>;
  productQuestionVersion?: Maybe<ProductCatalogQuestionVersionType>;
};

export type AdminCreateProductValidationRuleMappingsMutation = {
  __typename?: 'AdminCreateProductValidationRuleMappingsMutation';
  error?: Maybe<ErrorType>;
  productQuestionMappings?: Maybe<Array<ProductCatalogQuestionValidationRuleInsurerType>>;
};

export type AdminCreateProviderMutation = {
  __typename?: 'AdminCreateProviderMutation';
  error?: Maybe<ErrorType>;
  provider?: Maybe<ProviderType>;
};

export type AdminCreateSubcategoryProductFormMutation = {
  __typename?: 'AdminCreateSubcategoryProductFormMutation';
  error?: Maybe<ErrorType>;
  intakeSubcategoryProductForm?: Maybe<IntakeSubcategoryProductFormType>;
};

export type AdminCreateTicketMutation = {
  __typename?: 'AdminCreateTicketMutation';
  error?: Maybe<ErrorType>;
  ticket?: Maybe<TicketType>;
};

export type AdminCreateUserInput = {
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type AdminCreateUserMutation = {
  __typename?: 'AdminCreateUserMutation';
  user?: Maybe<UserType>;
};

export type AdminDeactivateFacilityMutation = {
  __typename?: 'AdminDeactivateFacilityMutation';
  error?: Maybe<ErrorType>;
  facility?: Maybe<FacilityType>;
};

export type AdminDeactivatePracticeMutation = {
  __typename?: 'AdminDeactivatePracticeMutation';
  error?: Maybe<ErrorType>;
  practice?: Maybe<PracticeType>;
};

export type AdminDeleteDocumentMutation = {
  __typename?: 'AdminDeleteDocumentMutation';
  deletionSuccessful?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
};

/**
 * Since there's no easy way to test this functionality aside from just pushing to
 * prod, when we need to make significant changes to this mutation, we should
 * temporarily add an entirely new mutation version even though tons of code will be
 * duplicated. Doing this will protect the existing Ops process and not block them
 * from deploying questions while we work out issues with the changes.
 *
 * Handles the deploy of a single intake question and either creates
 * or updates the intake question from input data based on the sync_id
 * and history_version_sync_id.
 */
export type AdminDeployIntakeQuestionMutation = {
  __typename?: 'AdminDeployIntakeQuestionMutation';
  created?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionObjectType>;
  /** If a new intake question is created, returns the previous version to reflect hcpcs mapping changes. */
  intakeQuestionPreviousVersion?: Maybe<IntakeQuestionObjectType>;
};

/** Create order coversheet for provider to sign  */
export type AdminDownloadProviderCoversheetMutation = {
  __typename?: 'AdminDownloadProviderCoversheetMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

/** Regenerate coversheet to send to supplier  */
export type AdminDownloadSupplierCoversheetMutation = {
  __typename?: 'AdminDownloadSupplierCoversheetMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export type AdminMarkOrderComplexMutation = {
  __typename?: 'AdminMarkOrderComplexMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminNetworkSupplierCreateBoxShare = {
  __typename?: 'AdminNetworkSupplierCreateBoxShare';
  collaboration?: Maybe<BoxCollaborationType>;
  error?: Maybe<ErrorType>;
  networkSupplier?: Maybe<NetworkSupplierType>;
};

/** For each supplier with a pending Box invitation, refresh the collaborations list. */
export type AdminNetworkSupplierRefreshBoxAccepted = {
  __typename?: 'AdminNetworkSupplierRefreshBoxAccepted';
  message?: Maybe<Scalars['String']['output']>;
};

export type AdminOpenCustomerBillingPortal = {
  __typename?: 'AdminOpenCustomerBillingPortal';
  url?: Maybe<Scalars['String']['output']>;
};

/**
 * Mutation to get question json based on given input data.
 *
 * Note: This will generate and return the question json and DOES NOT SAVE the
 * question to the database.
 */
export type AdminPreviewIntakeQuestionMutation = {
  __typename?: 'AdminPreviewIntakeQuestionMutation';
  error?: Maybe<ErrorType>;
  questionJson?: Maybe<Scalars['JSONString']['output']>;
};

export type AdminRecordPriorAuthInput = {
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  insurerId: Scalars['ID']['input'];
  orderLineItemId: Scalars['ID']['input'];
  outcome: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminRecordPriorAuthMutation = {
  __typename?: 'AdminRecordPriorAuthMutation';
  error?: Maybe<ErrorType>;
  priorAuthorizationLineItem?: Maybe<PriorAuthorizationLineItemType>;
};

export type AdminRecordStakeholderEngagementMutation = {
  __typename?: 'AdminRecordStakeholderEngagementMutation';
  error?: Maybe<ErrorType>;
  stakeholderEngagement?: Maybe<StakeholderEngagementType>;
};

export type AdminRemoveHcpcsCodesFromSubcategory = {
  __typename?: 'AdminRemoveHcpcsCodesFromSubcategory';
  error?: Maybe<ErrorType>;
  subcategory?: Maybe<IntakeSubcategoryType>;
};

export type AdminRemoveIntakeQuestionHcpcsMutation = {
  __typename?: 'AdminRemoveIntakeQuestionHcpcsMutation';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminRemoveOrderRelationMutation = {
  __typename?: 'AdminRemoveOrderRelationMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminRemovePatientsFromPracticeMutation = {
  __typename?: 'AdminRemovePatientsFromPracticeMutation';
  error?: Maybe<ErrorType>;
  practice?: Maybe<PracticeType>;
};

export type AdminRestartOrder = {
  __typename?: 'AdminRestartOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminSendRequestClinicalRulesSignatureEmailMutation = {
  __typename?: 'AdminSendRequestClinicalRulesSignatureEmailMutation';
  error?: Maybe<ErrorType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AdminSendWelcomeEmail = {
  __typename?: 'AdminSendWelcomeEmail';
  emailRecordId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type AdminSignInAs = {
  __typename?: 'AdminSignInAs';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAtTimestamp?: Maybe<Scalars['Int']['output']>;
  magicQueryString?: Maybe<Scalars['String']['output']>;
  sudoBearerToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type AdminUpdateDeliveryVerification = {
  __typename?: 'AdminUpdateDeliveryVerification';
  assignment?: Maybe<OrderAssignmentType>;
  error?: Maybe<ErrorType>;
};

export type AdminUpdateHcpcsDetailsMutation = {
  __typename?: 'AdminUpdateHcpcsDetailsMutation';
  error?: Maybe<ErrorType>;
  hcpcs?: Maybe<HcpcsType>;
};

export type AdminUpdateIntakeQuestionHcpcsMutation = {
  __typename?: 'AdminUpdateIntakeQuestionHcpcsMutation';
  error?: Maybe<ErrorType>;
  intakeQuestionHcpcs?: Maybe<IntakeQuestionHcpcsType>;
};

export type AdminUpdateIntakeQuestionMutation = {
  __typename?: 'AdminUpdateIntakeQuestionMutation';
  error?: Maybe<ErrorType>;
  intakeQuestion?: Maybe<IntakeQuestionType>;
};

export type AdminUpdateIntakeSubcategoryMutation = {
  __typename?: 'AdminUpdateIntakeSubcategoryMutation';
  error?: Maybe<ErrorType>;
  subcategory?: Maybe<IntakeSubcategoryType>;
};

export type AdminUpdateNetworkSupplier = {
  __typename?: 'AdminUpdateNetworkSupplier';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  networkSupplier?: Maybe<NetworkSupplierType>;
};

export type AdminUpdateNetworkSupplierCoveredInsurer = {
  __typename?: 'AdminUpdateNetworkSupplierCoveredInsurer';
  error?: Maybe<ErrorType>;
  insurer?: Maybe<InsurerType>;
  networkSupplier?: Maybe<NetworkSupplierType>;
};

export type AdminUpdateNetworkSupplierDb = {
  __typename?: 'AdminUpdateNetworkSupplierDB';
  document?: Maybe<DocumentType>;
  error?: Maybe<ErrorType>;
  logs?: Maybe<Array<LogRecordType>>;
};

export type AdminUpdateNetworkSupplierInput = {
  autoAccept?: InputMaybe<Scalars['Boolean']['input']>;
  automaticEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  billingTins?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  intakePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  isProofOfDeliveryRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isThdSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  networkSupplierHcpcs?: InputMaybe<Array<InputMaybe<NetworkSupplierHcpcsInput>>>;
  networkSupplierZipcodes?: InputMaybe<Array<InputMaybe<NetworkSupplierZipcodeInput>>>;
  npi?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  servicingCapability?: InputMaybe<AppNetworkSupplierServicingCapabilityChoices>;
  sftpUsername?: InputMaybe<Scalars['String']['input']>;
  statewideShip?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateOrCreateOrderStatusReasonMutation = {
  __typename?: 'AdminUpdateOrCreateOrderStatusReasonMutation';
  created?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  orderStatusReason?: Maybe<OrderStatusReasonType>;
};

export type AdminUpdateOrCreatePatientInsuranceMutation = {
  __typename?: 'AdminUpdateOrCreatePatientInsuranceMutation';
  created?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  patientInsurance?: Maybe<PatientInsuranceType>;
};

export type AdminUpdateOrCreatePatientMutation = {
  __typename?: 'AdminUpdateOrCreatePatientMutation';
  created?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientType>;
};

export type AdminUpdateOrderAssignment = {
  __typename?: 'AdminUpdateOrderAssignment';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminUpdateOrderAssignmentInput = {
  forcedRouting?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['ID']['input'];
  overrideSavingsBrick?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type AdminUpdateOrderFacilityMutation = {
  __typename?: 'AdminUpdateOrderFacilityMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminUpdateOrderFacilityMutationInput = {
  facilityId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type AdminUpdateOrderPracticeMutation = {
  __typename?: 'AdminUpdateOrderPracticeMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type AdminUpdateOrderPracticeMutationInput = {
  orderId: Scalars['ID']['input'];
  practiceId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminUpdateOrderRequirementMutation = {
  __typename?: 'AdminUpdateOrderRequirementMutation';
  error?: Maybe<ErrorType>;
  orderRequirement?: Maybe<OrderRequirementType>;
};

export type AdminUpdatePatientInsuranceMutation = {
  __typename?: 'AdminUpdatePatientInsuranceMutation';
  error?: Maybe<ErrorType>;
  patientInsurance?: Maybe<PatientInsuranceType>;
};

export type AdminUpdatePracticeInput = {
  addresses?: InputMaybe<Array<CreateOrGetAddressInput>>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['ID']['input'];
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdatePracticeMutation = {
  __typename?: 'AdminUpdatePracticeMutation';
  error?: Maybe<ErrorType>;
  practice?: Maybe<PracticeType>;
};

export type AdminUpdateProductMutation = {
  __typename?: 'AdminUpdateProductMutation';
  error?: Maybe<ErrorType>;
  product?: Maybe<ProductType>;
};

export type AdminUpdateProviderInput = {
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  npi: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateProviderMutation = {
  __typename?: 'AdminUpdateProviderMutation';
  error?: Maybe<ErrorType>;
  provider?: Maybe<ProviderType>;
};

export type AdminUpdateUserInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  updateProviderContact?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export type AdminUpdateUserMutation = {
  __typename?: 'AdminUpdateUserMutation';
  error?: Maybe<ErrorType>;
  user?: Maybe<UserType>;
};

export enum AgreementStatusEnum {
  ExistingOrgHasAgreement = 'EXISTING_ORG_HAS_AGREEMENT',
  ExistingOrgHasAgreementNewUser = 'EXISTING_ORG_HAS_AGREEMENT_NEW_USER',
  ExistingOrgNeedsAgreement = 'EXISTING_ORG_NEEDS_AGREEMENT',
  ExistingOrgNeedsAgreementNewUser = 'EXISTING_ORG_NEEDS_AGREEMENT_NEW_USER',
  NewOrgNeedsAgreement = 'NEW_ORG_NEEDS_AGREEMENT',
}

export type AgreementType = {
  __typename?: 'AgreementType';
  agreementType: AgreementTypes;
  agreementUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

/** An enumeration. */
export enum AgreementTypes {
  BusinessAssociateAgreement = 'BUSINESS_ASSOCIATE_AGREEMENT',
}

/** An enumeration. */
export enum AppAppNotificationStatusChoices {
  /** Acknowledged */
  Acknowledged = 'ACKNOWLEDGED',
  /** Read */
  Read = 'READ',
  /** Sent */
  Sent = 'SENT',
}

/** An enumeration. */
export enum AppCommentAudienceChoices {
  /** External */
  External = 'EXTERNAL',
  /** Internal */
  Internal = 'INTERNAL',
}

/** An enumeration. */
export enum AppDocumentExtractionJobAssignmentRevokeReasonChoices {
  /** Contains Multiple Patients */
  ContainsMultiplePatients = 'CONTAINS_MULTIPLE_PATIENTS',
  /** Not Enough Info */
  NotEnoughInfo = 'NOT_ENOUGH_INFO',
  /** Other */
  Other = 'OTHER',
  /** Product Not Carried */
  ProductNotCarried = 'PRODUCT_NOT_CARRIED',
  /** Product Out Of Stock */
  ProductOutOfStock = 'PRODUCT_OUT_OF_STOCK',
  /** Timed Out */
  TimedOut = 'TIMED_OUT',
  /** Zip Code Not Serviceable */
  ZipCodeNotServiceable = 'ZIP_CODE_NOT_SERVICEABLE',
}

/** An enumeration. */
export enum AppDocumentExtractionJobAssignmentRoleChoices {
  /** Exception Handler */
  ExceptionHandler = 'EXCEPTION_HANDLER',
  /** Initiator */
  Initiator = 'INITIATOR',
}

/** An enumeration. */
export enum AppDocumentExtractionJobSourceTypeChoices {
  /** Admin Upload */
  AdminUpload = 'ADMIN_UPLOAD',
  /** Fax */
  Fax = 'FAX',
}

/** An enumeration. */
export enum AppDocumentExtractionJobStatusChoices {
  /** Ai Prediction Failed */
  AiPredictionFailed = 'AI_PREDICTION_FAILED',
  /** Initiated */
  Initiated = 'INITIATED',
  /** Insufficient Order Seed Info */
  InsufficientOrderSeedInfo = 'INSUFFICIENT_ORDER_SEED_INFO',
  /** Manually Split */
  ManuallySplit = 'MANUALLY_SPLIT',
  /** Ocr Failed */
  OcrFailed = 'OCR_FAILED',
  /** Order Seed Created */
  OrderSeedCreated = 'ORDER_SEED_CREATED',
  /** Order Seed Finalized */
  OrderSeedFinalized = 'ORDER_SEED_FINALIZED',
  /** Timed Out */
  TimedOut = 'TIMED_OUT',
  /** Unexpected Process Failure */
  UnexpectedProcessFailure = 'UNEXPECTED_PROCESS_FAILURE',
}

/** An enumeration. */
export enum AppEhrSystemFhirVersionChoices {
  /** DSTU1 */
  Dstu1 = 'DSTU1',
  /** DSTU2 */
  Dstu2 = 'DSTU2',
  /** R4 */
  R4 = 'R4',
  /** R5 */
  R5 = 'R5',
  /** STU3 */
  Stu3 = 'STU3',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** An enumeration. */
export enum AppEhrSystemTypeChoices {
  /** Cerner */
  Cerner = 'CERNER',
  /** Epic */
  Epic = 'EPIC',
  /** Logica */
  Logica = 'LOGICA',
}

/** An enumeration. */
export enum AppExternalIntegrationRequestMessageStatusChoices {
  /** Deferred */
  Deferred = 'DEFERRED',
  /** Delivered */
  Delivered = 'DELIVERED',
  /** Discarded */
  Discarded = 'DISCARDED',
  /** Failure */
  Failure = 'FAILURE',
  /** Merged */
  Merged = 'MERGED',
  /** Partial */
  Partial = 'PARTIAL',
  /** Pending */
  Pending = 'PENDING',
}

/** An enumeration. */
export enum AppExtractionAddressResultAddress1ConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionAddressResultAddress2ConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionAddressResultCityConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionAddressResultPostalCodeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionAddressResultStateConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionFeedbackFeedbackCategoryChoices {
  /** Clinical Summary */
  ClinicalSummary = 'CLINICAL_SUMMARY',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultGroupNumberConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultInsurerNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultPlanNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultPolicyNumberConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultSubscriberFirstNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionInsuranceResultSubscriberLastNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultBrandNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultCptCodeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultFrequencyConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultHcpcsCodeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultLengthOfNeedConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultProductNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionLineItemResultQuantityConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetBloodGasStudyConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetChartNotesConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetCoverSheetConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetDischargeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetExtractorSourceChoices {
  /** Darby */
  Darby = 'DARBY',
  /** Inhouse Openai */
  InhouseOpenai = 'INHOUSE_OPENAI',
  /** Synthpop */
  Synthpop = 'SYNTHPOP',
  /** Tennr */
  Tennr = 'TENNR',
}

/** An enumeration. */
export enum AppExtractionResultsSetParentOrderSeedIdConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientAhiConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientBmiConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientBpConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientCellPhoneConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientDateOfBirthConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientEmailAddressConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientEssConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientFirstNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientHeightInInchesConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientHomePhoneConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientLastNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientLastSeenDateConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientMiddleNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientNeckSizeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientRdiConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientReiConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientSexConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetPrescriptionConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetProviderFirstNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetProviderLastNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetProviderNpiConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetProviderSignaturePresentConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetReferralDateConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetSleepStudyConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetSubcategoryIdConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetSubcategoryNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppExtractionResultsSetSupplierNameConfidenceChoices {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
}

/** An enumeration. */
export enum AppFaxTransmissionStatusChoices {
  /** Delivered */
  Delivered = 'DELIVERED',
  /** Failed */
  Failed = 'FAILED',
  /** Pending */
  Pending = 'PENDING',
}

/** An enumeration. */
export enum AppInsurerBusinessSegmentChoices {
  /** Commercial */
  Commercial = 'COMMERCIAL',
  /** Dual Plan */
  DualPlan = 'DUAL_PLAN',
  /** Managed Medicaid */
  ManagedMedicaid = 'MANAGED_MEDICAID',
  /** Medicaid */
  Medicaid = 'MEDICAID',
  /** Medicare */
  Medicare = 'MEDICARE',
  /** Medicare Advantage */
  MedicareAdvantage = 'MEDICARE_ADVANTAGE',
}

/** An enumeration. */
export enum AppInsurerInsurerCategoryDirectChoices {
  /** cca */
  Cca = 'CCA',
  /** geisinger */
  Geisinger = 'GEISINGER',
  /** shp */
  Shp = 'SHP',
}

/** An enumeration. */
export enum AppInsurerInsurerTypeChoices {
  /** Carrier */
  Carrier = 'CARRIER',
  /** Other */
  Other = 'OTHER',
  /** Plan */
  Plan = 'PLAN',
}

/** An enumeration. */
export enum AppInsurerPriorAuthorizationServiceLevelChoices {
  /** Documentation */
  Documentation = 'DOCUMENTATION',
  /** Lookup */
  Lookup = 'LOOKUP',
  /** Submission */
  Submission = 'SUBMISSION',
}

/** An enumeration. */
export enum AppIntakeQuestionQuestionTypeChoices {
  /** Condition detail */
  ConditionDetail = 'CONDITION_DETAIL',
  /** Condition detail free text */
  ConditionDetailFreeText = 'CONDITION_DETAIL_FREE_TEXT',
  /** Condition duration */
  ConditionDuration = 'CONDITION_DURATION',
  /** Diagnostic code */
  DiagnosticCode = 'DIAGNOSTIC_CODE',
  /** Document upload */
  DocumentUpload = 'DOCUMENT_UPLOAD',
  /** Document upload details */
  DocumentUploadDetails = 'DOCUMENT_UPLOAD_DETAILS',
  /** Encounter Date */
  EncounterDate = 'ENCOUNTER_DATE',
  /** Other */
  Other = 'OTHER',
}

/** An enumeration. */
export enum AppIntakeValidationRuleErrorCategoryChoices {
  /** Disqualifying diagnosis */
  DisqualifyingDiagnosis = 'DISQUALIFYING_DIAGNOSIS',
  /** Disqualifying testing */
  DisqualifyingTesting = 'DISQUALIFYING_TESTING',
  /** Exceeded quantity */
  ExceededQuantity = 'EXCEEDED_QUANTITY',
  /** Out of normal coverage bounds */
  OutOfNormalCoverageBounds = 'OUT_OF_NORMAL_COVERAGE_BOUNDS',
  /** Unmet clinical criteria */
  UnmetClinicalCriteria = 'UNMET_CLINICAL_CRITERIA',
  /** Unmet product criteria */
  UnmetProductCriteria = 'UNMET_PRODUCT_CRITERIA',
}

/** An enumeration. */
export enum AppIntakeValidationRuleResultResultChoices {
  /** Invalid */
  Invalid = 'INVALID',
  /** N/A */
  NotApplicable = 'NOT_APPLICABLE',
  /** Valid */
  Valid = 'VALID',
  /** Warned */
  Warned = 'WARNED',
}

/** An enumeration. */
export enum AppIntakeValidationRuleRuleTypeChoices {
  /** Clinical Validation Rule */
  ClinicalValidationRule = 'CLINICAL_VALIDATION_RULE',
  /** Product Validation Rule */
  ProductValidationRule = 'PRODUCT_VALIDATION_RULE',
}

/** An enumeration. */
export enum AppIntegrationIntegrationTypeChoices {
  /** Org */
  Org = 'ORG',
  /** System */
  System = 'SYSTEM',
}

/** An enumeration. */
export enum AppInvoiceStatusChoices {
  /** Cancelled */
  Canceled = 'CANCELED',
  /** Denied */
  Denied = 'DENIED',
  /** Estimate of reimbursement */
  Estimate = 'ESTIMATE',
  /** On hold */
  Hold = 'HOLD',
  /** Open */
  Open = 'OPEN',
  /** Paid */
  Paid = 'PAID',
  /** Not submitted */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Submitted */
  Submitted = 'SUBMITTED',
}

/** An enumeration. */
export enum AppLineItemSurveyResponseReasonChoices {
  /** Item defective or damaged */
  DefectiveOrDamaged = 'DEFECTIVE_OR_DAMAGED',
  /** Item took too long to be delivered */
  DeliveryTooLong = 'DELIVERY_TOO_LONG',
  /** Incorrect brand */
  IncorrectBrand = 'INCORRECT_BRAND',
  /** Item not received */
  NotReceived = 'NOT_RECEIVED',
  /** Other */
  Other = 'OTHER',
  /** Member dissatisfied with supplier's customer service */
  SupplierServiceDissatisfaction = 'SUPPLIER_SERVICE_DISSATISFACTION',
  /** Member doesn't know how to use item */
  UnknownUsage = 'UNKNOWN_USAGE',
}

/** An enumeration. */
export enum AppMetricDisplayUnitChoices {
  /** Days */
  Days = 'DAYS',
  /** Percent */
  Percent = 'PERCENT',
}

/** An enumeration. */
export enum AppNetworkSupplierServicingCapabilityChoices {
  /** Offline */
  Offline = 'OFFLINE',
  /** Portal */
  Portal = 'PORTAL',
}

/** An enumeration. */
export enum AppNetworkSupplierTimezoneChoices {
  /** Africa/Cairo */
  AfricaCairo = 'AFRICA_CAIRO',
  /** Africa/Casablanca */
  AfricaCasablanca = 'AFRICA_CASABLANCA',
  /** Africa/Harare */
  AfricaHarare = 'AFRICA_HARARE',
  /** Africa/Nairobi */
  AfricaNairobi = 'AFRICA_NAIROBI',
  /** Africa/Porto-Novo */
  AfricaPortoNovo = 'AFRICA_PORTO_NOVO',
  /** Africa/Windhoek */
  AfricaWindhoek = 'AFRICA_WINDHOEK',
  /** America/Bogota */
  AmericaBogota = 'AMERICA_BOGOTA',
  /** America/Buenos Aires */
  AmericaBuenosAires = 'AMERICA_BUENOS_AIRES',
  /** America/Caracas */
  AmericaCaracas = 'AMERICA_CARACAS',
  /** America/Chicago */
  AmericaChicago = 'AMERICA_CHICAGO',
  /** America/Chihuahua */
  AmericaChihuahua = 'AMERICA_CHIHUAHUA',
  /** America/Denver */
  AmericaDenver = 'AMERICA_DENVER',
  /** America/Godthab */
  AmericaGodthab = 'AMERICA_GODTHAB',
  /** America/Goose Bay */
  AmericaGooseBay = 'AMERICA_GOOSE_BAY',
  /** America/Guatemala */
  AmericaGuatemala = 'AMERICA_GUATEMALA',
  /** America/Juneau */
  AmericaJuneau = 'AMERICA_JUNEAU',
  /** America/La Paz */
  AmericaLaPaz = 'AMERICA_LA_PAZ',
  /** America/Los Angeles */
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  /** America/Manaus */
  AmericaManaus = 'AMERICA_MANAUS',
  /** America/Mexico City */
  AmericaMexicoCity = 'AMERICA_MEXICO_CITY',
  /** America/Montevideo */
  AmericaMontevideo = 'AMERICA_MONTEVIDEO',
  /** America/New York */
  AmericaNewYork = 'AMERICA_NEW_YORK',
  /** America/Phoenix */
  AmericaPhoenix = 'AMERICA_PHOENIX',
  /** America/Santiago */
  AmericaSantiago = 'AMERICA_SANTIAGO',
  /** America/Sao_Paulo */
  AmericaSaoPaulo = 'AMERICA_SAO_PAULO',
  /** America/St Johns */
  AmericaStJohns = 'AMERICA_ST_JOHNS',
  /** America/Tijuana */
  AmericaTijuana = 'AMERICA_TIJUANA',
  /** Asia/Almaty */
  AsiaAlmaty = 'ASIA_ALMATY',
  /** Asia/Amman */
  AsiaAmman = 'ASIA_AMMAN',
  /** Asia/Baghdad */
  AsiaBaghdad = 'ASIA_BAGHDAD',
  /** Asia/Baku */
  AsiaBaku = 'ASIA_BAKU',
  /** Asia/Bangkok */
  AsiaBangkok = 'ASIA_BANGKOK',
  /** Asia/Beirut */
  AsiaBeirut = 'ASIA_BEIRUT',
  /** Asia/Calcutta */
  AsiaCalcutta = 'ASIA_CALCUTTA',
  /** Asia/Colombo */
  AsiaColombo = 'ASIA_COLOMBO',
  /** Asia/Dhaka */
  AsiaDhaka = 'ASIA_DHAKA',
  /** Asia/Hong Kong */
  AsiaHongKong = 'ASIA_HONG_KONG',
  /** Asia/Jerusalem */
  AsiaJerusalem = 'ASIA_JERUSALEM',
  /** Asia/Kabul */
  AsiaKabul = 'ASIA_KABUL',
  /** Asia/Karachi */
  AsiaKarachi = 'ASIA_KARACHI',
  /** Asia/Katmandu */
  AsiaKatmandu = 'ASIA_KATMANDU',
  /** Asia/Krasnoyarsk */
  AsiaKrasnoyarsk = 'ASIA_KRASNOYARSK',
  /** Asia/Kuala Lumpur */
  AsiaKualaLumpur = 'ASIA_KUALA_LUMPUR',
  /** Asia/Kuwait */
  AsiaKuwait = 'ASIA_KUWAIT',
  /** Asia/Magadan */
  AsiaMagadan = 'ASIA_MAGADAN',
  /** Asia/Muscat */
  AsiaMuscat = 'ASIA_MUSCAT',
  /** Asia/Rangoon */
  AsiaRangoon = 'ASIA_RANGOON',
  /** Asia/Seoul */
  AsiaSeoul = 'ASIA_SEOUL',
  /** Asia/Shanghai */
  AsiaShanghai = 'ASIA_SHANGHAI',
  /** Asia/Taipei */
  AsiaTaipei = 'ASIA_TAIPEI',
  /** Asia/Tbilisi */
  AsiaTbilisi = 'ASIA_TBILISI',
  /** Asia/Tehran */
  AsiaTehran = 'ASIA_TEHRAN',
  /** Asia/Tokyo */
  AsiaTokyo = 'ASIA_TOKYO',
  /** Asia/Ulaanbaatar */
  AsiaUlaanbaatar = 'ASIA_ULAANBAATAR',
  /** Asia/Vladivostok */
  AsiaVladivostok = 'ASIA_VLADIVOSTOK',
  /** Asia/Yakutsk */
  AsiaYakutsk = 'ASIA_YAKUTSK',
  /** Asia/Yekaterinburg */
  AsiaYekaterinburg = 'ASIA_YEKATERINBURG',
  /** Asia/Yerevan */
  AsiaYerevan = 'ASIA_YEREVAN',
  /** Atlantic/Azores */
  AtlanticAzores = 'ATLANTIC_AZORES',
  /** Atlantic/Cape Verde */
  AtlanticCapeVerde = 'ATLANTIC_CAPE_VERDE',
  /** Atlantic/South Georgia */
  AtlanticSouthGeorgia = 'ATLANTIC_SOUTH_GEORGIA',
  /** Australia/Adelaide */
  AustraliaAdelaide = 'AUSTRALIA_ADELAIDE',
  /** Australia/Brisbane */
  AustraliaBrisbane = 'AUSTRALIA_BRISBANE',
  /** Australia/Darwin */
  AustraliaDarwin = 'AUSTRALIA_DARWIN',
  /** Australia/Hobart */
  AustraliaHobart = 'AUSTRALIA_HOBART',
  /** Australia/Perth */
  AustraliaPerth = 'AUSTRALIA_PERTH',
  /** Australia/Sydney */
  AustraliaSydney = 'AUSTRALIA_SYDNEY',
  /** Canada/Saskatchewan */
  CanadaSaskatchewan = 'CANADA_SASKATCHEWAN',
  /** Europe/Amsterdam */
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  /** Europe/Budapest */
  EuropeBudapest = 'EUROPE_BUDAPEST',
  /** Europe/Helsinki */
  EuropeHelsinki = 'EUROPE_HELSINKI',
  /** Europe/Istanbul */
  EuropeIstanbul = 'EUROPE_ISTANBUL',
  /** Europe/London */
  EuropeLondon = 'EUROPE_LONDON',
  /** Europe/Madrid */
  EuropeMadrid = 'EUROPE_MADRID',
  /** Europe/Minsk */
  EuropeMinsk = 'EUROPE_MINSK',
  /** Europe/Moscow */
  EuropeMoscow = 'EUROPE_MOSCOW',
  /** Europe/Sarajevo */
  EuropeSarajevo = 'EUROPE_SARAJEVO',
  /** Pacific/Auckland */
  PacificAuckland = 'PACIFIC_AUCKLAND',
  /** Pacific/Fiji */
  PacificFiji = 'PACIFIC_FIJI',
  /** Pacific/Guam */
  PacificGuam = 'PACIFIC_GUAM',
  /** Pacific/Honolulu */
  PacificHonolulu = 'PACIFIC_HONOLULU',
  /** Pacific/Kiritimati */
  PacificKiritimati = 'PACIFIC_KIRITIMATI',
  /** Pacific/Midway */
  PacificMidway = 'PACIFIC_MIDWAY',
  /** Pacific/Tongatapu */
  PacificTongatapu = 'PACIFIC_TONGATAPU',
  /** UTC */
  Utc = 'UTC',
}

export type AppNotificationType = {
  __typename?: 'AppNotificationType';
  bodyText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  portalUrl?: Maybe<Scalars['String']['output']>;
  status: AppAppNotificationStatusChoices;
  titleText: Scalars['String']['output'];
};

export type AppNotificationTypeConnection = {
  __typename?: 'AppNotificationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppNotificationTypeEdge>>;
  hasUnreadNotifications?: Maybe<Scalars['Boolean']['output']>;
  newNotificationCount?: Maybe<Scalars['Int']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppNotificationType` and its cursor. */
export type AppNotificationTypeEdge = {
  __typename?: 'AppNotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AppNotificationType>;
};

/** An enumeration. */
export enum AppOrderAddressAddressTypeChoices {
  /** Facility */
  Facility = 'FACILITY',
  /** Other */
  Other = 'OTHER',
  /** Patient */
  Patient = 'PATIENT',
}

/** An enumeration. */
export enum AppOrderAssignmentErpStatusChoices {
  /** Failure */
  Failure = 'FAILURE',
  /** Not Sent */
  NotSent = 'NOT_SENT',
  /** Sent */
  Sent = 'SENT',
}

/** An enumeration. */
export enum AppOrderAssignmentStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Delayed */
  Delayed = 'DELAYED',
  /** New */
  New = 'NEW',
  /** Partially Completed */
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  /** Pending */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Shipped */
  Shipped = 'SHIPPED',
  /** Timed Out */
  TimedOut = 'TIMED_OUT',
}

/** An enumeration. */
export enum AppOrderFulfilledByChoices {
  /** Internal */
  Internal = 'INTERNAL',
  /** In store retail */
  InStoreRetail = 'IN_STORE_RETAIL',
  /** Manually */
  Manually = 'MANUALLY',
  /** Network */
  Network = 'NETWORK',
  /** Supply closet */
  SupplyCloset = 'SUPPLY_CLOSET',
}

/** An enumeration. */
export enum AppOrderIntakeTypeChoices {
  /** HCPCS Based Order */
  HcpcsBased = 'HCPCS_BASED',
  /** Product Catalog Order */
  ProductCatalog = 'PRODUCT_CATALOG',
}

/** An enumeration. */
export enum AppOrderNikoStatusChoices {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Hold */
  Hold = 'HOLD',
  /** In progress */
  InProgress = 'IN_PROGRESS',
  /** New */
  New = 'NEW',
}

/** An enumeration. */
export enum AppOrderPreferredSupplierSourceChoices {
  /** Admin */
  Admin = 'ADMIN',
  /** Care Coordination */
  CareCoordination = 'CARE_COORDINATION',
  /** Gateway */
  Gateway = 'GATEWAY',
  /** Patient */
  Patient = 'PATIENT',
  /** Provider */
  Provider = 'PROVIDER',
  /** Resupply */
  Resupply = 'RESUPPLY',
  /** System */
  RoutingSystem = 'ROUTING_SYSTEM',
}

/** An enumeration. */
export enum AppOrderReferralChannelChoices {
  /** Care Coordination Portal */
  CareCoordinationPortal = 'CARE_COORDINATION_PORTAL',
  /** EHR System */
  EhrSystem = 'EHR_SYSTEM',
  /** Email */
  Email = 'EMAIL',
  /** Fax */
  Fax = 'FAX',
  /** Niko */
  Niko = 'NIKO',
  /** Phone */
  Phone = 'PHONE',
  /** RCom */
  Rcom = 'RCOM',
  /** RP Online */
  RpOnline = 'RP_ONLINE',
  /** RP Online - Anonymous */
  RpOnlineAnonymous = 'RP_ONLINE_ANONYMOUS',
}

/** An enumeration. */
export enum AppOrderReferralSourceChoices {
  /** Care Coordination */
  CareCoordination = 'CARE_COORDINATION',
  /** Patient */
  Patient = 'PATIENT',
  /** Provider */
  Provider = 'PROVIDER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** An enumeration. */
export enum AppOrderRequirementStakeholderTypeChoices {
  /** Care Coordination */
  CareCoordination = 'CARE_COORDINATION',
  /** Other */
  Other = 'OTHER',
  /** Patient */
  Patient = 'PATIENT',
  /** Referring Provider */
  ReferringProvider = 'REFERRING_PROVIDER',
  /** Supplier */
  Supplier = 'SUPPLIER',
}

/** An enumeration. */
export enum AppOrderRequirementStatusChoices {
  /** Blocked */
  Blocked = 'BLOCKED',
  /** Complete */
  Complete = 'COMPLETE',
  /** Open */
  Open = 'OPEN',
}

/** An enumeration. */
export enum AppOrderSeedPrimaryExtractorChoices {
  /** Darby */
  Darby = 'DARBY',
  /** Inhouse Openai */
  InhouseOpenai = 'INHOUSE_OPENAI',
  /** Synthpop */
  Synthpop = 'SYNTHPOP',
  /** Tennr */
  Tennr = 'TENNR',
}

/** An enumeration. */
export enum AppOrderSeedStatusChoices {
  /** Created */
  Created = 'CREATED',
  /** Duplicate Fax */
  DuplicateFax = 'DUPLICATE_FAX',
  /** Insufficient Info */
  InsufficientInfo = 'INSUFFICIENT_INFO',
  /** Multiple Orders */
  MultipleOrders = 'MULTIPLE_ORDERS',
  /** Order Created */
  OrderCreated = 'ORDER_CREATED',
  /** Order Updated */
  OrderUpdated = 'ORDER_UPDATED',
  /** Other */
  Other = 'OTHER',
  /** Post Service */
  PostService = 'POST_SERVICE',
  /** Reviewed */
  Reviewed = 'REVIEWED',
}

/** An enumeration. */
export enum AppOrderServiceTypeChoices {
  /** Discharge */
  Discharge = 'DISCHARGE',
  /** Standard */
  Standard = 'STANDARD',
  /** Urgent Outpatient */
  UrgentOutpatient = 'URGENT_OUTPATIENT',
}

/** An enumeration. */
export enum AppOrderSignerSigningMethodChoices {
  /** CPA */
  Cpa = 'CPA',
  /** Email */
  Email = 'EMAIL',
  /** Fax */
  Fax = 'FAX',
  /** SMS */
  Sms = 'SMS',
  /** Transcribed from EHR */
  TranscribedFromEhr = 'TRANSCRIBED_FROM_EHR',
  /** Upload Attachment */
  UploadAttachment = 'UPLOAD_ATTACHMENT',
}

/** An enumeration. */
export enum AppOrderSignerSigningRoleChoices {
  /** Prescriber */
  Prescriber = 'PRESCRIBER',
  /** Reviewer */
  Reviewer = 'REVIEWER',
  /** User */
  User = 'USER',
}

/** An enumeration. */
export enum AppOrderSourceChoices {
  /** Admin */
  Admin = 'ADMIN',
  /** App */
  App = 'APP',
  /** Docusign */
  Docusign = 'DOCUSIGN',
  /** Niko */
  Niko = 'NIKO',
}

/** An enumeration. */
export enum AppOrderStatusHistoryAddByTypeChoices {
  /** Admin */
  Admin = 'ADMIN',
  /** Box system */
  BoxSystem = 'BOX_SYSTEM',
  /** Celery Task */
  CeleryTask = 'CELERY_TASK',
  /** DME portal */
  DmePortal = 'DME_PORTAL',
  /** Docusign System */
  DocusignSystem = 'DOCUSIGN_SYSTEM',
  /** Intake system */
  IntakeSystem = 'INTAKE_SYSTEM',
  /** Niko system */
  NikoSystem = 'NIKO_SYSTEM',
  /** OMS system */
  OmsSystem = 'OMS_SYSTEM',
  /** Routing system */
  RoutingSystem = 'ROUTING_SYSTEM',
  /** SFTP upload */
  Sftp = 'SFTP',
  /** Supplier ERP API */
  SupplierErpApi = 'SUPPLIER_ERP_API',
}

/** An enumeration. */
export enum AppOrderStatusHistoryStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Appointment required */
  AppointmentRequired = 'APPOINTMENT_REQUIRED',
  /** Awaiting appointment */
  AwaitingAppointment = 'AWAITING_APPOINTMENT',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Delayed assignment */
  DelayedAssignment = 'DELAYED_ASSIGNMENT',
  /** Delivery Completed */
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  /** Docusign Cancelled */
  DocusignCancelled = 'DOCUSIGN_CANCELLED',
  /** Docusign Completed */
  DocusignCompleted = 'DOCUSIGN_COMPLETED',
  /** Docusign Created */
  DocusignCreated = 'DOCUSIGN_CREATED',
  /** Docusign Failed */
  DocusignFailed = 'DOCUSIGN_FAILED',
  /** Fax received */
  FaxReceived = 'FAX_RECEIVED',
  /** In Inbox */
  Inbox = 'INBOX',
  /** Initiated */
  Initiated = 'INITIATED',
  /** Manual review */
  ManualReview = 'MANUAL_REVIEW',
  /** Niko - Completed */
  NikoCompleted = 'NIKO_COMPLETED',
  /** Niko - Created */
  NikoCreated = 'NIKO_CREATED',
  /** Niko - Hold */
  NikoHold = 'NIKO_HOLD',
  /** Niko - In progress */
  NikoInProgress = 'NIKO_IN_PROGRESS',
  /** Partially Delivered */
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  /** Phone call received */
  PhoneCallReceived = 'PHONE_CALL_RECEIVED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requires documentation */
  RequiresDocumentation = 'REQUIRES_DOCUMENTATION',
  /** Requires review */
  RequiresReview = 'REQUIRES_REVIEW',
  /** Review complete */
  ReviewComplete = 'REVIEW_COMPLETE',
  /** Routed internal */
  RoutedInternal = 'ROUTED_INTERNAL',
  /** Routed network */
  RoutedNetwork = 'ROUTED_NETWORK',
  /** Routing ready */
  RoutingReady = 'ROUTING_READY',
  /** Shipped */
  Shipped = 'SHIPPED',
  /** Signature completed */
  SignatureCompleted = 'SIGNATURE_COMPLETED',
  /** Signature pending */
  SignaturePending = 'SIGNATURE_PENDING',
  /** Submitted */
  Submitted = 'SUBMITTED',
}

/** An enumeration. */
export enum AppOrderStatusReasonForStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Appointment required */
  AppointmentRequired = 'APPOINTMENT_REQUIRED',
  /** Awaiting appointment */
  AwaitingAppointment = 'AWAITING_APPOINTMENT',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Delayed assignment */
  DelayedAssignment = 'DELAYED_ASSIGNMENT',
  /** Delivery Completed */
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  /** Docusign Cancelled */
  DocusignCancelled = 'DOCUSIGN_CANCELLED',
  /** Docusign Completed */
  DocusignCompleted = 'DOCUSIGN_COMPLETED',
  /** Docusign Created */
  DocusignCreated = 'DOCUSIGN_CREATED',
  /** Docusign Failed */
  DocusignFailed = 'DOCUSIGN_FAILED',
  /** Fax received */
  FaxReceived = 'FAX_RECEIVED',
  /** In Inbox */
  Inbox = 'INBOX',
  /** Initiated */
  Initiated = 'INITIATED',
  /** Manual review */
  ManualReview = 'MANUAL_REVIEW',
  /** Niko - Completed */
  NikoCompleted = 'NIKO_COMPLETED',
  /** Niko - Created */
  NikoCreated = 'NIKO_CREATED',
  /** Niko - Hold */
  NikoHold = 'NIKO_HOLD',
  /** Niko - In progress */
  NikoInProgress = 'NIKO_IN_PROGRESS',
  /** Partially Delivered */
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  /** Phone call received */
  PhoneCallReceived = 'PHONE_CALL_RECEIVED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requires documentation */
  RequiresDocumentation = 'REQUIRES_DOCUMENTATION',
  /** Requires review */
  RequiresReview = 'REQUIRES_REVIEW',
  /** Review complete */
  ReviewComplete = 'REVIEW_COMPLETE',
  /** Routed internal */
  RoutedInternal = 'ROUTED_INTERNAL',
  /** Routed network */
  RoutedNetwork = 'ROUTED_NETWORK',
  /** Routing ready */
  RoutingReady = 'ROUTING_READY',
  /** Shipped */
  Shipped = 'SHIPPED',
  /** Signature completed */
  SignatureCompleted = 'SIGNATURE_COMPLETED',
  /** Signature pending */
  SignaturePending = 'SIGNATURE_PENDING',
  /** Submitted */
  Submitted = 'SUBMITTED',
}

/** An enumeration. */
export enum AppOrderSupplierSuggestionReasonChoices {
  /** Patient is already using a supplier for this product */
  AlreadyEstablished = 'ALREADY_ESTABLISHED',
  /** Patient uses a supplier for a different product */
  DifferentProduct = 'DIFFERENT_PRODUCT',
  /** Other */
  Other = 'OTHER',
  /** Patient requested a supplier for another reason */
  PatientRequestOther = 'PATIENT_REQUEST_OTHER',
  /** Payor requires routing to supplier */
  PayorRequirement = 'PAYOR_REQUIREMENT',
  /** Provider requests a supplier for brand preference */
  ProviderRequestBrand = 'PROVIDER_REQUEST_BRAND',
  /** Provider requests a supplier for another reason */
  ProviderRequestOther = 'PROVIDER_REQUEST_OTHER',
}

/** An enumeration. */
export enum AppOrderSupplierSuggestionSourceChoices {
  /** Admin */
  Admin = 'ADMIN',
  /** Care Coordination */
  CareCoordination = 'CARE_COORDINATION',
  /** Gateway */
  Gateway = 'GATEWAY',
  /** Patient */
  Patient = 'PATIENT',
  /** Provider */
  Provider = 'PROVIDER',
  /** Resupply */
  Resupply = 'RESUPPLY',
  /** System */
  RoutingSystem = 'ROUTING_SYSTEM',
}

/** An enumeration. */
export enum AppOrgUserRoleChoices {
  /** Org Admin */
  OrgAdmin = 'ORG_ADMIN',
  /** Org Staff */
  OrgStaff = 'ORG_STAFF',
}

/** An enumeration. */
export enum AppPatientInsurancePriorityChoices {
  /** Primary */
  Primary = 'PRIMARY',
  /** Secondary */
  Secondary = 'SECONDARY',
  /** Tertiary */
  Tertiary = 'TERTIARY',
  /** Unassigned */
  Unassigned = 'UNASSIGNED',
}

/** An enumeration. */
export enum AppPatientInsuranceStatusChoices {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
  /** Unverified */
  Unverified = 'UNVERIFIED',
}

/** An enumeration. */
export enum AppPaymentMethodChoices {
  /** ACH Transfer */
  Ach = 'ACH',
  /** Credit/Debit Card */
  Card = 'CARD',
  /** Cash */
  Cash = 'CASH',
  /** Check */
  Check = 'CHECK',
  /** Other */
  Other = 'OTHER',
}

/** An enumeration. */
export enum AppPaymentProcessorChoices {
  /** Allegiance */
  Allegiance = 'ALLEGIANCE',
  /** Stripe */
  Stripe = 'STRIPE',
}

/** An enumeration. */
export enum AppPaymentStatusChoices {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Failed */
  Failed = 'FAILED',
  /** New */
  New = 'NEW',
  /** Pending */
  Pending = 'PENDING',
  /** Succeeded */
  Succeeded = 'SUCCEEDED',
}

/** An enumeration. */
export enum AppPriorAuthRequestRequestTypeChoices {
  /** Initial Request */
  InitialRequest = 'INITIAL_REQUEST',
  /** Resubmission */
  Resubmission = 'RESUBMISSION',
}

/** An enumeration. */
export enum AppPriorAuthResponseLineItemDecisionChoices {
  /** Approved */
  Approved = 'APPROVED',
  /** Approved With Modification */
  ApprovedWithModification = 'APPROVED_WITH_MODIFICATION',
  /** Denied */
  Denied = 'DENIED',
  /** More Info Needed */
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
}

/** An enumeration. */
export enum AppPriorAuthorizationLineItemOutcomeChoices {
  /** Approved */
  Approved = 'APPROVED',
  /** Denied */
  Denied = 'DENIED',
}

/** An enumeration. */
export enum AppProductValidationRuleResultErrorCategoryChoices {
  /** Exceeded quantity */
  ExceededQuantity = 'EXCEEDED_QUANTITY',
  /** Out of normal coverage bounds */
  OutOfNormalCoverageBounds = 'OUT_OF_NORMAL_COVERAGE_BOUNDS',
  /** Unmet product criteria */
  UnmetProductCriteria = 'UNMET_PRODUCT_CRITERIA',
}

/** An enumeration. */
export enum AppProductValidationRuleResultResultChoices {
  /** Invalid */
  Invalid = 'INVALID',
  /** N/A */
  NotApplicable = 'NOT_APPLICABLE',
  /** Valid */
  Valid = 'VALID',
  /** Warned */
  Warned = 'WARNED',
}

/** An enumeration. */
export enum AppShipmentStatusChoices {
  /** Delivered */
  Delivered = 'DELIVERED',
  /** In transit */
  InTransit = 'IN_TRANSIT',
  /** Pre transit */
  PreTransit = 'PRE_TRANSIT',
  /** See Note */
  SeeNote = 'SEE_NOTE',
}

/** An enumeration. */
export enum AppSmsMessageDirectionChoices {
  /** Inbound */
  Inbound = 'INBOUND',
  /** Outbound */
  Outbound = 'OUTBOUND',
}

/** An enumeration. */
export enum AppSmsMessageStatusChoices {
  /** Delivered */
  Delivered = 'DELIVERED',
  /** Failed */
  Failed = 'FAILED',
}

/** An enumeration. */
export enum AppStakeholderEngagementEngagementStateChoices {
  /** Disengaged */
  Disengaged = 'DISENGAGED',
  /** Engaged */
  Engaged = 'ENGAGED',
  /** Supplemental Outreach */
  SupplementalOutreach = 'SUPPLEMENTAL_OUTREACH',
}

/** An enumeration. */
export enum AppStakeholderEngagementStakeholderTypeChoices {
  /** Care Coordination */
  CareCoordination = 'CARE_COORDINATION',
  /** Other */
  Other = 'OTHER',
  /** Patient */
  Patient = 'PATIENT',
  /** Referring Provider */
  ReferringProvider = 'REFERRING_PROVIDER',
  /** Supplier */
  Supplier = 'SUPPLIER',
}

/** An enumeration. */
export enum AppTicketEventTypeChoices {
  /** Complex Order */
  ComplexOrder = 'COMPLEX_ORDER',
  /** Insufficient Order Seed Info */
  InsufficientOrderSeedInfo = 'INSUFFICIENT_ORDER_SEED_INFO',
  /** Manual Create */
  ManualCreate = 'MANUAL_CREATE',
  /** New Comment */
  NewComment = 'NEW_COMMENT',
  /** New Fax */
  NewFax = 'NEW_FAX',
  /** New Order Seed */
  NewOrderSeed = 'NEW_ORDER_SEED',
  /** Nudge */
  Nudge = 'NUDGE',
  /** Sms Event */
  SmsEvent = 'SMS_EVENT',
  /** Status Change */
  StatusChange = 'STATUS_CHANGE',
  /** Supplier Confirmation */
  SupplierConfirmation = 'SUPPLIER_CONFIRMATION',
}

/** An enumeration. */
export enum AppTicketKustomerTicketStatusChoices {
  /** Alerted */
  Alerted = 'ALERTED',
  /** Done */
  Done = 'DONE',
  /** Missing */
  Missing = 'MISSING',
  /** Open */
  Open = 'OPEN',
  /** Snoozed */
  Snoozed = 'SNOOZED',
  /** Suppressed */
  Suppressed = 'SUPPRESSED',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** An enumeration. */
export enum AppTicketRequiredActionChoices {
  /** Comments */
  Comments = 'COMMENTS',
  /** Customer Support Outreach */
  CustomerSupportOutreach = 'CUSTOMER_SUPPORT_OUTREACH',
  /** Documentation Retrieval Outreach */
  DocumentationRetrievalOutreach = 'DOCUMENTATION_RETRIEVAL_OUTREACH',
  /** Manual Ticket */
  ManualTicket = 'MANUAL_TICKET',
  /** Member Outreach */
  MemberOutreach = 'MEMBER_OUTREACH',
  /** Order Requirements Outreach */
  OrderRequirementsOutreach = 'ORDER_REQUIREMENTS_OUTREACH',
  /** Outreach */
  Outreach = 'OUTREACH',
  /** Review */
  Review = 'REVIEW',
  /** Routing */
  Routing = 'ROUTING',
  /** Triage */
  Triage = 'TRIAGE',
}

/** An enumeration. */
export enum AppTrackingNumberStatusStatusChoices {
  /** Canceled */
  Canceled = 'CANCELED',
  /** Delivered */
  Delivered = 'DELIVERED',
  /** Failed delivery */
  FailedDelivery = 'FAILED_DELIVERY',
  /** In transit */
  InTransit = 'IN_TRANSIT',
  /** Pre-transit */
  PreTransit = 'PRE_TRANSIT',
}

export type AppointmentType = {
  __typename?: 'AppointmentType';
  appointmentDate: Scalars['Date']['output'];
  id: Scalars['UUID']['output'];
  patient: PatientType;
  provider: ProviderType;
};

export type AssignOrgDocumentExtractionJobInput = {
  documentExtractionJobId: Scalars['ID']['input'];
  hcpcsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

/** Mutation to assign a DocumentExtractionJob to a supplier for exception handling */
export type AssignOrgDocumentExtractionJobMutation = {
  __typename?: 'AssignOrgDocumentExtractionJobMutation';
  documentExtractionJob?: Maybe<DocumentExtractionJobType>;
  error?: Maybe<ErrorType>;
};

export type AssignOrgUserDocumentExtractionJobInput = {
  documentExtractionJobId: Scalars['ID']['input'];
  orgUserId?: InputMaybe<Scalars['ID']['input']>;
};

/** Mutation to assign a DocumentExtractionJob to a specific org user for exception handling */
export type AssignOrgUserDocumentExtractionJobMutation = {
  __typename?: 'AssignOrgUserDocumentExtractionJobMutation';
  documentExtractionJob?: Maybe<DocumentExtractionJobType>;
  error?: Maybe<ErrorType>;
};

export type AssignOwnerInput = {
  orderId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum AudienceEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

/**
 * We don't want to auto-generate this enum using graphene style because the
 * generated enums are different from these choices, probably because all the values
 * are starting with numbers
 */
export enum AuthorityEnum {
  Authority_1115 = 'AUTHORITY_1115',
  Authority_1915B = 'AUTHORITY_1915B',
  Authority_1915C = 'AUTHORITY_1915C',
}

/**
 * Defines ordering when sorting orders by a specific field
 *
 * Provides ascending and descending ordering for sorting orders
 */
export enum BaseCustomOrdering {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type BoundingBoxType = {
  __typename?: 'BoundingBoxType';
  height?: Maybe<Scalars['Float']['output']>;
  left?: Maybe<Scalars['Float']['output']>;
  top?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/**
 * Class representing boxsdk.object.collaboration.Collaboration objects
 *
 * Pending collabs look like:
 * 'type': 'collaboration',
 * 'id': '12345678900',
 * 'created_by': <Box User - 11111111111 (Tomorrow Health)>,
 * 'created_at': '2020-12-09T07:36:05-08:00',
 * 'modified_at': '2020-12-09T07:36:05-08:00',
 * 'expires_at': None,
 * 'status': 'pending',
 * 'accessible_by': None,
 * 'invite_email': 'name@example.com',
 * 'role': 'editor',
 * 'acknowledged_at': None,
 * 'item': <Box Folder - 123456789012 (Folder Name)>,
 *
 * Accepted collabs look like:
 * 'type': 'collaboration',
 * 'id': '12345678901',
 * 'created_by': <Box User - 11111111111 (Tomorrow Health)>,
 * 'created_at': '2020-12-03T05:34:07-08:00',
 * 'modified_at': '2020-12-03T06:38:38-08:00',
 * 'expires_at': None,
 * 'status': 'accepted',
 * 'accessible_by': <Box User - 12345678901 (First Last)>,
 * 'invite_email': None,
 * 'role': 'editor',
 * 'acknowledged_at': '2020-12-03T06:38:38-08:00',
 * 'item': <Box Folder - 123456789012 (Folder Name)>,
 */
export type BoxCollaborationType = {
  __typename?: 'BoxCollaborationType';
  accessibleBy?: Maybe<BoxUserType>;
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<BoxUserType>;
  /** Alias for `.inviteEmail || .accessibleBy.login` */
  emailAddress?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inviteEmail?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type BoxCommentType = {
  __typename?: 'BoxCommentType';
  boxId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdByEmailAddress?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdByName?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extBoxFileId?: Maybe<Scalars['String']['output']>;
  extBoxFileName?: Maybe<Scalars['String']['output']>;
  extBoxFolderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isReplyComment?: Maybe<Scalars['Boolean']['output']>;
  message: Scalars['String']['output'];
  networkSupplier?: Maybe<OptimizedNetworkSupplierType>;
  order?: Maybe<OrderType>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Class representing boxsdk.object.user.User objects */
export type BoxUserType = {
  __typename?: 'BoxUserType';
  id: Scalars['String']['output'];
  login?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Allows a logged in user to bulk upload claim approvals for an insurer */
export type BulkClaimApprovalUpload = {
  __typename?: 'BulkClaimApprovalUpload';
  approvalCreationCount?: Maybe<Scalars['Int']['output']>;
  duplicateApprovalCount?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type BulkClaimApprovalUploadInput = {
  claims: Array<InputMaybe<ClaimApprovalInput>>;
  insurerId: Scalars['ID']['input'];
};

/**
 * This input type takes in a list of ProductCatalogQuestionValidationRuleInsurer
 * IDs and a list of IntakeValidationRule history version IDs in order to associate/
 * disassociate them
 */
export type BulkUpdateProductValidationRuleMappingsInput = {
  /** Whether this operation should associate the rules to the question mappings or disassociate them. Default is to associate. */
  associate?: InputMaybe<Scalars['Boolean']['input']>;
  /** IDs of product question mappings. */
  productQuestionMappingIds: Array<Scalars['UUID']['input']>;
  /** History IDs of product validation rules to associate with product question mappings. Performs a full replace. */
  validationRuleHistoryIds: Array<Scalars['ID']['input']>;
};

/**
 * This is a one off mutation that allows TH staff to submit updated
 * rankings for supplier categories. This infers what to create or delete by
 * doing a diff between the supplier categories provided and what exists.
 */
export type BulkUpdateSupplierCategoryRankings = {
  __typename?: 'BulkUpdateSupplierCategoryRankings';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  supplierCategories?: Maybe<Array<Maybe<NetworkSupplierCategoryType>>>;
};

/**
 * Mutation to allow TH staff to update quality metrics for a given supplier and
 * category.
 */
export type BulkUpdateSupplierQualityMetrics = {
  __typename?: 'BulkUpdateSupplierQualityMetrics';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  supplierCategories?: Maybe<Array<Maybe<NetworkSupplierCategoryType>>>;
};

/**
 * This input type takes in a list of IntakeQuestionHcpcs IDs and a list of
 * IntakeValidationRule history version IDs in order to associate/disassociate them
 */
export type BulkUpdateValidationRuleMappingsInput = {
  /** Whether this operation should associate the rules to the question mappings or disassociate them. Default is to associate. */
  associate?: InputMaybe<Scalars['Boolean']['input']>;
  /** IDs of clinical question mappings. */
  intakeQuestionHcpcsIds: Array<Scalars['ID']['input']>;
  /** History IDs of clinical validation rules to associate with clinical question mappings. Performs a full replace. */
  validationRuleHistoryIds: Array<Scalars['ID']['input']>;
};

export type CancelOrderInput = {
  orderId: Scalars['UUID']['input'];
  reasonCode: Scalars['String']['input'];
  reasonText?: InputMaybe<Scalars['String']['input']>;
};

export type CancelOrderMutation = {
  __typename?: 'CancelOrderMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type CareCoordinationType = {
  __typename?: 'CareCoordinationType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  org?: Maybe<OrgType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChoiceInput = {
  text: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ChoiceType = {
  __typename?: 'ChoiceType';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ClaimApprovalInput = {
  hcpcsCode: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
  supplierTin: Scalars['String']['input'];
  validFrom: Scalars['Date']['input'];
  validTo: Scalars['Date']['input'];
};

export type CommentMentionType = {
  __typename?: 'CommentMentionType';
  id: Scalars['UUID']['output'];
  isTh: Scalars['Boolean']['output'];
  org?: Maybe<OrgSlimType>;
  user?: Maybe<UserSlimType>;
};

export type CommentType = {
  __typename?: 'CommentType';
  audience: AppCommentAudienceChoices;
  belongsToLoggedInUser?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  isStaff: Scalars['Boolean']['output'];
  mentions?: Maybe<Array<Maybe<CommentMentionType>>>;
  message: Scalars['String']['output'];
  org?: Maybe<OrgSlimType>;
  postedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserSlimType>;
};

export type CompleteOrderMutation = {
  __typename?: 'CompleteOrderMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type CompletedLineItemInput = {
  completedAt: Scalars['Date']['input'];
  orderLineItemId: Scalars['UUID']['input'];
};

export type ContactInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactType = {
  __typename?: 'ContactType';
  emailAddress?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  smsNumber?: Maybe<Scalars['String']['output']>;
};

export type ContentTypeType = {
  __typename?: 'ContentTypeType';
  appLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
};

export type CoordinatesAddressResultType = {
  __typename?: 'CoordinatesAddressResultType';
  address1?: Maybe<LocationInfoType>;
  address2?: Maybe<LocationInfoType>;
  city?: Maybe<LocationInfoType>;
  postalCode?: Maybe<LocationInfoType>;
  state?: Maybe<LocationInfoType>;
};

export type CoordinatesDiagnosisType = {
  __typename?: 'CoordinatesDiagnosisType';
  diagnosisCode?: Maybe<LocationInfoType>;
};

export type CoordinatesInsuranceType = {
  __typename?: 'CoordinatesInsuranceType';
  groupNumber?: Maybe<LocationInfoType>;
  insurerName?: Maybe<LocationInfoType>;
  planName?: Maybe<LocationInfoType>;
  policyNumber?: Maybe<LocationInfoType>;
};

export type CoordinatesLineItemType = {
  __typename?: 'CoordinatesLineItemType';
  hcpcsCode?: Maybe<LocationInfoType>;
  quantity?: Maybe<LocationInfoType>;
};

export type CoordinatesSetType = {
  __typename?: 'CoordinatesSetType';
  dxCodes?: Maybe<Array<Maybe<CoordinatesDiagnosisType>>>;
  lineItems?: Maybe<Array<Maybe<CoordinatesLineItemType>>>;
  orderReferralDate?: Maybe<LocationInfoType>;
  orderSendToSupplierName?: Maybe<LocationInfoType>;
  orderSubcategoryId?: Maybe<LocationInfoType>;
  orderSubcategoryName?: Maybe<LocationInfoType>;
  patientCellPhone?: Maybe<LocationInfoType>;
  patientDateOfBirth?: Maybe<LocationInfoType>;
  patientEmailAddress?: Maybe<LocationInfoType>;
  patientFirstName?: Maybe<LocationInfoType>;
  patientHeightInInches?: Maybe<LocationInfoType>;
  patientHomeAddress?: Maybe<CoordinatesAddressResultType>;
  patientHomePhone?: Maybe<LocationInfoType>;
  patientLastName?: Maybe<LocationInfoType>;
  patientMiddleName?: Maybe<LocationInfoType>;
  patientPrimaryInsurance?: Maybe<CoordinatesInsuranceType>;
  patientSecondaryInsurance?: Maybe<CoordinatesInsuranceType>;
  patientSex?: Maybe<LocationInfoType>;
  patientShippingAddress?: Maybe<CoordinatesAddressResultType>;
  patientTertiaryInsurance?: Maybe<CoordinatesInsuranceType>;
  patientWeightInPounds?: Maybe<LocationInfoType>;
  referringProviderFirstName?: Maybe<LocationInfoType>;
  referringProviderLastName?: Maybe<LocationInfoType>;
  referringProviderNpi?: Maybe<LocationInfoType>;
};

export type CreateAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateAgreementInput = {
  agreementType: AgreementTypes;
  agreementUrl: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  validFrom: Scalars['DateTime']['input'];
};

export type CreateAgreementMutation = {
  __typename?: 'CreateAgreementMutation';
  agreement?: Maybe<AgreementType>;
  error?: Maybe<ErrorType>;
};

export type CreateBoxCommentInput = {
  commentText: Scalars['String']['input'];
  mentionsOrderAcceptor?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['ID']['input'];
  originalCommentBoxId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDocumentInput = {
  patientId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateDocumentMutation = {
  __typename?: 'CreateDocumentMutation';
  document?: Maybe<DocumentType>;
};

/**
 * Allows an org user to submit free-text feedback on the quality of our extraction. As of 6/5/2024, this
 * will only be used for synthpop clinical summary feedback.
 */
export type CreateExtractionFeedbackMutation = {
  __typename?: 'CreateExtractionFeedbackMutation';
  error?: Maybe<ErrorType>;
  extractionFeedback?: Maybe<ExtractionFeedbackType>;
};

export type CreateIntakeValidationRuleMutation = {
  __typename?: 'CreateIntakeValidationRuleMutation';
  error?: Maybe<ErrorType>;
  intakeValidationRule?: Maybe<IntakeValidationRuleType>;
};

export type CreateNetworkSupplierLocation = {
  __typename?: 'CreateNetworkSupplierLocation';
  error?: Maybe<ErrorType>;
  supplierLocation?: Maybe<NetworkSupplierLocationType>;
};

export type CreateNetworkSupplierLocationInput = {
  name: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

/**
 * An input type that facilitates address creation and updating depending on
 * whether the address_id is passed in
 */
export type CreateOrGetAddressInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOrderCommentInput = {
  audience: AudienceEnum;
  message: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  /** Optional field to post to specific organizations */
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateOrderCommentMutation = {
  __typename?: 'CreateOrderCommentMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type CreateOrderDocumentsInput = {
  documents: Array<InputMaybe<DocumentInput>>;
  emailNote?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  replaceDocumentId?: InputMaybe<Scalars['ID']['input']>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOrgPatientInput = {
  orgId: Scalars['UUID']['input'];
  patient: OrgPatientInput;
  patientFhirId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrgPatientMutation = {
  __typename?: 'CreateOrgPatientMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type CreateOrgUserDelegationMutation = {
  __typename?: 'CreateOrgUserDelegationMutation';
  delegateUser?: Maybe<UserType>;
  error?: Maybe<ErrorType>;
  errorsList?: Maybe<Array<ErrorListItemType>>;
};

export type CreatePatientInsuranceInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  policyNumber: Scalars['String']['input'];
};

export type CreatePatientInsuranceMutation = {
  __typename?: 'CreatePatientInsuranceMutation';
  error?: Maybe<ErrorType>;
  patientInsurance?: Maybe<PatientInsuranceType>;
};

export type CreatePatientSignatureMutation = {
  __typename?: 'CreatePatientSignatureMutation';
  signature?: Maybe<SignatureType>;
};

export type CreatePracticePatientInput = {
  patient: PracticePatientInput;
  patientFhirId?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['String']['input'];
};

export type CreatePracticePatientMutation = {
  __typename?: 'CreatePracticePatientMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type CreatePreOrderInput = {
  deliveryAddress?: InputMaybe<Scalars['String']['input']>;
  doctorsName?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  hasPrescription?: InputMaybe<Scalars['Boolean']['input']>;
  isResupply?: InputMaybe<Scalars['Boolean']['input']>;
  itemData?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePriorAuthRequestMutation = {
  __typename?: 'CreatePriorAuthRequestMutation';
  error?: Maybe<ErrorType>;
  priorAuthRequest?: Maybe<PriorAuthRequestType>;
};

export type CreatePriorAuthRequestSubmissionMutation = {
  __typename?: 'CreatePriorAuthRequestSubmissionMutation';
  error?: Maybe<ErrorType>;
  priorAuthRequestSubmission?: Maybe<PriorAuthRequestSubmissionType>;
};

export type CreatePriorAuthResponseMutation = {
  __typename?: 'CreatePriorAuthResponseMutation';
  error?: Maybe<ErrorType>;
  priorAuthResponse?: Maybe<PriorAuthResponseType>;
};

/**
 * Create a Stripe SetupIntent object for the Stripe Customer associated with
 * the current user. Requires login.
 *
 * Callers probably will use the clientSecret to pass to Stripe Elements to
 * create a PaymentMethod.
 */
export type CreateStripeSetupIntent = {
  __typename?: 'CreateStripeSetupIntent';
  clientSecret?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  /** Stripe SetupIntent ID */
  id?: Maybe<Scalars['String']['output']>;
  paymentMethodTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreateSupplierPatientMessageInput = {
  orderAssignmentId: Scalars['ID']['input'];
  templateId: Scalars['Int']['input'];
};

export type CreateSupplierPatientMessageMutation = {
  __typename?: 'CreateSupplierPatientMessageMutation';
  error?: Maybe<ErrorType>;
  orderAssignment?: Maybe<OrderAssignmentType>;
};

export type CreateTicketInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  expedited?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  requiredAction?: InputMaybe<AppTicketRequiredActionChoices>;
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  /** The datetime this object was created in stripe. */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** default payment method used for subscriptions and invoices for the customer. */
  defaultPaymentMethod?: Maybe<StripePaymentMethod>;
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** Null here indicates that the livemode status is unknown or was previously unrecorded. Otherwise, this field indicates whether this record comes from Stripe test mode or live mode operation. */
  livemode?: Maybe<Scalars['Boolean']['output']>;
  /** The customer's full name or business name. */
  name: Scalars['String']['output'];
  /** Customer to which this PaymentMethod is saved. This will not be set when the PaymentMethod has not been saved to a Customer. */
  paymentMethods: Array<StripePaymentMethod>;
  /** The customer's phone number. */
  phone: Scalars['String']['output'];
};

/** An enumeration. */
export enum DecisionEnum {
  Approved = 'APPROVED',
  AwaitingResponse = 'AWAITING_RESPONSE',
  Denied = 'DENIED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  PartiallyApproved = 'PARTIALLY_APPROVED',
}

export type DefectDefinitionInput = {
  detail?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DeferredOrderSupplierSuggestionType = {
  __typename?: 'DeferredOrderSupplierSuggestionType';
  faxNumber: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  order: OrderType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  supplierName: Scalars['String']['output'];
};

export type DeferredSupplierSuggestionInput = {
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  supplierName?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCommentInput = {
  commentId: Scalars['ID']['input'];
};

export type DeleteCommentMutation = {
  __typename?: 'DeleteCommentMutation';
  error?: Maybe<ErrorType>;
};

export type DeployIntakeQuestionHcpcsInput = {
  hcpcsCode?: InputMaybe<Scalars['String']['input']>;
  insurerName?: InputMaybe<Scalars['String']['input']>;
  intakeValidationRules?: InputMaybe<Array<DeployIntakeValidationRuleInput>>;
  validFrom: Scalars['DateTime']['input'];
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeployIntakeQuestionInput = {
  /** If a new question version needs to be created, this date is applied to any hcpcs/insurer mapping that exists on previous question version but not on new one */
  defaultValidTo?: InputMaybe<Scalars['DateTime']['input']>;
  historyVersionSyncId: Scalars['ID']['input'];
  intakeQuestionHcpcs?: InputMaybe<Array<DeployIntakeQuestionHcpcsInput>>;
  questionJson: Scalars['JSONString']['input'];
  questionType: QuestionType;
  /** Skip hcpcs mapping and validation rule creation. This should be used if a rule depends on two different questions. In that case, both questions should be deployed with skip_mappings=True in order for the questions to be created then both questions should be re-deployed with skip_mappings=False to create the mappings and validation rules. Only considered during question creation and not question update. */
  skipMappings?: InputMaybe<Scalars['Boolean']['input']>;
  syncId: Scalars['ID']['input'];
};

export type DeployIntakeValidationRuleInput = {
  /** The history version sync ids of questions this rule's assertion depends on */
  assertionDependsOn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The history version sync ids of questions this rule's condition depends on */
  conditionDependsOn?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  errorCategory?: InputMaybe<AppIntakeValidationRuleErrorCategoryChoices>;
  highContextErrorText?: InputMaybe<Scalars['String']['input']>;
  highContextSuccessText?: InputMaybe<Scalars['String']['input']>;
  highContextWarningText?: InputMaybe<Scalars['String']['input']>;
  historyVersion?: InputMaybe<DeployIntakeValidationRuleInput>;
  historyVersionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  lowContextErrorText?: InputMaybe<Scalars['String']['input']>;
  lowContextSuccessText?: InputMaybe<Scalars['String']['input']>;
  lowContextWarningText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ruleAssertion: Scalars['String']['input'];
  ruleCondition?: InputMaybe<Scalars['String']['input']>;
  validFrom: Scalars['DateTime']['input'];
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DiagnosisType = {
  __typename?: 'DiagnosisType';
  billable: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type DiagnosisTypeConnection = {
  __typename?: 'DiagnosisTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DiagnosisTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DiagnosisType` and its cursor. */
export type DiagnosisTypeEdge = {
  __typename?: 'DiagnosisTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DiagnosisType>;
};

/** An enumeration. */
export enum DjstripePaymentMethodTypeChoices {
  /** Acss Dbit */
  AcssDebit = 'ACSS_DEBIT',
  /** Afterpay Clearpay */
  AfterpayClearpay = 'AFTERPAY_CLEARPAY',
  /** Alipay */
  Alipay = 'ALIPAY',
  /** BECS Debit (Australia) */
  AuBecsDebit = 'AU_BECS_DEBIT',
  /** Bacs Direct Debit */
  BacsDebit = 'BACS_DEBIT',
  /** Bancontact */
  Bancontact = 'BANCONTACT',
  /** Boleto */
  Boleto = 'BOLETO',
  /** Card */
  Card = 'CARD',
  /** Card present */
  CardPresent = 'CARD_PRESENT',
  /** EPS */
  Eps = 'EPS',
  /** FPX */
  Fpx = 'FPX',
  /** Giropay */
  Giropay = 'GIROPAY',
  /** Grabpay */
  Grabpay = 'GRABPAY',
  /** iDEAL */
  Ideal = 'IDEAL',
  /** Interac (card present) */
  InteracPresent = 'INTERAC_PRESENT',
  /** Klarna */
  Klarna = 'KLARNA',
  /** OXXO */
  Oxxo = 'OXXO',
  /** Przelewy24 */
  P24 = 'P24',
  /** SEPA Direct Debit */
  SepaDebit = 'SEPA_DEBIT',
  /** SOFORT */
  Sofort = 'SOFORT',
  /** Wechat Pay */
  WechatPay = 'WECHAT_PAY',
}

export type DocumentExtractionJobAssignmentType = {
  __typename?: 'DocumentExtractionJobAssignmentType';
  /** When the org user assignment was made */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedOrgUser?: Maybe<OrgUserType>;
  documentExtractionJob: DocumentExtractionJobType;
  /** When the org assignment is due */
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  org: OrgSlimType;
  /** Prevent reassignment if supplier already rejected this job */
  preventReassignment: Scalars['Boolean']['output'];
  revokeReason?: Maybe<AppDocumentExtractionJobAssignmentRevokeReasonChoices>;
  revokedByOrgUser?: Maybe<OrgUserType>;
  revokedOn?: Maybe<Scalars['DateTime']['output']>;
  role: AppDocumentExtractionJobAssignmentRoleChoices;
};

/**
 * Public portal facing groupings of DocumentExtractionJob statuses.
 *
 * This groups together internal facing statuses into more user-friendly groups
 */
export enum DocumentExtractionJobStatusGroupType {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export type DocumentExtractionJobType = {
  __typename?: 'DocumentExtractionJobType';
  createdAt: Scalars['DateTime']['output'];
  document: DocumentType;
  /** The current exception handler org assignment, which will be used to determine view/edit permissions on the job. A null value means TH is the exception handler */
  exceptionHandler?: Maybe<DocumentExtractionJobAssignmentType>;
  id: Scalars['UUID']['output'];
  /** The initiator job assignment, which will be used to determine view/edit permissions on the job. A null value means TH initiated */
  initiator?: Maybe<DocumentExtractionJobAssignmentType>;
  metadata?: Maybe<Scalars['JSONString']['output']>;
  ocrResults?: Maybe<Scalars['String']['output']>;
  orderSeed?: Maybe<OrderSeedType>;
  /** In the event that an extraction job needs to have its document(s) split, this is the original document pre-split. An example of when this splitting might happen would be when a single pdf contains multiple orders and therefore must be split into separate jobs. */
  parentDocument?: Maybe<DocumentType>;
  /** For the fax source_type, this stores the fax number that the fax was sent to */
  receiverFaxNumber?: Maybe<Scalars['String']['output']>;
  searchableDocument?: Maybe<DocumentType>;
  /** For the fax source_type, this stores the sender fax number that initiatedthe job */
  senderFaxNumber?: Maybe<Scalars['String']['output']>;
  sourceType: AppDocumentExtractionJobSourceTypeChoices;
  status: AppDocumentExtractionJobStatusChoices;
  textractJobId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DocumentExtractionJobTypeConnection = {
  __typename?: 'DocumentExtractionJobTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentExtractionJobTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DocumentExtractionJobType` and its cursor. */
export type DocumentExtractionJobTypeEdge = {
  __typename?: 'DocumentExtractionJobTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DocumentExtractionJobType>;
};

export type DocumentInput = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentType?: InputMaybe<DocumentTypeEnum>;
  originalFileName?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
  s3Url?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentResultsType = {
  __typename?: 'DocumentResultsType';
  documents?: Maybe<DocumentTypeConnection>;
  error?: Maybe<ErrorType>;
};

export type DocumentResultsTypeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentS3UrlResult = {
  __typename?: 'DocumentS3UrlResult';
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentS3UrlType = DocumentS3UrlResult | ErrorType;

export type DocumentType = {
  __typename?: 'DocumentType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSONString']['output']>;
  networkSupplier?: Maybe<OptimizedNetworkSupplierType>;
  nikoId?: Maybe<Scalars['String']['output']>;
  /** Documents for this order */
  orders: OrderTypeConnection;
  originalFileName?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<PatientType>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  s3Bucket?: Maybe<Scalars['String']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserTypeForAdminView>;
};

export type DocumentTypeOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentTypeConnection = {
  __typename?: 'DocumentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `DocumentType` and its cursor. */
export type DocumentTypeEdge = {
  __typename?: 'DocumentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DocumentType>;
};

export enum DocumentTypeEnum {
  BatchOrderStatusDocument = 'BATCH_ORDER_STATUS_DOCUMENT',
  ClaimApprovalDocument = 'CLAIM_APPROVAL_DOCUMENT',
  H2067CollectionDocument = 'H2067_COLLECTION_DOCUMENT',
  H2067Document = 'H2067_DOCUMENT',
  NetworkSupplierDocument = 'NETWORK_SUPPLIER_DOCUMENT',
  OrderDeliveryDocument = 'ORDER_DELIVERY_DOCUMENT',
  PatientDocument = 'PATIENT_DOCUMENT',
  PriorAuthRequestFaxDocument = 'PRIOR_AUTH_REQUEST_FAX_DOCUMENT',
  ProductCatalogDocument = 'PRODUCT_CATALOG_DOCUMENT',
  SkuPricingDocument = 'SKU_PRICING_DOCUMENT',
}

export type DocumentUploadS3UrlType = {
  __typename?: 'DocumentUploadS3UrlType';
  fields?: Maybe<Scalars['JSONString']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DownloadOrderAsPdfMutation = {
  __typename?: 'DownloadOrderAsPdfMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export type DownloadPriorAuthorizationPdfMutation = {
  __typename?: 'DownloadPriorAuthorizationPdfMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export type DownloadTitleXixFormPdfMutation = {
  __typename?: 'DownloadTitleXIXFormPdfMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export type EhrLinkPatientInput = {
  ehrPatientFhirId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
};

export type EhrLinkPatientMutation = {
  __typename?: 'EHRLinkPatientMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type EditCommentInput = {
  commentId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type EditCommentMutation = {
  __typename?: 'EditCommentMutation';
  comment?: Maybe<CommentType>;
  error?: Maybe<ErrorType>;
};

/**
 * Output object representing EHR patient search results.
 *
 * If a previously linked patient exists, we will return that single patient.
 *
 * If no linked patient is found by ID, then we will return a list of potential
 * candidate patient objects, so the frontend can display them to the user to choose
 * which, if any, to link.
 */
export type EhrPatientSearchResult = {
  __typename?: 'EhrPatientSearchResult';
  candidatePatients?: Maybe<Array<PatientSlimType>>;
  ehrPatient?: Maybe<PatientSlimType>;
  linkedPatient?: Maybe<PatientSlimType>;
};

export type EhrSystemObjectType = {
  __typename?: 'EhrSystemObjectType';
  clientId?: Maybe<Scalars['String']['output']>;
  fhirServerUrl?: Maybe<Scalars['String']['output']>;
  fhirVersion: AppEhrSystemFhirVersionChoices;
  id: Scalars['UUID']['output'];
  iss?: Maybe<Scalars['String']['output']>;
  jwksUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Orgs related to this EHR System */
  orgs: Array<OrgSlimType>;
  scopes?: Maybe<Scalars['String']['output']>;
  type: AppEhrSystemTypeChoices;
};

export type EhrSystemSlimType = {
  __typename?: 'EhrSystemSlimType';
  clientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  scopes?: Maybe<Scalars['String']['output']>;
};

export enum EhrVendorEnum {
  Cerner = 'CERNER',
  Epic = 'EPIC',
  Logica = 'LOGICA',
}

export type EligibleSuppliersForRoutingCriteriaType = {
  __typename?: 'EligibleSuppliersForRoutingCriteriaType';
  error?: Maybe<ErrorType>;
  suppliers?: Maybe<Array<Maybe<OptimizedNetworkSupplierType>>>;
};

/** Class representing an error returned in a list of errors */
export type ErrorListItemType = {
  __typename?: 'ErrorListItemType';
  /** Unique identifier for this error */
  id?: Maybe<Scalars['ID']['output']>;
  /** Id that links the error to the source of the error */
  itemId?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  /** User-visible error message */
  message: Scalars['String']['output'];
  /** Class of error that this represents */
  type?: Maybe<Scalars['String']['output']>;
};

/** Class representing user-visible errors */
export type ErrorType = {
  __typename?: 'ErrorType';
  /** Unique identifier for this error */
  id?: Maybe<Scalars['ID']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  /** User-visible error message */
  message: Scalars['String']['output'];
  /** Class of error that this represents */
  type?: Maybe<Scalars['String']['output']>;
};

export type EstimatedDeliveryLineItemInput = {
  estimatedDeliveryAt: Scalars['Date']['input'];
  orderLineItemId: Scalars['UUID']['input'];
};

export type ExternalIntegrationRequestMessageType = {
  __typename?: 'ExternalIntegrationRequestMessageType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  integration?: Maybe<IntegrationType>;
  lastHttpResponseStatusCode?: Maybe<Scalars['Int']['output']>;
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  payload?: Maybe<Scalars['JSONString']['output']>;
  referenceId?: Maybe<Scalars['UUID']['output']>;
  requestData: Scalars['JSONString']['output'];
  requestDataType?: Maybe<Scalars['String']['output']>;
  responseCallbackData?: Maybe<Scalars['JSONString']['output']>;
  status: AppExternalIntegrationRequestMessageStatusChoices;
};

export type ExternalServiceType = {
  __typename?: 'ExternalServiceType';
  id: Scalars['UUID']['output'];
  /** Human-readable identifier */
  name: Scalars['String']['output'];
};

export type ExtractionAddressResultCorrectionInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractionAddressResultType = {
  __typename?: 'ExtractionAddressResultType';
  address1Confidence?: Maybe<AppExtractionAddressResultAddress1ConfidenceChoices>;
  address1Corrected?: Maybe<Scalars['String']['output']>;
  address1Raw?: Maybe<Scalars['String']['output']>;
  address2Confidence?: Maybe<AppExtractionAddressResultAddress2ConfidenceChoices>;
  address2Corrected?: Maybe<Scalars['String']['output']>;
  address2Raw?: Maybe<Scalars['String']['output']>;
  cityConfidence?: Maybe<AppExtractionAddressResultCityConfidenceChoices>;
  cityCorrected?: Maybe<Scalars['String']['output']>;
  cityRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  postalCodeConfidence?: Maybe<AppExtractionAddressResultPostalCodeConfidenceChoices>;
  postalCodeCorrected?: Maybe<Scalars['String']['output']>;
  postalCodeRaw?: Maybe<Scalars['String']['output']>;
  stateConfidence?: Maybe<AppExtractionAddressResultStateConfidenceChoices>;
  stateCorrected?: Maybe<Scalars['String']['output']>;
  stateRaw?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExtractionDiagnosisResultCorrectionInput = {
  diagnosisCode?: InputMaybe<Scalars['String']['input']>;
  diagnosisResultId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ExtractionDiagnosisResultType = {
  __typename?: 'ExtractionDiagnosisResultType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  diagnosisCodeConfidence?: Maybe<AppExtractionDiagnosisResultDiagnosisCodeConfidenceChoices>;
  diagnosisCodeCorrected?: Maybe<Scalars['String']['output']>;
  diagnosisCodeRaw?: Maybe<Scalars['String']['output']>;
  extractionResultsSet: ExtractionResultsSetType;
  id: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ExtractionDocumentResultCorrectionInput = {
  bloodGasStudy?: InputMaybe<Scalars['String']['input']>;
  chartNotes?: InputMaybe<Scalars['String']['input']>;
  coverSheet?: InputMaybe<Scalars['String']['input']>;
  prescription?: InputMaybe<Scalars['String']['input']>;
  sleepStudy?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractionFeedbackInput = {
  extractionResultsSetId: Scalars['ID']['input'];
  feedbackCategory: AppExtractionFeedbackFeedbackCategoryChoices;
  orgUserId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type ExtractionFeedbackType = {
  __typename?: 'ExtractionFeedbackType';
  extractionResultsSet: ExtractionResultsSetType;
  feedbackCategory: AppExtractionFeedbackFeedbackCategoryChoices;
  id: Scalars['UUID']['output'];
  orgUser: OrgUserType;
  text: Scalars['String']['output'];
};

export type ExtractionInsuranceResultCorrectionInput = {
  groupNumber?: InputMaybe<Scalars['String']['input']>;
  insurerName?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  subscriberFirstName?: InputMaybe<Scalars['String']['input']>;
  subscriberLastName?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractionInsuranceResultType = {
  __typename?: 'ExtractionInsuranceResultType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  groupNumberConfidence?: Maybe<AppExtractionInsuranceResultGroupNumberConfidenceChoices>;
  groupNumberCorrected?: Maybe<Scalars['String']['output']>;
  groupNumberRaw?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  insurerNameConfidence?: Maybe<AppExtractionInsuranceResultInsurerNameConfidenceChoices>;
  insurerNameCorrected?: Maybe<Scalars['String']['output']>;
  insurerNameRaw?: Maybe<Scalars['String']['output']>;
  planNameConfidence?: Maybe<AppExtractionInsuranceResultPlanNameConfidenceChoices>;
  planNameCorrected?: Maybe<Scalars['String']['output']>;
  planNameRaw?: Maybe<Scalars['String']['output']>;
  policyNumberConfidence?: Maybe<AppExtractionInsuranceResultPolicyNumberConfidenceChoices>;
  policyNumberCorrected?: Maybe<Scalars['String']['output']>;
  policyNumberRaw?: Maybe<Scalars['String']['output']>;
  subscriberFirstNameConfidence?: Maybe<AppExtractionInsuranceResultSubscriberFirstNameConfidenceChoices>;
  subscriberFirstNameCorrected?: Maybe<Scalars['String']['output']>;
  subscriberFirstNameRaw?: Maybe<Scalars['String']['output']>;
  subscriberLastNameConfidence?: Maybe<AppExtractionInsuranceResultSubscriberLastNameConfidenceChoices>;
  subscriberLastNameCorrected?: Maybe<Scalars['String']['output']>;
  subscriberLastNameRaw?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExtractionLineItemResultCorrectionInput = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  cptCode?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  hcpcsCode?: InputMaybe<Scalars['String']['input']>;
  lengthOfNeed?: InputMaybe<Scalars['String']['input']>;
  lineItemResultId?: InputMaybe<Scalars['UUID']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ExtractionLineItemResultType = {
  __typename?: 'ExtractionLineItemResultType';
  brandNameConfidence?: Maybe<AppExtractionLineItemResultBrandNameConfidenceChoices>;
  brandNameCorrected?: Maybe<Scalars['String']['output']>;
  brandNameRaw?: Maybe<Scalars['String']['output']>;
  cptCodeConfidence?: Maybe<AppExtractionLineItemResultCptCodeConfidenceChoices>;
  cptCodeCorrected?: Maybe<Scalars['String']['output']>;
  cptCodeRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extractionResultsSet: ExtractionResultsSetType;
  frequencyConfidence?: Maybe<AppExtractionLineItemResultFrequencyConfidenceChoices>;
  frequencyCorrected?: Maybe<Scalars['String']['output']>;
  frequencyRaw?: Maybe<Scalars['String']['output']>;
  hcpcsCodeConfidence?: Maybe<AppExtractionLineItemResultHcpcsCodeConfidenceChoices>;
  hcpcsCodeCorrected?: Maybe<Scalars['String']['output']>;
  hcpcsCodeRaw?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lengthOfNeedConfidence?: Maybe<AppExtractionLineItemResultLengthOfNeedConfidenceChoices>;
  lengthOfNeedCorrected?: Maybe<Scalars['String']['output']>;
  lengthOfNeedRaw?: Maybe<Scalars['String']['output']>;
  productNameConfidence?: Maybe<AppExtractionLineItemResultProductNameConfidenceChoices>;
  productNameCorrected?: Maybe<Scalars['String']['output']>;
  productNameRaw?: Maybe<Scalars['String']['output']>;
  quantityConfidence?: Maybe<AppExtractionLineItemResultQuantityConfidenceChoices>;
  quantityCorrected?: Maybe<Scalars['Int']['output']>;
  quantityRaw?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExtractionOrderResultCorrectionInput = {
  discharge?: InputMaybe<Scalars['Boolean']['input']>;
  dxCodes?: InputMaybe<Array<ExtractionDiagnosisResultCorrectionInput>>;
  lineItems?: InputMaybe<Array<ExtractionLineItemResultCorrectionInput>>;
  referralDate?: InputMaybe<Scalars['Date']['input']>;
  subcategoryId?: InputMaybe<Scalars['UUID']['input']>;
  subcategoryName?: InputMaybe<Scalars['String']['input']>;
  /** This is modifiable only by internal TH staff. Inputs will be ignored for non-staff. */
  supplierName?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractionPatientResultCorrectionInput = {
  ahi?: InputMaybe<Scalars['Float']['input']>;
  bmi?: InputMaybe<Scalars['Float']['input']>;
  bp?: InputMaybe<Scalars['String']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  ess?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  heightInInches?: InputMaybe<Scalars['Float']['input']>;
  homeAddress?: InputMaybe<ExtractionAddressResultCorrectionInput>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  hypopneaScore?: InputMaybe<Scalars['Float']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastSeenDate?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  neckCircumferenceInInches?: InputMaybe<Scalars['Float']['input']>;
  neckSize?: InputMaybe<Scalars['String']['input']>;
  primaryInsurance?: InputMaybe<ExtractionInsuranceResultCorrectionInput>;
  rdi?: InputMaybe<Scalars['Float']['input']>;
  rei?: InputMaybe<Scalars['Float']['input']>;
  secondaryInsurance?: InputMaybe<ExtractionInsuranceResultCorrectionInput>;
  sex?: InputMaybe<SexEnum>;
  shippingAddress?: InputMaybe<ExtractionAddressResultCorrectionInput>;
  tertiaryInsurance?: InputMaybe<ExtractionInsuranceResultCorrectionInput>;
  totalSleepTime?: InputMaybe<Scalars['Float']['input']>;
  weightInPounds?: InputMaybe<Scalars['Float']['input']>;
};

export type ExtractionProviderResultCorrectionInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  signaturePresent?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * The top level extraction corrections input type.
 *
 * This and many of its component sub types implement a validate and an apply_to_results method.
 * The validate method is responsible for validating input ids for duplicates or invalid ids and is
 * expected to be called prior to any apply_to_results method.
 *
 * apply_to_results will take in the existing extraction results set and apply the corrections input
 * to it and add the affected objects into a save buffer.
 *
 * The buffer collects all the objects needing saving so that we don't issue duplicate saves on the
 * same object
 *
 * Users need only to call the validate and apply_to_results_set on this top level
 * ExtractionResultsSetCorrectionsInput instance and it will call any sub input type validate and
 * apply_to_results methods as necessary
 */
export type ExtractionResultsSetCorrectionsInput = {
  documents?: InputMaybe<ExtractionDocumentResultCorrectionInput>;
  order?: InputMaybe<ExtractionOrderResultCorrectionInput>;
  patient?: InputMaybe<ExtractionPatientResultCorrectionInput>;
  provider?: InputMaybe<ExtractionProviderResultCorrectionInput>;
  resultsSetId: Scalars['UUID']['input'];
};

export type ExtractionResultsSetType = {
  __typename?: 'ExtractionResultsSetType';
  bloodGasStudyConfidence?: Maybe<AppExtractionResultsSetBloodGasStudyConfidenceChoices>;
  bloodGasStudyCorrected?: Maybe<Scalars['String']['output']>;
  bloodGasStudyRaw?: Maybe<Scalars['String']['output']>;
  chartNotesConfidence?: Maybe<AppExtractionResultsSetChartNotesConfidenceChoices>;
  chartNotesCorrected?: Maybe<Scalars['String']['output']>;
  chartNotesRaw?: Maybe<Scalars['String']['output']>;
  coverSheetConfidence?: Maybe<AppExtractionResultsSetCoverSheetConfidenceChoices>;
  coverSheetCorrected?: Maybe<Scalars['String']['output']>;
  coverSheetRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dischargeConfidence?: Maybe<AppExtractionResultsSetDischargeConfidenceChoices>;
  dischargeCorrected?: Maybe<Scalars['Boolean']['output']>;
  dischargeRaw?: Maybe<Scalars['Boolean']['output']>;
  dxCodes: Array<ExtractionDiagnosisResultType>;
  extractorSource: AppExtractionResultsSetExtractorSourceChoices;
  feedbackSet: Array<ExtractionFeedbackType>;
  homeAddress: ExtractionAddressResultType;
  id: Scalars['UUID']['output'];
  lineItems: Array<ExtractionLineItemResultType>;
  normalizedCoordinates?: Maybe<CoordinatesSetType>;
  orderFieldRequirements: Scalars['JSONString']['output'];
  orderSeed?: Maybe<OrderSeedType>;
  parentOrderSeedIdConfidence?: Maybe<AppExtractionResultsSetParentOrderSeedIdConfidenceChoices>;
  parentOrderSeedIdCorrected?: Maybe<Scalars['String']['output']>;
  parentOrderSeedIdRaw?: Maybe<Scalars['String']['output']>;
  patientAhiConfidence?: Maybe<AppExtractionResultsSetPatientAhiConfidenceChoices>;
  patientAhiCorrected?: Maybe<Scalars['Float']['output']>;
  patientAhiRaw?: Maybe<Scalars['Float']['output']>;
  patientBmiConfidence?: Maybe<AppExtractionResultsSetPatientBmiConfidenceChoices>;
  patientBmiCorrected?: Maybe<Scalars['Float']['output']>;
  patientBmiRaw?: Maybe<Scalars['Float']['output']>;
  patientBpConfidence?: Maybe<AppExtractionResultsSetPatientBpConfidenceChoices>;
  patientBpCorrected?: Maybe<Scalars['String']['output']>;
  patientBpRaw?: Maybe<Scalars['String']['output']>;
  patientCellPhoneConfidence?: Maybe<AppExtractionResultsSetPatientCellPhoneConfidenceChoices>;
  patientCellPhoneCorrected?: Maybe<Scalars['String']['output']>;
  patientCellPhoneRaw?: Maybe<Scalars['String']['output']>;
  patientDateOfBirthConfidence?: Maybe<AppExtractionResultsSetPatientDateOfBirthConfidenceChoices>;
  patientDateOfBirthCorrected?: Maybe<Scalars['String']['output']>;
  patientDateOfBirthRaw?: Maybe<Scalars['String']['output']>;
  patientEmailAddressConfidence?: Maybe<AppExtractionResultsSetPatientEmailAddressConfidenceChoices>;
  patientEmailAddressCorrected?: Maybe<Scalars['String']['output']>;
  patientEmailAddressRaw?: Maybe<Scalars['String']['output']>;
  patientEssConfidence?: Maybe<AppExtractionResultsSetPatientEssConfidenceChoices>;
  patientEssCorrected?: Maybe<Scalars['Float']['output']>;
  patientEssRaw?: Maybe<Scalars['Float']['output']>;
  patientFirstNameConfidence?: Maybe<AppExtractionResultsSetPatientFirstNameConfidenceChoices>;
  patientFirstNameCorrected?: Maybe<Scalars['String']['output']>;
  patientFirstNameRaw?: Maybe<Scalars['String']['output']>;
  patientHeightInInchesConfidence?: Maybe<AppExtractionResultsSetPatientHeightInInchesConfidenceChoices>;
  patientHeightInInchesCorrected?: Maybe<Scalars['Float']['output']>;
  patientHeightInInchesRaw?: Maybe<Scalars['Float']['output']>;
  patientHomePhoneConfidence?: Maybe<AppExtractionResultsSetPatientHomePhoneConfidenceChoices>;
  patientHomePhoneCorrected?: Maybe<Scalars['String']['output']>;
  patientHomePhoneRaw?: Maybe<Scalars['String']['output']>;
  patientHypopneaScoreConfidence?: Maybe<AppExtractionResultsSetPatientHypopneaScoreConfidenceChoices>;
  patientHypopneaScoreCorrected?: Maybe<Scalars['Float']['output']>;
  patientHypopneaScoreRaw?: Maybe<Scalars['Float']['output']>;
  patientLastNameConfidence?: Maybe<AppExtractionResultsSetPatientLastNameConfidenceChoices>;
  patientLastNameCorrected?: Maybe<Scalars['String']['output']>;
  patientLastNameRaw?: Maybe<Scalars['String']['output']>;
  patientLastSeenDateConfidence?: Maybe<AppExtractionResultsSetPatientLastSeenDateConfidenceChoices>;
  patientLastSeenDateCorrected?: Maybe<Scalars['String']['output']>;
  patientLastSeenDateRaw?: Maybe<Scalars['String']['output']>;
  patientMiddleNameConfidence?: Maybe<AppExtractionResultsSetPatientMiddleNameConfidenceChoices>;
  patientMiddleNameCorrected?: Maybe<Scalars['String']['output']>;
  patientMiddleNameRaw?: Maybe<Scalars['String']['output']>;
  patientNeckCircumferenceInInchesConfidence?: Maybe<AppExtractionResultsSetPatientNeckCircumferenceInInchesConfidenceChoices>;
  patientNeckCircumferenceInInchesCorrected?: Maybe<Scalars['Float']['output']>;
  patientNeckCircumferenceInInchesRaw?: Maybe<Scalars['Float']['output']>;
  patientNeckSizeConfidence?: Maybe<AppExtractionResultsSetPatientNeckSizeConfidenceChoices>;
  patientNeckSizeCorrected?: Maybe<Scalars['String']['output']>;
  patientNeckSizeRaw?: Maybe<Scalars['String']['output']>;
  patientRdiConfidence?: Maybe<AppExtractionResultsSetPatientRdiConfidenceChoices>;
  patientRdiCorrected?: Maybe<Scalars['Float']['output']>;
  patientRdiRaw?: Maybe<Scalars['Float']['output']>;
  patientReiConfidence?: Maybe<AppExtractionResultsSetPatientReiConfidenceChoices>;
  patientReiCorrected?: Maybe<Scalars['Float']['output']>;
  patientReiRaw?: Maybe<Scalars['Float']['output']>;
  patientSexConfidence?: Maybe<AppExtractionResultsSetPatientSexConfidenceChoices>;
  patientSexCorrected?: Maybe<Scalars['String']['output']>;
  patientSexRaw?: Maybe<Scalars['String']['output']>;
  patientTotalSleepTimeConfidence?: Maybe<AppExtractionResultsSetPatientTotalSleepTimeConfidenceChoices>;
  patientTotalSleepTimeCorrected?: Maybe<Scalars['Float']['output']>;
  patientTotalSleepTimeRaw?: Maybe<Scalars['Float']['output']>;
  patientWeightInPoundsConfidence?: Maybe<AppExtractionResultsSetPatientWeightInPoundsConfidenceChoices>;
  patientWeightInPoundsCorrected?: Maybe<Scalars['Float']['output']>;
  patientWeightInPoundsRaw?: Maybe<Scalars['Float']['output']>;
  prescriptionConfidence?: Maybe<AppExtractionResultsSetPrescriptionConfidenceChoices>;
  prescriptionCorrected?: Maybe<Scalars['String']['output']>;
  prescriptionRaw?: Maybe<Scalars['String']['output']>;
  primaryInsurance: ExtractionInsuranceResultType;
  providerFirstNameConfidence?: Maybe<AppExtractionResultsSetProviderFirstNameConfidenceChoices>;
  providerFirstNameCorrected?: Maybe<Scalars['String']['output']>;
  providerFirstNameRaw?: Maybe<Scalars['String']['output']>;
  providerLastNameConfidence?: Maybe<AppExtractionResultsSetProviderLastNameConfidenceChoices>;
  providerLastNameCorrected?: Maybe<Scalars['String']['output']>;
  providerLastNameRaw?: Maybe<Scalars['String']['output']>;
  providerNpiConfidence?: Maybe<AppExtractionResultsSetProviderNpiConfidenceChoices>;
  providerNpiCorrected?: Maybe<Scalars['String']['output']>;
  providerNpiRaw?: Maybe<Scalars['String']['output']>;
  providerSignaturePresentConfidence?: Maybe<AppExtractionResultsSetProviderSignaturePresentConfidenceChoices>;
  providerSignaturePresentCorrected?: Maybe<Scalars['Boolean']['output']>;
  providerSignaturePresentRaw?: Maybe<Scalars['Boolean']['output']>;
  referralDateConfidence?: Maybe<AppExtractionResultsSetReferralDateConfidenceChoices>;
  referralDateCorrected?: Maybe<Scalars['String']['output']>;
  referralDateRaw?: Maybe<Scalars['String']['output']>;
  secondaryInsurance: ExtractionInsuranceResultType;
  shippingAddress: ExtractionAddressResultType;
  sleepStudyConfidence?: Maybe<AppExtractionResultsSetSleepStudyConfidenceChoices>;
  sleepStudyCorrected?: Maybe<Scalars['String']['output']>;
  sleepStudyRaw?: Maybe<Scalars['String']['output']>;
  subcategoryIdConfidence?: Maybe<AppExtractionResultsSetSubcategoryIdConfidenceChoices>;
  subcategoryIdCorrected?: Maybe<Scalars['String']['output']>;
  subcategoryIdRaw?: Maybe<Scalars['String']['output']>;
  subcategoryNameConfidence?: Maybe<AppExtractionResultsSetSubcategoryNameConfidenceChoices>;
  subcategoryNameCorrected?: Maybe<Scalars['String']['output']>;
  subcategoryNameRaw?: Maybe<Scalars['String']['output']>;
  supplierNameConfidence?: Maybe<AppExtractionResultsSetSupplierNameConfidenceChoices>;
  supplierNameCorrected?: Maybe<Scalars['String']['output']>;
  supplierNameRaw?: Maybe<Scalars['String']['output']>;
  synthpopClinicalSummary?: Maybe<Scalars['String']['output']>;
  synthpopPassedQualification?: Maybe<Scalars['Boolean']['output']>;
  synthpopQualificationRelevant?: Maybe<Scalars['Boolean']['output']>;
  synthpopTaskStatus?: Maybe<Scalars['String']['output']>;
  tertiaryInsurance: ExtractionInsuranceResultType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FacilityType = {
  __typename?: 'FacilityType';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distanceFromPostalCode?: Maybe<Scalars['String']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FacilityTypeDistanceFromPostalCodeArgs = {
  postalCode: Scalars['String']['input'];
};

export type FacilityTypeConnection = {
  __typename?: 'FacilityTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacilityTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `FacilityType` and its cursor. */
export type FacilityTypeEdge = {
  __typename?: 'FacilityTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FacilityType>;
};

export type FaxPriorAuthRequestInput = {
  /** Max 100 characters */
  insurerAttentionTo?: InputMaybe<Scalars['String']['input']>;
  /** Must be 10 digits only */
  insurerFaxNumber: Scalars['String']['input'];
  /** Max 100 characters */
  insurerRecipientName: Scalars['String']['input'];
  submissionId: Scalars['UUID']['input'];
};

export type FaxPriorAuthRequestToInsurerMutation = {
  __typename?: 'FaxPriorAuthRequestToInsurerMutation';
  error?: Maybe<ErrorType>;
  faxTransmission?: Maybe<FaxTransmissionType>;
};

export type FaxTransmissionType = {
  __typename?: 'FaxTransmissionType';
  /** Time fax was delivered or failed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  document?: Maybe<DocumentType>;
  id: Scalars['UUID']['output'];
  /** Stored in the E164 format */
  recipientFaxNumber: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  /** Follows sender_name in header of every fax page. Expected by Faxage in 1.XXX.XXX.XXXX format, but stored in the E164 format */
  senderFaxNumber: Scalars['String']['output'];
  /** Goes after 'From:' in header of every fax page */
  senderName: Scalars['String']['output'];
  /** Time of first attempt to submit fax to Faxage */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByUser?: Maybe<UserSlimType>;
  status: AppFaxTransmissionStatusChoices;
  updatedAt: Scalars['DateTime']['output'];
};

export type FaxbackCoversheetInput = {
  additionalComments?: InputMaybe<Scalars['String']['input']>;
  orderSeedId: Scalars['ID']['input'];
  unmetRequirementsOverride?: InputMaybe<Scalars['String']['input']>;
};

export enum FhirVersionEnum {
  Dstu1 = 'DSTU1',
  Dstu2 = 'DSTU2',
  R4 = 'R4',
  R5 = 'R5',
  Stu3 = 'STU3',
  Unknown = 'UNKNOWN',
}

export enum FulfilledByEnum {
  InStoreRetail = 'IN_STORE_RETAIL',
  Manually = 'MANUALLY',
  SupplyCloset = 'SUPPLY_CLOSET',
}

/** Class representing Django permission groups. */
export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID']['output'];
  members?: Maybe<Array<UserTypeForAdminView>>;
  name: Scalars['String']['output'];
  permissions: Array<PermissionType>;
};

export type HcpcsInput = {
  code: Scalars['String']['input'];
  friendlyDescription?: InputMaybe<Scalars['String']['input']>;
  imgUrl?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HcpcsInsurerListItemInsurer = {
  __typename?: 'HcpcsInsurerListItemInsurer';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HcpcsInsurerListItemType = {
  __typename?: 'HcpcsInsurerListItemType';
  hcpcs?: Maybe<Scalars['String']['output']>;
  insurer?: Maybe<HcpcsInsurerListItemInsurer>;
};

export type HcpcsType = {
  __typename?: 'HcpcsType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  friendlyDescription?: Maybe<Scalars['String']['output']>;
  imgUrl?: Maybe<Scalars['String']['output']>;
  intakeQuestionHcpcs?: Maybe<Array<Maybe<IntakeQuestionHcpcsType>>>;
  longDescription?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type HcpcsTypeConnection = {
  __typename?: 'HcpcsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HcpcsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `HcpcsType` and its cursor. */
export type HcpcsTypeEdge = {
  __typename?: 'HcpcsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<HcpcsType>;
};

export type HistoricalChangesDetailFieldType = {
  __typename?: 'HistoricalChangesDetailFieldType';
  field?: Maybe<Scalars['String']['output']>;
  new?: Maybe<Scalars['String']['output']>;
  old?: Maybe<Scalars['String']['output']>;
};

export type HistoricalChangesDetailRecordType = {
  __typename?: 'HistoricalChangesDetailRecordType';
  changeType?: Maybe<Scalars['String']['output']>;
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<UserTypeForAdminView>;
  fields?: Maybe<Array<Maybe<HistoricalChangesDetailFieldType>>>;
};

export type HistoricalChangesDetailType = {
  __typename?: 'HistoricalChangesDetailType';
  error?: Maybe<ErrorType>;
  results?: Maybe<Array<Maybe<HistoricalChangesDetailRecordType>>>;
};

export type HistoricalChangesListItem = {
  __typename?: 'HistoricalChangesListItem';
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<UserTypeForAdminView>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['UUID']['output']>;
  state?: Maybe<HistoryRecordStateEnum>;
};

export type HistoricalChangesListType = {
  __typename?: 'HistoricalChangesListType';
  error?: Maybe<ErrorType>;
  results?: Maybe<Array<Maybe<HistoricalChangesListItem>>>;
};

export enum HistoryRecordStateEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type InitiateDocumentExtractionJobInput = {
  customEngine?: InputMaybe<Scalars['String']['input']>;
  customPrompt?: InputMaybe<Scalars['String']['input']>;
  customTemperature?: InputMaybe<Scalars['Float']['input']>;
  customTopP?: InputMaybe<Scalars['Float']['input']>;
  originalFileName: Scalars['String']['input'];
  runDefaults?: InputMaybe<Scalars['Boolean']['input']>;
  s3Key?: InputMaybe<Scalars['String']['input']>;
  s3Url?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation to manually initiate document extraction job.
 *
 * This expects that the pdf has already been uploaded to the s3 bucket indicated
 * by ORDER_INTAKE_DOCUMENT_S3_BUCKET
 * (tomorrow-health-{settings.APP_ENV}-order-intake-documents-us-east-2).
 * As such, this does not take in a bucket name.
 *
 * This was built for a time-constrained demo and can use some cleanup. In the demo
 * context, this was called by a lambda function.
 */
export type InitiateDocumentExtractionJobMutation = {
  __typename?: 'InitiateDocumentExtractionJobMutation';
  error?: Maybe<ErrorType>;
};

/** Indicates input type allowed for the intake questions */
export enum InputType {
  Boolean = 'BOOLEAN',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Dropdown = 'DROPDOWN',
  File = 'FILE',
  Number = 'NUMBER',
  RadioGroup = 'RADIO_GROUP',
  Text = 'TEXT',
}

export type InsuranceInput = {
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  policyNumber: Scalars['String']['input'];
  syncId?: InputMaybe<Scalars['ID']['input']>;
};

/** Insurance response type for full verification details. */
export type InsuranceVerificationType = {
  __typename?: 'InsuranceVerificationType';
  active?: Maybe<Scalars['Boolean']['output']>;
  benefitsValidFrom?: Maybe<Scalars['Date']['output']>;
  benefitsValidTo?: Maybe<Scalars['Date']['output']>;
  changeHealthRawResponse?: Maybe<Scalars['JSONString']['output']>;
  coinsurance?: Maybe<Scalars['Decimal']['output']>;
  copay?: Maybe<Scalars['Decimal']['output']>;
  deductible?: Maybe<Scalars['Decimal']['output']>;
  deductibleRemaining?: Maybe<Scalars['Decimal']['output']>;
  error?: Maybe<ErrorType>;
  familyDeductible?: Maybe<Scalars['Decimal']['output']>;
  familyDeductibleRemaining?: Maybe<Scalars['Decimal']['output']>;
  familyOutOfPocketMax?: Maybe<Scalars['Decimal']['output']>;
  familyOutOfPocketMaxRemaining?: Maybe<Scalars['Decimal']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  outOfPocketMax?: Maybe<Scalars['Decimal']['output']>;
  outOfPocketMaxRemaining?: Maybe<Scalars['Decimal']['output']>;
  patient?: Maybe<MemberType>;
  planDetails?: Maybe<Scalars['String']['output']>;
  policyHolder?: Maybe<MemberType>;
  policyName?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['Date']['output']>;
  validTo?: Maybe<Scalars['Date']['output']>;
};

export enum InsurerCategoryInputEnum {
  CommonwealthCareAlliance = 'COMMONWEALTH_CARE_ALLIANCE',
  Geisinger = 'GEISINGER',
  Shp = 'SHP',
}

export type InsurerInput = {
  allowInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  baseEntityId?: InputMaybe<Scalars['ID']['input']>;
  businessSegment?: InputMaybe<AppInsurerBusinessSegmentChoices>;
  carrierId?: InputMaybe<Scalars['ID']['input']>;
  claimCode?: InputMaybe<Scalars['String']['input']>;
  claimEntityId?: InputMaybe<Scalars['ID']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  eligibilityCode?: InputMaybe<Scalars['String']['input']>;
  eligibilityEntityId?: InputMaybe<Scalars['ID']['input']>;
  eligibilityShowErrors?: InputMaybe<Scalars['Boolean']['input']>;
  /** Insurer category */
  insurerCategory?: InputMaybe<InsurerCategoryInputEnum>;
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  insurerType: InsurerTypeEnum;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isRural?: InputMaybe<Scalars['Boolean']['input']>;
  isUsedForRouting?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  priorAuthorizationServiceLevel?: InputMaybe<AppInsurerPriorAuthorizationServiceLevelChoices>;
  requiresTitleXix?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  supportsHcpcsRouting?: InputMaybe<Scalars['Boolean']['input']>;
  syncId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsurerSlimType = {
  __typename?: 'InsurerSlimType';
  id: Scalars['UUID']['output'];
  insurerType?: Maybe<AppInsurerInsurerTypeChoices>;
  name: Scalars['String']['output'];
  syncId?: Maybe<Scalars['UUID']['output']>;
};

export type InsurerSlimTypeConnection = {
  __typename?: 'InsurerSlimTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InsurerSlimTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `InsurerSlimType` and its cursor. */
export type InsurerSlimTypeEdge = {
  __typename?: 'InsurerSlimTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InsurerSlimType>;
};

export type InsurerType = {
  __typename?: 'InsurerType';
  allowInsurance: Scalars['Boolean']['output'];
  baseInsurerId?: Maybe<Scalars['ID']['output']>;
  businessSegment?: Maybe<AppInsurerBusinessSegmentChoices>;
  claimCode?: Maybe<Scalars['String']['output']>;
  claimInsurerId?: Maybe<Scalars['ID']['output']>;
  coveringNetworkSuppliers?: Maybe<Array<Maybe<NetworkSupplierType>>>;
  display: Scalars['String']['output'];
  eligibilityCode?: Maybe<Scalars['String']['output']>;
  eligibilityInsurerId?: Maybe<Scalars['ID']['output']>;
  /** When set, warn users if CHC eligibility checks fail, else ignore */
  eligibilityShowErrors: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  insurerCategory?: Maybe<Scalars['String']['output']>;
  insurerCategoryDirect?: Maybe<AppInsurerInsurerCategoryDirectChoices>;
  insurerType?: Maybe<AppInsurerInsurerTypeChoices>;
  intakeQuestionHcpcs: IntakeQuestionHcpcsTypeConnection;
  isActive: Scalars['Boolean']['output'];
  isRural?: Maybe<Scalars['Boolean']['output']>;
  isUsedForRouting?: Maybe<Scalars['Boolean']['output']>;
  logoUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  npi?: Maybe<Scalars['String']['output']>;
  otherChildren?: Maybe<Array<Maybe<InsurerType>>>;
  parent?: Maybe<InsurerType>;
  plans?: Maybe<Array<Maybe<InsurerType>>>;
  priorAuthorizationServiceLevel?: Maybe<AppInsurerPriorAuthorizationServiceLevelChoices>;
  requiresTitleXix: Scalars['Boolean']['output'];
  routingInsurer?: Maybe<InsurerType>;
  state?: Maybe<Scalars['String']['output']>;
  supportsHcpcsRouting: Scalars['Boolean']['output'];
  syncId?: Maybe<Scalars['UUID']['output']>;
};

export type InsurerTypeIntakeQuestionHcpcsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type InsurerTypePlansArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type InsurerTypeConnection = {
  __typename?: 'InsurerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InsurerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `InsurerType` and its cursor. */
export type InsurerTypeEdge = {
  __typename?: 'InsurerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InsurerType>;
};

export enum InsurerTypeEnum {
  Carrier = 'CARRIER',
  Other = 'OTHER',
  Plan = 'PLAN',
}

export type IntakeCategoryType = {
  __typename?: 'IntakeCategoryType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  intakesubcategorySet: Array<IntakeSubcategoryType>;
  /** Name of the intake category */
  name: Scalars['String']['output'];
  syncId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IntakeCategoryTypeIntakesubcategorySetArgs = {
  hasProductsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntakeDefectType = {
  __typename?: 'IntakeDefectType';
  detail?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type IntakeQuestionAttributesInput = {
  /** Choices for radiogroup and checkbox type questions */
  choices?: InputMaybe<Array<InputMaybe<ChoiceInput>>>;
  /** Type of input control */
  componentType: InputType;
  /** Default answer for question. Only single value for text and radio inputs and multiple allowed for checkbox input. */
  defaultValue?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Helper text to display for the question */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Diagnosis codes applicable for "diagnostic_code" questions with "boolean" inputs. For "radiogroup" and checkbox" type inputs, diagnosis codes will be parsed and validated from values provided as choice inputs. */
  diagnosticCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Allow "other" choice for radiogroup and checkbox type question? */
  hasOtherOption?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of HCPCS codes applicable for the question.HCPCS codes are required for hcpcs_code selection questions and other questions like product options, where the question is applicable to specific HCPCS codes. */
  hcpcsCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Indicate if the question is a product catalog question or not */
  isProductCatalogQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the question read-only? */
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the question required to be answered? */
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the question visible? */
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Label to display for "true" choice of boolean question. */
  labelTrue?: InputMaybe<Scalars['String']['input']>;
  /** Max value for the input answer. Applicable for numeric input type questions. */
  maxValue?: InputMaybe<Scalars['Int']['input']>;
  /** Minimum value for the input answer. Applicable for numeric input type questions. */
  minValue?: InputMaybe<Scalars['Int']['input']>;
  /** Temporary name for the question, useful in case of product form questions to build visibleIf conditions. Name of the questionwill be different once the json is generated */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Name for the option in case the option_type is OTHER */
  optionName?: InputMaybe<Scalars['String']['input']>;
  /** Option type applicable for the question. This is applicable for questions like product_options. (Example: In case of product option questions, this could be something like "color" or "brand" etc. */
  optionType?: InputMaybe<QuestionOptionType>;
  /** Label for "other" choice */
  otherDisplayText?: InputMaybe<Scalars['String']['input']>;
  /** Input placeholder text. Applicable for text input and "other" input of radio and checkbox types if enabled. */
  placeholderText?: InputMaybe<Scalars['String']['input']>;
  /** Type of intake question */
  questionType: QuestionType;
  /** Text to display when question is required and not answered */
  requiredErrorText?: InputMaybe<Scalars['String']['input']>;
  /** List of subproduct codes applicable for the question.Subproduct codes are required for subproduct_code selection questions and other questions like product options, where the question is applicable to specific subproduct codes. */
  subproductCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Display text for the question */
  title: Scalars['String']['input'];
  /** List of product validation rules that should be applied on the question. This should only be used in the context of product questions. It will be the responsibility of the retool FE to pass this in the correctly formatted manner since this should be relatively short lived with the upcoming product form deprecation */
  validationRules?: InputMaybe<Scalars['JSONString']['input']>;
  /** Value to be used internally as answer for truthy answer of boolean question type (if other than true or false). */
  valueIfTrue?: InputMaybe<Scalars['String']['input']>;
  /** Conditions when the given question is visible. */
  visibleIf?: InputMaybe<Scalars['String']['input']>;
};

export type IntakeQuestionByHistoryType = {
  __typename?: 'IntakeQuestionByHistoryType';
  historyVersionId?: Maybe<Scalars['ID']['output']>;
  questions?: Maybe<Array<Maybe<IntakeQuestionType>>>;
};

export type IntakeQuestionHcpcsInput = {
  hcpcsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Whether or not we should disregard the fact that creation of one of these mappings will cause an insurer/hcpcs combination to stop showing certain questions. If true, we will create the mapping and allow the question drop */
  ignoreDroppedQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  insurerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** History IDs of validation rules to associate with this mapping */
  validationRuleHistoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IntakeQuestionHcpcsObjectType = {
  __typename?: 'IntakeQuestionHcpcsObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hcpcs?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  insurer_Id?: Maybe<Scalars['String']['output']>;
  insurer_Name?: Maybe<Scalars['String']['output']>;
  intakeQuestionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
  validationRules?: Maybe<Array<Maybe<IntakeValidationRuleObjectType>>>;
};

export type IntakeQuestionHcpcsType = {
  __typename?: 'IntakeQuestionHcpcsType';
  /** Retrieves the currently active validation rules tied to this mapping.  */
  activeValidationRuleVersions?: Maybe<Array<IntakeValidationRuleType>>;
  createdAt: Scalars['DateTime']['output'];
  hcpcs?: Maybe<HcpcsType>;
  id: Scalars['UUID']['output'];
  insurer?: Maybe<InsurerType>;
  intakeQuestion: IntakeQuestionType;
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Retrieves the direct relational association with a validation rule. Since associations are performed through the base version of a rule, this will only return the base versions! If you need the current active version, get activeValidationRuleVersions instead. */
  validationRules?: Maybe<Array<IntakeValidationRuleType>>;
};

export type IntakeQuestionHcpcsTypeConnection = {
  __typename?: 'IntakeQuestionHcpcsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntakeQuestionHcpcsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntakeQuestionHcpcsType` and its cursor. */
export type IntakeQuestionHcpcsTypeEdge = {
  __typename?: 'IntakeQuestionHcpcsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntakeQuestionHcpcsType>;
};

export type IntakeQuestionInput = {
  historyVersionId?: InputMaybe<Scalars['ID']['input']>;
  /** Optionally specifies history_version_sync_id, defaults to the same as history_version_id */
  historyVersionSyncId?: InputMaybe<Scalars['ID']['input']>;
  /** Field for storing internal notes related to intake questions like policy source links. */
  internalNote?: InputMaybe<Scalars['String']['input']>;
  /** Nickname to be able to easily search/identify questions on the Ops dashboard. This is not used to generate the question. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Attributes used to create the question json as per SurveyJS format */
  questionAttributes?: InputMaybe<IntakeQuestionAttributesInput>;
  /** Question JSON as per SurveyJS question format */
  questionJson?: InputMaybe<Scalars['JSONString']['input']>;
  /** Type of intake question */
  questionType: QuestionType;
  /** Optionally specifies sync_id, defaults to the same as question id */
  syncId?: InputMaybe<Scalars['ID']['input']>;
};

export type IntakeQuestionObjectType = {
  __typename?: 'IntakeQuestionObjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  historyVersionId?: Maybe<Scalars['ID']['output']>;
  historyVersionSyncId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  intakeQuestionHcpcs?: Maybe<Array<Maybe<IntakeQuestionHcpcsObjectType>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  questionJson?: Maybe<Scalars['JSONString']['output']>;
  questionType?: Maybe<QuestionType>;
  syncId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IntakeQuestionType = {
  __typename?: 'IntakeQuestionType';
  createdAt: Scalars['DateTime']['output'];
  dependentRules?: Maybe<Array<Maybe<IntakeValidationRuleType>>>;
  historyVersion: IntakeQuestionType;
  historyVersionSyncId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  intakeQuestionHcpcs?: Maybe<Array<Maybe<IntakeQuestionHcpcsType>>>;
  internalNote?: Maybe<Scalars['String']['output']>;
  isEditable: Scalars['Boolean']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  questionJson: Scalars['JSONString']['output'];
  questionType: AppIntakeQuestionQuestionTypeChoices;
  syncId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type IntakeQuestionTypeConnection = {
  __typename?: 'IntakeQuestionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntakeQuestionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntakeQuestionType` and its cursor. */
export type IntakeQuestionTypeEdge = {
  __typename?: 'IntakeQuestionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntakeQuestionType>;
};

/** Returns filtered intake questions as well as json */
export type IntakeQuestionsForHcpcs = {
  __typename?: 'IntakeQuestionsForHcpcs';
  /** List of hcpcs and associated base entity insurer used */
  hcpcsToInsurer?: Maybe<Array<Maybe<HcpcsInsurerListItemType>>>;
  intakeQuestions?: Maybe<Array<Maybe<IntakeQuestionType>>>;
  json?: Maybe<Scalars['JSONString']['output']>;
};

export type IntakeSubcategoryProductFormType = {
  __typename?: 'IntakeSubcategoryProductFormType';
  createdAt: Scalars['DateTime']['output'];
  formJson?: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IntakeSubcategoryType = {
  __typename?: 'IntakeSubcategoryType';
  activeProductForm?: Maybe<IntakeSubcategoryProductFormType>;
  /** @deprecated Deprecated */
  allowsDischarge?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Deprecated */
  allowsUrgent?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  defaultDeliveryHours: Scalars['Int']['output'];
  defaultUrgentDeliveryHours: Scalars['Int']['output'];
  hcpcsCodes?: Maybe<Array<Maybe<HcpcsType>>>;
  id: Scalars['UUID']['output'];
  /** Intake Category parent for IntakeSubcategory */
  intakeCategory: IntakeCategoryType;
  /** Name of the product subcategory */
  name: Scalars['String']['output'];
  productForms?: Maybe<Array<Maybe<IntakeSubcategoryProductFormType>>>;
  requiredDocuments?: Maybe<Array<Scalars['String']['output']>>;
  syncId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IntakeValidationRuleInput = {
  assertionDependsOn?: InputMaybe<Array<Scalars['ID']['input']>>;
  assertionDependsOnProductQuestions?: InputMaybe<Array<Scalars['UUID']['input']>>;
  conditionDependsOn?: InputMaybe<Array<Scalars['ID']['input']>>;
  conditionDependsOnProductQuestions?: InputMaybe<Array<Scalars['UUID']['input']>>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  errorCategory?: InputMaybe<AppIntakeValidationRuleErrorCategoryChoices>;
  forceUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  highContextErrorText?: InputMaybe<Scalars['String']['input']>;
  highContextSuccessText?: InputMaybe<Scalars['String']['input']>;
  highContextWarningText?: InputMaybe<Scalars['String']['input']>;
  /** id for rule to be updated (if input is used for an update) */
  intakeValidationRuleId?: InputMaybe<Scalars['ID']['input']>;
  /** Field for storing internal notes related to intake questions like policy source links. */
  internalNote?: InputMaybe<Scalars['String']['input']>;
  lowContextErrorText?: InputMaybe<Scalars['String']['input']>;
  lowContextSuccessText?: InputMaybe<Scalars['String']['input']>;
  lowContextWarningText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ruleAssertion?: InputMaybe<Scalars['String']['input']>;
  ruleCondition?: InputMaybe<Scalars['String']['input']>;
  ruleType?: InputMaybe<AppIntakeValidationRuleRuleTypeChoices>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntakeValidationRuleObjectType = {
  __typename?: 'IntakeValidationRuleObjectType';
  assertionDependsOn?: Maybe<Array<Scalars['ID']['output']>>;
  conditionDependsOn?: Maybe<Array<Scalars['ID']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  errorCategory?: Maybe<AppIntakeValidationRuleErrorCategoryChoices>;
  highContextErrorText?: Maybe<Scalars['String']['output']>;
  highContextSuccessText?: Maybe<Scalars['String']['output']>;
  highContextWarningText?: Maybe<Scalars['String']['output']>;
  historyVersionId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lowContextErrorText?: Maybe<Scalars['String']['output']>;
  lowContextSuccessText?: Maybe<Scalars['String']['output']>;
  lowContextWarningText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ruleAssertion?: Maybe<Scalars['String']['output']>;
  ruleCondition?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type IntakeValidationRuleOverlapType = {
  __typename?: 'IntakeValidationRuleOverlapType';
  intakeValidationRule?: Maybe<IntakeValidationRuleType>;
  overlappingVersions?: Maybe<Array<Maybe<IntakeValidationRuleType>>>;
};

export type IntakeValidationRuleResultInput = {
  finalAnswerValue: Array<Scalars['String']['input']>;
  initialAnswerValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id of the intake question associated with this rule result */
  intakeQuestionId: Scalars['ID']['input'];
  /** id of the intake validation rule that generated this result */
  intakeValidationRuleId: Scalars['ID']['input'];
  result: AppIntakeValidationRuleResultResultChoices;
};

export type IntakeValidationRuleResultType = {
  __typename?: 'IntakeValidationRuleResultType';
  /** The final answer from the user that produced this result */
  finalAnswerValue: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** The latest invalid answer from the user. Used to track how often a user encountered an error then changed answer. */
  initialAnswerValue?: Maybe<Array<Scalars['String']['output']>>;
  /** The associated OrderIntakeAnswer for this result */
  intakeAnswer: OrderIntakeAnswerType;
  /** The specific version of intake question this result was captured on */
  intakeQuestion: IntakeQuestionType;
  /** The specific version of rule that was evaluated for this result */
  intakeValidationRule: IntakeValidationRuleType;
  result: AppIntakeValidationRuleResultResultChoices;
};

export type IntakeValidationRuleType = {
  __typename?: 'IntakeValidationRuleType';
  assertionDependsOn?: Maybe<Array<IntakeQuestionType>>;
  assertionDependsOnProductQuestions?: Maybe<Array<ProductCatalogQuestionType>>;
  conditionDependsOn?: Maybe<Array<IntakeQuestionType>>;
  conditionDependsOnProductQuestions?: Maybe<Array<ProductCatalogQuestionType>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Friendly description giving synopsis of what this rule is checking */
  description?: Maybe<Scalars['String']['output']>;
  errorCategory?: Maybe<AppIntakeValidationRuleErrorCategoryChoices>;
  /** The high-context error message that should be displayed to the user when the rule is violated. This will be used in scenarios where the full context of the rule/question is needed. A rule cannot have both warning and error text */
  highContextErrorText?: Maybe<Scalars['String']['output']>;
  /** The high-context success message that should be displayed to the user when the rule is satisfied. This will be used in scenarios where the full context of the rule/question is needed */
  highContextSuccessText?: Maybe<Scalars['String']['output']>;
  /** The high-context warning that should be displayed to the user when the rule is violated. This will be used in scenarios where the full context of the rule/question is needed. A rule cannot have both warning and error text */
  highContextWarningText?: Maybe<Scalars['String']['output']>;
  historyVersion: IntakeValidationRuleType;
  id: Scalars['UUID']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  /** The low-context error message that should be displayed to the user when the rule is violated. This will be used in scenarios where the context of the rule/question is already present so minimal text is needed. A rule cannot have both warning and error text */
  lowContextErrorText?: Maybe<Scalars['String']['output']>;
  /** The low-context success message that should be displayed to the user when the rule is satisfied. This will be used in scenarios where the context of the rule/question is already present so minimal text is needed. */
  lowContextSuccessText?: Maybe<Scalars['String']['output']>;
  /** The low-context warning that should be displayed to the user when the rule is violated. This will be used in scenarios where the context of the rule/question is already present so minimal text is needed. A rule cannot have both warning and error text */
  lowContextWarningText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overlaps?: Maybe<Array<Maybe<IntakeValidationRuleOverlapType>>>;
  /** SurveyJS compatible string expressing the rule's primary assertion. If it evaluates to false, the rule has been violated. */
  ruleAssertion: Scalars['String']['output'];
  /** SurveyJS compatible string expressing when the rule should be applied. If it evaluates to true, the rule's assertion strings will be checked. */
  ruleCondition: Scalars['String']['output'];
  ruleType: AppIntakeValidationRuleRuleTypeChoices;
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type IntakeValidationRuleTypeConnection = {
  __typename?: 'IntakeValidationRuleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntakeValidationRuleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `IntakeValidationRuleType` and its cursor. */
export type IntakeValidationRuleTypeEdge = {
  __typename?: 'IntakeValidationRuleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntakeValidationRuleType>;
};

export type IntegrationType = {
  __typename?: 'IntegrationType';
  externalService: ExternalServiceType;
  id: Scalars['UUID']['output'];
  integrationType: AppIntegrationIntegrationTypeChoices;
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  adjustmentsSum: Scalars['Decimal']['output'];
  allowable?: Maybe<Scalars['Decimal']['output']>;
  amount: Scalars['Decimal']['output'];
  balance: Scalars['Decimal']['output'];
  coinsurance: Scalars['Decimal']['output'];
  copay: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  deductible: Scalars['Decimal']['output'];
  id: Scalars['UUID']['output'];
  isReimbursed?: Maybe<Scalars['Boolean']['output']>;
  nikoId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
  payerAllowable?: Maybe<Scalars['Decimal']['output']>;
  paymentsSum: Scalars['Decimal']['output'];
  primaryPatientInsurance?: Maybe<PatientInsuranceType>;
  secondaryPatientInsurance?: Maybe<PatientInsuranceType>;
  status: AppInvoiceStatusChoices;
  updatedAt: Scalars['DateTime']['output'];
};

export type LanguageInput = {
  code: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageType = {
  __typename?: 'LanguageType';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LineItemSurveyResponseType = {
  __typename?: 'LineItemSurveyResponseType';
  isReceived?: Maybe<Scalars['Boolean']['output']>;
  isSatisfied?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<AppLineItemSurveyResponseReasonChoices>;
  reasonText?: Maybe<Scalars['String']['output']>;
};

export type LocationInfoType = {
  __typename?: 'LocationInfoType';
  boundingBox?: Maybe<BoundingBoxType>;
  page?: Maybe<Scalars['Int']['output']>;
};

/** Class representing logging.LogRecord objects */
export type LogRecordType = {
  __typename?: 'LogRecordType';
  created?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  funcName?: Maybe<Scalars['String']['output']>;
  levelname?: Maybe<Scalars['String']['output']>;
  levelno?: Maybe<Scalars['Int']['output']>;
  lineno?: Maybe<Scalars['Int']['output']>;
  /** Formatted log message */
  message?: Maybe<Scalars['String']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  /** Log message template */
  msg?: Maybe<Scalars['String']['output']>;
};

export type MarkPriorAuthRequestSubmissionAsSubmittedInput = {
  submissionId: Scalars['UUID']['input'];
  submittedAt: Scalars['DateTime']['input'];
};

export type MarkPriorAuthRequestSubmissionAsSubmittedMutation = {
  __typename?: 'MarkPriorAuthRequestSubmissionAsSubmittedMutation';
  error?: Maybe<ErrorType>;
  priorAuthRequestSubmission?: Maybe<PriorAuthRequestSubmissionType>;
};

export type MemberType = {
  __typename?: 'MemberType';
  addressInfo?: Maybe<Scalars['JSONString']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Scalars['String']['output']>;
};

export type MetricResultType = ErrorType | MetricType;

export type MetricType = {
  __typename?: 'MetricType';
  displayName: Scalars['String']['output'];
  displayUnit: AppMetricDisplayUnitChoices;
  key: Scalars['String']['output'];
  networkMetricValues?: Maybe<Array<Maybe<NetworkMetricValueType>>>;
  orgMetricValues?: Maybe<Array<Maybe<OrgMetricValueType>>>;
};

export type MetricTypeOrgMetricValuesArgs = {
  orgId: Scalars['ID']['input'];
};

export type ModelFieldChoiceType = {
  __typename?: 'ModelFieldChoiceType';
  falconStatusChoices?: Maybe<Array<Maybe<ChoiceType>>>;
  orderFulfilledByChoices?: Maybe<Array<Maybe<ChoiceType>>>;
  orderSourceChoices?: Maybe<Array<Maybe<ChoiceType>>>;
  orgStatusChoices?: Maybe<Array<Maybe<ChoiceType>>>;
  rpStatusChoices?: Maybe<Array<Maybe<ChoiceType>>>;
};

export type ModelFieldChoiceTypeOrgStatusChoicesArgs = {
  orgType: OrgTypes;
};

export type Mutation = {
  __typename?: 'Mutation';
  ackAllAppNotifications?: Maybe<AckAllAppNotificationsMutation>;
  /** Adds a patient to an org. */
  addPatientToOrg?: Maybe<AddPatientToOrgMutation>;
  addSupplierDocuments?: Maybe<AddSupplierDocuments>;
  /**
   * If the user exists, add it to org. Otherwise, create a user and add it to the org.
   * It is assumed that supplying a user_id as an input to this mutation means that the
   * caller believes the user already exists. However, if a user_id is not supplied and
   * user object is found with the input email, that existing user object will be used.
   *
   * To specify an org, either org_id or org_type and org_name must be provided.
   */
  addUserToOrg?: Maybe<AddUserToOrgMutation>;
  adminAddDefectDefinition?: Maybe<AdminAddDefectDefinitionMutation>;
  adminAddEhrSystemToOrg?: Maybe<AdminAddEhrSystemToOrgMutation>;
  adminAddHcpcsCodesToSubcategory?: Maybe<AdminAddHcpcsCodesToSubcategory>;
  /** @deprecated PracticePatient is deprecated */
  adminAddPatientsToPractice?: Maybe<AdminAddPatientsToPracticeMutation>;
  adminAssignOwner?: Maybe<AdminAssignOwnerMutation>;
  adminAutoRouteOrder?: Maybe<AdminAutoRouteOrder>;
  adminBulkUpdateIntakeQuestionHcpcs?: Maybe<AdminBulkUpdateIntakeQuestionHcpcsMutation>;
  /**
   * In contrast to the existing bulk_replace_insurer_coverage function, this
   * mutation forces users to be explicit about insurer coverage additions and removals
   * rather than performing a full replace where the user must pass in the full list of
   * insurers that are covered and omit those that should not be (declarative).
   *
   * The advantage of requiring explicitly defined additions and removals is that it is less
   * susceptible to concurrent user issues. Declarative operations can inadvertently, and silently,
   * overwrite concurrent users changes letting only the last one win.
   */
  adminBulkUpdateNetworkSupplierCoveredInsurers?: Maybe<AdminBulkUpdateNetworkSupplierCoveredInsurers>;
  /**
   * Associate/disassociate product validation rules to/from product question
   * mappings
   */
  adminBulkUpdateProductValidationRuleMappings?: Maybe<AdminBulkUpdateProductValidationRuleMappingsMutation>;
  /**
   * Associate/disassociate clinical validation rules to/from clinical question
   * mappings
   */
  adminBulkUpdateValidationRuleMappings?: Maybe<AdminBulkUpdateValidationRuleMappingsMutation>;
  adminCancelNetworkOrder?: Maybe<AdminCancelNetworkOrder>;
  adminChangeSigningMethod?: Maybe<AdminChangeSigningMethodMutation>;
  adminChargeInvoice?: Maybe<AdminChargeInvoiceMutation>;
  adminCheckPatientInsuranceEligibility?: Maybe<AdminCheckPatientInsuranceEligibilityMutation>;
  adminCloneIntakeQuestion?: Maybe<AdminCloneIntakeQuestionMutation>;
  adminCloneIntakeQuestionVersion?: Maybe<AdminCloneIntakeQuestionVersionMutation>;
  /**
   * When CR questions are queried, for a given hcpcs, we first search for any
   * mappings associated with the order's primary insurer. If we find no mappings, then
   * we'll traverse the insurer base_entity hierarchy up and perform another search until
   * we find a single mapping.
   *
   * Let's say A0001 is mapped to question1 and question2 for the default insurer (None).
   * We want to add a new question3 to A0001 only for GHP but still have question1 and
   * question2 appear for A0001.
   *
   * It would be insufficient to just add a new mapping between A0001/question3/GHP
   * because the insurer hierarchy traversal logic will start with GHP, find the mapping
   * for question3 then stop. This means that question 1 and 2 will stop appearing!
   *
   * In order to get all three questions to appear, we need to clone the original
   * mappings that would have been resolved through the hierarchy traversal into the GHP
   * insurer, then create the new mapping for the new question.
   * This way when we query for mappings for A0001/GHP, all three questions show up.
   *
   * This mutation is meant to provide the initial cloning of all hierarchy-resolved
   * mappings for a HCPCS into mappings specific for the input insurer.
   */
  adminCloneMappingsForInsurerHcpcs?: Maybe<AdminCloneMappingsForInsurerHcpcsMutation>;
  adminCompleteNetworkOrder?: Maybe<AdminCompleteNetworkOrder>;
  /**
   * Create a comment on a Box order.
   *
   * If the input includes an original_comment_id, the comment is a reply
   * to the original comment.
   *
   * We do not store the comment in the database, as a successful comment creation
   * will trigger a webhook event which will in turn store the comment in the database.
   * @deprecated Box is deprecated
   */
  adminCreateBoxComment?: Maybe<AdminCreateBoxComment>;
  adminCreateCareCoordination?: Maybe<AdminCreateCareCoordination>;
  adminCreateEhrSystem?: Maybe<AdminCreateEhrSystemMutation>;
  /**
   * Mutation to create new intake question.
   *
   * Constraints for creating intake questions:
   * 1. If history_version_id is included, question_type input must match the
   *    question_type of the IntakeQuestion with id=history_version_id.
   * 2. If history_version_id matches a question, will create a new version of that
   *    original question. The new IntakeQuestion's history_version_id will be the id
   *    of the original.
   * 3. If history_version_id is included and a matching question for it cannot be
   *    found, will raise an error.
   */
  adminCreateIntakeQuestion?: Maybe<AdminCreateIntakeQuestionMutation>;
  adminCreateIntakeQuestionHcpcs?: Maybe<AdminCreateIntakeQuestionHcpcsMutation>;
  adminCreateNetworkSupplier?: Maybe<AdminCreateNetworkSupplier>;
  adminCreateOrUpdateInsurer?: Maybe<AdminCreateOrUpdateInsurerMutation>;
  adminCreateOrUpdateWaiver?: Maybe<AdminCreateOrUpdateWaiverMutation>;
  adminCreateOrderDocuments?: Maybe<AdminCreateOrderDocumentsMutation>;
  adminCreatePractice?: Maybe<AdminCreatePracticeMutation>;
  adminCreateProduct?: Maybe<AdminCreateProductMutation>;
  adminCreateProductFormVersion?: Maybe<AdminCreateProductFormVersionMutation>;
  /**
   * Mutation to create a new product question: either a new
   * ProductCatalogQuestionVersion of an existing ProductCatalogQuestion, or a new
   * ProductCatalogQuestionVersion of a new ProductCatalogQuestion
   *
   * Constraints for creating product question:
   * 1. If product_catalog_question_id is passed in and  matches an existing
   * ProductCatalogQuestion: create a new ProductCatalogQuestionVersion and
   * associate it with the existing ProductCatalogQuestion. The question_type
   * input must match the question_type of the existing ProductCatalogQuestion's
   * active_question_version; otherwise, raise error.
   * 2. If product_catalog_question_id is passed in and doesn't match any existing
   * ProductCatalogQuestion: raise error.
   * 3. If product_catalog_question_id is not passed in: (1) create a new
   * ProductCatalogQuestion and (2) create a new ProductCatalogQuestionVersion and
   * associate it with the newly created ProductCatalogQuestion
   */
  adminCreateProductQuestionVersion?: Maybe<AdminCreateProductQuestionVersionMutation>;
  adminCreateProductValidationRuleMappings?: Maybe<AdminCreateProductValidationRuleMappingsMutation>;
  adminCreateProvider?: Maybe<AdminCreateProviderMutation>;
  adminCreateSubcategoryProductForm?: Maybe<AdminCreateSubcategoryProductFormMutation>;
  adminCreateTicket?: Maybe<AdminCreateTicketMutation>;
  adminCreateUser?: Maybe<AdminCreateUserMutation>;
  adminDeactivateFacility?: Maybe<AdminDeactivateFacilityMutation>;
  adminDeactivatePractice?: Maybe<AdminDeactivatePracticeMutation>;
  adminDeleteDocument?: Maybe<AdminDeleteDocumentMutation>;
  /**
   * Since there's no easy way to test this functionality aside from just pushing to
   * prod, when we need to make significant changes to this mutation, we should
   * temporarily add an entirely new mutation version even though tons of code will be
   * duplicated. Doing this will protect the existing Ops process and not block them
   * from deploying questions while we work out issues with the changes.
   *
   * Handles the deploy of a single intake question and either creates
   * or updates the intake question from input data based on the sync_id
   * and history_version_sync_id.
   */
  adminDeployIntakeQuestion?: Maybe<AdminDeployIntakeQuestionMutation>;
  /** Create order coversheet for provider to sign  */
  adminDownloadProviderCoversheet?: Maybe<AdminDownloadProviderCoversheetMutation>;
  /** Regenerate coversheet to send to supplier  */
  adminDownloadSupplierCoversheet?: Maybe<AdminDownloadSupplierCoversheetMutation>;
  adminGroupAddMember?: Maybe<Scalars['Boolean']['output']>;
  adminGroupRemoveMember?: Maybe<Scalars['Boolean']['output']>;
  adminMarkOrderComplex?: Maybe<AdminMarkOrderComplexMutation>;
  /** @deprecated Box is deprecated */
  adminNetworkSupplierCreateBoxShare?: Maybe<AdminNetworkSupplierCreateBoxShare>;
  /**
   * For each supplier with a pending Box invitation, refresh the collaborations list.
   * @deprecated Box is deprecated
   */
  adminNetworkSupplierRefreshBoxAccepted?: Maybe<AdminNetworkSupplierRefreshBoxAccepted>;
  adminOpenCustomerBillingPortal?: Maybe<AdminOpenCustomerBillingPortal>;
  /**
   * Mutation to get question json based on given input data.
   *
   * Note: This will generate and return the question json and DOES NOT SAVE the
   * question to the database.
   */
  adminPreviewIntakeQuestion?: Maybe<AdminPreviewIntakeQuestionMutation>;
  adminRecordPriorAuth?: Maybe<AdminRecordPriorAuthMutation>;
  adminRemoveHcpcsCodesFromSubcategory?: Maybe<AdminRemoveHcpcsCodesFromSubcategory>;
  adminRemoveIntakeQuestionHcpcs?: Maybe<AdminRemoveIntakeQuestionHcpcsMutation>;
  adminRemoveOrderRelation?: Maybe<AdminRemoveOrderRelationMutation>;
  /** @deprecated PracticePatient is deprecated */
  adminRemovePatientsFromPractice?: Maybe<AdminRemovePatientsFromPracticeMutation>;
  adminRestartOrder?: Maybe<AdminRestartOrder>;
  adminSendRequestClinicalRulesSignatureEmail?: Maybe<AdminSendRequestClinicalRulesSignatureEmailMutation>;
  adminSendWelcomeEmail?: Maybe<AdminSendWelcomeEmail>;
  adminSignInAs?: Maybe<AdminSignInAs>;
  adminUpdateDeliveryVerification?: Maybe<AdminUpdateDeliveryVerification>;
  adminUpdateHcpcsDetails?: Maybe<AdminUpdateHcpcsDetailsMutation>;
  adminUpdateIntakeQuestion?: Maybe<AdminUpdateIntakeQuestionMutation>;
  /** @deprecated Deprecated by AdminBulkUpdateIntakeQuestionHcpcs */
  adminUpdateIntakeQuestionHcpcs?: Maybe<AdminUpdateIntakeQuestionHcpcsMutation>;
  adminUpdateIntakeSubcategory?: Maybe<AdminUpdateIntakeSubcategoryMutation>;
  adminUpdateNetworkSupplier?: Maybe<AdminUpdateNetworkSupplier>;
  adminUpdateNetworkSupplierCoveredInsurer?: Maybe<AdminUpdateNetworkSupplierCoveredInsurer>;
  adminUpdateNetworkSupplierDb?: Maybe<AdminUpdateNetworkSupplierDb>;
  adminUpdateOrCreateOrderStatusReason?: Maybe<AdminUpdateOrCreateOrderStatusReasonMutation>;
  adminUpdateOrCreatePatient?: Maybe<AdminUpdateOrCreatePatientMutation>;
  adminUpdateOrCreatePatientInsurance?: Maybe<AdminUpdateOrCreatePatientInsuranceMutation>;
  adminUpdateOrderAssignment?: Maybe<AdminUpdateOrderAssignment>;
  adminUpdateOrderFacility?: Maybe<AdminUpdateOrderFacilityMutation>;
  adminUpdateOrderPractice?: Maybe<AdminUpdateOrderPracticeMutation>;
  adminUpdatePatientInsurance?: Maybe<AdminUpdatePatientInsuranceMutation>;
  adminUpdatePractice?: Maybe<AdminUpdatePracticeMutation>;
  adminUpdateProduct?: Maybe<AdminUpdateProductMutation>;
  adminUpdateProvider?: Maybe<AdminUpdateProviderMutation>;
  adminUpdateUser?: Maybe<AdminUpdateUserMutation>;
  /** Mutation to assign a DocumentExtractionJob to a supplier for exception handling */
  assignOrgDocumentExtractionJob?: Maybe<AssignOrgDocumentExtractionJobMutation>;
  /** Mutation to assign a DocumentExtractionJob to a specific org user for exception handling */
  assignOrgUserDocumentExtractionJob?: Maybe<AssignOrgUserDocumentExtractionJobMutation>;
  /** Allows a logged in user to bulk upload claim approvals for an insurer */
  bulkClaimApprovalUpload?: Maybe<BulkClaimApprovalUpload>;
  /**
   * This is a one off mutation that allows TH staff to submit updated
   * rankings for supplier categories. This infers what to create or delete by
   * doing a diff between the supplier categories provided and what exists.
   */
  bulkUpdateSupplierCategoryRankings?: Maybe<BulkUpdateSupplierCategoryRankings>;
  /**
   * Mutation to allow TH staff to update quality metrics for a given supplier and
   * category.
   */
  bulkUpdateSupplierQualityMetrics?: Maybe<BulkUpdateSupplierQualityMetrics>;
  cancelOrder?: Maybe<CancelOrderMutation>;
  completeOrder?: Maybe<CompleteOrderMutation>;
  createAgreementMutation?: Maybe<CreateAgreementMutation>;
  /** @deprecated Deprecated in favor of adminUpdateCatalog */
  createDocument?: Maybe<CreateDocumentMutation>;
  /**
   * Allows an org user to submit free-text feedback on the quality of our extraction. As of 6/5/2024, this
   * will only be used for synthpop clinical summary feedback.
   */
  createExtractionFeedbackMutation?: Maybe<CreateExtractionFeedbackMutation>;
  createIntakeValidationRule?: Maybe<CreateIntakeValidationRuleMutation>;
  createNetworkSupplierLocation?: Maybe<CreateNetworkSupplierLocation>;
  createOrderComment?: Maybe<CreateOrderCommentMutation>;
  createOrgPatient?: Maybe<CreateOrgPatientMutation>;
  createOrgUserDelegation?: Maybe<CreateOrgUserDelegationMutation>;
  createPatientInsurance?: Maybe<CreatePatientInsuranceMutation>;
  createPatientSignature?: Maybe<CreatePatientSignatureMutation>;
  /** @deprecated PracticePatient is deprecated */
  createPracticePatient?: Maybe<CreatePracticePatientMutation>;
  createPriorAuthRequest?: Maybe<CreatePriorAuthRequestMutation>;
  createPriorAuthRequestSubmission?: Maybe<CreatePriorAuthRequestSubmissionMutation>;
  createPriorAuthResponse?: Maybe<CreatePriorAuthResponseMutation>;
  /**
   * Create a Stripe SetupIntent object for the Stripe Customer associated with
   * the current user. Requires login.
   *
   * Callers probably will use the clientSecret to pass to Stripe Elements to
   * create a PaymentMethod.
   */
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntent>;
  createSupplierPatientMessage?: Maybe<CreateSupplierPatientMessageMutation>;
  deleteComment?: Maybe<DeleteCommentMutation>;
  downloadOrderAsPdf?: Maybe<DownloadOrderAsPdfMutation>;
  downloadPriorAuthorizationPdf?: Maybe<DownloadPriorAuthorizationPdfMutation>;
  downloadTitleXixFormPdf?: Maybe<DownloadTitleXixFormPdfMutation>;
  editComment?: Maybe<EditCommentMutation>;
  ehrLinkPatient?: Maybe<EhrLinkPatientMutation>;
  faxPriorAuthRequestToInsurer?: Maybe<FaxPriorAuthRequestToInsurerMutation>;
  /**
   * Mutation to manually initiate document extraction job.
   *
   * This expects that the pdf has already been uploaded to the s3 bucket indicated
   * by ORDER_INTAKE_DOCUMENT_S3_BUCKET
   * (tomorrow-health-{settings.APP_ENV}-order-intake-documents-us-east-2).
   * As such, this does not take in a bucket name.
   *
   * This was built for a time-constrained demo and can use some cleanup. In the demo
   * context, this was called by a lambda function.
   */
  initiateDocumentExtractionJob?: Maybe<InitiateDocumentExtractionJobMutation>;
  markPriorAuthRequestSubmissionAsSubmitted?: Maybe<MarkPriorAuthRequestSubmissionAsSubmittedMutation>;
  omniCreateOrder?: Maybe<OmniCreateOrderMutation>;
  omniSubmitOrderForRouting?: Maybe<OmniSubmitOrderForRoutingMutation>;
  omniUpdateOrder?: Maybe<OmniUpdateOrderMutation>;
  openCustomerBillingPortal?: Maybe<OpenCustomerBillingPortal>;
  orgReadAllCommentsOnOrder?: Maybe<OrgReadAllCommentsOnOrderMutation>;
  /** Mutation to allow a supplier to unassign themselves from a DocumentExtractionJob */
  orgRevokeDocumentExtractionJob?: Maybe<OrgRevokeDocumentExtractionJobMutation>;
  orgUserAcceptInvite?: Maybe<OrgUserAcceptInvite>;
  /** Generates a faxback cover sheet for a given order seed, unment requirements override, and additional comments */
  previewFaxbackCoversheet?: Maybe<PreviewFaxbackCoversheetMutation>;
  readAllAppNotifications?: Maybe<ReadAllAppNotificationsMutation>;
  readAppNotification?: Maybe<ReadAppNotificationMutation>;
  recordCareCoordinationEvent?: Maybe<RecordCareCoordinationEventMutation>;
  recordStakeholderEngagement?: Maybe<AdminRecordStakeholderEngagementMutation>;
  refreshToken?: Maybe<Refresh>;
  removeNetworkSupplierLocation?: Maybe<RemoveNetworkSupplierLocation>;
  removeOrgUser?: Maybe<RemoveOrgUserMutation>;
  removeOrgUserDelegation?: Maybe<RemoveOrgUserDelegationMutation>;
  /**
   * This is a SOFT deletion of the document used by the supplier. It
   * does not actually delete the document on S3.
   */
  removeSupplierDocument?: Maybe<RemoveSupplierDocument>;
  resendOrgUserInvite?: Maybe<ResendOrgUserInviteMutation>;
  /**
   * Enables suppliers to send a fax manually with additional comments
   * using the same faxback template as for the automated faxback flow.
   */
  sendFaxbackCoversheet?: Maybe<SendFaxbackCoversheetMutation>;
  sendPreOrderEmail?: Maybe<SendPreOrderEmail>;
  /**
   * Mutation to resend message (Email or SMS based on signing method)
   * requesting the provider (signer) to sign the order.
   */
  sendReminderToSignOrder?: Maybe<SendSignatureReminderMutation>;
  /**
   * This mutation is DEPRECATED, please use UpdateOrderSupplierAttributesMutation
   * instead
   *
   * This mutation sets the location on the current assignment of a given order
   *
   * This mutation takes in an order ID and a location ID and sets the location of the
   * order's current assignment to the specified location. It verifies that the order ID
   * is valid and that the current user has permissions to modify the current assignment
   * of the order in question. It also verifies that the location ID is valid and that
   * the location is not deleted for the supplier of the current assignment. If the
   * location ID passed in is an empty string, then we unset the location on the current
   * assignment. Ideally, this would be specifically null for this case instead of an
   * empty string, but our current version of graphene doesn't support explicit null
   * values (was added in 3.0.0).
   * @deprecated Please use updateOrderSupplierAttributes instead
   */
  setOrderLocation?: Maybe<SetOrderLocationMutation>;
  /**
   * Sets an order seed into a terminal state without seeding info into an order.
   *
   * In the event that an order seed is generated from a source that legitimately does
   * not have enough info to identify an order or a patient, this mutation allows a user
   * to mark the seed as unviable to seed an order so that we know someone actioned on
   * the order seed and can stop emitting nudge tickets for it.
   *
   * This also will set the associated document extraction job into a terminal state
   *
   * TODO: Add ticketing logic here instead of doing it through a django signal
   * https://tomorrowhealth.atlassian.net/browse/ABS-118
   */
  setOrderSeedUnviable?: Maybe<SetOrderSeedUnviableMutation>;
  /**
   * Mutation to sign an agreement by an OrgUser.
   *
   * The OrgUser needs to be associated with the org they are signing for.
   * The user should only be signing the latest version of an agreement.
   * A user should not be signing the same agreement for the same org.
   */
  signOrgAgreement?: Maybe<SignOrgAgreementMutation>;
  /**
   * Mutation to split a Document of a DocumentExtractionJob into multiple
   *
   * When a human sees that the document contains information pertaining to multiple
   * orders, this allows them to select which pages belong to which order and re-enqueue
   * those individual documents back to the top of the extraction process.
   */
  splitDocumentExtraction?: Maybe<SplitDocumentExtractionMutation>;
  /**
   * Allows a user to submit new prompts to our AI client (currently OpenAI).
   *
   * This supports both one-shot prompts where all the information OpenAI needs is within
   * a single prompt as well as multi-shot prompts where the user can pass a thread
   * history to OpenAI for context.
   *
   * For now, will use the default model, temperature, and top_p since it's unlikely
   * needed at this point.
   *
   * TODO: support using custom models and temp/top_p
   * https://tomorrowhealth.atlassian.net/browse/ABS-181
   */
  submitAiPromptMutation?: Maybe<SubmitAiPromptMutation>;
  submitDocumentationSurveyResponse?: Maybe<SupplierSubmitDocumentationSurveyResponseMutation>;
  supplierAcceptOrder?: Maybe<SupplierAcceptOrder>;
  supplierCancelOrder?: Maybe<SupplierCancelOrder>;
  supplierCreateOrder?: Maybe<SupplierCreateOrderMutation>;
  supplierRejectOrder?: Maybe<SupplierRejectOrder>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  /**
   * This "update" mutation is a bit tricky because it may either:
   *     1) create a new version of a rule, which consists of creating a new row in
   *         the rules table with a history_version_id that matches the old rule, or
   *     2) perform more of a true "update" where no new rows in the rules table are
   *         created. This should only happen if none of the properties of the rule are
   *         being updated **except** for the `valid_from` and `valid_to` dates.
   *
   * After rule version update or new rule version creation, this will return all
   * versions of this rule whose valid dates overlap with each other.
   */
  updateIntakeValidationRule?: Maybe<UpdateIntakeValidationRuleMutation>;
  /**
   * This mutation updates attributes of the network supplier. Initially it
   * is used to bulk update zipcode and hcpcs coverage by users. The bulk updates
   * do an inference to determine which entities need to be created or deleted
   * based on lists of coverages passed into the mutation.
   *
   * Admin as well as supplier users are able to update a supplier. This mutation
   * updates list attributes while AdminUpdateNetworkSupplier updates singular and
   * list attributes. Eventually these should be combined to allow admins and DME users
   * to update their respective supplier attributes. Refer to THP-2115.
   */
  updateNetworkSupplier?: Maybe<UpdateNetworkSupplier>;
  updateNetworkSupplierLocation?: Maybe<UpdateNetworkSupplierLocation>;
  updateOrderDocument?: Maybe<UpdateOrderDocumentMutation>;
  updateOrderRequirement?: Maybe<AdminUpdateOrderRequirementMutation>;
  /** Mutation to update an OrderSeed's corrected extraction results. */
  updateOrderSeed?: Maybe<UpdateOrderSeedMutation>;
  /** Mutation for allowing suppliers to update attributes on an order that they are permitted to update */
  updateOrderSupplierAttributes?: Maybe<UpdateOrderSupplierAttributesMutation>;
  /**
   * Updates a patient that is already associated with the org.  This is a patch-style
   * update, so passing null for a field will remove/nullify it on the patient. Omitting
   * an input field will leave it unchanged on the patient. Note that homePhoneNumber,
   * sex, address, and primaryInsurance cannot be passed in as null.
   */
  updateOrgPatient?: Maybe<UpdateOrgPatientMutation>;
  updateOrgUser?: Maybe<UpdateOrgUserMutation>;
  updatePatient?: Maybe<UpdatePatientMutation>;
  /** @deprecated PracticePatient is deprecated */
  updatePracticePatient?: Maybe<UpdatePracticePatientMutation>;
  updateUserName?: Maybe<UpdateUserNameMutation>;
  updateUserNotificationPreferences?: Maybe<UpdateUserNotificationPreferences>;
  validateOrderSignerToken?: Maybe<ValidateOrderSignerTokenMutation>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAddPatientToOrgArgs = {
  orgId: Scalars['UUID']['input'];
  patientId: Scalars['UUID']['input'];
};

export type MutationAddSupplierDocumentsArgs = {
  input: AddSupplierDocumentsInput;
};

export type MutationAddUserToOrgArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  orgType?: InputMaybe<OrgTypes>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAdminAddDefectDefinitionArgs = {
  input: DefectDefinitionInput;
};

export type MutationAdminAddEhrSystemToOrgArgs = {
  ehrSystemId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type MutationAdminAddHcpcsCodesToSubcategoryArgs = {
  input: SubcategoryHcpcsInput;
};

export type MutationAdminAddPatientsToPracticeArgs = {
  patientIds: Array<Scalars['ID']['input']>;
  practiceId: Scalars['ID']['input'];
};

export type MutationAdminAssignOwnerArgs = {
  input: AssignOwnerInput;
};

export type MutationAdminAutoRouteOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationAdminBulkUpdateIntakeQuestionHcpcsArgs = {
  intakeQuestionHcpcsIds: Array<Scalars['ID']['input']>;
  validFrom: Scalars['DateTime']['input'];
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
  validationRuleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationAdminBulkUpdateNetworkSupplierCoveredInsurersArgs = {
  insurerUpdates: Array<UpdateNetworkSupplierCoveredInsurerInput>;
  networkSupplierId: Scalars['ID']['input'];
};

export type MutationAdminBulkUpdateProductValidationRuleMappingsArgs = {
  inputData: BulkUpdateProductValidationRuleMappingsInput;
};

export type MutationAdminBulkUpdateValidationRuleMappingsArgs = {
  inputData: BulkUpdateValidationRuleMappingsInput;
};

export type MutationAdminCancelNetworkOrderArgs = {
  orderId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AdminCancelNetworkOrderType>;
};

export type MutationAdminChangeSigningMethodArgs = {
  input: AdminChangeSigningMethodInput;
};

export type MutationAdminChargeInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminCheckPatientInsuranceEligibilityArgs = {
  patientInsuranceId: Scalars['ID']['input'];
};

export type MutationAdminCloneIntakeQuestionArgs = {
  intakeQuestionId: Scalars['ID']['input'];
  questionAttributes?: InputMaybe<IntakeQuestionAttributesInput>;
  questionJson?: InputMaybe<Scalars['JSONString']['input']>;
  setAllValidToToNone?: InputMaybe<Scalars['Boolean']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationAdminCloneIntakeQuestionVersionArgs = {
  intakeQuestionId: Scalars['ID']['input'];
  questionAttributes?: InputMaybe<IntakeQuestionAttributesInput>;
  questionJson?: InputMaybe<Scalars['JSONString']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationAdminCloneMappingsForInsurerHcpcsArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  hcpcsCode: Scalars['String']['input'];
  insurerId: Scalars['ID']['input'];
};

export type MutationAdminCompleteNetworkOrderArgs = {
  orderId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminCreateBoxCommentArgs = {
  input: CreateBoxCommentInput;
};

export type MutationAdminCreateCareCoordinationArgs = {
  name: Scalars['String']['input'];
};

export type MutationAdminCreateEhrSystemArgs = {
  input: AdminCreateEhrSystemInput;
};

export type MutationAdminCreateIntakeQuestionArgs = {
  input: IntakeQuestionInput;
};

export type MutationAdminCreateIntakeQuestionHcpcsArgs = {
  insurerHcpcsMap: Array<InputMaybe<IntakeQuestionHcpcsInput>>;
  intakeQuestionId: Scalars['ID']['input'];
};

export type MutationAdminCreateNetworkSupplierArgs = {
  input: AdminCreateNetworkSupplierInput;
};

export type MutationAdminCreateOrUpdateInsurerArgs = {
  input: InsurerInput;
};

export type MutationAdminCreateOrUpdateWaiverArgs = {
  input: WaiverInput;
};

export type MutationAdminCreateOrderDocumentsArgs = {
  input: CreateOrderDocumentsInput;
};

export type MutationAdminCreatePracticeArgs = {
  input: AdminCreatePracticeInput;
};

export type MutationAdminCreateProductArgs = {
  input: ProductInput;
};

export type MutationAdminCreateProductFormVersionArgs = {
  input: ProductFormVersionInput;
};

export type MutationAdminCreateProductQuestionVersionArgs = {
  input: ProductQuestionVersionInput;
};

export type MutationAdminCreateProductValidationRuleMappingsArgs = {
  input: ProductValidationRuleMappingsInput;
};

export type MutationAdminCreateProviderArgs = {
  npi?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminCreateSubcategoryProductFormArgs = {
  productForm?: InputMaybe<Scalars['JSONString']['input']>;
  productFormAttributes?: InputMaybe<Array<InputMaybe<ProductItemInput>>>;
  subcategoryId: Scalars['ID']['input'];
};

export type MutationAdminCreateTicketArgs = {
  input: CreateTicketInput;
};

export type MutationAdminCreateUserArgs = {
  input: AdminCreateUserInput;
};

export type MutationAdminDeactivateFacilityArgs = {
  facilityId: Scalars['ID']['input'];
};

export type MutationAdminDeactivatePracticeArgs = {
  practiceId: Scalars['String']['input'];
};

export type MutationAdminDeleteDocumentArgs = {
  documentId: Scalars['ID']['input'];
};

export type MutationAdminDeployIntakeQuestionArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  input: DeployIntakeQuestionInput;
};

export type MutationAdminDownloadProviderCoversheetArgs = {
  orderId: Scalars['UUID']['input'];
};

export type MutationAdminDownloadSupplierCoversheetArgs = {
  orderId: Scalars['UUID']['input'];
};

export type MutationAdminGroupAddMemberArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAdminGroupRemoveMemberArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAdminMarkOrderComplexArgs = {
  isComplex: Scalars['Boolean']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationAdminNetworkSupplierCreateBoxShareArgs = {
  supplierId: Scalars['ID']['input'];
};

export type MutationAdminOpenCustomerBillingPortalArgs = {
  customerId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type MutationAdminPreviewIntakeQuestionArgs = {
  questionAttributes: IntakeQuestionAttributesInput;
};

export type MutationAdminRecordPriorAuthArgs = {
  inputData: AdminRecordPriorAuthInput;
};

export type MutationAdminRemoveHcpcsCodesFromSubcategoryArgs = {
  input: SubcategoryHcpcsInput;
};

export type MutationAdminRemoveIntakeQuestionHcpcsArgs = {
  intakeQuestionHcpcsId: Scalars['ID']['input'];
};

export type MutationAdminRemoveOrderRelationArgs = {
  input: RemoveOrderRelationInput;
};

export type MutationAdminRemovePatientsFromPracticeArgs = {
  patientIds: Array<Scalars['ID']['input']>;
  practiceId: Scalars['ID']['input'];
};

export type MutationAdminRestartOrderArgs = {
  orderId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminSendRequestClinicalRulesSignatureEmailArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationAdminSendWelcomeEmailArgs = {
  createdByPhone?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddress: Scalars['String']['input'];
};

export type MutationAdminSignInAsArgs = {
  emailAddress: Scalars['String']['input'];
};

export type MutationAdminUpdateDeliveryVerificationArgs = {
  assignmentId: Scalars['ID']['input'];
  verify: Scalars['Boolean']['input'];
};

export type MutationAdminUpdateHcpcsDetailsArgs = {
  hcpcsInput: HcpcsInput;
};

export type MutationAdminUpdateIntakeQuestionArgs = {
  historyVersionSyncId?: InputMaybe<Scalars['ID']['input']>;
  intakeQuestionId: Scalars['ID']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  questionAttributes?: InputMaybe<IntakeQuestionAttributesInput>;
  questionJson?: InputMaybe<Scalars['JSONString']['input']>;
  syncId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAdminUpdateIntakeQuestionHcpcsArgs = {
  intakeQuestionHcpcsId: Scalars['ID']['input'];
  validFrom: Scalars['DateTime']['input'];
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationAdminUpdateIntakeSubcategoryArgs = {
  input: UpdateSubcategoryInput;
};

export type MutationAdminUpdateNetworkSupplierArgs = {
  input: AdminUpdateNetworkSupplierInput;
};

export type MutationAdminUpdateNetworkSupplierCoveredInsurerArgs = {
  enabled: Scalars['Boolean']['input'];
  insurerId: Scalars['ID']['input'];
  networkSupplierId: Scalars['ID']['input'];
};

export type MutationAdminUpdateNetworkSupplierDbArgs = {
  dryRun: Scalars['Boolean']['input'];
  ignoreInvalidHcpcs?: InputMaybe<Scalars['Boolean']['input']>;
  redownloadOk?: InputMaybe<Scalars['Boolean']['input']>;
  s3Bucket: Scalars['String']['input'];
  s3Key?: InputMaybe<Scalars['String']['input']>;
  s3Prefix?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminUpdateOrCreateOrderStatusReasonArgs = {
  input: OrderStatusReasonInput;
};

export type MutationAdminUpdateOrCreatePatientArgs = {
  input: PatientInput;
};

export type MutationAdminUpdateOrCreatePatientInsuranceArgs = {
  input: PatientInsuranceInput;
};

export type MutationAdminUpdateOrderAssignmentArgs = {
  input: AdminUpdateOrderAssignmentInput;
};

export type MutationAdminUpdateOrderFacilityArgs = {
  input: AdminUpdateOrderFacilityMutationInput;
};

export type MutationAdminUpdateOrderPracticeArgs = {
  input: AdminUpdateOrderPracticeMutationInput;
};

export type MutationAdminUpdatePatientInsuranceArgs = {
  input: UpdatePatientInsuranceInput;
};

export type MutationAdminUpdatePracticeArgs = {
  input: AdminUpdatePracticeInput;
};

export type MutationAdminUpdateProductArgs = {
  input: ProductInput;
};

export type MutationAdminUpdateProviderArgs = {
  inputData: AdminUpdateProviderInput;
};

export type MutationAdminUpdateUserArgs = {
  inputData: AdminUpdateUserInput;
};

export type MutationAssignOrgDocumentExtractionJobArgs = {
  inputData: AssignOrgDocumentExtractionJobInput;
};

export type MutationAssignOrgUserDocumentExtractionJobArgs = {
  inputData: AssignOrgUserDocumentExtractionJobInput;
};

export type MutationBulkClaimApprovalUploadArgs = {
  input: BulkClaimApprovalUploadInput;
};

export type MutationBulkUpdateSupplierCategoryRankingsArgs = {
  input: Array<InputMaybe<UpdateSupplierCategoryRankingsInput>>;
};

export type MutationBulkUpdateSupplierQualityMetricsArgs = {
  input: Array<InputMaybe<UpdateSupplierQualityMetricsInput>>;
};

export type MutationCancelOrderArgs = {
  cancelInput: CancelOrderInput;
};

export type MutationCompleteOrderArgs = {
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  completedLineItems?: InputMaybe<Array<CompletedLineItemInput>>;
  orderId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type MutationCreateAgreementMutationArgs = {
  input: CreateAgreementInput;
};

export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};

export type MutationCreateExtractionFeedbackMutationArgs = {
  inputData: ExtractionFeedbackInput;
};

export type MutationCreateIntakeValidationRuleArgs = {
  input: IntakeValidationRuleInput;
};

export type MutationCreateNetworkSupplierLocationArgs = {
  input: CreateNetworkSupplierLocationInput;
};

export type MutationCreateOrderCommentArgs = {
  input: CreateOrderCommentInput;
};

export type MutationCreateOrgPatientArgs = {
  input: CreateOrgPatientInput;
};

export type MutationCreateOrgUserDelegationArgs = {
  authorizerUserIds: Array<Scalars['ID']['input']>;
  delegateUserId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type MutationCreatePatientInsuranceArgs = {
  input: CreatePatientInsuranceInput;
};

export type MutationCreatePracticePatientArgs = {
  input: CreatePracticePatientInput;
};

export type MutationCreatePriorAuthRequestArgs = {
  input: PriorAuthRequestInput;
};

export type MutationCreatePriorAuthRequestSubmissionArgs = {
  input: PriorAuthRequestSubmissionInput;
};

export type MutationCreatePriorAuthResponseArgs = {
  inputData: PriorAuthResponseInput;
};

export type MutationCreateSupplierPatientMessageArgs = {
  input: CreateSupplierPatientMessageInput;
};

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationDownloadOrderAsPdfArgs = {
  orderId: Scalars['String']['input'];
};

export type MutationDownloadPriorAuthorizationPdfArgs = {
  priorAuthRequestSubmissionId: Scalars['UUID']['input'];
};

export type MutationDownloadTitleXixFormPdfArgs = {
  orderId?: InputMaybe<Scalars['UUID']['input']>;
  priorAuthRequestId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationEditCommentArgs = {
  input: EditCommentInput;
};

export type MutationEhrLinkPatientArgs = {
  input: EhrLinkPatientInput;
};

export type MutationFaxPriorAuthRequestToInsurerArgs = {
  inputData: FaxPriorAuthRequestInput;
};

export type MutationInitiateDocumentExtractionJobArgs = {
  inputData: InitiateDocumentExtractionJobInput;
};

export type MutationMarkPriorAuthRequestSubmissionAsSubmittedArgs = {
  inputData: MarkPriorAuthRequestSubmissionAsSubmittedInput;
};

export type MutationOmniCreateOrderArgs = {
  inputData: OmniCreateOrderInput;
};

export type MutationOmniSubmitOrderForRoutingArgs = {
  inputData: OmniSubmitOrderForRoutingInput;
};

export type MutationOmniUpdateOrderArgs = {
  inputData: OmniUpdateOrderInput;
};

export type MutationOrgReadAllCommentsOnOrderArgs = {
  input: OrgReadAllCommentsOnOrderInput;
};

export type MutationOrgRevokeDocumentExtractionJobArgs = {
  inputData: OrgRevokeDocumentExtractionJobInput;
};

export type MutationOrgUserAcceptInviteArgs = {
  orgId: Scalars['ID']['input'];
};

export type MutationPreviewFaxbackCoversheetArgs = {
  inputData: FaxbackCoversheetInput;
};

export type MutationReadAppNotificationArgs = {
  input: ReadAppNotificationInput;
};

export type MutationRecordCareCoordinationEventArgs = {
  input: RecordCareCoordinationEventInput;
};

export type MutationRecordStakeholderEngagementArgs = {
  inputData: RecordStakeholderEngagementInput;
};

export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveNetworkSupplierLocationArgs = {
  input: RemoveNetworkSupplierLocationInput;
};

export type MutationRemoveOrgUserArgs = {
  orgId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveOrgUserDelegationArgs = {
  authorizerUserIds: Array<Scalars['ID']['input']>;
  delegateUserId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type MutationRemoveSupplierDocumentArgs = {
  input: RemoveSupplierDocumentInput;
};

export type MutationResendOrgUserInviteArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSendFaxbackCoversheetArgs = {
  inputData: FaxbackCoversheetInput;
};

export type MutationSendPreOrderEmailArgs = {
  input: CreatePreOrderInput;
};

export type MutationSendReminderToSignOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationSetOrderLocationArgs = {
  input: SetOrderLocationMutationInput;
};

export type MutationSetOrderSeedUnviableArgs = {
  orderSeedId: Scalars['ID']['input'];
};

export type MutationSignOrgAgreementArgs = {
  agreementId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type MutationSplitDocumentExtractionArgs = {
  inputData: SplitDocumentExtractionInput;
};

export type MutationSubmitAiPromptMutationArgs = {
  inputData: SubmitAiPromptInput;
};

export type MutationSubmitDocumentationSurveyResponseArgs = {
  input: SupplierSubmitDocumentationSurveyResponseInput;
};

export type MutationSupplierAcceptOrderArgs = {
  estimatedDeliveryAt?: InputMaybe<Scalars['DateTime']['input']>;
  lineItemEstimatedDeliveryAt?: InputMaybe<Array<InputMaybe<EstimatedDeliveryLineItemInput>>>;
  orderId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type MutationSupplierCancelOrderArgs = {
  input: SupplierCancelOrderInput;
};

export type MutationSupplierCreateOrderArgs = {
  input: SupplierCreateOrderInput;
};

export type MutationSupplierRejectOrderArgs = {
  input: SupplierRejectOrderInput;
};

export type MutationTokenAuthArgs = {
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdateIntakeValidationRuleArgs = {
  input: IntakeValidationRuleInput;
};

export type MutationUpdateNetworkSupplierArgs = {
  input: UpdateNetworkSupplierInput;
};

export type MutationUpdateNetworkSupplierLocationArgs = {
  input: UpdateNetworkSupplierLocationInput;
};

export type MutationUpdateOrderDocumentArgs = {
  input: UpdateOrderDocumentMutationInput;
};

export type MutationUpdateOrderRequirementArgs = {
  inputData: UpdateOrderRequirementInput;
};

export type MutationUpdateOrderSeedArgs = {
  inputData: UpdateOrderSeedInput;
};

export type MutationUpdateOrderSupplierAttributesArgs = {
  input: UpdateOrderSupplierAttributesMutationInput;
};

export type MutationUpdateOrgPatientArgs = {
  input: UpdateOrgPatientInput;
};

export type MutationUpdateOrgUserArgs = {
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};

export type MutationUpdatePracticePatientArgs = {
  input: UpdatePracticePatientInput;
};

export type MutationUpdateUserNameArgs = {
  input: UpdateUserNameInput;
};

export type MutationUpdateUserNotificationPreferencesArgs = {
  input: UpdateUserNotificationPreferencesInput;
};

export type MutationValidateOrderSignerTokenArgs = {
  orderId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type NpiRecordType = {
  __typename?: 'NPIRecordType';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is deprecated. Use npiNumber instead. */
  npi?: Maybe<Scalars['Int']['output']>;
  /** NPI associated with the provider. */
  npiNumber?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['ID']['output']>;
  sms?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type NpiSearchResultsType = {
  __typename?: 'NPISearchResultsType';
  error?: Maybe<ErrorType>;
  results?: Maybe<Array<Maybe<NpiRecordType>>>;
};

export type NetworkMetricValueType = {
  __typename?: 'NetworkMetricValueType';
  metric: MetricType;
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type NetworkSupplierCategoryType = {
  __typename?: 'NetworkSupplierCategoryType';
  category: IntakeCategoryType;
  id: Scalars['UUID']['output'];
  networkSupplier: OptimizedNetworkSupplierType;
  qualityScore?: Maybe<Scalars['Float']['output']>;
  qualityThresholdRatio?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type NetworkSupplierCoveredInsurerInput = {
  id: Scalars['UUID']['input'];
};

export type NetworkSupplierHcpcsInput = {
  code: Scalars['String']['input'];
};

export type NetworkSupplierHcpcsType = {
  __typename?: 'NetworkSupplierHcpcsType';
  hcpcsCode: HcpcsType;
  networkSupplier: OptimizedNetworkSupplierType;
};

export type NetworkSupplierLocationType = {
  __typename?: 'NetworkSupplierLocationType';
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  supplier: OptimizedNetworkSupplierType;
};

export type NetworkSupplierSlimType = {
  __typename?: 'NetworkSupplierSlimType';
  coveredHcpcs: Array<NetworkSupplierHcpcsType>;
  coveredInsurers?: Maybe<Array<Maybe<InsurerType>>>;
  coveredZipcodes: Array<NetworkSupplierZipcodeType>;
  id: Scalars['UUID']['output'];
};

export type NetworkSupplierType = {
  __typename?: 'NetworkSupplierType';
  /** Whether orders will be automatically accepted */
  autoAccept: Scalars['Boolean']['output'];
  /** Whether supplier is able to be assigned DocumentExtractionJobs */
  automatedFaxEnabled: Scalars['Boolean']['output'];
  /** Whether supplier is considered for automatic routing */
  automaticEnabled: Scalars['Boolean']['output'];
  billingTins?: Maybe<Array<Scalars['String']['output']>>;
  boxAdminEmail?: Maybe<Scalars['String']['output']>;
  boxInvitationAccepted: Scalars['Boolean']['output'];
  boxInvitationSentAt?: Maybe<Scalars['DateTime']['output']>;
  categories: Array<NetworkSupplierCategoryType>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  coveredHcpcs: Array<NetworkSupplierHcpcsType>;
  coveredInsurers?: Maybe<InsurerSlimTypeConnection>;
  coveredZipcodes: Array<NetworkSupplierZipcodeType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  intakePhoneNumber?: Maybe<Scalars['String']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to nudge supplier for stale orders needing POD */
  isProofOfDeliveryBoxNudgeEnabled: Scalars['Boolean']['output'];
  /** Whether supplier is required to upload POD documents */
  isProofOfDeliveryRequired: Scalars['Boolean']['output'];
  /** Whether supplier is a Tomorrow Health Direct supplier */
  isThdSupplier: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  npi?: Maybe<Scalars['String']['output']>;
  opsId: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  servicingCapability?: Maybe<AppNetworkSupplierServicingCapabilityChoices>;
  sftpUsername?: Maybe<Scalars['String']['output']>;
  statewideShip: Scalars['Boolean']['output'];
  /** ('Default timezone for SFTP uploads (and TODO for business hours logic)',) */
  timezone?: Maybe<AppNetworkSupplierTimezoneChoices>;
  tin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkSupplierTypeCoveredInsurersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type NetworkSupplierZipcodeInput = {
  urgentOk?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode: Scalars['String']['input'];
};

export type NetworkSupplierZipcodeType = {
  __typename?: 'NetworkSupplierZipcodeType';
  networkSupplier: OptimizedNetworkSupplierType;
  urgentOk: Scalars['Boolean']['output'];
  zipcode: Scalars['String']['output'];
};

export type NotificationPreferenceInput = {
  appEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  emailEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  notificationPreferenceId: Scalars['String']['input'];
};

/**
 * Notification Preference Type does not expose the User field as
 * its parent is the UserType.
 */
export type NotificationPreferenceType = {
  __typename?: 'NotificationPreferenceType';
  appEnabled?: Maybe<Scalars['Boolean']['output']>;
  emailEnabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  templateInfo?: Maybe<TemplateInfoType>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

/**
 * This Graphql input type wraps the above sub input types into a single type
 * for use in the omni order create mutation
 */
export type OmniCreateOrderInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  defectInput?: InputMaybe<OmniDefectInput>;
  dischargeInput?: InputMaybe<OmniDischargeInput>;
  documentInput?: InputMaybe<OmniDocumentInput>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<FulfilledByEnum>;
  intakeAnswerAndSignerInput?: InputMaybe<OmniIntakeAnswerAndSignerInput>;
  /** Whether the changes made are related to an in-progress call (used to determine whether to merge messages to insurer) */
  isRelatedToInProgressCall?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderAddressInput?: InputMaybe<OmniOrderAddressInput>;
  orderProductsInput?: InputMaybe<OmniOrderProductsInput>;
  orderSeedId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  prescriptionInput?: InputMaybe<OmniPrescriptionInput>;
  referralInput?: InputMaybe<OmniReferralInput>;
  serviceType?: InputMaybe<AppOrderServiceTypeChoices>;
  source: AppOrderSourceChoices;
  status?: InputMaybe<StatusEnum>;
  subcategoryId?: InputMaybe<Scalars['ID']['input']>;
  submitForReview?: InputMaybe<Scalars['Boolean']['input']>;
  supplierPreferenceInput?: InputMaybe<OmniSupplierPreferenceInput>;
  /** If the changes made are related to an in-progress call, caller should provide the Ticket ID for the call (if available) to aid in merging messages to insurer */
  ticketId?: InputMaybe<Scalars['UUID']['input']>;
};

export type OmniCreateOrderMutation = {
  __typename?: 'OmniCreateOrderMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains intake defect related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.DefectInput
 */
export type OmniDefectInput = {
  defectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  removeDefectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains discharge related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.DischargeInput
 */
export type OmniDischargeInput = {
  careManagerContactInfo?: InputMaybe<Scalars['String']['input']>;
  careManagerName?: InputMaybe<Scalars['String']['input']>;
  dischargeAt?: InputMaybe<Scalars['DateTime']['input']>;
  dischargeAtDate?: InputMaybe<Scalars['Date']['input']>;
  dischargeInfoJson?: InputMaybe<Scalars['JSONString']['input']>;
  emergencyContact?: InputMaybe<PersonInput>;
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>;
  recentlyCovidPositive?: InputMaybe<Scalars['Boolean']['input']>;
  serviceType?: InputMaybe<AppOrderServiceTypeChoices>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains document related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.DocumentInput
 */
export type OmniDocumentInput = {
  additionalDocuments?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  primaryDocument?: InputMaybe<DocumentInput>;
  removeDocumentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  signingDocument?: InputMaybe<DocumentInput>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains CR and signer related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.IntakeAnswerAndSignerInput
 */
export type OmniIntakeAnswerAndSignerInput = {
  clinicalAnswerJson?: InputMaybe<Scalars['JSONString']['input']>;
  clinicalFormJson?: InputMaybe<Scalars['JSONString']['input']>;
  /** Whether clinical form validation was actually performed given that not all environments are able to perform the FE validation logic */
  clinicalFormValidated?: InputMaybe<Scalars['Boolean']['input']>;
  dateSigned?: InputMaybe<Scalars['Date']['input']>;
  intakeValidationRuleResults?: InputMaybe<Array<InputMaybe<IntakeValidationRuleResultInput>>>;
  /** Indicates if the request to update intake answers is submitted bya CA/admin rather than a portal user */
  isAdminRequest?: InputMaybe<Scalars['Boolean']['input']>;
  nameSigned?: InputMaybe<Scalars['String']['input']>;
  orderSignerId?: InputMaybe<Scalars['ID']['input']>;
  primaryDocument?: InputMaybe<DocumentInput>;
  productAnswerJson?: InputMaybe<Scalars['JSONString']['input']>;
  productFormJson?: InputMaybe<Scalars['JSONString']['input']>;
  /** Whether product form validation was actually performed. Needed since not all environments are able to perform the FE validation logic */
  productFormValidated?: InputMaybe<Scalars['Boolean']['input']>;
  productValidationRuleResults?: InputMaybe<Array<InputMaybe<ProductValidationRuleResultInput>>>;
  referringProviderId?: InputMaybe<Scalars['ID']['input']>;
  requiresNewSignature?: InputMaybe<Scalars['Boolean']['input']>;
  signingDocument?: InputMaybe<DocumentInput>;
  signingMethod?: InputMaybe<AppOrderSignerSigningMethodChoices>;
  submitterEmail?: InputMaybe<Scalars['String']['input']>;
  submitterName?: InputMaybe<Scalars['String']['input']>;
  submitterPhone?: InputMaybe<Scalars['String']['input']>;
  supervisingPhysician?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains order address related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.OrderAddressInput
 */
export type OmniOrderAddressInput = {
  orderAddresses?: InputMaybe<Array<InputMaybe<OrderAddressInput>>>;
  removeOrderAddressIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shippingAddress?: InputMaybe<OrderAddressInput>;
  shippingDetails?: InputMaybe<Scalars['String']['input']>;
};

export type OmniOrderClinicalInput = {
  clinicalAnswerJson?: InputMaybe<Scalars['JSONString']['input']>;
  clinicalFormJson?: InputMaybe<Scalars['JSONString']['input']>;
  /** Whether clinical form validation was actually performed given that not all environments are able to perform the FE validation logic */
  clinicalFormValidated?: InputMaybe<Scalars['Boolean']['input']>;
  intakeValidationRuleResults?: InputMaybe<Array<InputMaybe<IntakeValidationRuleResultInput>>>;
  /** Used in the OMS requests when admins update clinical information */
  requiresNewSignature?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmniOrderProductsInput = {
  /** Whether product form validation was actually performed. Needed since not all environments are able to perform the FE validation logic */
  productFormsValidated?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of Order Product Items to be created and/or updated. */
  productItems?: InputMaybe<Array<InputMaybe<OrderProductItemInput>>>;
  /** IDs of Order Product Items to be removed. */
  removeProductItemIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type OmniOrderSignerInput = {
  dateSigned?: InputMaybe<Scalars['Date']['input']>;
  nameSigned?: InputMaybe<Scalars['String']['input']>;
  /** Required to update or sign the order anonymously */
  orderSignerId?: InputMaybe<Scalars['ID']['input']>;
  primaryDocument?: InputMaybe<DocumentInput>;
  referringProviderId?: InputMaybe<Scalars['ID']['input']>;
  /** Used in the OMS requests when admin updates clinical information */
  requiresNewSignature?: InputMaybe<Scalars['Boolean']['input']>;
  signingDocument?: InputMaybe<DocumentInput>;
  signingMethod?: InputMaybe<AppOrderSignerSigningMethodChoices>;
  submitterEmail?: InputMaybe<Scalars['String']['input']>;
  submitterName?: InputMaybe<Scalars['String']['input']>;
  submitterPhone?: InputMaybe<Scalars['String']['input']>;
  supervisingPhysician?: InputMaybe<Scalars['String']['input']>;
  /** Required to update or sign the order anonymously */
  tokenId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains prescription related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.PrescriptionInput
 */
export type OmniPrescriptionInput = {
  orderLineItems?: InputMaybe<Array<InputMaybe<OrderLineItemInput>>>;
  referralDate?: InputMaybe<Scalars['Date']['input']>;
  referringProviderId?: InputMaybe<Scalars['ID']['input']>;
  removeLineItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains referral related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.ReferralInput
 */
export type OmniReferralInput = {
  /** Date of last or next scheduled appointment with referring provider */
  appointmentDate?: InputMaybe<Scalars['Date']['input']>;
  facilityId?: InputMaybe<Scalars['ID']['input']>;
  isVerbal?: InputMaybe<Scalars['Boolean']['input']>;
  practiceId?: InputMaybe<Scalars['ID']['input']>;
  referralChannel?: InputMaybe<AppOrderReferralChannelChoices>;
  referralDate?: InputMaybe<Scalars['Date']['input']>;
  referralSource?: InputMaybe<AppOrderReferralSourceChoices>;
  referrer?: InputMaybe<PersonInput>;
  referringOrgId?: InputMaybe<Scalars['ID']['input']>;
  referringProviderContact?: InputMaybe<ContactInput>;
  referringProviderId?: InputMaybe<Scalars['ID']['input']>;
};

export type OmniSubmitOrderForRoutingInput = {
  orderId: Scalars['UUID']['input'];
};

export type OmniSubmitOrderForRoutingMutation = {
  __typename?: 'OmniSubmitOrderForRoutingMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains supplier preference related information on an order and is meant to be
 * used for create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.SupplierPreferenceInput
 */
export type OmniSupplierPreferenceInput = {
  deferredSupplierSuggestion?: InputMaybe<DeferredSupplierSuggestionInput>;
  removeSupplierSuggestionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  supplierSuggestions?: InputMaybe<Array<InputMaybe<SupplierSuggestionInput>>>;
  userSupplierPreferenceNote?: InputMaybe<Scalars['String']['input']>;
};

/**
 * This Graphql input type wraps the above sub input types into a single type
 * for use in the omni order update mutation
 */
export type OmniUpdateOrderInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  defectInput?: InputMaybe<OmniDefectInput>;
  dischargeInput?: InputMaybe<OmniDischargeInput>;
  documentInput?: InputMaybe<OmniDocumentInput>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<FulfilledByEnum>;
  intakeAnswerAndSignerInput?: InputMaybe<OmniIntakeAnswerAndSignerInput>;
  /** Whether the changes made are related to an in-progress call (used to determine whether to merge messages to insurer) */
  isRelatedToInProgressCall?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderAddressInput?: InputMaybe<OmniOrderAddressInput>;
  orderClinicalInput?: InputMaybe<OmniOrderClinicalInput>;
  orderId: Scalars['ID']['input'];
  orderProductsInput?: InputMaybe<OmniOrderProductsInput>;
  orderSeedId?: InputMaybe<Scalars['ID']['input']>;
  orderSignerInput?: InputMaybe<OmniOrderSignerInput>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  prescriptionInput?: InputMaybe<OmniPrescriptionInput>;
  referralInput?: InputMaybe<OmniReferralInput>;
  serviceType?: InputMaybe<AppOrderServiceTypeChoices>;
  source?: InputMaybe<AppOrderSourceChoices>;
  status?: InputMaybe<StatusEnum>;
  subcategoryId?: InputMaybe<Scalars['ID']['input']>;
  submitForReview?: InputMaybe<Scalars['Boolean']['input']>;
  submitForSignature?: InputMaybe<Scalars['Boolean']['input']>;
  supplierPreferenceInput?: InputMaybe<OmniSupplierPreferenceInput>;
  /** If the changes made are related to an in-progress call, caller should provide the Ticket ID for the call (if available) to aid in merging messages to insurer */
  ticketId?: InputMaybe<Scalars['UUID']['input']>;
};

export type OmniUpdateOrderMutation = {
  __typename?: 'OmniUpdateOrderMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type OpenAiMessageInput = {
  content: Scalars['String']['input'];
  role: OpenAiRole;
};

export type OpenAiMessageType = {
  __typename?: 'OpenAIMessageType';
  content: Scalars['String']['output'];
  role: OpenAiRole;
};

export type OpenAiPromptResponse = {
  __typename?: 'OpenAIPromptResponse';
  thread?: Maybe<Array<OpenAiMessageType>>;
  tokenCount: Scalars['Int']['output'];
};

/**
 * Enumeration of valid OpenAI roles
 *
 *     SYSTEM is usually used to provide initial context at the start of a thread to
 *         preload the model its purpose.
 *     USER is us
 *     ASSISTANT is OpenAI's the role responding to USER prompts
 *
 */
export enum OpenAiRole {
  Assistant = 'ASSISTANT',
  System = 'SYSTEM',
  User = 'USER',
}

export type OpenCustomerBillingPortal = {
  __typename?: 'OpenCustomerBillingPortal';
  url?: Maybe<Scalars['String']['output']>;
};

export type OptimizedNetworkSupplierType = {
  __typename?: 'OptimizedNetworkSupplierType';
  /** Whether orders will be automatically accepted */
  autoAccept: Scalars['Boolean']['output'];
  /** Whether supplier is able to be assigned DocumentExtractionJobs */
  automatedFaxEnabled: Scalars['Boolean']['output'];
  /** Whether supplier is considered for automatic routing */
  automaticEnabled: Scalars['Boolean']['output'];
  billingTins?: Maybe<Array<Scalars['String']['output']>>;
  boxAcceptedFolderId?: Maybe<Scalars['String']['output']>;
  boxAdminEmail?: Maybe<Scalars['String']['output']>;
  boxCompletedFolderId?: Maybe<Scalars['String']['output']>;
  boxInboxFolderId?: Maybe<Scalars['String']['output']>;
  boxInvitationAccepted: Scalars['Boolean']['output'];
  boxInvitationSentAt?: Maybe<Scalars['DateTime']['output']>;
  boxMainFolderId?: Maybe<Scalars['String']['output']>;
  boxRejectedFolderId?: Maybe<Scalars['String']['output']>;
  boxcommentSet: Array<BoxCommentType>;
  categories: Array<NetworkSupplierCategoryType>;
  categoriesSubset?: Maybe<Array<Maybe<NetworkSupplierCategoryType>>>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  contacts: Array<ContactType>;
  coveredHcpcs: Array<NetworkSupplierHcpcsType>;
  coveredHcpcsSubset?: Maybe<Array<Maybe<NetworkSupplierHcpcsType>>>;
  coveredInsurers?: Maybe<InsurerSlimTypeConnection>;
  coveredInsurersSubset?: Maybe<Array<Maybe<InsurerType>>>;
  coveredZipcodes: Array<NetworkSupplierZipcodeType>;
  coveredZipcodesSubset?: Maybe<Array<Maybe<NetworkSupplierZipcodeType>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  erpExternalIdentifierCodeSystem?: Maybe<Scalars['String']['output']>;
  erpIntegration?: Maybe<IntegrationType>;
  id: Scalars['UUID']['output'];
  intakePhoneNumber?: Maybe<Scalars['String']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to nudge supplier for stale orders needing POD */
  isProofOfDeliveryBoxNudgeEnabled: Scalars['Boolean']['output'];
  /** Whether supplier is required to upload POD documents */
  isProofOfDeliveryRequired: Scalars['Boolean']['output'];
  /** Whether supplier is a Tomorrow Health Direct supplier */
  isThdSupplier: Scalars['Boolean']['output'];
  locations: Array<NetworkSupplierLocationType>;
  name: Scalars['String']['output'];
  npi?: Maybe<Scalars['String']['output']>;
  opsId: Scalars['String']['output'];
  org?: Maybe<OrgSlimType>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  servicingCapability?: Maybe<AppNetworkSupplierServicingCapabilityChoices>;
  sftpUsername?: Maybe<Scalars['String']['output']>;
  shipments: Array<ShipmentType>;
  statewideShip: Scalars['Boolean']['output'];
  statewideShipStates?: Maybe<Array<Scalars['String']['output']>>;
  thirdPartyIdentifier?: Maybe<Scalars['String']['output']>;
  /** ('Default timezone for SFTP uploads (and TODO for business hours logic)',) */
  timezone?: Maybe<AppNetworkSupplierTimezoneChoices>;
  tin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OptimizedNetworkSupplierTypeCategoriesSubsetArgs = {
  categories: Array<Scalars['String']['input']>;
};

export type OptimizedNetworkSupplierTypeCoveredHcpcsSubsetArgs = {
  hcpcs: Array<Scalars['String']['input']>;
};

export type OptimizedNetworkSupplierTypeCoveredInsurersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OptimizedNetworkSupplierTypeCoveredInsurersSubsetArgs = {
  insurerIds: Array<Scalars['UUID']['input']>;
};

export type OptimizedNetworkSupplierTypeCoveredZipcodesSubsetArgs = {
  zipcodes: Array<Scalars['String']['input']>;
};

export type OrderAddressInput = {
  address: CreateOrGetAddressInput;
  addressType: AddressTypeEnum;
  nickname?: InputMaybe<Scalars['String']['input']>;
};

export type OrderAddressType = {
  __typename?: 'OrderAddressType';
  address?: Maybe<AddressType>;
  addressType: AppOrderAddressAddressTypeChoices;
  id: Scalars['UUID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
};

export type OrderAssignmentLineItemType = {
  __typename?: 'OrderAssignmentLineItemType';
  completedAt?: Maybe<Scalars['Date']['output']>;
  estimatedDeliveryAt?: Maybe<Scalars['Date']['output']>;
  orderLineItem: OrderLineItemType;
  trackingNumberStatus?: Maybe<TrackingNumberStatusType>;
};

export type OrderAssignmentTrackingNumberSummaryType = {
  __typename?: 'OrderAssignmentTrackingNumberSummaryType';
  hasFailedTrackingNumber: Scalars['Boolean']['output'];
  hasTrackingNumber: Scalars['Boolean']['output'];
};

export type OrderAssignmentType = {
  __typename?: 'OrderAssignmentType';
  acceptedRejectedAt?: Maybe<Scalars['DateTime']['output']>;
  assignmentLineItems: Array<OrderAssignmentLineItemType>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  erpStatus: AppOrderAssignmentErpStatusChoices;
  estimatedDeliveryAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  isAutomatic: Scalars['Boolean']['output'];
  isDocumentationSurveyAnswered?: Maybe<Scalars['Boolean']['output']>;
  lastNudgedAt?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<NetworkSupplierLocationType>;
  networkSupplier: OptimizedNetworkSupplierType;
  sentToErpSystemAt?: Maybe<Scalars['DateTime']['output']>;
  showsH2067: Scalars['Boolean']['output'];
  slaDeadlineAt?: Maybe<Scalars['DateTime']['output']>;
  status: AppOrderAssignmentStatusChoices;
  /** The code for an org's representation of a status for this order */
  thirdPartyStatusCode?: Maybe<Scalars['String']['output']>;
  /** The human consumable version of an org's representation of a status for this order */
  thirdPartyStatusDisplay?: Maybe<Scalars['String']['output']>;
  trackingNumberSummary: OrderAssignmentTrackingNumberSummaryType;
  updatedAt: Scalars['DateTime']['output'];
  verifiedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderCategory = {
  __typename?: 'OrderCategory';
  category: IntakeCategoryType;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  order: OrderType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderDischargeInfoType = {
  __typename?: 'OrderDischargeInfoType';
  id: Scalars['UUID']['output'];
  infoJson: Scalars['JSONString']['output'];
};

export type OrderIntakeAnswerType = {
  __typename?: 'OrderIntakeAnswerType';
  clinicalAnswerJson?: Maybe<Scalars['JSONString']['output']>;
  clinicalFormJson?: Maybe<Scalars['JSONString']['output']>;
  clinicalFormValidated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  /** The associated OrderIntakeAnswer for this result */
  intakeValidationRuleResults: Array<IntakeValidationRuleResultType>;
  isClinicalFormValid: Scalars['Boolean']['output'];
  isProductFormValid: Scalars['Boolean']['output'];
  productAnswerJson?: Maybe<Scalars['JSONString']['output']>;
  productFormJson?: Maybe<Scalars['JSONString']['output']>;
  productFormValidated: Scalars['Boolean']['output'];
  /** The associated OrderIntakeAnswer for this result */
  productValidationRuleResults: Array<ProductValidationRuleResultType>;
  requiresManualReview: Scalars['Boolean']['output'];
  /** Signers for the answer excluding deleted */
  signers?: Maybe<Array<Maybe<OrderSignerType>>>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserSlimType>;
};

export type OrderLineItemAddition = {
  estimatedDeliveryAt?: InputMaybe<Scalars['Date']['input']>;
  hcpcsCode: Scalars['String']['input'];
  lengthOfNeed?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reasonText: Scalars['String']['input'];
};

export type OrderLineItemDeletion = {
  orderLineItemId: Scalars['UUID']['input'];
  reasonCode: Scalars['String']['input'];
};

/**
 * This Graphql input type is analogous to a corresponding OrderClient input type.
 * This knows how to translate itself into an instance of the OrderClient input type.
 * Contains line item related information on an order and is meant to be used for
 * create/update mutations
 *
 * OrderClient input analogue: order.client_inputs.LineItemData
 */
export type OrderLineItemInput = {
  diagnosisCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hcpcsCode: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isReimbursed?: InputMaybe<Scalars['Boolean']['input']>;
  lengthOfNeed?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  removeDiagnosisCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  surveyResponse?: InputMaybe<SurveyResponseInput>;
};

export type OrderLineItemModificationReasonType = {
  __typename?: 'OrderLineItemModificationReasonType';
  code: Scalars['String']['output'];
  externalDescription: Scalars['String']['output'];
  isUserSelectable: Scalars['Boolean']['output'];
};

export type OrderLineItemModificationReasonTypeConnection = {
  __typename?: 'OrderLineItemModificationReasonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderLineItemModificationReasonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `OrderLineItemModificationReasonType` and its cursor. */
export type OrderLineItemModificationReasonTypeEdge = {
  __typename?: 'OrderLineItemModificationReasonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrderLineItemModificationReasonType>;
};

export type OrderLineItemType = {
  __typename?: 'OrderLineItemType';
  discountSum?: Maybe<Scalars['Decimal']['output']>;
  displayStatus?: Maybe<Scalars['String']['output']>;
  displayText: Scalars['String']['output'];
  finalPrice?: Maybe<Scalars['Decimal']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  /** HCPCS code line item (cannot be combined with supplier item) */
  hcpcsCode?: Maybe<HcpcsType>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  patientPrescriptionItem?: Maybe<PatientPrescriptionItemType>;
  price?: Maybe<Scalars['Decimal']['output']>;
  priceEstimate?: Maybe<Scalars['Decimal']['output']>;
  priceEstimateHigh?: Maybe<Scalars['Decimal']['output']>;
  priceEstimateLow?: Maybe<Scalars['Decimal']['output']>;
  priorAuthorizationLineItem?: Maybe<PriorAuthorizationLineItemType>;
  /** Top-level product on the order that produced this line item. */
  productItem?: Maybe<OrderProductItemType>;
  quantity: Scalars['Int']['output'];
  reimbursed: Scalars['Boolean']['output'];
  reimbursedBy?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<ShipmentType>;
  /** HCPCS-level component of a product. This is a way to get the HCPCS code for this line item. */
  subproduct?: Maybe<SubproductType>;
  /** Supplier SKU line item (cannot be combined with HCPCS code) */
  supplierItem?: Maybe<SupplierItemType>;
  surveyResponse?: Maybe<LineItemSurveyResponseType>;
  tax?: Maybe<Scalars['Decimal']['output']>;
  unitPrice?: Maybe<Scalars['Decimal']['output']>;
};

export type OrderProductItemInput = {
  /** Custom Product Description. Required when creating a new order product item and product ID is not provided  */
  customProductDescription?: InputMaybe<Scalars['String']['input']>;
  /** List of Diagnosis codes to be added to the order product item.Only applicable when adding/updating order product item w/o using product_form and product_options */
  diagnosisCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Order Product Item ID. Required when updating an existing order product item. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  productCatalogValidationRuleResults?: InputMaybe<Array<ProductCatalogValidationRuleResultInput>>;
  /** Product Form JSON. Required when creating a new order product item. */
  productForm?: InputMaybe<Scalars['JSONString']['input']>;
  /** Product ID. Required when creating a new order product item and custom product description is not provided  */
  productId?: InputMaybe<Scalars['UUID']['input']>;
  /** Product Options JSON. Required when creating a new product item and when updating an existing order product item. */
  productOptions?: InputMaybe<Scalars['JSONString']['input']>;
  /** Quantity of the order product item.Only applicable when adding/updating order product item w/o using product_form and product_options */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderProductItemType = {
  __typename?: 'OrderProductItemType';
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  product?: Maybe<ProductType>;
  productForm?: Maybe<Scalars['JSONString']['output']>;
  productOptions?: Maybe<Scalars['JSONString']['output']>;
};

export type OrderRequirementType = {
  __typename?: 'OrderRequirementType';
  blocks: Array<OrderRequirementType>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: OrderType;
  requirementType?: Maybe<Scalars['String']['output']>;
  stakeholderType?: Maybe<AppOrderRequirementStakeholderTypeChoices>;
  status: AppOrderRequirementStatusChoices;
};

export type OrderSeedType = {
  __typename?: 'OrderSeedType';
  childSeeds: Array<OrderSeedType>;
  createdAt: Scalars['DateTime']['output'];
  documentExtractionJob: DocumentExtractionJobType;
  extractionResultsJsonSchema?: Maybe<Scalars['JSONString']['output']>;
  extractionResultsSet?: Maybe<ExtractionResultsSetType>;
  faxTransmissions?: Maybe<Array<FaxTransmissionType>>;
  id: Scalars['UUID']['output'];
  missingRequirements?: Maybe<Scalars['JSONString']['output']>;
  order?: Maybe<OrderType>;
  parentOrderSeed?: Maybe<OrderSeedType>;
  primaryExtractor: AppOrderSeedPrimaryExtractorChoices;
  rawExtractionConfidence?: Maybe<Scalars['JSONString']['output']>;
  /** tied to OpenAI extraction */
  rawExtractionResults?: Maybe<Scalars['JSONString']['output']>;
  reviewer?: Maybe<UserTypeForAdminView>;
  status: AppOrderSeedStatusChoices;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderSignerType = {
  __typename?: 'OrderSignerType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isPrescriber: Scalars['Boolean']['output'];
  isRequestor: Scalars['Boolean']['output'];
  isSigner: Scalars['Boolean']['output'];
  nameSigned?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  signedDate?: Maybe<Scalars['Date']['output']>;
  signingMethod: AppOrderSignerSigningMethodChoices;
  signingRole: AppOrderSignerSigningRoleChoices;
  signingUserAgent?: Maybe<Scalars['String']['output']>;
  signingUserIpAddress?: Maybe<Scalars['String']['output']>;
  smsNumber?: Maybe<Scalars['String']['output']>;
  supervisingPhysician?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserSlimType>;
};

export type OrderStatusHistorySlimType = {
  __typename?: 'OrderStatusHistorySlimType';
  addByEmail?: Maybe<Scalars['String']['output']>;
  addById?: Maybe<Scalars['ID']['output']>;
  addByName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Optional reason for why order transitioned to this status */
  reasonCode?: Maybe<OrderStatusReasonType>;
  reasonText?: Maybe<Scalars['String']['output']>;
  rpStatus?: Maybe<Scalars['String']['output']>;
  status: AppOrderStatusHistoryStatusChoices;
  supplyStatus?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type OrderStatusHistoryType = {
  __typename?: 'OrderStatusHistoryType';
  addByEmail?: Maybe<Scalars['String']['output']>;
  addById?: Maybe<Scalars['ID']['output']>;
  addByName?: Maybe<Scalars['String']['output']>;
  addByType: AppOrderStatusHistoryAddByTypeChoices;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSONString']['output']>;
  networkSupplier?: Maybe<OptimizedNetworkSupplierType>;
  order: OrderType;
  /** Optional reason for why order transitioned to this status */
  reasonCode?: Maybe<OrderStatusReasonType>;
  reasonText?: Maybe<Scalars['String']['output']>;
  rpStatus?: Maybe<Scalars['String']['output']>;
  status: AppOrderStatusHistoryStatusChoices;
  timestamp: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderStatusMapType = {
  __typename?: 'OrderStatusMapType';
  displayStatus?: Maybe<Scalars['String']['output']>;
  orderStatuses?: Maybe<Array<Maybe<AppOrderStatusHistoryStatusChoices>>>;
};

export type OrderStatusReasonInput = {
  code: Scalars['String']['input'];
  externalDescription: Scalars['String']['input'];
  forStatus: Scalars['String']['input'];
  internalDescription?: InputMaybe<Scalars['String']['input']>;
};

export type OrderStatusReasonType = {
  __typename?: 'OrderStatusReasonType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalDescription: Scalars['String']['output'];
  forOrgTypes?: Maybe<Array<Scalars['String']['output']>>;
  forStatus: AppOrderStatusReasonForStatusChoices;
  internalDescription?: Maybe<Scalars['String']['output']>;
  isDefaultCancelReason?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderStatusReasonTypeConnection = {
  __typename?: 'OrderStatusReasonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderStatusReasonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `OrderStatusReasonType` and its cursor. */
export type OrderStatusReasonTypeEdge = {
  __typename?: 'OrderStatusReasonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrderStatusReasonType>;
};

export type OrderSupplierSuggestionType = {
  __typename?: 'OrderSupplierSuggestionType';
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: OrderType;
  reason?: Maybe<AppOrderSupplierSuggestionReasonChoices>;
  source?: Maybe<AppOrderSupplierSuggestionSourceChoices>;
  supplier: OptimizedNetworkSupplierType;
  taken: Scalars['Boolean']['output'];
};

export type OrderTimelineEntryType = {
  __typename?: 'OrderTimelineEntryType';
  displayText: Scalars['String']['output'];
  entryType: Scalars['String']['output'];
  faxTransmission?: Maybe<FaxTransmissionType>;
  statusHistory?: Maybe<OrderStatusHistorySlimType>;
  timestamp: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
  userOrgName?: Maybe<Scalars['String']['output']>;
};

export type OrderType = {
  __typename?: 'OrderType';
  addresses?: Maybe<Array<Maybe<OrderAddressType>>>;
  allShipments: Array<ShipmentType>;
  appointment?: Maybe<AppointmentType>;
  billingAddress?: Maybe<AddressType>;
  boxComments?: Maybe<Array<Maybe<BoxCommentType>>>;
  careManagerContactInfo?: Maybe<Scalars['String']['output']>;
  careManagerName?: Maybe<Scalars['String']['output']>;
  categories: Array<OrderCategory>;
  comments?: Maybe<Array<Maybe<CommentType>>>;
  createdAt: Scalars['DateTime']['output'];
  currentAnswer?: Maybe<OrderIntakeAnswerType>;
  currentAssignment?: Maybe<OrderAssignmentType>;
  currentOrgs?: Maybe<Array<Maybe<OrgSlimType>>>;
  /** Current signers excluding deleted */
  currentSigners?: Maybe<Array<Maybe<OrderSignerType>>>;
  deferredSupplierSuggestion?: Maybe<DeferredOrderSupplierSuggestionType>;
  deliveryDocuments?: Maybe<Array<Maybe<DocumentType>>>;
  dischargeAnswers?: Maybe<OrderDischargeInfoType>;
  dischargeAt?: Maybe<Scalars['DateTime']['output']>;
  dischargeAtDate?: Maybe<Scalars['Date']['output']>;
  discountSum: Scalars['Decimal']['output'];
  displayId: Scalars['String']['output'];
  documents: Array<DocumentType>;
  emergencyContact?: Maybe<PersonType>;
  emergencyContactRelationship?: Maybe<Scalars['String']['output']>;
  externalMentionableUsers?: Maybe<Array<Maybe<UserSlimType>>>;
  facility?: Maybe<FacilityType>;
  falconStatus?: Maybe<OrderStatusHistoryType>;
  finalPrice: Scalars['Decimal']['output'];
  /** User-visible ID assigned at intake */
  friendlyId?: Maybe<Scalars['String']['output']>;
  fulfilledBy?: Maybe<AppOrderFulfilledByChoices>;
  hasRelatedInsurerIntegrations?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  intakeAnswers?: Maybe<Array<Maybe<OrderIntakeAnswerType>>>;
  intakeCategory?: Maybe<IntakeCategoryType>;
  intakeDefects?: Maybe<Array<Maybe<IntakeDefectType>>>;
  intakeSubcategory?: Maybe<IntakeSubcategoryType>;
  intakeType: AppOrderIntakeTypeChoices;
  internalMentionableUsers?: Maybe<Array<Maybe<UserSlimType>>>;
  isClinicalRulesElectronicSigned?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates that the order is complex and needs special handling. Standard ticket creation is suppressed until the order is no longer marked complex. */
  isComplex: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  /** If this is a verbal order, meaning it does not have complete documentation at the time of submission */
  isVerbal: Scalars['Boolean']['output'];
  lineItems: Array<OrderLineItemType>;
  nikoId?: Maybe<Scalars['String']['output']>;
  nikoStatus?: Maybe<AppOrderNikoStatusChoices>;
  note?: Maybe<Scalars['String']['output']>;
  oldestUnreadComment?: Maybe<CommentType>;
  openTicketCount?: Maybe<Scalars['Int']['output']>;
  orderOwner?: Maybe<UserSlimType>;
  /** This fields is very expensive to resolve and should only be resolved when querying single orders. */
  orderTimeline?: Maybe<Array<Maybe<OrderTimelineEntryType>>>;
  patient: PatientType;
  practice?: Maybe<PracticeSlimType>;
  preferredSupplier?: Maybe<OptimizedNetworkSupplierType>;
  preferredSupplierNote?: Maybe<Scalars['String']['output']>;
  preferredSupplierSource?: Maybe<AppOrderPreferredSupplierSourceChoices>;
  prescription?: Maybe<PatientPrescriptionType>;
  priceEstimate: Scalars['Decimal']['output'];
  priceEstimateHigh: Scalars['Decimal']['output'];
  priceEstimateLow: Scalars['Decimal']['output'];
  priceSum: Scalars['Decimal']['output'];
  primaryDocument?: Maybe<DocumentType>;
  productItems?: Maybe<Array<Maybe<OrderProductItemType>>>;
  providerContact?: Maybe<ContactType>;
  recentlyCovidPositive?: Maybe<Scalars['Boolean']['output']>;
  referralChannel?: Maybe<AppOrderReferralChannelChoices>;
  referralDate?: Maybe<Scalars['Date']['output']>;
  referralSource?: Maybe<AppOrderReferralSourceChoices>;
  referrer?: Maybe<PersonType>;
  referringOrg?: Maybe<OrgSlimType>;
  referringProvider?: Maybe<ProviderType>;
  requirements?: Maybe<Array<Maybe<OrderRequirementType>>>;
  rpPracticeUsers?: Maybe<Array<Maybe<OrgUserSlimType>>>;
  rpTimeline?: Maybe<Array<Maybe<OrderStatusHistorySlimType>>>;
  scheduledDate?: Maybe<Scalars['Date']['output']>;
  seeds: Array<OrderSeedType>;
  serviceType?: Maybe<AppOrderServiceTypeChoices>;
  shipments?: Maybe<Array<Maybe<ShipmentType>>>;
  shippingAddress?: Maybe<AddressType>;
  shippingDetails?: Maybe<Scalars['String']['output']>;
  /** All signers including deleted */
  signers?: Maybe<Array<Maybe<OrderSignerType>>>;
  source: AppOrderSourceChoices;
  stakeholderEngagements?: Maybe<Array<Maybe<StakeholderEngagementType>>>;
  /** @deprecated Renamed to niko_status */
  status?: Maybe<Scalars['String']['output']>;
  statusHistory: Array<OrderStatusHistoryType>;
  supplierSuggestions?: Maybe<Array<Maybe<OrderSupplierSuggestionType>>>;
  taxSum: Scalars['Decimal']['output'];
  tickets: Array<TicketType>;
  updatedAt: Scalars['DateTime']['output'];
  urgent: Scalars['Boolean']['output'];
  user?: Maybe<UserTypeForAdminView>;
  userRelations: Array<OrderUserRelationType>;
  userSupplierPreferenceNote?: Maybe<Scalars['String']['output']>;
};

export type OrderTypeOldestUnreadCommentArgs = {
  orgId: Scalars['UUID']['input'];
};

export type OrderTypeRpPracticeUsersArgs = {
  practiceId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderTypeSupplierSuggestionsArgs = {
  source?: InputMaybe<AppOrderSupplierSuggestionSourceChoices>;
  taken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderTypeConnection = {
  __typename?: 'OrderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `OrderType` and its cursor. */
export type OrderTypeEdge = {
  __typename?: 'OrderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrderType>;
};

export type OrderUserRelationType = {
  __typename?: 'OrderUserRelationType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  order: OrderType;
  relationType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: UserTypeForAdminView;
};

export type OrgAgreementType = {
  __typename?: 'OrgAgreementType';
  agreement?: Maybe<AgreementType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  orgUser?: Maybe<OrgUserType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrgMetricValueType = {
  __typename?: 'OrgMetricValueType';
  metric: MetricType;
  org: OrgSlimType;
  timestamp: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type OrgPatientInput = {
  address: UpdatePatientAddressInput;
  dateOfBirth: Scalars['Date']['input'];
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  guardian?: InputMaybe<PersonInput>;
  heightInInches?: InputMaybe<Scalars['Float']['input']>;
  homePhoneNumber: Scalars['String']['input'];
  languagePreference?: InputMaybe<LanguageInput>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  primaryInsurance?: InputMaybe<InsuranceInput>;
  secondaryInsurance?: InputMaybe<InsuranceInput>;
  sex: SexEnum;
  tertiaryInsurance?: InputMaybe<InsuranceInput>;
  waiverId?: InputMaybe<Scalars['UUID']['input']>;
  weightInPounds?: InputMaybe<Scalars['Float']['input']>;
};

export type OrgReadAllCommentsOnOrderInput = {
  lastCommentId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type OrgReadAllCommentsOnOrderMutation = {
  __typename?: 'OrgReadAllCommentsOnOrderMutation';
  error?: Maybe<ErrorType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OrgRevokeDocumentExtractionJobInput = {
  documentExtractionJobId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  revokeReason: AppDocumentExtractionJobAssignmentRevokeReasonChoices;
  revokeReasonDescription?: InputMaybe<Scalars['String']['input']>;
  role: AppDocumentExtractionJobAssignmentRoleChoices;
};

/** Mutation to allow a supplier to unassign themselves from a DocumentExtractionJob */
export type OrgRevokeDocumentExtractionJobMutation = {
  __typename?: 'OrgRevokeDocumentExtractionJobMutation';
  documentExtractionJob?: Maybe<DocumentExtractionJobType>;
  error?: Maybe<ErrorType>;
};

export type OrgSlimType = {
  __typename?: 'OrgSlimType';
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by orgTypeEnum */
  orgType?: Maybe<Scalars['String']['output']>;
  orgTypeEnum: OrgTypes;
};

export type OrgType = {
  __typename?: 'OrgType';
  agreementStatus: AgreementStatusEnum;
  careCoordination?: Maybe<CareCoordinationType>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Orgs related to this EHR System */
  ehrSystems: Array<EhrSystemSlimType>;
  id: Scalars['UUID']['output'];
  /** @deprecated Deprecated, use insurerCategoryEnum instead */
  insurerCategory?: Maybe<Scalars['String']['output']>;
  /** Insurer category */
  insurerCategoryEnum?: Maybe<AppInsurerInsurerCategoryDirectChoices>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by orgTypeEnum */
  orgType?: Maybe<Scalars['String']['output']>;
  orgTypeEnum: OrgTypes;
  orgUsers?: Maybe<Array<Maybe<OrgUserType>>>;
  practice?: Maybe<PracticeType>;
  supplier?: Maybe<OptimizedNetworkSupplierType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrgTypeConnection = {
  __typename?: 'OrgTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrgTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `OrgType` and its cursor. */
export type OrgTypeEdge = {
  __typename?: 'OrgTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrgType>;
};

/** An enumeration. */
export enum OrgTypes {
  CareCoordination = 'CARE_COORDINATION',
  Payer = 'PAYER',
  Practice = 'PRACTICE',
  Supplier = 'SUPPLIER',
}

export type OrgUserAcceptInvite = {
  __typename?: 'OrgUserAcceptInvite';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type OrgUserSlimType = {
  __typename?: 'OrgUserSlimType';
  acceptedInviteAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  orgId?: Maybe<Scalars['ID']['output']>;
  payerId?: Maybe<Scalars['ID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  practiceId?: Maybe<Scalars['ID']['output']>;
  smsNumber?: Maybe<Scalars['String']['output']>;
  supplierId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type OrgUserType = {
  __typename?: 'OrgUserType';
  acceptedInviteAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** returns a list of OrgUsers that have authorized the current user to take action on their behalf */
  delegatedBy?: Maybe<Array<Maybe<OrgUserType>>>;
  /** returns a list of OrgUsers that have been authorized to take action on behalf of the current user */
  delegates?: Maybe<Array<Maybe<OrgUserType>>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  org?: Maybe<OrgType>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role: AppOrgUserRoleChoices;
  smsNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PanelFormInput = {
  /** List of attributes used to create the question json as per SurveyJS format */
  elements: Array<InputMaybe<IntakeQuestionAttributesInput>>;
  /** Name for the panel */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Display text for the panel */
  title: Scalars['String']['input'];
  /** Conditions when the given panel is visible. */
  visibleIf?: InputMaybe<Scalars['String']['input']>;
};

export type PatientInput = {
  address?: InputMaybe<UpdatePatientAddressInput>;
  dateOfBirth: Scalars['Date']['input'];
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  guardian?: InputMaybe<PersonInput>;
  heightInInches?: InputMaybe<Scalars['Float']['input']>;
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  languagePreference?: InputMaybe<LanguageInput>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  sex: SexEnum;
  tomorrowHealthSmsConsentIsGranted?: InputMaybe<Scalars['Boolean']['input']>;
  waiverId?: InputMaybe<Scalars['UUID']['input']>;
  weightInPounds?: InputMaybe<Scalars['Float']['input']>;
};

/** Slim type that exposes minimal info for unauthenticated endpoint */
export type PatientInsuranceInformationType = {
  __typename?: 'PatientInsuranceInformationType';
  patientGroupNumber?: Maybe<Scalars['String']['output']>;
  patientInsuranceType?: Maybe<Scalars['String']['output']>;
};

export type PatientInsuranceInput = {
  allowUnverified?: InputMaybe<Scalars['Boolean']['input']>;
  insurerId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  policyNumber: Scalars['String']['input'];
  priority?: InputMaybe<PriorityEnum>;
};

export type PatientInsuranceType = {
  __typename?: 'PatientInsuranceType';
  coinsurance?: Maybe<Scalars['Decimal']['output']>;
  copay?: Maybe<Scalars['Decimal']['output']>;
  deductible?: Maybe<Scalars['Decimal']['output']>;
  deductibleSpend?: Maybe<Scalars['Decimal']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  insurer: InsurerType;
  insurerName?: Maybe<Scalars['String']['output']>;
  outOfPocketMax?: Maybe<Scalars['Decimal']['output']>;
  outOfPocketMaxSpend?: Maybe<Scalars['Decimal']['output']>;
  patient: PatientType;
  planDetails?: Maybe<Scalars['String']['output']>;
  policyName?: Maybe<Scalars['String']['output']>;
  policyNumber: Scalars['String']['output'];
  priority: AppPatientInsurancePriorityChoices;
  status: AppPatientInsuranceStatusChoices;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
  verifiedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Slim type that exposes minimal info for unauthenticated endpoint */
export type PatientInsuranceVerificationType = {
  __typename?: 'PatientInsuranceVerificationType';
  insuranceInformation?: Maybe<PatientInsuranceInformationType>;
  showErrors?: Maybe<Scalars['Boolean']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type PatientPrescriptionItemType = {
  __typename?: 'PatientPrescriptionItemType';
  diagnoses?: Maybe<Array<Maybe<DiagnosisType>>>;
  /** HCPCS code line item (cannot be combined with supplier item) */
  hcpcsCode?: Maybe<HcpcsType>;
  id: Scalars['UUID']['output'];
  /** In months (99 represents unlimited) */
  lengthOfNeed: Scalars['Int']['output'];
  /** Prescription this line item is for */
  prescription: PatientPrescriptionType;
  quantity: Scalars['Int']['output'];
  /** Supplier SKU line item (cannot be combined with HCPCS code) */
  supplierItem?: Maybe<SupplierItemType>;
};

export type PatientPrescriptionType = {
  __typename?: 'PatientPrescriptionType';
  id: Scalars['UUID']['output'];
  isDraft: Scalars['Boolean']['output'];
  /** Prescription this line item is for */
  lineItems: Array<PatientPrescriptionItemType>;
  patient: PatientType;
  referringProvider?: Maybe<ProviderType>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type PatientSlimType = {
  __typename?: 'PatientSlimType';
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  /** This is the Legally Authorized Representative of the patient */
  guardian?: Maybe<PersonType>;
  heightInInches?: Maybe<Scalars['Float']['output']>;
  homePhoneNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** This is the preferred language of this patient */
  languagePreference?: Maybe<LanguageType>;
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  orders?: Maybe<Array<Maybe<OrderType>>>;
  patientWaiver?: Maybe<PatientWaiverType>;
  practices?: Maybe<Array<PracticeSlimType>>;
  primaryCareProvider?: Maybe<ProviderType>;
  primaryCareProviderContact?: Maybe<ContactType>;
  primaryInsurance?: Maybe<PatientInsuranceType>;
  secondaryInsurance?: Maybe<PatientInsuranceType>;
  sex?: Maybe<SexEnum>;
  shippingAddress?: Maybe<AddressType>;
  supplierOrders?: Maybe<Array<Maybe<SupplierOrderType>>>;
  tertiaryInsurance?: Maybe<PatientInsuranceType>;
  weightInPounds?: Maybe<Scalars['Float']['output']>;
};

export type PatientSlimTypeOrdersArgs = {
  practiceId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PatientSlimTypeConnection = {
  __typename?: 'PatientSlimTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientSlimTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PatientSlimType` and its cursor. */
export type PatientSlimTypeEdge = {
  __typename?: 'PatientSlimTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PatientSlimType>;
};

export type PatientType = {
  __typename?: 'PatientType';
  address?: Maybe<AddressType>;
  canReceiveSms?: Maybe<Scalars['Boolean']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  /** This is the Legally Authorized Representative of the patient */
  guardian?: Maybe<PersonType>;
  hasStripePaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  heightInInches?: Maybe<Scalars['Float']['output']>;
  homePhoneNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** This is the preferred language of this patient */
  languagePreference?: Maybe<LanguageType>;
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  nikoId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  patientWaiver?: Maybe<PatientWaiverType>;
  practices?: Maybe<Array<PracticeSlimType>>;
  primaryCareProvider?: Maybe<ProviderType>;
  primaryCareProviderContact?: Maybe<ContactType>;
  primaryInsurance?: Maybe<PatientInsuranceType>;
  secondaryInsurance?: Maybe<PatientInsuranceType>;
  sex?: Maybe<SexEnum>;
  shippingAddress?: Maybe<AddressType>;
  signature?: Maybe<SignatureType>;
  smsAuthorized: Scalars['Boolean']['output'];
  stripeCustomer?: Maybe<CustomerType>;
  tertiaryInsurance?: Maybe<PatientInsuranceType>;
  tomorrowHealthSmsConsentIsGranted?: Maybe<Scalars['Boolean']['output']>;
  twilioSmsConsentIsGranted?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserTypeForAdminView>;
  weightInPounds?: Maybe<Scalars['Float']['output']>;
  workPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PatientTypeConnection = {
  __typename?: 'PatientTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PatientType` and its cursor. */
export type PatientTypeEdge = {
  __typename?: 'PatientTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PatientType>;
};

export type PatientWaiverType = {
  __typename?: 'PatientWaiverType';
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  patient: PatientType;
  waiver?: Maybe<WaiverType>;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  amount: Scalars['Decimal']['output'];
  failureReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  invoice?: Maybe<InvoiceType>;
  method: AppPaymentMethodChoices;
  methodDetails?: Maybe<Scalars['JSONString']['output']>;
  nikoId?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
  processor?: Maybe<AppPaymentProcessorChoices>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  status: AppPaymentStatusChoices;
};

/** Class representing Django permissions. */
export type PermissionType = {
  __typename?: 'PermissionType';
  codename: Scalars['String']['output'];
  contentType: ContentTypeType;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type PersonInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PersonType = {
  __typename?: 'PersonType';
  address?: Maybe<AddressType>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<SexEnum>;
};

export type PracticePatientInput = {
  address: UpdatePatientAddressInput;
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  primaryInsurance: InsuranceInput;
  secondaryInsurance?: InputMaybe<InsuranceInput>;
  sex: SexEnum;
  waiverId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type PracticeSlimType = {
  __typename?: 'PracticeSlimType';
  /** Addresses of the practice */
  addresses: Array<AddressType>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  tin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PracticeType = {
  __typename?: 'PracticeType';
  /** Addresses of the practice */
  addresses: Array<AddressType>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  facility?: Maybe<FacilityType>;
  id: Scalars['UUID']['output'];
  leadId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  org?: Maybe<OrgType>;
  /** @deprecated PracticePatient is deprecated */
  patients?: Maybe<Array<Maybe<PatientSlimType>>>;
  tin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PracticeTypeConnection = {
  __typename?: 'PracticeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PracticeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PracticeType` and its cursor. */
export type PracticeTypeEdge = {
  __typename?: 'PracticeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PracticeType>;
};

/** Generates a faxback cover sheet for a given order seed, unment requirements override, and additional comments */
export type PreviewFaxbackCoversheetMutation = {
  __typename?: 'PreviewFaxbackCoversheetMutation';
  error?: Maybe<ErrorType>;
  /** base64 string of the PDF */
  pdfData?: Maybe<Scalars['Base64']['output']>;
};

export type PriorAuthRequestInput = {
  isExpedited: Scalars['Boolean']['input'];
  /** Defaults to false */
  isRenewal?: InputMaybe<Scalars['Boolean']['input']>;
  lineItems: Array<PriorAuthRequestLineItemInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['UUID']['input']>;
  orgId: Scalars['UUID']['input'];
  patientId: Scalars['UUID']['input'];
  prescriberAddress: CreateOrGetAddressInput;
  prescriberContact: ContactInput;
  prescriberId: Scalars['UUID']['input'];
  priorityJustification?: InputMaybe<Scalars['String']['input']>;
  /** Defaults to INITIAL_REQUEST */
  requestType?: InputMaybe<AppPriorAuthRequestRequestTypeChoices>;
  supplierAddress?: InputMaybe<CreateOrGetAddressInput>;
  supplierContact?: InputMaybe<ContactInput>;
};

export type PriorAuthRequestLineItemInput = {
  authPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  authPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  hcpcsCode: Scalars['String']['input'];
  isRental: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relevantDiagnosisCodes: Array<Scalars['String']['input']>;
  totalUnits?: InputMaybe<Scalars['Int']['input']>;
};

export type PriorAuthRequestLineItemType = {
  __typename?: 'PriorAuthRequestLineItemType';
  authPeriodEnd?: Maybe<Scalars['Date']['output']>;
  authPeriodStart?: Maybe<Scalars['Date']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  hcpcs: HcpcsType;
  id: Scalars['UUID']['output'];
  isRental: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  relevantDiagnoses: Array<DiagnosisType>;
  totalUnits: Scalars['Int']['output'];
  traceId: Scalars['String']['output'];
};

export type PriorAuthRequestSubmissionInput = {
  requestId: Scalars['UUID']['input'];
  supportingDocuments: Array<DocumentInput>;
};

export type PriorAuthRequestSubmissionType = {
  __typename?: 'PriorAuthRequestSubmissionType';
  createdAt: Scalars['DateTime']['output'];
  faxTransmissions: Array<FaxTransmissionType>;
  generatedPacket?: Maybe<DocumentType>;
  id: Scalars['UUID']['output'];
  request: PriorAuthRequestType;
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  submittedBy: UserTypeForAdminView;
  supportingDocuments: Array<DocumentType>;
};

export type PriorAuthRequestTimelineEntryType = {
  __typename?: 'PriorAuthRequestTimelineEntryType';
  displayText: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type PriorAuthRequestType = {
  __typename?: 'PriorAuthRequestType';
  createdAt: Scalars['DateTime']['output'];
  /** User-visible ID assigned at creation */
  friendlyId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isExpedited: Scalars['Boolean']['output'];
  isRenewal: Scalars['Boolean']['output'];
  lineItems: Array<PriorAuthRequestLineItemType>;
  note?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
  org?: Maybe<OrgType>;
  patient: PatientType;
  prescriber: ProviderType;
  prescriberAddress: AddressType;
  prescriberContact: ContactType;
  priorityJustification?: Maybe<Scalars['String']['output']>;
  requestType: AppPriorAuthRequestRequestTypeChoices;
  responses: Array<PriorAuthResponseType>;
  status: RequestStatusEnum;
  submissions?: Maybe<Array<PriorAuthRequestSubmissionType>>;
  supplierAddress?: Maybe<AddressType>;
  supplierContact?: Maybe<ContactType>;
  timeline: Array<PriorAuthRequestTimelineEntryType>;
};

export type PriorAuthRequestTypeConnection = {
  __typename?: 'PriorAuthRequestTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PriorAuthRequestTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PriorAuthRequestType` and its cursor. */
export type PriorAuthRequestTypeEdge = {
  __typename?: 'PriorAuthRequestTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PriorAuthRequestType>;
};

export type PriorAuthRequirementType = {
  __typename?: 'PriorAuthRequirementType';
  hcpcs: HcpcsType;
  insurer: InsurerType;
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type PriorAuthResponseInput = {
  documents?: InputMaybe<Array<DocumentInput>>;
  issuedAt: Scalars['DateTime']['input'];
  lineItems: Array<PriorAuthResponseLineItemInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['UUID']['input'];
};

export type PriorAuthResponseLineItemInput = {
  decision: AppPriorAuthResponseLineItemDecisionChoices;
  frequency?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  requestLineItemId: Scalars['UUID']['input'];
  totalUnits?: InputMaybe<Scalars['Int']['input']>;
  validFrom: Scalars['Date']['input'];
  validTo: Scalars['Date']['input'];
};

export type PriorAuthResponseLineItemType = {
  __typename?: 'PriorAuthResponseLineItemType';
  createdAt: Scalars['DateTime']['output'];
  decision: AppPriorAuthResponseLineItemDecisionChoices;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  requestLineItem: PriorAuthRequestLineItemType;
  totalUnits: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['Date']['output'];
  validTo: Scalars['Date']['output'];
};

export type PriorAuthResponseType = {
  __typename?: 'PriorAuthResponseType';
  createdAt: Scalars['DateTime']['output'];
  decision: DecisionEnum;
  documents: Array<DocumentType>;
  id: Scalars['UUID']['output'];
  issuedAt: Scalars['DateTime']['output'];
  lineItems: Array<PriorAuthResponseLineItemType>;
  note?: Maybe<Scalars['String']['output']>;
  request: PriorAuthRequestType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PriorAuthorizationLineItemType = {
  __typename?: 'PriorAuthorizationLineItemType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  insuranceChoices?: Maybe<Array<Maybe<PatientInsuranceType>>>;
  insurer: InsurerType;
  orderLineItem: OrderLineItemType;
  outcome: AppPriorAuthorizationLineItemOutcomeChoices;
  reason?: Maybe<Scalars['String']['output']>;
};

export enum PriorityEnum {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
  Unassigned = 'UNASSIGNED',
}

export type ProductCatalogQuestionType = {
  __typename?: 'ProductCatalogQuestionType';
  activeQuestionVersion?: Maybe<ProductCatalogQuestionVersionType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductCatalogQuestionTypeConnection = {
  __typename?: 'ProductCatalogQuestionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCatalogQuestionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductCatalogQuestionType` and its cursor. */
export type ProductCatalogQuestionTypeEdge = {
  __typename?: 'ProductCatalogQuestionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductCatalogQuestionType>;
};

export type ProductCatalogQuestionValidationRuleInsurerType = {
  __typename?: 'ProductCatalogQuestionValidationRuleInsurerType';
  /** Retrieves the currently active validation rules tied to this mapping.  */
  activeValidationRuleVersions?: Maybe<Array<IntakeValidationRuleType>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  insurer?: Maybe<InsurerType>;
  productCatalogQuestion: ProductCatalogQuestionType;
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Retrieves the direct relational association with a validation rule. Since associations are performed through the base version of a rule, this will only return the base versions! If you need the current active version, get activeValidationRuleVersions instead. */
  validationRules?: Maybe<Array<IntakeValidationRuleType>>;
};

export type ProductCatalogQuestionValidationRuleInsurerTypeConnection = {
  __typename?: 'ProductCatalogQuestionValidationRuleInsurerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCatalogQuestionValidationRuleInsurerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductCatalogQuestionValidationRuleInsurerType` and its cursor. */
export type ProductCatalogQuestionValidationRuleInsurerTypeEdge = {
  __typename?: 'ProductCatalogQuestionValidationRuleInsurerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductCatalogQuestionValidationRuleInsurerType>;
};

export type ProductCatalogQuestionVersionType = {
  __typename?: 'ProductCatalogQuestionVersionType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  productCatalogQuestion: ProductCatalogQuestionType;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  questionJson: Scalars['JSONString']['output'];
  updatedAt: Scalars['DateTime']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductCatalogQuestionVersionTypeConnection = {
  __typename?: 'ProductCatalogQuestionVersionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCatalogQuestionVersionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductCatalogQuestionVersionType` and its cursor. */
export type ProductCatalogQuestionVersionTypeEdge = {
  __typename?: 'ProductCatalogQuestionVersionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductCatalogQuestionVersionType>;
};

export type ProductCatalogValidationRuleResultInput = {
  finalAnswerValue: Array<Scalars['String']['input']>;
  initialAnswerValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id of the intake validation rule that generated this result */
  intakeValidationRuleId: Scalars['UUID']['input'];
  /** id of the question version associated with this rule result */
  productCatalogQuestionVersionId: Scalars['UUID']['input'];
  result: AppIntakeValidationRuleResultResultChoices;
};

export type ProductFormInEditModeType = {
  __typename?: 'ProductFormInEditModeType';
  error?: Maybe<ErrorType>;
  formElements?: Maybe<Scalars['JSONString']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ProductFormVersionInput = {
  /** IDs of product catalog questions to be added into the form */
  productCatalogQuestionIds: Array<Scalars['UUID']['input']>;
  /** ID of product that the form is created for */
  productId: Scalars['UUID']['input'];
};

export type ProductFormVersionType = {
  __typename?: 'ProductFormVersionType';
  id: Scalars['UUID']['output'];
  product: ProductType;
  /** Ordered list of product catalog questions appearing on the product form page for the product. Query these only when youare fetching data corresponding to a single product on the adminside. */
  productCatalogQuestions?: Maybe<Array<ProductCatalogQuestionType>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductInput = {
  /** Description of product. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Product ID. Required when updating product. Shall not set when creating product. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** URL of product's image. */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Name of product. Required when creating product. Shall not set to None or empty. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** IDs of intake subcategories that product belongs to. Required when creating product. Shall not set to None or empty. */
  subcategoryIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Codes of subproducts that product is associated with. */
  subproductCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductItemInput = {
  /** A Panel with list of questions shown in it */
  panel?: InputMaybe<PanelFormInput>;
  /** Attributes used to create the question json as per SurveyJS format */
  questionAttributes?: InputMaybe<IntakeQuestionAttributesInput>;
};

export type ProductQuestionVersionInput = {
  /** Nickname of question. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** ID of product catalog question that the product question is associated with. Required when creating a new version of an existing question. */
  productCatalogQuestionId?: InputMaybe<Scalars['UUID']['input']>;
  /** Attributes used to create question JSON as per SurveyJS format. */
  questionAttributes: IntakeQuestionAttributesInput;
  /** Together with 'valid_to', define the period for which the question is valid. If not provided, default it to current datetime for now. */
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Together with 'valid_from', define the period for which the question is valid. */
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductSelectionInput = {
  /** Product form question json */
  productFormJson: Scalars['JSONString']['input'];
  /** Selected product */
  productId: Scalars['ID']['input'];
  /** Product form answer json */
  resultsJson: Scalars['JSONString']['input'];
};

export type ProductType = {
  __typename?: 'ProductType';
  activeFormJson?: Maybe<Scalars['JSONString']['output']>;
  activeFormVersion?: Maybe<ProductFormVersionType>;
  addOns?: Maybe<Array<Maybe<ProductType>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  subcategories?: Maybe<Array<Maybe<IntakeSubcategoryType>>>;
  subproducts?: Maybe<Array<Maybe<SubproductType>>>;
};

export type ProductTypeActiveFormJsonArgs = {
  insurerId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductType` and its cursor. */
export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductType>;
};

export type ProductValidationRuleMappingsInput = {
  insurerIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  productCatalogQuestionId: Scalars['UUID']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductValidationRuleResultInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  errorCategory?: InputMaybe<AppProductValidationRuleResultErrorCategoryChoices>;
  errorText?: InputMaybe<Scalars['String']['input']>;
  finalAnswerValue: Array<Scalars['String']['input']>;
  initialAnswerValue?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id of product form associated with this rule result */
  productFormId: Scalars['ID']['input'];
  result: AppProductValidationRuleResultResultChoices;
  ruleAssertion: Scalars['String']['input'];
  ruleCondition?: InputMaybe<Scalars['String']['input']>;
  successText?: InputMaybe<Scalars['String']['input']>;
  warningText?: InputMaybe<Scalars['String']['input']>;
};

export type ProductValidationRuleResultType = {
  __typename?: 'ProductValidationRuleResultType';
  /** Friendly description giving synopsis of what rule was violated */
  description?: Maybe<Scalars['String']['output']>;
  errorCategory?: Maybe<AppProductValidationRuleResultErrorCategoryChoices>;
  /** The error text that was displayed to the user */
  errorText?: Maybe<Scalars['String']['output']>;
  /** The final answer from the user that produced this result */
  finalAnswerValue: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** The latest invalid answer from the user. Used to track how often a user encountered an error then changed answer. */
  initialAnswerValue?: Maybe<Array<Scalars['String']['output']>>;
  /** The associated OrderIntakeAnswer for this result */
  intakeAnswer: OrderIntakeAnswerType;
  /** The associated product form this result was captured on */
  productForm: IntakeSubcategoryProductFormType;
  result: AppProductValidationRuleResultResultChoices;
  /** SurveyJS compatible string assertion that was evaluated */
  ruleAssertion: Scalars['String']['output'];
  /** SurveyJS compatible string that was met in order for the assertion to be evaluated. */
  ruleCondition?: Maybe<Scalars['String']['output']>;
  /** The success text that was displayed to the user */
  successText?: Maybe<Scalars['String']['output']>;
  /** The warning text that was displayed to the user */
  warningText?: Maybe<Scalars['String']['output']>;
};

export type ProviderType = {
  __typename?: 'ProviderType';
  address?: Maybe<AddressType>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  npi: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  smsNumber?: Maybe<Scalars['String']['output']>;
};

export type ProviderTypeConnection = {
  __typename?: 'ProviderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProviderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProviderType` and its cursor. */
export type ProviderTypeEdge = {
  __typename?: 'ProviderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProviderType>;
};

export type Query = {
  __typename?: 'Query';
  activeAgreement?: Maybe<AgreementType>;
  adminCompletedOrdersForDelighted?: Maybe<Array<Maybe<OrderType>>>;
  adminDocumentExtractionJobs?: Maybe<DocumentExtractionJobTypeConnection>;
  adminDocumentS3Url?: Maybe<DocumentS3UrlType>;
  adminDocuments?: Maybe<DocumentResultsType>;
  adminEhrSystems?: Maybe<Array<EhrSystemObjectType>>;
  adminExternalIntegrationRequestMessages?: Maybe<Array<Maybe<ExternalIntegrationRequestMessageType>>>;
  adminFieldChoices?: Maybe<ModelFieldChoiceType>;
  adminGetNetworkSupplier?: Maybe<OptimizedNetworkSupplierType>;
  adminGetOrder?: Maybe<OrderType>;
  adminGetPatient?: Maybe<PatientType>;
  adminGetPatientSet?: Maybe<Array<Maybe<PatientType>>>;
  adminHcpcsForProductForm?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  adminInsuranceVerification?: Maybe<InsuranceVerificationType>;
  adminInsurers?: Maybe<InsurerTypeConnection>;
  adminIntakeQuestionHcpcsFiltered?: Maybe<IntakeQuestionHcpcsTypeConnection>;
  /** @deprecated Replaced by adminIntakeQuestionsFiltered */
  adminIntakeQuestions?: Maybe<Array<Maybe<IntakeQuestionByHistoryType>>>;
  adminIntakeQuestionsFiltered?: Maybe<IntakeQuestionTypeConnection>;
  /** valid_at is optional, defaults to current time */
  adminIntakeQuestionsForHcpcs?: Maybe<IntakeQuestionsForHcpcs>;
  /** Utilizes underlying IntakeQuestionFilter but returns the latest version of a question that matches */
  adminIntakeQuestionsHistoryFiltered?: Maybe<IntakeQuestionTypeConnection>;
  adminIntakeValidationRulesFiltered?: Maybe<IntakeValidationRuleTypeConnection>;
  adminListGroups?: Maybe<Array<GroupType>>;
  adminListInvoicesReadyReimbursed?: Maybe<Array<Maybe<InvoiceType>>>;
  adminListProviders?: Maybe<ProviderTypeConnection>;
  adminListUsers?: Maybe<Array<Maybe<UserTypeForAdminView>>>;
  adminListUsersForOrgs?: Maybe<UserTypeConnection>;
  adminManualReviewOrders?: Maybe<OrderTypeConnection>;
  /** @deprecated Box is deprecated */
  adminNetworkSupplierBoxCollaborations?: Maybe<Array<BoxCollaborationType>>;
  adminNetworkSuppliers?: Maybe<Array<Maybe<OptimizedNetworkSupplierType>>>;
  /** Full change history for a specific object (instance) */
  adminObjectHistoricalDetails?: Maybe<HistoricalChangesDetailType>;
  adminOrder?: Maybe<OrderType>;
  adminOrderStatusReasons?: Maybe<OrderStatusReasonTypeConnection>;
  adminOrdersFiltered?: Maybe<OrderTypeConnection>;
  adminOrgs?: Maybe<OrgTypeConnection>;
  adminPatientsFiltered?: Maybe<PatientTypeConnection>;
  adminPractices?: Maybe<PracticeTypeConnection>;
  /** List API for all object IDs (active and deleted) for a model class and and corresponding network supplier id */
  adminSupplierObjectHistoricalList?: Maybe<HistoricalChangesListType>;
  adminSuppliersForOrder?: Maybe<Array<Maybe<OptimizedNetworkSupplierType>>>;
  adminUnverifiedOrders?: Maybe<Array<Maybe<OrderType>>>;
  adminUserNotificationPreferences?: Maybe<Array<Maybe<NotificationPreferenceType>>>;
  allProducts?: Maybe<Array<Maybe<ProductType>>>;
  appNotifications?: Maybe<AppNotificationTypeConnection>;
  clinicalQuestionsForProducts?: Maybe<Scalars['JSONString']['output']>;
  diagnosisCodes?: Maybe<DiagnosisTypeConnection>;
  documentS3Url?: Maybe<DocumentS3UrlType>;
  documentUploadS3Url?: Maybe<DocumentUploadS3UrlType>;
  /** @deprecated prototype query */
  ehrFhirPatient?: Maybe<Scalars['String']['output']>;
  ehrFhirUser?: Maybe<Scalars['String']['output']>;
  ehrSearchPatientMatch?: Maybe<EhrPatientSearchResult>;
  eligibleSuppliersForRoutingCriteria?: Maybe<EligibleSuppliersForRoutingCriteriaType>;
  facilities?: Maybe<FacilityTypeConnection>;
  fhirClient?: Maybe<EhrSystemSlimType>;
  fieldChoices?: Maybe<ModelFieldChoiceType>;
  getIntakeQuestionAsFormInput?: Maybe<Scalars['JSONString']['output']>;
  getProductFormAsFormInput?: Maybe<ProductFormInEditModeType>;
  hcpcsCodes?: Maybe<Array<Maybe<HcpcsType>>>;
  hcpcsCodesPaginated?: Maybe<HcpcsTypeConnection>;
  healthy?: Maybe<Scalars['Boolean']['output']>;
  insurerHierarchy?: Maybe<Array<Maybe<InsurerType>>>;
  insurers?: Maybe<Array<Maybe<InsurerType>>>;
  intakeCategories?: Maybe<Array<Maybe<IntakeCategoryType>>>;
  intakeDefects?: Maybe<Array<Maybe<IntakeDefectType>>>;
  intakeQuestionsForHcpcs?: Maybe<Scalars['JSONString']['output']>;
  intakeSubcategories?: Maybe<Array<Maybe<IntakeSubcategoryType>>>;
  intakeSubcategoryProductForms?: Maybe<Array<Maybe<IntakeSubcategoryProductFormType>>>;
  languages?: Maybe<Array<Maybe<LanguageType>>>;
  listUsersForOrg?: Maybe<UserTypeConnection>;
  metric?: Maybe<MetricResultType>;
  npiSearch?: Maybe<NpiSearchResultsType>;
  order?: Maybe<OrderType>;
  orderLineItemModificationReasons?: Maybe<OrderLineItemModificationReasonTypeConnection>;
  orderSeed?: Maybe<OrderSeedType>;
  orderSeedPotentialOrderMatches?: Maybe<Array<Maybe<OrderType>>>;
  orderSeedPotentialPatientMatches?: Maybe<Array<Maybe<PatientType>>>;
  orderStatusReasons?: Maybe<OrderStatusReasonTypeConnection>;
  orders?: Maybe<Array<Maybe<OrderType>>>;
  org?: Maybe<OrgType>;
  orgDocumentExtractionJobs?: Maybe<DocumentExtractionJobTypeConnection>;
  orgInsurances?: Maybe<Array<InsurerSlimType>>;
  orgOrders?: Maybe<OrderTypeConnection>;
  orgPatients?: Maybe<PatientSlimTypeConnection>;
  patientInsuranceDetails?: Maybe<PatientInsuranceVerificationType>;
  patientInsurances?: Maybe<Array<Maybe<PatientInsuranceType>>>;
  practice?: Maybe<PracticeType>;
  /** @deprecated PracticePatient is deprecated */
  practicePatients?: Maybe<PatientSlimTypeConnection>;
  /** Retrieve a single prior authorization request. */
  priorAuthRequest?: Maybe<PriorAuthRequestType>;
  /** Retrieve prior authorization requests. */
  priorAuthRequests?: Maybe<PriorAuthRequestTypeConnection>;
  priorAuthRequirements?: Maybe<Array<PriorAuthRequirementType>>;
  product?: Maybe<ProductType>;
  productCatalogQuestionVersions?: Maybe<ProductCatalogQuestionVersionTypeConnection>;
  productCatalogQuestions?: Maybe<ProductCatalogQuestionTypeConnection>;
  productValidationRuleMappings?: Maybe<ProductCatalogQuestionValidationRuleInsurerTypeConnection>;
  products?: Maybe<ProductTypeConnection>;
  statusMappingByOrgType?: Maybe<Array<Maybe<OrderStatusMapType>>>;
  subproducts?: Maybe<SubproductTypeConnection>;
  supplier?: Maybe<SupplierType>;
  supplierDocumentationSurveyReasons?: Maybe<SupplierDocumentationSurveyReasonTypeConnection>;
  supplierItem?: Maybe<SupplierItemType>;
  supplierItems?: Maybe<Array<Maybe<SupplierItemType>>>;
  supplierLocations?: Maybe<Array<Maybe<NetworkSupplierLocationType>>>;
  supplierOrder?: Maybe<SupplierOrderResultType>;
  supplierOrders?: Maybe<SupplierOrderTypeConnection>;
  supplierPatientMessageTemplates?: Maybe<Array<Maybe<SupplierPatientMessageTemplateType>>>;
  suppliers?: Maybe<Array<Maybe<SupplierType>>>;
  trackingNumberStatuses?: Maybe<TrackingNumberStatusTypeConnection>;
  user?: Maybe<UserType>;
  waivers?: Maybe<WaiverTypeConnection>;
};

export type QueryActiveAgreementArgs = {
  agreementType: AgreementTypes;
};

export type QueryAdminCompletedOrdersForDelightedArgs = {
  endDate: Scalars['Date']['input'];
  forShp?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['Date']['input'];
};

export type QueryAdminDocumentExtractionJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedOrgUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AppDocumentExtractionJobStatusChoices>;
};

export type QueryAdminDocumentS3UrlArgs = {
  documentId: Scalars['ID']['input'];
};

export type QueryAdminDocumentsArgs = {
  documentType: DocumentTypeEnum;
};

export type QueryAdminExternalIntegrationRequestMessagesArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryAdminGetNetworkSupplierArgs = {
  supplierId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryAdminGetOrderArgs = {
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAdminGetPatientArgs = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAdminGetPatientSetArgs = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminHcpcsForProductFormArgs = {
  productFormJson: Scalars['JSONString']['input'];
};

export type QueryAdminInsuranceVerificationArgs = {
  dateOfBirth: Scalars['Date']['input'];
  eligibilityCode?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
};

export type QueryAdminInsurersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  businessSegment?: InputMaybe<AppInsurerBusinessSegmentChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isUsedForRouting?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameIcontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminIntakeQuestionHcpcsFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hcpcs_Code?: InputMaybe<Scalars['String']['input']>;
  insurerName?: InputMaybe<Scalars['String']['input']>;
  insurer_Id?: InputMaybe<Scalars['UUID']['input']>;
  intakeQuestion_Id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminIntakeQuestionsFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hcpcs?: InputMaybe<Scalars['String']['input']>;
  historyVersionSyncId?: InputMaybe<Scalars['UUID']['input']>;
  historyVersion_Id?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insurerId?: InputMaybe<Scalars['UUID']['input']>;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  questionJsonIcontains?: InputMaybe<Scalars['String']['input']>;
  questionJsonVisibleifIcontains?: InputMaybe<Scalars['String']['input']>;
  questionName?: InputMaybe<Scalars['String']['input']>;
  questionType?: InputMaybe<AppIntakeQuestionQuestionTypeChoices>;
  syncId?: InputMaybe<Scalars['UUID']['input']>;
  useDefaultInsurer?: InputMaybe<Scalars['Boolean']['input']>;
  validAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryAdminIntakeQuestionsForHcpcsArgs = {
  hcpcs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  validAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryAdminIntakeQuestionsHistoryFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hcpcs?: InputMaybe<Scalars['String']['input']>;
  historyVersionSyncId?: InputMaybe<Scalars['UUID']['input']>;
  historyVersion_Id?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insurerId?: InputMaybe<Scalars['UUID']['input']>;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  questionJsonIcontains?: InputMaybe<Scalars['String']['input']>;
  questionJsonVisibleifIcontains?: InputMaybe<Scalars['String']['input']>;
  questionName?: InputMaybe<Scalars['String']['input']>;
  questionType?: InputMaybe<AppIntakeQuestionQuestionTypeChoices>;
  syncId?: InputMaybe<Scalars['UUID']['input']>;
  useDefaultInsurer?: InputMaybe<Scalars['Boolean']['input']>;
  validAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryAdminIntakeValidationRulesFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  errorCategory?: InputMaybe<AppIntakeValidationRuleErrorCategoryChoices>;
  first?: InputMaybe<Scalars['Int']['input']>;
  historyVersion_Id?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyBaseVersions?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ruleType?: InputMaybe<AppIntakeValidationRuleRuleTypeChoices>;
};

export type QueryAdminListProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAdminListUsersArgs = {
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAdminListUsersForOrgsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  canSignOrders?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inOrg?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orgType: OrgTypes;
};

export type QueryAdminManualReviewOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfter?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  createdAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  falconStatus?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<AppOrderFulfilledByChoices>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  networkSupplier?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientInsurers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  paymentOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  referringProviderUsers?: InputMaybe<Scalars['String']['input']>;
  referringProviders?: InputMaybe<Scalars['String']['input']>;
  rpStatusIn?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  serviceLevels?: InputMaybe<Scalars['String']['input']>;
  signatureOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<AppOrderSourceChoices>;
  statusIn?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['UUID']['input']>;
  submitterOrSigners?: InputMaybe<Scalars['String']['input']>;
  submitters?: InputMaybe<Scalars['String']['input']>;
  supplierTin?: InputMaybe<Scalars['String']['input']>;
  updatedAtAfter?: InputMaybe<Scalars['Date']['input']>;
  updatedAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtBefore?: InputMaybe<Scalars['Date']['input']>;
  updatedAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAdminNetworkSupplierBoxCollaborationsArgs = {
  supplierId: Scalars['ID']['input'];
};

export type QueryAdminNetworkSuppliersArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coveredInsurers?: InputMaybe<Array<Scalars['UUID']['input']>>;
  hcpcs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  includeNonServiceableSuppliers?: InputMaybe<Scalars['Boolean']['input']>;
  nameIcontains?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminObjectHistoricalDetailsArgs = {
  modelClassName: Scalars['String']['input'];
  objectId: Scalars['ID']['input'];
};

export type QueryAdminOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryAdminOrderStatusReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forStatus?: InputMaybe<AppOrderStatusReasonForStatusChoices>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminOrdersFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfter?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  createdAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  falconStatus?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<AppOrderFulfilledByChoices>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isPracticeOrder?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  networkSupplier?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientInsurers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  paymentOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  practiceId?: InputMaybe<Scalars['ID']['input']>;
  referringProviderUsers?: InputMaybe<Scalars['String']['input']>;
  referringProviders?: InputMaybe<Scalars['String']['input']>;
  rpStatusIn?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  serviceLevels?: InputMaybe<Scalars['String']['input']>;
  signatureOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<AppOrderSourceChoices>;
  statusIn?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['UUID']['input']>;
  submitterOrSigners?: InputMaybe<Scalars['String']['input']>;
  submitters?: InputMaybe<Scalars['String']['input']>;
  supplierTin?: InputMaybe<Scalars['String']['input']>;
  updatedAtAfter?: InputMaybe<Scalars['Date']['input']>;
  updatedAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtBefore?: InputMaybe<Scalars['Date']['input']>;
  updatedAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAdminOrgsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameFuzzy?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orgType?: InputMaybe<OrgTypes>;
};

export type QueryAdminPatientsFilteredArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthAfter?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthBefore?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insuranceCarrier?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  practiceId?: InputMaybe<Scalars['UUID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminPracticesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFuzzy?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminSupplierObjectHistoricalListArgs = {
  modelClassName: Scalars['String']['input'];
  networkSupplierId: Scalars['UUID']['input'];
};

export type QueryAdminSuppliersForOrderArgs = {
  includeManual?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['ID']['input'];
};

export type QueryAdminUserNotificationPreferencesArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryAppNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClinicalQuestionsForProductsArgs = {
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  omitProductNames?: InputMaybe<Scalars['Boolean']['input']>;
  productSelections: Array<ProductSelectionInput>;
};

export type QueryDiagnosisCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codeContains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDocumentS3UrlArgs = {
  documentId: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEhrFhirPatientArgs = {
  ehrPatientId: Scalars['String']['input'];
};

export type QueryEhrSearchPatientMatchArgs = {
  ehrPatientFhirId: Scalars['String']['input'];
};

export type QueryEligibleSuppliersForRoutingCriteriaArgs = {
  routingCriteriaInput: RoutingCriteriaInput;
};

export type QueryFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cityFuzzy?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameFuzzy?: InputMaybe<Scalars['String']['input']>;
  enterprise?: InputMaybe<Scalars['String']['input']>;
  enterpriseFuzzy?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalIdFuzzy?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  faxNumberFuzzy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberFuzzy?: InputMaybe<Scalars['String']['input']>;
  specialtyFuzzy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFhirClientArgs = {
  fhirServerUrl: Scalars['String']['input'];
  launch?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIntakeQuestionAsFormInputArgs = {
  questionJson: Scalars['JSONString']['input'];
};

export type QueryGetProductFormAsFormInputArgs = {
  productFormId: Scalars['ID']['input'];
};

export type QueryHcpcsCodesPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyDescription?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyValid?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInsurerHierarchyArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIntakeCategoriesArgs = {
  hasProductsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryIntakeQuestionsForHcpcsArgs = {
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  productFormJson: Scalars['JSONString']['input'];
  resultsJson: Scalars['JSONString']['input'];
};

export type QueryListUsersForOrgArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  canSignOrders?: InputMaybe<Scalars['Boolean']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};

export type QueryMetricArgs = {
  key: Scalars['String']['input'];
};

export type QueryNpiSearchArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npiNumber: Scalars['String']['input'];
};

export type QueryOrderArgs = {
  displayId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderLineItemModificationReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isUserSelectable?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrderSeedArgs = {
  orderSeedId: Scalars['ID']['input'];
};

export type QueryOrderSeedPotentialOrderMatchesArgs = {
  orderSeedId: Scalars['UUID']['input'];
  orgId?: InputMaybe<Scalars['UUID']['input']>;
  patientId: Scalars['UUID']['input'];
};

export type QueryOrderSeedPotentialPatientMatchesArgs = {
  orderSeedId: Scalars['UUID']['input'];
  orgId: Scalars['UUID']['input'];
};

export type QueryOrderStatusReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forOrgType?: InputMaybe<OrgTypes>;
  forStatus?: InputMaybe<AppOrderStatusReasonForStatusChoices>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrgArgs = {
  orgId: Scalars['UUID']['input'];
};

export type QueryOrgDocumentExtractionJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedOrgUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['UUID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AppDocumentExtractionJobStatusChoices>;
  statusGroup?: InputMaybe<DocumentExtractionJobStatusGroupType>;
};

export type QueryOrgInsurancesArgs = {
  orgId: Scalars['UUID']['input'];
};

export type QueryOrgOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfter?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  createdAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  falconStatus?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<AppOrderFulfilledByChoices>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  includeLinkedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  networkSupplier?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderStatusIn?: InputMaybe<Array<AppOrderStatusHistoryStatusChoices>>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientInsurers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  paymentOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  referringOrgId: Scalars['UUID']['input'];
  referringProviderUsers?: InputMaybe<Scalars['String']['input']>;
  referringProviders?: InputMaybe<Scalars['String']['input']>;
  rpStatusIn?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  serviceLevels?: InputMaybe<Scalars['String']['input']>;
  signatureOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<AppOrderSourceChoices>;
  statusIn?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['UUID']['input']>;
  submitterOrSigners?: InputMaybe<Scalars['String']['input']>;
  submitters?: InputMaybe<Scalars['String']['input']>;
  supplierTin?: InputMaybe<Scalars['String']['input']>;
  updatedAtAfter?: InputMaybe<Scalars['Date']['input']>;
  updatedAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtBefore?: InputMaybe<Scalars['Date']['input']>;
  updatedAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOrgPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthAfter?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthBefore?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insuranceCarrier?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPatientInsuranceDetailsArgs = {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  insurerId: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
};

export type QueryPracticeArgs = {
  practiceId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryPracticePatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthAfter?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthBefore?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insuranceCarrier?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['ID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPriorAuthRequestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPriorAuthRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isExpedited?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['UUID']['input']>;
  orgId?: InputMaybe<Scalars['UUID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusFilterEnum>;
};

export type QueryPriorAuthRequirementsArgs = {
  hcpcsCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  insurerId: Scalars['UUID']['input'];
};

export type QueryProductArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProductCatalogQuestionVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  productCatalogQuestionId: Scalars['UUID']['input'];
};

export type QueryProductCatalogQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  questionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductValidationRuleMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  insurerId?: InputMaybe<Scalars['UUID']['input']>;
  insurerName?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['UUID']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['UUID']['input']>;
  questionNickname?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  categoryOrSubcategoryIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  excludeCustomProduct?: InputMaybe<Scalars['Boolean']['input']>;
  excludeNoActiveForm?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  subcategoryIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type QueryStatusMappingByOrgTypeArgs = {
  orgType: OrgTypes;
};

export type QuerySubproductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchText: Scalars['String']['input'];
};

export type QuerySupplierArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySupplierDocumentationSurveyReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isUserSelectable?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySupplierItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySupplierLocationsArgs = {
  supplierId: Scalars['ID']['input'];
};

export type QuerySupplierOrderArgs = {
  orderId: Scalars['UUID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type QuerySupplierOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  assignedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfter?: InputMaybe<Scalars['Date']['input']>;
  createdAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  createdAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryAfter?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDeliveryBefore?: InputMaybe<Scalars['DateTime']['input']>;
  falconStatus?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendlyId?: InputMaybe<Scalars['String']['input']>;
  fulfilledBy?: InputMaybe<AppOrderFulfilledByChoices>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  networkSupplier?: InputMaybe<Scalars['String']['input']>;
  nikoId?: InputMaybe<Scalars['String']['input']>;
  noEstimatedDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByAssignedAt?: InputMaybe<BaseCustomOrdering>;
  orderByEstimatedDeliveryDate?: InputMaybe<BaseCustomOrdering>;
  orderByLocationName?: InputMaybe<BaseCustomOrdering>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientInsurers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  paymentOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  referringProviderUsers?: InputMaybe<Scalars['String']['input']>;
  referringProviders?: InputMaybe<Scalars['String']['input']>;
  rpStatusIn?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  serviceLevels?: InputMaybe<Scalars['String']['input']>;
  signatureOnFile?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<AppOrderSourceChoices>;
  statusIn?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<SupplyStatus>>>;
  subcategory?: InputMaybe<Scalars['UUID']['input']>;
  submitterOrSigners?: InputMaybe<Scalars['String']['input']>;
  submitters?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['ID']['input'];
  supplierTin?: InputMaybe<Scalars['String']['input']>;
  updatedAtAfter?: InputMaybe<Scalars['Date']['input']>;
  updatedAtAfterDt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtBefore?: InputMaybe<Scalars['Date']['input']>;
  updatedAtBeforeDt?: InputMaybe<Scalars['DateTime']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySupplierPatientMessageTemplatesArgs = {
  orgId: Scalars['ID']['input'];
};

export type QueryTrackingNumberStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AppTrackingNumberStatusStatusChoices>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWaiversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  authority?: InputMaybe<AuthorityEnum>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['Date']['input']>;
  validTo?: InputMaybe<Scalars['Date']['input']>;
};

/** Available options for generic question types like product_options */
export enum QuestionOptionType {
  Brand = 'BRAND',
  Color = 'COLOR',
  Frequency = 'FREQUENCY',
  Material = 'MATERIAL',
  Other = 'OTHER',
  Size = 'SIZE',
}

/** All intake question types we support. Product and clinical rules questions */
export enum QuestionType {
  ConditionDetail = 'CONDITION_DETAIL',
  ConditionDetailFreeText = 'CONDITION_DETAIL_FREE_TEXT',
  ConditionDuration = 'CONDITION_DURATION',
  DiagnosticCode = 'DIAGNOSTIC_CODE',
  DocumentUpload = 'DOCUMENT_UPLOAD',
  DocumentUploadDetails = 'DOCUMENT_UPLOAD_DETAILS',
  EncounterDate = 'ENCOUNTER_DATE',
  Frequency = 'FREQUENCY',
  HcpcsCode = 'HCPCS_CODE',
  LengthOfNeed = 'LENGTH_OF_NEED',
  Other = 'OTHER',
  ProductDescription = 'PRODUCT_DESCRIPTION',
  ProductOption = 'PRODUCT_OPTION',
  ProductOther = 'PRODUCT_OTHER',
  Quantity = 'QUANTITY',
  Resupply = 'RESUPPLY',
  SubproductCode = 'SUBPRODUCT_CODE',
  SupplierPreference = 'SUPPLIER_PREFERENCE',
}

export type ReadAllAppNotificationsMutation = {
  __typename?: 'ReadAllAppNotificationsMutation';
  error?: Maybe<ErrorType>;
};

export type ReadAppNotificationInput = {
  appNotificationId: Scalars['ID']['input'];
};

export type ReadAppNotificationMutation = {
  __typename?: 'ReadAppNotificationMutation';
  appNotification?: Maybe<AppNotificationType>;
  error?: Maybe<ErrorType>;
};

export type RecordCareCoordinationEventInput = {
  actionNeededReason?: InputMaybe<Scalars['String']['input']>;
  isActionNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  orderLineItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type RecordCareCoordinationEventMutation = {
  __typename?: 'RecordCareCoordinationEventMutation';
  error?: Maybe<ErrorType>;
};

export type RecordStakeholderEngagementInput = {
  engagementState: AppStakeholderEngagementEngagementStateChoices;
  /** Whether the changes made are related to an in-progress call (used to determine whether to merge messages to insurer) */
  isRelatedToInProgressCall?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  stakeholderType: AppStakeholderEngagementStakeholderTypeChoices;
  /** If the changes made are related to an in-progress call, caller should provide the Ticket ID for the call (if available) to aid in merging messages to insurer */
  ticketId?: InputMaybe<Scalars['UUID']['input']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type RemoveNetworkSupplierLocation = {
  __typename?: 'RemoveNetworkSupplierLocation';
  error?: Maybe<ErrorType>;
};

export type RemoveNetworkSupplierLocationInput = {
  supplierLocationId: Scalars['String']['input'];
};

export type RemoveOrderRelationInput = {
  relationId: Scalars['ID']['input'];
};

export type RemoveOrgUserDelegationMutation = {
  __typename?: 'RemoveOrgUserDelegationMutation';
  delegateUser?: Maybe<UserType>;
  error?: Maybe<ErrorType>;
};

export type RemoveOrgUserMutation = {
  __typename?: 'RemoveOrgUserMutation';
  error?: Maybe<ErrorType>;
  orgUser?: Maybe<OrgUserType>;
};

/**
 * This is a SOFT deletion of the document used by the supplier. It
 * does not actually delete the document on S3.
 */
export type RemoveSupplierDocument = {
  __typename?: 'RemoveSupplierDocument';
  deletedDocument?: Maybe<DocumentType>;
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type RemoveSupplierDocumentInput = {
  documentId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

/** An enumeration. */
export enum RequestStatusEnum {
  Approved = 'APPROVED',
  AwaitingResponse = 'AWAITING_RESPONSE',
  Cancelled = 'CANCELLED',
  Denied = 'DENIED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  PartiallyApproved = 'PARTIALLY_APPROVED',
  SubmissionPending = 'SUBMISSION_PENDING',
}

export type ResendOrgUserInviteMutation = {
  __typename?: 'ResendOrgUserInviteMutation';
  error?: Maybe<ErrorType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RoutingCriteriaInput = {
  categoryIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  hcpcsCodes: Array<InputMaybe<Scalars['String']['input']>>;
  patientDateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  primaryInsurerId?: InputMaybe<Scalars['UUID']['input']>;
  secondaryInsurerId?: InputMaybe<Scalars['UUID']['input']>;
  state: Scalars['String']['input'];
  tertiaryInsurerId?: InputMaybe<Scalars['UUID']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode: Scalars['String']['input'];
};

/** An enumeration. */
export enum SendByTypeEnum {
  Admin = 'admin',
  Patient = 'patient',
  Supplier = 'supplier',
  System = 'system',
}

/**
 * Enables suppliers to send a fax manually with additional comments
 * using the same faxback template as for the automated faxback flow.
 */
export type SendFaxbackCoversheetMutation = {
  __typename?: 'SendFaxbackCoversheetMutation';
  error?: Maybe<ErrorType>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  transmission?: Maybe<FaxTransmissionType>;
};

export type SendPreOrderEmail = {
  __typename?: 'SendPreOrderEmail';
  delivered?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Mutation to resend message (Email or SMS based on signing method)
 * requesting the provider (signer) to sign the order.
 */
export type SendSignatureReminderMutation = {
  __typename?: 'SendSignatureReminderMutation';
  error?: Maybe<ErrorType>;
  message?: Maybe<Scalars['String']['output']>;
};

/**
 * This mutation is DEPRECATED, please use UpdateOrderSupplierAttributesMutation
 * instead
 *
 * This mutation sets the location on the current assignment of a given order
 *
 * This mutation takes in an order ID and a location ID and sets the location of the
 * order's current assignment to the specified location. It verifies that the order ID
 * is valid and that the current user has permissions to modify the current assignment
 * of the order in question. It also verifies that the location ID is valid and that
 * the location is not deleted for the supplier of the current assignment. If the
 * location ID passed in is an empty string, then we unset the location on the current
 * assignment. Ideally, this would be specifically null for this case instead of an
 * empty string, but our current version of graphene doesn't support explicit null
 * values (was added in 3.0.0).
 */
export type SetOrderLocationMutation = {
  __typename?: 'SetOrderLocationMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type SetOrderLocationMutationInput = {
  locationId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

/**
 * Sets an order seed into a terminal state without seeding info into an order.
 *
 * In the event that an order seed is generated from a source that legitimately does
 * not have enough info to identify an order or a patient, this mutation allows a user
 * to mark the seed as unviable to seed an order so that we know someone actioned on
 * the order seed and can stop emitting nudge tickets for it.
 *
 * This also will set the associated document extraction job into a terminal state
 *
 * TODO: Add ticketing logic here instead of doing it through a django signal
 * https://tomorrowhealth.atlassian.net/browse/ABS-118
 */
export type SetOrderSeedUnviableMutation = {
  __typename?: 'SetOrderSeedUnviableMutation';
  error?: Maybe<ErrorType>;
  orderSeed?: Maybe<OrderSeedType>;
};

export enum SexEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
}

export type ShipmentType = {
  __typename?: 'ShipmentType';
  carrier: Scalars['String']['output'];
  cost?: Maybe<Scalars['Decimal']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveredDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  lineItems: Array<OrderLineItemType>;
  method?: Maybe<Scalars['String']['output']>;
  shippedDate?: Maybe<Scalars['DateTime']['output']>;
  status: AppShipmentStatusChoices;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};

/**
 * Mutation to sign an agreement by an OrgUser.
 *
 * The OrgUser needs to be associated with the org they are signing for.
 * The user should only be signing the latest version of an agreement.
 * A user should not be signing the same agreement for the same org.
 */
export type SignOrgAgreementMutation = {
  __typename?: 'SignOrgAgreementMutation';
  error?: Maybe<ErrorType>;
  orgAgreement?: Maybe<OrgAgreementType>;
};

export type SignatureType = {
  __typename?: 'SignatureType';
  signedDate?: Maybe<Scalars['Date']['output']>;
};

export type SmsMessageType = {
  __typename?: 'SmsMessageType';
  direction: AppSmsMessageDirectionChoices;
  id: Scalars['UUID']['output'];
  messageBody: Scalars['String']['output'];
  sendByType?: Maybe<SendByTypeEnum>;
  status?: Maybe<AppSmsMessageStatusChoices>;
};

/**
 * Input specifying how to split a Document from an Extraction Job page-wise.
 *
 * Given a document extraction job id, this allows a user to specify which
 * original page numbers should be grouped together to create a new document
 *
 * Ex: page_groups = [[1, 2, 4], [3, 5, 6]]
 * will indicate that pages 1, 2, and 4 of the original document should be used to
 * create new_document_1 and pages 3, 5, and 6 of the original document should be used
 * to create new_document_2
 */
export type SplitDocumentExtractionInput = {
  documentExtractionJobId: Scalars['ID']['input'];
  pageGroups?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/**
 * Mutation to split a Document of a DocumentExtractionJob into multiple
 *
 * When a human sees that the document contains information pertaining to multiple
 * orders, this allows them to select which pages belong to which order and re-enqueue
 * those individual documents back to the top of the extraction process.
 */
export type SplitDocumentExtractionMutation = {
  __typename?: 'SplitDocumentExtractionMutation';
  error?: Maybe<ErrorType>;
  splitResults?: Maybe<Array<SplitDocumentType>>;
};

/** Summary of a single new document created from a split document request */
export type SplitDocumentType = {
  __typename?: 'SplitDocumentType';
  /** The new document id that was created from the parent split */
  documentId: Scalars['ID']['output'];
  /** The originating document id */
  parentDocumentId: Scalars['ID']['output'];
  /** Which of the originating document's pages were used to create the new document */
  parentDocumentPages: Array<Scalars['Int']['output']>;
};

export type StakeholderEngagementType = {
  __typename?: 'StakeholderEngagementType';
  addByUser?: Maybe<UserTypeForAdminView>;
  createdAt: Scalars['DateTime']['output'];
  engagementState: AppStakeholderEngagementEngagementStateChoices;
  order: OrderType;
  reason: Scalars['String']['output'];
  stakeholderType: AppStakeholderEngagementStakeholderTypeChoices;
};

export enum StatusEnum {
  RequiresDocumentation = 'REQUIRES_DOCUMENTATION',
  RequiresReview = 'REQUIRES_REVIEW',
  ReviewComplete = 'REVIEW_COMPLETE',
}

export enum StatusFilterEnum {
  /** Retrieve approved prior authorization requests. */
  Approved = 'APPROVED',
  /** Retrieve cancelled prior authorization requests. */
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
}

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  /** Additional information for payment methods of type `card` */
  card?: Maybe<Scalars['JSONString']['output']>;
  /** The datetime this object was created in stripe. */
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The type of the PaymentMethod. */
  type: DjstripePaymentMethodTypeChoices;
};

export type SubcategoryHcpcsInput = {
  hcpcsCodes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subcategoryId: Scalars['ID']['input'];
};

export type SubmitAiPromptInput = {
  prompt: Scalars['String']['input'];
  thread?: InputMaybe<Array<OpenAiMessageInput>>;
};

/**
 * Allows a user to submit new prompts to our AI client (currently OpenAI).
 *
 * This supports both one-shot prompts where all the information OpenAI needs is within
 * a single prompt as well as multi-shot prompts where the user can pass a thread
 * history to OpenAI for context.
 *
 * For now, will use the default model, temperature, and top_p since it's unlikely
 * needed at this point.
 *
 * TODO: support using custom models and temp/top_p
 * https://tomorrowhealth.atlassian.net/browse/ABS-181
 */
export type SubmitAiPromptMutation = {
  __typename?: 'SubmitAIPromptMutation';
  aiPromptResponse?: Maybe<OpenAiPromptResponse>;
  error?: Maybe<ErrorType>;
};

export type SubproductType = {
  __typename?: 'SubproductType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  hcpcs: HcpcsType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubproductTypeConnection = {
  __typename?: 'SubproductTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubproductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SubproductType` and its cursor. */
export type SubproductTypeEdge = {
  __typename?: 'SubproductTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SubproductType>;
};

export type SupplierAcceptOrder = {
  __typename?: 'SupplierAcceptOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type SupplierCancelOrder = {
  __typename?: 'SupplierCancelOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type SupplierCancelOrderInput = {
  orderId: Scalars['ID']['input'];
  reasonCode: Scalars['String']['input'];
  reasonText?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type SupplierCreateOrderInput = {
  additionalDocuments?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  fulfilledBy: FulfilledByEnum;
  note?: InputMaybe<Scalars['String']['input']>;
  orderLineItems: Array<OrderLineItemInput>;
  patientId: Scalars['ID']['input'];
  primaryDocument: DocumentInput;
  referralDate: Scalars['Date']['input'];
  referringProviderId: Scalars['ID']['input'];
  referringSupplierId: Scalars['ID']['input'];
};

export type SupplierCreateOrderMutation = {
  __typename?: 'SupplierCreateOrderMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type SupplierDocumentationSurveyReasonType = {
  __typename?: 'SupplierDocumentationSurveyReasonType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalDescription: Scalars['String']['output'];
  isUserSelectable: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplierDocumentationSurveyReasonTypeConnection = {
  __typename?: 'SupplierDocumentationSurveyReasonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SupplierDocumentationSurveyReasonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SupplierDocumentationSurveyReasonType` and its cursor. */
export type SupplierDocumentationSurveyReasonTypeEdge = {
  __typename?: 'SupplierDocumentationSurveyReasonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SupplierDocumentationSurveyReasonType>;
};

export type SupplierItemType = {
  __typename?: 'SupplierItemType';
  cost: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  inventoryAmount?: Maybe<Scalars['Int']['output']>;
  /** Supplier SKU line item (cannot be combined with HCPCS code) */
  lineItems: Array<OrderLineItemType>;
  nikoId?: Maybe<Scalars['String']['output']>;
  /** Supplier SKU line item (cannot be combined with HCPCS code) */
  prescriptionLineItems: Array<PatientPrescriptionItemType>;
  shippingCost: Scalars['Decimal']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  supplier: SupplierType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SupplierOrderResultType = ErrorType | SupplierOrderType;

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderType = {
  __typename?: 'SupplierOrderType';
  addresses?: Maybe<Array<Maybe<OrderAddressType>>>;
  appointment?: Maybe<AppointmentType>;
  billingAddress?: Maybe<AddressType>;
  comments?: Maybe<Array<Maybe<CommentType>>>;
  createdAt: Scalars['DateTime']['output'];
  dischargeAt?: Maybe<Scalars['DateTime']['output']>;
  dischargeAtDate?: Maybe<Scalars['Date']['output']>;
  displayId: Scalars['String']['output'];
  documents?: Maybe<Array<Maybe<DocumentType>>>;
  externalMentionableUsers?: Maybe<Array<Maybe<UserSlimType>>>;
  /** User-visible ID assigned at intake */
  friendlyId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  intakeCategory?: Maybe<IntakeCategoryType>;
  intakeSubcategory?: Maybe<IntakeSubcategoryType>;
  internalMentionableUsers?: Maybe<Array<Maybe<UserSlimType>>>;
  isClinicalRulesElectronicSigned?: Maybe<Scalars['Boolean']['output']>;
  isPayorPartnered?: Maybe<Scalars['Boolean']['output']>;
  mostRecentAssignmentForSupplier?: Maybe<OrderAssignmentType>;
  oldestUnreadComment?: Maybe<CommentType>;
  patient: PatientType;
  patientMessages?: Maybe<Array<Maybe<SmsMessageType>>>;
  practice?: Maybe<PracticeSlimType>;
  primaryDocument?: Maybe<DocumentType>;
  referralChannel?: Maybe<AppOrderReferralChannelChoices>;
  referralDate?: Maybe<Scalars['Date']['output']>;
  referralSource?: Maybe<AppOrderReferralSourceChoices>;
  referrer?: Maybe<PersonType>;
  referringProvider?: Maybe<ProviderType>;
  seeds: Array<OrderSeedType>;
  serviceType?: Maybe<AppOrderServiceTypeChoices>;
  shippingAddress?: Maybe<AddressType>;
  shippingDetails?: Maybe<Scalars['String']['output']>;
  supplyEventTimeline?: Maybe<Array<Maybe<OrderTimelineEntryType>>>;
  supplyTimeline?: Maybe<Array<Maybe<OrderStatusHistorySlimType>>>;
  urgent: Scalars['Boolean']['output'];
  user?: Maybe<UserTypeForAdminView>;
};

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderTypeMostRecentAssignmentForSupplierArgs = {
  supplierId: Scalars['ID']['input'];
};

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderTypeOldestUnreadCommentArgs = {
  orgId: Scalars['UUID']['input'];
};

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderTypePatientMessagesArgs = {
  supplierId: Scalars['ID']['input'];
};

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderTypeSupplyEventTimelineArgs = {
  supplierId: Scalars['ID']['input'];
};

/**
 * A slim type for fetching data accessible to suppliers.
 *
 * Note that the OrderType defined after this slim type is the default
 * type for Order. In case the type of Order is ambiguous, graphene
 * will resolve it to the default type if no other info is present.
 */
export type SupplierOrderTypeSupplyTimelineArgs = {
  supplierId: Scalars['ID']['input'];
};

export type SupplierOrderTypeConnection = {
  __typename?: 'SupplierOrderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SupplierOrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SupplierOrderType` and its cursor. */
export type SupplierOrderTypeEdge = {
  __typename?: 'SupplierOrderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SupplierOrderType>;
};

export type SupplierPatientMessageTemplateType = {
  __typename?: 'SupplierPatientMessageTemplateType';
  templateDescription?: Maybe<Scalars['String']['output']>;
  templateId: Scalars['ID']['output'];
  templateName: Scalars['String']['output'];
};

export type SupplierRejectOrder = {
  __typename?: 'SupplierRejectOrder';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type SupplierRejectOrderInput = {
  orderId: Scalars['ID']['input'];
  reasonCode: Scalars['String']['input'];
  reasonText?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type SupplierSubmitDocumentationSurveyResponseInput = {
  isAdditionalDocumentationRequired: Scalars['Boolean']['input'];
  orderId: Scalars['UUID']['input'];
  reasonCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  supplierId: Scalars['UUID']['input'];
};

export type SupplierSubmitDocumentationSurveyResponseMutation = {
  __typename?: 'SupplierSubmitDocumentationSurveyResponseMutation';
  error?: Maybe<ErrorType>;
  isDocumentationSurveyAnswered?: Maybe<Scalars['Boolean']['output']>;
};

export type SupplierSuggestionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<AppOrderSupplierSuggestionReasonChoices>;
  source?: InputMaybe<AppOrderSupplierSuggestionSourceChoices>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
  taken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SupplierType = {
  __typename?: 'SupplierType';
  address?: Maybe<AddressType>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  items: Array<SupplierItemType>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export enum SupplyDocumentType {
  H2067Document = 'H2067_DOCUMENT',
  OrderDeliveryDocument = 'ORDER_DELIVERY_DOCUMENT',
  PatientDocument = 'PATIENT_DOCUMENT',
}

export enum SupplyStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Shipped = 'SHIPPED',
  TimedOut = 'TIMED_OUT',
}

export type SurveyResponseInput = {
  isReceived?: InputMaybe<Scalars['Boolean']['input']>;
  isSatisfied?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonText?: InputMaybe<Scalars['String']['input']>;
};

/**
 * This class is not derived from a django model, but is rather a generic ObjectType.
 * The template_info_id is also the lower case enum value from TemplateInfoId.
 */
export type TemplateInfoType = {
  __typename?: 'TemplateInfoType';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TicketType = {
  __typename?: 'TicketType';
  /** The user that created the manual ticket. */
  addByUser?: Maybe<UserTypeForAdminView>;
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  eventType: AppTicketEventTypeChoices;
  expedited?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  kustomerTicketStatus: AppTicketKustomerTicketStatusChoices;
  note?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
  /** The action that the CA needs to perform for this Ticket. */
  requiredAction?: Maybe<AppTicketRequiredActionChoices>;
  /** When in the future the ticket should be created in the CRM. */
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  /** In the context of a scheduled Ticket, this is when the ticket was actually emitted to the CRM. For ASAP tickets, this will remain empty */
  schedulerEmittedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TrackingNumberLineItemInput = {
  orderLineItemId: Scalars['UUID']['input'];
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TrackingNumberStatusType = {
  __typename?: 'TrackingNumberStatusType';
  carrier?: Maybe<Scalars['String']['output']>;
  carrierStatus?: Maybe<Scalars['String']['output']>;
  status: AppTrackingNumberStatusStatusChoices;
  trackingNumber: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type TrackingNumberStatusTypeConnection = {
  __typename?: 'TrackingNumberStatusTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TrackingNumberStatusTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TrackingNumberStatusType` and its cursor. */
export type TrackingNumberStatusTypeEdge = {
  __typename?: 'TrackingNumberStatusTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TrackingNumberStatusType>;
};

/**
 * This "update" mutation is a bit tricky because it may either:
 *     1) create a new version of a rule, which consists of creating a new row in
 *         the rules table with a history_version_id that matches the old rule, or
 *     2) perform more of a true "update" where no new rows in the rules table are
 *         created. This should only happen if none of the properties of the rule are
 *         being updated **except** for the `valid_from` and `valid_to` dates.
 *
 * After rule version update or new rule version creation, this will return all
 * versions of this rule whose valid dates overlap with each other.
 */
export type UpdateIntakeValidationRuleMutation = {
  __typename?: 'UpdateIntakeValidationRuleMutation';
  error?: Maybe<ErrorType>;
  intakeValidationRule?: Maybe<IntakeValidationRuleType>;
};

/**
 * This mutation updates attributes of the network supplier. Initially it
 * is used to bulk update zipcode and hcpcs coverage by users. The bulk updates
 * do an inference to determine which entities need to be created or deleted
 * based on lists of coverages passed into the mutation.
 *
 * Admin as well as supplier users are able to update a supplier. This mutation
 * updates list attributes while AdminUpdateNetworkSupplier updates singular and
 * list attributes. Eventually these should be combined to allow admins and DME users
 * to update their respective supplier attributes. Refer to THP-2115.
 */
export type UpdateNetworkSupplier = {
  __typename?: 'UpdateNetworkSupplier';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  networkSupplier?: Maybe<NetworkSupplierSlimType>;
};

export type UpdateNetworkSupplierCoveredInsurerInput = {
  enabled: Scalars['Boolean']['input'];
  insurerId: Scalars['ID']['input'];
};

export type UpdateNetworkSupplierInput = {
  networkSupplierCoveredInsurers?: InputMaybe<Array<InputMaybe<NetworkSupplierCoveredInsurerInput>>>;
  networkSupplierHcpcs?: InputMaybe<Array<InputMaybe<NetworkSupplierHcpcsInput>>>;
  networkSupplierZipcodes?: InputMaybe<Array<InputMaybe<NetworkSupplierZipcodeInput>>>;
  supplierId: Scalars['UUID']['input'];
};

export type UpdateNetworkSupplierLocation = {
  __typename?: 'UpdateNetworkSupplierLocation';
  error?: Maybe<ErrorType>;
  supplierLocation?: Maybe<NetworkSupplierLocationType>;
};

export type UpdateNetworkSupplierLocationInput = {
  name: Scalars['String']['input'];
  supplierLocationId: Scalars['String']['input'];
};

export type UpdateOrderDocumentMutation = {
  __typename?: 'UpdateOrderDocumentMutation';
  error?: Maybe<ErrorType>;
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderType>;
};

export type UpdateOrderDocumentMutationInput = {
  document?: InputMaybe<DocumentInput>;
  orderId: Scalars['ID']['input'];
};

export type UpdateOrderRequirementInput = {
  /** Whether the changes made are related to an in-progress call (used to determine whether to merge messages to insurer) */
  isUpdateRelatedToInProgressCall?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderRequirementId: Scalars['ID']['input'];
  status?: InputMaybe<AppOrderRequirementStatusChoices>;
  /** If the changes made are related to an in-progress call, caller should provide the Ticket ID for the call (if available) to aid in merging messages to insurer */
  ticketId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateOrderSeedInput = {
  /** New features should not use input as it will be deprecated in favor of the extraction_results_set_corrections input. */
  correctedExtractionResults?: InputMaybe<Scalars['JSONString']['input']>;
  extractionResultsSetCorrections?: InputMaybe<ExtractionResultsSetCorrectionsInput>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  orderSeedId: Scalars['ID']['input'];
  status?: InputMaybe<AppOrderSeedStatusChoices>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation to update an OrderSeed's corrected extraction results. */
export type UpdateOrderSeedMutation = {
  __typename?: 'UpdateOrderSeedMutation';
  error?: Maybe<ErrorType>;
  orderSeed?: Maybe<OrderSeedType>;
};

/**
 * This mutation sets supplier attributes on an order (aka editable fields on the
 * OrderAssignment)
 */
export type UpdateOrderSupplierAttributesMutation = {
  __typename?: 'UpdateOrderSupplierAttributesMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<SupplierOrderType>;
};

export type UpdateOrderSupplierAttributesMutationInput = {
  /** List of dx codes for order line items. If not provided, will use set of dx codes from other line items by default */
  diagnosisCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The estimated delivery datetime to set on the order */
  estimatedDeliveryAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Whether the changes made are related to an in-progress call (used to determine whether to merge messages to insurer) */
  isUpdateRelatedToInProgressCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Line items to add to an order */
  lineItemAddRequests?: InputMaybe<Array<OrderLineItemAddition>>;
  /** Line items to delete from an order */
  lineItemDeleteRequests?: InputMaybe<Array<OrderLineItemDeletion>>;
  /** The line item-level estimated delivery datetimes */
  lineItemEstimatedDeliveryAt?: InputMaybe<Array<EstimatedDeliveryLineItemInput>>;
  /** To delete tracking number pass order_line_item_id with null tracking number */
  lineItemTrackingNumber?: InputMaybe<Array<TrackingNumberLineItemInput>>;
  /** The ID of the location entity to set on the order. Can be null to remove an existing location from an order */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the order to update */
  orderId: Scalars['ID']['input'];
  /** If the changes made are related to an in-progress call, caller should provide the Ticket ID for the call (if available) to aid in merging messages to insurer */
  ticketId?: InputMaybe<Scalars['UUID']['input']>;
  /** Tracking number to apply to all line items in the order */
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrgPatientInput = {
  /** Currently saved as patient's address and shipping_address */
  address?: InputMaybe<UpdatePatientAddressInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guardian?: InputMaybe<PersonInput>;
  heightInInches?: InputMaybe<Scalars['Float']['input']>;
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  languagePreference?: InputMaybe<LanguageInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['UUID']['input'];
  patientId: Scalars['UUID']['input'];
  primaryInsurance?: InputMaybe<InsuranceInput>;
  secondaryInsurance?: InputMaybe<InsuranceInput>;
  sex?: InputMaybe<SexEnum>;
  tertiaryInsurance?: InputMaybe<InsuranceInput>;
  waiverId?: InputMaybe<Scalars['UUID']['input']>;
  weightInPounds?: InputMaybe<Scalars['Float']['input']>;
};

/**
 * Updates a patient that is already associated with the org.  This is a patch-style
 * update, so passing null for a field will remove/nullify it on the patient. Omitting
 * an input field will leave it unchanged on the patient. Note that homePhoneNumber,
 * sex, address, and primaryInsurance cannot be passed in as null.
 */
export type UpdateOrgPatientMutation = {
  __typename?: 'UpdateOrgPatientMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type UpdateOrgUserMutation = {
  __typename?: 'UpdateOrgUserMutation';
  error?: Maybe<ErrorType>;
  orgUser?: Maybe<OrgUserType>;
};

export type UpdatePatientAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePatientInput = {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  shippingAddress: UpdatePatientAddressInput;
  smsAuthorized: Scalars['Boolean']['input'];
};

export type UpdatePatientInsuranceInput = {
  allowUnverified?: InputMaybe<Scalars['Boolean']['input']>;
  insurerId?: InputMaybe<Scalars['ID']['input']>;
  patientInsuranceId: Scalars['ID']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<PriorityEnum>;
};

export type UpdatePatientMutation = {
  __typename?: 'UpdatePatientMutation';
  addressIsValid?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientType>;
};

export type UpdatePracticePatientInput = {
  address: UpdatePatientAddressInput;
  height?: InputMaybe<Scalars['Float']['input']>;
  patientId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['String']['input'];
  primaryInsurance: InsuranceInput;
  secondaryInsurance?: InputMaybe<InsuranceInput>;
  sex?: InputMaybe<SexEnum>;
  waiverId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePracticePatientMutation = {
  __typename?: 'UpdatePracticePatientMutation';
  error?: Maybe<ErrorType>;
  patient?: Maybe<PatientSlimType>;
};

export type UpdateSubcategoryInput = {
  defaultDeliveryHours?: InputMaybe<Scalars['Int']['input']>;
  defaultUrgentDeliveryHours?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateSupplierCategoryRankingsInput = {
  categoryName: Scalars['ID']['input'];
  ranking: Scalars['Decimal']['input'];
  supplierOpsId: Scalars['ID']['input'];
};

export type UpdateSupplierQualityMetricsInput = {
  categoryName: Scalars['ID']['input'];
  qualityScore: Scalars['Decimal']['input'];
  qualityThresholdRatio: Scalars['Decimal']['input'];
  supplierOpsId: Scalars['ID']['input'];
};

export type UpdateUserNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type UpdateUserNameMutation = {
  __typename?: 'UpdateUserNameMutation';
  user?: Maybe<UserType>;
};

export type UpdateUserNotificationPreferences = {
  __typename?: 'UpdateUserNotificationPreferences';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  notificationPreferences?: Maybe<Array<Maybe<NotificationPreferenceType>>>;
};

export type UpdateUserNotificationPreferencesInput = {
  notificationPreferences: Array<NotificationPreferenceInput>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserSlimType = {
  __typename?: 'UserSlimType';
  defaultBillingAddress?: Maybe<AddressType>;
  defaultShippingAddress?: Maybe<AddressType>;
  emailAddress: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  groupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  notificationPreferences?: Maybe<Array<Maybe<NotificationPreferenceType>>>;
  provider?: Maybe<ProviderType>;
};

export type UserType = {
  __typename?: 'UserType';
  createdByUser?: Maybe<UserSlimType>;
  defaultBillingAddress?: Maybe<AddressType>;
  defaultShippingAddress?: Maybe<AddressType>;
  emailAddress: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  groupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  notificationPreferences?: Maybe<Array<Maybe<NotificationPreferenceType>>>;
  orgUsers?: Maybe<Array<OrgUserType>>;
  patient?: Maybe<PatientType>;
  primaryPracticeId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<ProviderType>;
  /** @deprecated Moved to Patient */
  stripeCustomer?: Maybe<CustomerType>;
};

export type UserTypeOrgUsersArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
  orgType?: InputMaybe<OrgTypes>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserType>;
};

/**
 * Like UserType, but limits the fields accessible to the bare minimum. We can
 * remove this in favor of UserType if we decide that admins should be able to
 * view more data. This also exposes `id`, which UserType doesn't.
 */
export type UserTypeForAdminView = {
  __typename?: 'UserTypeForAdminView';
  emailAddress: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  stripeCustomer?: Maybe<CustomerType>;
};

export type ValidateOrderSignerTokenMutation = {
  __typename?: 'ValidateOrderSignerTokenMutation';
  error?: Maybe<ErrorType>;
  order?: Maybe<OrderType>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar']['output'];
};

export type WaiverInput = {
  /** The issuing authority of the waiver */
  authority?: InputMaybe<AuthorityEnum>;
  /** ID of the waiver if you are updating an existing waiver */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  validFrom: Scalars['Date']['input'];
  validTo: Scalars['Date']['input'];
};

export type WaiverType = {
  __typename?: 'WaiverType';
  /** The issuing authority of the waiver */
  authority?: Maybe<AuthorityEnum>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['Date']['output'];
  validTo: Scalars['Date']['output'];
};

export type WaiverTypeConnection = {
  __typename?: 'WaiverTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WaiverTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `WaiverType` and its cursor. */
export type WaiverTypeEdge = {
  __typename?: 'WaiverTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<WaiverType>;
};
