import {
  CreateOmniOrderMutation,
  useCreateOmniOrderMutation,
} from '../../../api-clients/falcon-api/graphql/mutations/createOmniOrder.generated';
import { logAction } from '../../../utils/logging';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  UpdateOmniOrderMutation,
  useUpdateOmniOrderMutation,
} from '../../../api-clients/falcon-api/graphql/mutations/updateOmniOrder.generated';
import React from 'react';

export function clearCachedOrderData(queryClient: QueryClient) {
  // This will trigger order data to be refetched on screens which show lists of orders

  const dependentQueryKeys = [
    ['getSingleOrgOrder'],
    ['getOrgOrders'],
    ['getOrgPatientOrders'],
    ['getOrgPatientSupplierOrders'],
    ['getEhrPatient'],
    ['getFaxExtractionResults'],
  ];

  for (const queryKey of dependentQueryKeys) queryClient.invalidateQueries({ queryKey });
}

export function clearCachedOrderLists(queryClient: QueryClient) {
  // This will trigger order data to be refetched on screens which show lists of orders

  const dependentQueryKeys = [
    ['getSupplierOrders'],
    ['getOrgOrders'],
    ['getOrgPatientOrders'],
    ['getOrgPatientSupplierOrders'],
    ['getEhrPatient'],
    ['getFaxExtractionResults'],
  ];

  for (const queryKey of dependentQueryKeys) queryClient.invalidateQueries({ queryKey });
}

function useStartDate() {
  return React.useMemo(() => new Date(), []);
}

function logOrderMutate(response: CreateOmniOrderMutation | UpdateOmniOrderMutation, startDate: Date, step?: string) {
  const serverOrder = response.order?.order;

  if (!serverOrder || response.order?.error) {
    throw new Error(response.order?.error?.message || 'Missing order in response when saving order.');
  }

  const duration = new Date().valueOf() - startDate.valueOf();

  if (step) {
    logAction(`intake.step.${step}.mutate`, { orderId: serverOrder.id, duration });
  }
}

export function useCreateOmniOrder(step?: string) {
  const queryClient = useQueryClient();
  const startDate = useStartDate();

  return useCreateOmniOrderMutation({
    onSettled: () => clearCachedOrderData(queryClient),
    onSuccess: (data) => logOrderMutate(data, startDate, step),
  });
}

export function useUpdateOmniOrder(step?: string) {
  const queryClient = useQueryClient();
  const startDate = useStartDate();

  return useUpdateOmniOrderMutation({
    onSettled: () => clearCachedOrderData(queryClient),
    onSuccess: (data) => logOrderMutate(data, startDate, step),
  });
}
