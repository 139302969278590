import { AppDocumentExtractionJobAssignmentRevokeReasonChoices } from '../../../../api-clients/falcon-api/graphql/types.generated';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { Option, Select } from '../../../../components/Select/Select';
import { useState } from 'react';
import Button from '@mui/material/Button';
import OrderActionDialog from '../../../Supplier/components/SupplierOrderDrawer/components/OrderActionDialog';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const REJECT_REASON_OPTIONS: Option[] = [
  {
    label: 'Product or brand is not carried',
    value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.ProductNotCarried,
  },
  {
    label: 'Product is temporarily out of stock',
    value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.ProductOutOfStock,
  },
  {
    label: 'Zip code cannot be serviced',
    value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.ZipCodeNotServiceable,
  },
  {
    label: 'Document contains multiple patients',
    value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.ContainsMultiplePatients,
  },
  {
    label: 'Not enough information in the document',
    value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.NotEnoughInfo,
  },
  { label: 'Other', value: AppDocumentExtractionJobAssignmentRevokeReasonChoices.Other },
];

export type FaxWizardRejectionFormValues = {
  reasonCode: AppDocumentExtractionJobAssignmentRevokeReasonChoices;
  otherReasonText: string;
};

type Form = UseFormReturn<FaxWizardRejectionFormValues>;

interface FaxWizardToolbarProps extends React.PropsWithChildren {
  onClose: (() => void) | undefined;
  onPrevStep: (() => void) | undefined;
  onNextStep: (() => void) | undefined;
  onReject: ((data: FaxWizardRejectionFormValues) => void) | undefined;
  hideNextStepButton?: boolean;
  hidePrevStepButton?: boolean;
  hideRejectButton?: boolean;
  nextStepDisplayText: string;
  loading: boolean;
}

export const FaxWizardToolbar = ({
  onClose,
  onPrevStep,
  onNextStep,
  onReject,
  hideNextStepButton,
  hidePrevStepButton,
  hideRejectButton,
  nextStepDisplayText,
  loading,
  children,
}: FaxWizardToolbarProps) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const form = useForm<FaxWizardRejectionFormValues>({
    defaultValues: {
      reasonCode: undefined,
      otherReasonText: undefined,
    },
  });

  const handleOnSubmit = (values: FaxWizardRejectionFormValues) => onReject?.(values);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    form.reset();
  };

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={1} spacing={1}>
        <Stack direction="row" spacing={1}>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Exit
          </Button>
          {!hideRejectButton && (
            <Button color="warning" disabled={loading} onClick={() => setDialogOpen(true)}>
              Reject Document
            </Button>
          )}
        </Stack>

        {children}

        <Stack direction="row" spacing={1}>
          {!hidePrevStepButton && (
            <Button disabled={!onPrevStep || loading} onClick={onPrevStep} variant="secondary">
              Back
            </Button>
          )}
          {!hideNextStepButton && (
            <Button disabled={!onNextStep || loading} onClick={onNextStep}>
              {nextStepDisplayText}
            </Button>
          )}
        </Stack>
      </Stack>

      <OrderActionDialog onClose={handleCloseDialog} open={isDialogOpen}>
        <Stack spacing={2}>
          <div>
            <Typography fontWeight="600" paragraph variant="subtitle1">
              Are you sure you want to reject this document?
            </Typography>
            <Typography color="textSecondary">
              Just a heads up – once you send the document back, you won&apos;t be able to see it anymore.
            </Typography>
          </div>

          <form noValidate onSubmit={form.handleSubmit(handleOnSubmit)}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Controller
                  control={form.control}
                  name="reasonCode"
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      error={!!fieldState.error?.message}
                      errorText={fieldState.error?.message}
                      label="Select a reason"
                      onChange={(e) => {
                        if (e.target.value === AppDocumentExtractionJobAssignmentRevokeReasonChoices.Other) {
                          form.setValue('otherReasonText', '');
                        }

                        field.onChange(e);
                      }}
                      options={REJECT_REASON_OPTIONS}
                      required
                    />
                  )}
                  rules={{ required: 'Please select a reason' }}
                />
                <OtherReasonInput form={form} />
              </Stack>

              <Stack direction="row" flexWrap="wrap" justifyContent="flex-end" spacing={1}>
                <Button aria-label="cancel" onClick={handleCloseDialog} variant="text">
                  Cancel
                </Button>

                <Button aria-label="confirm" color="warning" disabled={loading} type="submit">
                  Yes, reject
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </OrderActionDialog>
    </>
  );
};

const OtherReasonInput = ({ form }: { form: Form }) => {
  const reasonCode = form.watch('reasonCode');

  if (reasonCode !== AppDocumentExtractionJobAssignmentRevokeReasonChoices.Other) {
    return null;
  }

  return (
    <Controller
      control={form.control}
      name="otherReasonText"
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            inputRef={field.ref}
            multiline
            placeholder="Reason"
            rows={3}
            size="small"
          />
        );
      }}
      rules={{ required: 'Please provide a reason' }}
    />
  );
};

export default FaxWizardToolbar;
