import { useEffect, useState } from 'react';

const useDebounce = (value: string, delay: number, callback?: (debouncing: boolean) => void) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value !== debouncedValue) {
      if (callback) {
        callback(true);
      }
      const handler = setTimeout(() => {
        setDebouncedValue(value);

        callback?.(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }

    callback?.(false);

    return undefined;
  }, [value, delay, callback, debouncedValue]);

  return debouncedValue;
};

export default useDebounce;
