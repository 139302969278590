import { useLayoutEffect, useState } from 'react';
import { v4 } from 'uuid';

// viewIds are used to correlate client side actions (via datadogRum.addAction's
// context argument inside our logAction() function) with server side logging by
// way of an HTTP header `th-view-ids`, as set inside fetcher().
//
// Multiple IDs can exist at once to support nested views, for things such as
// parent views with children views (ex: modals/drawers) or wizards where the
// wizard can set a view ID as well as each step in the wizard.
//
// UI that wants to opt-in to set a viewID should call useAddViewIdToHttpHeaders
export const viewIds: string[] = [];

export function useAddViewIdToHttpHeaders() {
  const [viewId] = useState(v4);

  // useLayoutEffect instead of useEffect to ensure viewId has been added before
  // any calls to logAction
  useLayoutEffect(
    function addViewIdToListForHttpHeader() {
      viewIds.push(viewId);

      return () => {
        const index = viewIds.indexOf(viewId);
        if (index !== -1) viewIds.splice(index, 1);
      };
    },
    [viewId],
  );
}
