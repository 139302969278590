import { AddressFragment } from '../../api-clients/falcon-api/graphql/fragments/PatientFragment.generated';
import { AppOrderAddressAddressTypeChoices } from '../../api-clients/falcon-api/graphql/types.generated';

export type MaybeType<T> = {
  [Property in keyof T]?: T[Property] | null;
};

export const formatAddressOneLine = (address: MaybeType<Omit<AddressFragment, 'id'>>): string => {
  let result = [address.addressLine1, address.addressLine2, address.city, address.state].filter((v) => !!v).join(', ');
  result += address.postalCode ? ` ${address.postalCode}` : '';
  return result;
};

export const AddressTypeDisplayMap = {
  [AppOrderAddressAddressTypeChoices.Patient]: 'Patient Home Address',
  [AppOrderAddressAddressTypeChoices.Facility]: 'Facility Address',
  [AppOrderAddressAddressTypeChoices.Other]: 'Other',
};
