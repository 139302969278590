import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetHcpcsCodesPaginatedQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetHcpcsCodesPaginatedQuery = {
  __typename?: 'Query';
  hcpcsCodesPaginated?: {
    __typename?: 'HcpcsTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'HcpcsTypeEdge';
      node?: {
        __typename?: 'HcpcsType';
        code: string;
        longDescription?: string | null;
        friendlyDescription?: string | null;
        imgUrl?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type HcpcsCodeFragment = {
  __typename?: 'HcpcsType';
  code: string;
  longDescription?: string | null;
  friendlyDescription?: string | null;
  imgUrl?: string | null;
};

export const HcpcsCodeFragmentDoc = `
    fragment HcpcsCodeFragment on HcpcsType {
  code
  longDescription
  friendlyDescription
  imgUrl
}
    `;
export const GetHcpcsCodesPaginatedDocument = `
    query getHcpcsCodesPaginated($first: Int, $searchText: String) {
  hcpcsCodesPaginated(first: $first, searchText: $searchText) {
    totalCount
    edges {
      node {
        ...HcpcsCodeFragment
      }
    }
  }
}
    ${HcpcsCodeFragmentDoc}`;

export const useGetHcpcsCodesPaginatedQuery = <TData = GetHcpcsCodesPaginatedQuery, TError = unknown>(
  variables?: GetHcpcsCodesPaginatedQueryVariables,
  options?: Omit<UseQueryOptions<GetHcpcsCodesPaginatedQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetHcpcsCodesPaginatedQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetHcpcsCodesPaginatedQuery, TError, TData>({
    queryKey: variables === undefined ? ['getHcpcsCodesPaginated'] : ['getHcpcsCodesPaginated', variables],
    queryFn: fetcher<GetHcpcsCodesPaginatedQuery, GetHcpcsCodesPaginatedQueryVariables>(
      GetHcpcsCodesPaginatedDocument,
      variables,
    ),
    ...options,
  });
};

useGetHcpcsCodesPaginatedQuery.getKey = (variables?: GetHcpcsCodesPaginatedQueryVariables) =>
  variables === undefined ? ['getHcpcsCodesPaginated'] : ['getHcpcsCodesPaginated', variables];

export const useInfiniteGetHcpcsCodesPaginatedQuery = <
  TData = InfiniteData<GetHcpcsCodesPaginatedQuery>,
  TError = unknown,
>(
  variables: GetHcpcsCodesPaginatedQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetHcpcsCodesPaginatedQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetHcpcsCodesPaginatedQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetHcpcsCodesPaginatedQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['getHcpcsCodesPaginated.infinite']
            : ['getHcpcsCodesPaginated.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetHcpcsCodesPaginatedQuery, GetHcpcsCodesPaginatedQueryVariables>(GetHcpcsCodesPaginatedDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetHcpcsCodesPaginatedQuery.getKey = (variables?: GetHcpcsCodesPaginatedQueryVariables) =>
  variables === undefined ? ['getHcpcsCodesPaginated.infinite'] : ['getHcpcsCodesPaginated.infinite', variables];

useGetHcpcsCodesPaginatedQuery.fetcher = (
  variables?: GetHcpcsCodesPaginatedQueryVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<GetHcpcsCodesPaginatedQuery, GetHcpcsCodesPaginatedQueryVariables>(
    GetHcpcsCodesPaginatedDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetHcpcsCodesPaginatedQuery((req, res, ctx) => {
 *   const { first, searchText } = req.variables;
 *   return res(
 *     ctx.data({ hcpcsCodesPaginated })
 *   )
 * })
 */
export const mockGetHcpcsCodesPaginatedQuery = (
  resolver: Parameters<typeof graphql.query<GetHcpcsCodesPaginatedQuery, GetHcpcsCodesPaginatedQueryVariables>>[1],
) =>
  graphql.query<GetHcpcsCodesPaginatedQuery, GetHcpcsCodesPaginatedQueryVariables>('getHcpcsCodesPaginated', resolver);
