import { getDefaultPageForOrg } from '../../router/index';
import { memo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useCurrentOrgUser } from '../../router/useCurrentOrgUser';
import { useCurrentUser } from '../../api-clients/falcon-api/hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ChevronDown from '../../assets/chevron-down.svg?react';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export const OrgSelect = memo(function OrgSelect() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();
  const currentOrgUser = useCurrentOrgUser();
  const numOfOrgs = user?.orgUsers?.length || 0;

  if (numOfOrgs <= 1) return <Typography variant="h4">{currentOrgUser.org?.name}</Typography>;

  return (
    <Select
      IconComponent={() => (
        <Box
          onClick={() => setOpen(true)}
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            position: 'absolute',
            right: '6px',
            top: '0px',
            cursor: 'pointer',
          }}
        >
          <ChevronDown />
        </Box>
      )}
      input={<StyledInputBase />}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: {
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
            borderRadius: '3px',
            border: '1px solid #E6E9EB;',
            maxHeight: '33%',
            marginTop: '10px',
            '& .MuiList-root': {
              padding: '0',
            },

            '& .MuiMenuItem-root': {
              padding: '16px 30px 16px 20px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'block',
              fontSize: '16px',

              '&.Mui-selected': {
                background: 'rgba(9, 177, 117, 0.05)',
                fontWeight: 600,
                '&::after': {
                  content:
                    'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik00LjMzNTk0IDkuMzMzOThMNy42NjkyNyAxMi42NjczIiBzdHJva2U9IiM3RUIwQjUiIHN0cm9rZS13aWR0aD0iMS4yNSIvPg0KPHBhdGggZD0iTTYuODM1OTQgMTIuNjY2TDEzLjUwMjYgNS45OTkzNSIgc3Ryb2tlPSIjN0VCMEI1IiBzdHJva2Utd2lkdGg9IjEuMjUiLz4NCjwvc3ZnPg==")',
                  position: 'absolute',
                  right: '23px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
              },
            },
          },
        },
      }}
      onChange={(evt) => navigate(evt.target.value)}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      renderValue={() => <Typography variant="h4">{currentOrgUser.org.name}</Typography>}
      size="small"
      value={getDefaultPageForOrg(currentOrgUser.org)}
    >
      {user?.orgUsers
        ?.flatMap((orgUser) => (orgUser.org?.id && orgUser.org.name ? [orgUser.org] : []))
        ?.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
        ?.map((org) => (
          <MenuItem key={org.id} value={getDefaultPageForOrg(org)}>
            {org.name}
          </MenuItem>
        ))}
    </Select>
  );
});

const StyledInputBase = styled(InputBase)({
  borderRadius: '2px',
  '& .MuiInputBase-input': {
    '&:focus-within': {
      outline: '1px solid',
    },
  },
});
