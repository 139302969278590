import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<void>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      throw new Error('Web browser does not support copying to clipboard');
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);

      setCopiedText(text);
    } catch (error) {
      setCopiedText(null);

      throw new Error('Failed to copy to clipboard');
    }
  };

  return [copiedText, copy];
}
