/* eslint-disable no-console */
import { createClient, EntrySkeletonType } from 'contentful';
import { keepPreviousData, QueryClient, useQuery } from '@tanstack/react-query';
import { logWarning } from '../utils/logging';
import { useCallback, useMemo } from 'react';
import { useCurrentUser, User } from '../api-clients/falcon-api/hooks/useCurrentUser';
import { wrapStorage } from '../utils/wrapStorage';

/* eslint sort-keys: "error" */
const flagsMask = {
  add_or_remove_user: true,
  add_user_permissions: true,
  cca_launch_date_enforced: true,
  create_patient_permission: true,
  fax_intake_flow_highlights: true,
  fax_intake_qualification_fields: true,
  intake_cc_pcp_appointment_date_input: true,
  intake_cc_show_provider_step_pcp_field: true,
  intake_cc_view_review_step: true,
  intake_patient_search_strict_search: true,
  rp_patients_screen: true,
  rp_payor_qualifications_fax_view: true,
  rp_product_catalog: true,
  sandbox_forms: true,
  show_supplier_fax_document_label_step: true,
  show_supplier_fax_orders_screen: true,
  show_supplier_fax_orders_screen_user_assignment: true,
  show_supplier_page_banner_tabs: true,
  show_supplier_patient_orders_screen: true,
  show_supplier_performance_screen: true,
  shp_launch_date_enforced: true,
  supplier_order_line_item_delivery_dates: true,
  supplier_order_line_item_delivery_dates_add_remove: true,
  supplier_prior_auth_fax: true,
};
/* eslint-disable sort-keys */

export type FeatureFlags = {
  [Key in keyof typeof flagsMask]?: boolean;
};

export interface DevTools {
  clearFlagOverrides(): void;
  overrideFlag(name: keyof FeatureFlags, value: boolean | undefined): Promise<void>;
  showFlags(): void;
}

const contentfulKey = 'featureFlags:contentful';
const devtoolsKey = 'featureFlags:devtools';

const devtoolsFlagsStorage = wrapStorage<FeatureFlags>(window.localStorage, devtoolsKey);
let devtoolsFlags = devtoolsFlagsStorage.getJSON() ?? {};

export function enableFeatureFlagsDevTools(queryClient: QueryClient) {
  const devTools: DevTools = {
    async clearFlagOverrides() {
      devtoolsFlags = {};
      devtoolsFlagsStorage.setJSON(null);
      await queryClient.refetchQueries({ queryKey: [devtoolsKey] });
    },

    async overrideFlag(name: keyof FeatureFlags, value: boolean | undefined) {
      if (import.meta.env.REACT_APP_ENV === 'production') {
        // If any user overrides a feature flag in prod, we want to know about
        // it. Only TH staff should be ever do this, and rarely. The valid use
        // case is when deploying new code to prod, that is disabled via feature
        // flag, and a dev/QA wants to test it in prod before enabling it for
        // everyone.
        logWarning('Overriding feature flag in production', { [name]: value });
      }

      if (flagsMask[name] !== undefined) {
        devtoolsFlags = { ...devtoolsFlags };

        if (value === undefined) delete devtoolsFlags[name];
        else devtoolsFlags[name] = Boolean(value);

        devtoolsFlagsStorage.setJSON(devtoolsFlags);
        await queryClient.refetchQueries({ queryKey: [devtoolsKey] });
      } else {
        console.error('Unrecognized flag.');
      }
    },

    showFlags() {
      console.log('Contentful Flags:');
      console.table(queryClient.getQueryData([contentfulKey]));

      console.log('Devtools Flags:');
      console.table(queryClient.getQueryData([devtoolsKey]));
    },
  };

  // exposes devtools commands to the browser window object
  Object.assign(window, devTools);

  // Don't advertise devtools commands in prod in case any users are snooping.
  // These should be as difficult as possible to discover for external users.
  if (import.meta.env.REACT_APP_ENV !== 'production') {
    // Mute logging during Jest tests to keep output clear of noise.
    if (import.meta.env['VITEST_POOL_ID'] === undefined) {
      if (Object.keys(devtoolsFlags).length) {
        console.warn('The following feature flags are being overridden via devtools:');
        console.table(queryClient.getQueryData([devtoolsKey]));
      }

      console.log('Feature flags devtools commands available:');
      console.table(Object.entries(devTools).map(([name, value]) => `window.${name}(${value.length ? '...' : ''})`));
    }
  }
}

type ContentfulFlag = {
  name: string;
  isActive: boolean;
  description?: string;
  conditions?: {
    allowedOrgIds: string[];
    forbiddenOrgIds: string[];
    allowedUserIds: string[];
  };
};
// This is the shape of the flags JSON as stored in Contentful:
type ContentfulFlagsKey = `flags_${ImportMetaEnv['REACT_APP_ENV']}`;
type ContentfulFlags = EntrySkeletonType<{
  flagsObject: Record<ContentfulFlagsKey, ContentfulFlag[]>;
}>;

async function fetchContentfulFeatureFlags(): Promise<ContentfulFlag[]> {
  const client = createClient({
    space: import.meta.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: import.meta.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: import.meta.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    retryOnError: true,
    retryLimit: 3,
  });

  const entries = await client.getEntries<ContentfulFlags>({ content_type: 'featureFlags' });
  const allContentfulFlags = entries.items[0].fields as ContentfulFlags['fields'] | undefined;
  const appEnv = import.meta.env.REACT_APP_ENV;

  // Map 'deploy preview' env to staging env feature flags
  const contentfulEnvFlagsKey: ContentfulFlagsKey = appEnv === 'preview' ? `flags_staging` : `flags_${appEnv}`;
  const contentfulEnvFlags = allContentfulFlags?.flagsObject[contentfulEnvFlagsKey] || [];

  if (!contentfulEnvFlags.length) {
    logWarning(`No feature flags found for ${contentfulEnvFlagsKey}`);
  }

  // Sorting is helpful for looking at flags in dev tools or console
  contentfulEnvFlags.sort((a, b) => a.name.localeCompare(b.name));

  return contentfulEnvFlags;
}

export function useContentfulFeatureFlags() {
  const { data: user } = useCurrentUser();

  return useQuery({
    queryKey: [contentfulKey],
    queryFn: fetchContentfulFeatureFlags,
    staleTime: Infinity,
    meta: {
      errorMessage: 'Contentful error.',
      logErrors: true,
    },
    select: useCallback((query: ContentfulFlag[]) => transformContentfulFlags(query, user), [user]),
  });
}

function useLocalFeatureFlags() {
  // Putting the flags into RQ state so that components rerender on change

  return useQuery({ queryKey: [devtoolsKey], queryFn: () => devtoolsFlags, placeholderData: keepPreviousData });
}

export function useFeatureFlags(): FeatureFlags {
  const contentful = useContentfulFeatureFlags();
  const local = useLocalFeatureFlags();

  return useMemo(() => ({ ...contentful.data, ...local.data }), [contentful.data, local.data]);
}

function matchesOrgIds(orgIds: string[], user: User) {
  return (
    user.orgUsers?.some((orgUser) => {
      const orgId = orgUser.org?.id;

      if (!orgId) return false;

      return orgIds.includes(orgId);
    }) || false
  );
}

function matchesUserIds(userIds: string[], user: User) {
  return userIds.includes(user.id);
}

function isFeatureFlagEnabled(flag: ContentfulFlag, user: User) {
  if (!flag.isActive) return false;
  if (!flag.conditions) return true;
  if (!user) return false; // this logic is assuming all will conditions depend on user being logged in & defined

  const isInAllowedOrg = matchesOrgIds(flag.conditions.allowedOrgIds ?? [], user);
  const isInForbiddenOrg = matchesOrgIds(flag.conditions.forbiddenOrgIds ?? [], user);
  const isAllowedUserId = matchesUserIds(flag.conditions.allowedUserIds ?? [], user);

  // all paths are true EXCEPT (!allowed-org  forbidden-org !allowed-user = false)
  // note allowed-org overrules forbidden-org so (allowed-org  forbidden-org  allowed-user = true)
  return isInAllowedOrg || !isInForbiddenOrg || isAllowedUserId;
}

function transformContentfulFlags(query: ContentfulFlag[], user) {
  // Transforming the array into a map of booleans makes checking individual
  // flags simpler and faster.
  const result: FeatureFlags = {};
  for (const flag of query) {
    if (flagsMask[flag.name] !== undefined) {
      result[flag.name] = isFeatureFlagEnabled(flag, user);
    }
  }

  return result;
}
