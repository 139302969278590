import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import MuiTab, { tabClasses } from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export interface TabItem {
  key: string;
  label: React.ReactNode;
  tooltip?: string;
  icon?: string;
  disabled?: boolean;
}
interface TabsProps {
  isMainTabs?: boolean;
  centered?: boolean;
  items: TabItem[];
  sx?: SxProps;
  secondaryItem?: TabItem;
  selectedIndex: number | boolean;
  setIndex?: (newIndex: number) => void;
  onTabItemClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const StyledTabs = styled(MuiTabs, { shouldForwardProp: (propName) => propName !== 'isMainTabs' })(
  ({ isMainTabs }: { isMainTabs?: boolean }) => ({
    '& .MuiTabs-indicator': {
      height: '3px',
      display: isMainTabs ? 'inline-block' : 'none',
    },
    '& .MuiTab-root': {
      [`&.${tabClasses.selected}`]: {
        '&:not(:hover)': {
          backgroundColor: isMainTabs ? 'transparent' : undefined,
        },
      },
    },
  }),
);

export const Tabs = ({ items, selectedIndex, isMainTabs, setIndex, centered, onTabItemClick, sx }: TabsProps) => {
  return (
    <StyledTabs
      centered={centered}
      isMainTabs={isMainTabs}
      onChange={(e, newIndex: number) => setIndex?.(newIndex)}
      sx={sx}
      value={selectedIndex}
    >
      {items.map((tabItem) => (
        <Tooltip key={tabItem.key} enterTouchDelay={0} title={tabItem.tooltip || false}>
          <MuiTab
            disabled={tabItem.disabled}
            disableRipple
            icon={tabItem.icon}
            label={tabItem.label}
            onClick={onTabItemClick}
          />
        </Tooltip>
      ))}
    </StyledTabs>
  );
};
