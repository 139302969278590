import { styled } from '@mui/material/styles';

const BannerIconContainer = styled('div')({
  display: 'flex',
  border: `1px solid rgba(153, 168, 175, 0.5)`,
  borderRadius: '18px',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px',
  width: '80px',
});

export default BannerIconContainer;
