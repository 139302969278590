import { FaxbackCoversheetInput } from '../../../../api-clients/falcon-api/graphql/types.generated';
import { useObjectURL } from '../../../../hooks/useObjectURL';
import { usePreviewFaxbackCoversheetMutation } from '../../../../api-clients/falcon-api/graphql/mutations/previewFaxbackCoversheet.generated';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
export interface PreviewFaxBackStepFormValues {
  unmetRequirementsOverride?: string;
  additionalComments?: string;
}

export function toDefaultFormValues(): PreviewFaxBackStepFormValues {
  return {
    unmetRequirementsOverride: '',
    additionalComments: '',
  };
}

export function usePdfPreviewUrl(input: FaxbackCoversheetInput) {
  const previewFaxbackCoversheetMutation = usePreviewFaxbackCoversheetMutation();
  const { objectURL, setObject } = useObjectURL(null);
  const prevQueryDataRef = useRef<Blob>();

  const query = useQuery({
    queryKey: [['document.faxback'], input.orderSeedId, input.additionalComments, input.unmetRequirementsOverride],
    queryFn: async function getPreviewFaxbackCoverSheet(): Promise<Blob | undefined> {
      const response = await previewFaxbackCoversheetMutation.mutateAsync({
        input,
      });

      // pdfData is a base64 string that represents the PDF
      const base64PdfData = response.previewFaxbackCoversheet?.pdfData;

      if (!base64PdfData) {
        return undefined;
      }

      const pdfBlob = base64ToBlob(base64PdfData, 'application/pdf');

      return pdfBlob;
    },
    enabled: !!input.orderSeedId,
    staleTime: Infinity,
    meta: {
      errorMessage: 'Testing',
      logErrors: true,
    },
  });

  if (query.data && query.data !== prevQueryDataRef.current) {
    setObject(query.data);
  }

  prevQueryDataRef.current = query.data;

  return { ...query, data: objectURL, isLoading: query.isLoading || (!!query.data && !objectURL) };
}

export function base64ToBlob(base64: string, contentType: string): Blob {
  // Decode base64 string to a binary string
  const binaryString = atob(base64);

  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  // Create a Blob from the ArrayBuffer
  return new Blob([bytes.buffer], { type: contentType });
}
