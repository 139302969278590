import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type OrgUserAcceptInviteMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
}>;

export type OrgUserAcceptInviteMutation = {
  __typename?: 'Mutation';
  orgUserAcceptInvite?: {
    __typename?: 'OrgUserAcceptInvite';
    ok?: boolean | null;
    error?: { __typename?: 'ErrorType'; id?: string | null; message: string } | null;
  } | null;
};

export const OrgUserAcceptInviteDocument = `
    mutation orgUserAcceptInvite($orgId: ID!) {
  orgUserAcceptInvite(orgId: $orgId) {
    ok
    error {
      id
      message
    }
  }
}
    `;

export const useOrgUserAcceptInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<OrgUserAcceptInviteMutation, TError, OrgUserAcceptInviteMutationVariables, TContext>,
) => {
  return useMutation<OrgUserAcceptInviteMutation, TError, OrgUserAcceptInviteMutationVariables, TContext>({
    mutationKey: ['orgUserAcceptInvite'],
    mutationFn: (variables?: OrgUserAcceptInviteMutationVariables) =>
      fetcher<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>(
        OrgUserAcceptInviteDocument,
        variables,
      )(),
    ...options,
  });
};

useOrgUserAcceptInviteMutation.getKey = () => ['orgUserAcceptInvite'];

useOrgUserAcceptInviteMutation.fetcher = (
  variables: OrgUserAcceptInviteMutationVariables,
  options?: RequestInit['headers'],
) =>
  fetcher<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>(
    OrgUserAcceptInviteDocument,
    variables,
    options,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOrgUserAcceptInviteMutation((req, res, ctx) => {
 *   const { orgId } = req.variables;
 *   return res(
 *     ctx.data({ orgUserAcceptInvite })
 *   )
 * })
 */
export const mockOrgUserAcceptInviteMutation = (
  resolver: Parameters<typeof graphql.mutation<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>>[1],
) =>
  graphql.mutation<OrgUserAcceptInviteMutation, OrgUserAcceptInviteMutationVariables>('orgUserAcceptInvite', resolver);
