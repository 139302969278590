import { v4 as uuidv4 } from 'uuid';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

type NotificationType = 'info' | 'success' | 'warning' | 'error';
export type NotificationPlacementType = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface NotificationInputParam {
  type: NotificationType;
  title: string;
  subtitle?: React.ReactNode | (({ dismiss }: { dismiss: () => void }) => React.ReactNode);
  placement?: NotificationPlacementType;
}

export interface Notification extends NotificationInputParam {
  id: string;
}

type SnackbarInternalContextType = {
  notifications: Notification[];
  removeSnackbarNotification: (notification: Notification) => void;
};

const SnackbarInternalContext = createContext<SnackbarInternalContextType>({
  notifications: [],
  removeSnackbarNotification: () => undefined,
});

type SnackbarContextType = {
  showSnackbarNotification: (notification: NotificationInputParam) => void;
};

const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbarNotification: () => undefined,
});

export interface SnackbarProviderProps {
  maxItems?: number;
  children: React.ReactNode;
}

// * maxItems set to two as default for "best recommended UX"
const SnackbarProvider = ({ children, maxItems = 2 }: SnackbarProviderProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showSnackbarNotification = useCallback(
    (notification: NotificationInputParam) => {
      setNotifications((notifications) => {
        const updatedNotifications = [
          ...notifications.filter(
            (item) => !(item.title === notification.title && item.subtitle === notification.subtitle),
          ),
          { ...notification, id: uuidv4() },
        ];

        if (updatedNotifications.length > maxItems) {
          updatedNotifications.shift();
        }

        return updatedNotifications;
      });
    },
    [maxItems],
  );

  // TODO: Remove this once we have all functions converted to use the hook way
  // @ts-ignore
  window.showSnackbarNotification = showSnackbarNotification;

  const removeSnackbarNotification = useCallback(
    (notification: Notification) =>
      setNotifications((notifications) => notifications.filter((not) => not !== notification)),
    [],
  );

  const SnackbarInternalValue = useMemo(
    () => ({ notifications, removeSnackbarNotification }),
    [notifications, removeSnackbarNotification],
  );

  const SnackbarValue = useMemo(
    () => ({ showSnackbarNotification, removeSnackbarNotification }),
    [showSnackbarNotification, removeSnackbarNotification],
  );

  return (
    <SnackbarInternalContext.Provider value={SnackbarInternalValue}>
      <SnackbarContext.Provider value={SnackbarValue}>{children}</SnackbarContext.Provider>
    </SnackbarInternalContext.Provider>
  );
};

declare global {
  interface Window {
    showSnackbarNotification: ((notification: NotificationInputParam) => void) | undefined;
  }
}

export default SnackbarProvider;

export const useSnackbar = () => useContext(SnackbarContext);
export const useSnackbarInternalState = () => useContext(SnackbarInternalContext);
