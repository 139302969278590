import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export function CloseButton(props: IconButtonProps) {
  return (
    <Tooltip enterTouchDelay={0} placement="top" title="Close">
      <IconButton size="small" {...props}>
        <CloseLineIcon size={24} />
      </IconButton>
    </Tooltip>
  );
}
