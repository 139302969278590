import { EhrContext, useAuth } from '../contexts/AuthContext';
import { useQuery } from '@tanstack/react-query';

// Puts EHR context from Auth provider into react-query cache, so that it's
// more conveniently accessible in React components.
export function useEhrContext() {
  const auth = useAuth();

  return useQuery({
    queryKey: [['auth.ehr.context'], auth.status],
    queryFn: async function getEhrMeta(): Promise<EhrContext | null> {
      if (auth.status !== 'authenticated') return null;
      const { ehr } = await auth.getMeta();
      return ehr || null;
    },
    staleTime: Infinity,
    meta: {
      errorMessage: 'Failed to get EHR context',
      logErrors: true,
    },
  });
}
