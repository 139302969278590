import {
  GetInsurerHierarchyQuery,
  useGetInsurerHierarchyQuery,
} from '../graphql/queries/getInsurerHierarchy.generated';
import { keepPreviousData } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';

export function useInsurers() {
  return useGetInsurerHierarchyQuery(
    {},
    {
      staleTime: milliseconds({ hours: 12 }),
      placeholderData: keepPreviousData,
      meta: {
        errorMessage: 'Failed to load insurers.',
      },
      select: transformGetInsurersQuery,
    },
  );
}

function transformGetInsurersQuery({ insurerHierarchy }: GetInsurerHierarchyQuery) {
  return (
    insurerHierarchy?.flatMap((ins) => {
      if (!ins) return [];
      const plans = ins.plans?.flatMap((plan) => (plan ? [plan] : [])) ?? [];
      // TODO: Eventually we want to only show carriers that have plans and make plans required
      // if (!plans.length) return [];
      return [{ ...ins, plans }];
    }) ?? []
  );
}
