import { theme } from '@tomorrow/ui/theme';
import Box, { BoxProps } from '@mui/material/Box';

export const Separator = ({ margin, ...rest }: BoxProps) => (
  <Box margin={margin || '20px 0'} position="relative" {...rest} />
);

interface LineProps {
  className?: string;
  margin?: string;
  width?: string;
  color?: string;
  opacity?: number;
  height?: string;
  position?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'fixed'
    | '-webkit-sticky'
    | 'absolute'
    | 'relative'
    | 'static'
    | 'sticky';
}

export const Line = ({ color, height, margin, opacity, position, width, className }: LineProps) => {
  return (
    <Box
      className={className}
      sx={{
        backgroundColor: color || theme.colors.colorPrimary,
        height: height || '2px',
        left: '0',
        margin: margin || '0',
        opacity: opacity || 0.1,
        position: position || 'absolute',
        top: '50%',
        width: width || '100%',
      }}
    />
  );
};
