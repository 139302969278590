import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql } from 'msw';
export type GetStatusMappingsQueryVariables = Types.Exact<{
  orgType: Types.OrgTypes;
}>;

export type GetStatusMappingsQuery = {
  __typename?: 'Query';
  statusMappingByOrgType?: Array<{
    __typename?: 'OrderStatusMapType';
    displayStatus?: string | null;
    orderStatuses?: Array<Types.AppOrderStatusHistoryStatusChoices | null> | null;
  } | null> | null;
};

export const GetStatusMappingsDocument = `
    query getStatusMappings($orgType: OrgTypes!) {
  statusMappingByOrgType(orgType: $orgType) {
    displayStatus
    orderStatuses
  }
}
    `;

export const useGetStatusMappingsQuery = <TData = GetStatusMappingsQuery, TError = unknown>(
  variables: GetStatusMappingsQueryVariables,
  options?: Omit<UseQueryOptions<GetStatusMappingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetStatusMappingsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetStatusMappingsQuery, TError, TData>({
    queryKey: ['getStatusMappings', variables],
    queryFn: fetcher<GetStatusMappingsQuery, GetStatusMappingsQueryVariables>(GetStatusMappingsDocument, variables),
    ...options,
  });
};

useGetStatusMappingsQuery.getKey = (variables: GetStatusMappingsQueryVariables) => ['getStatusMappings', variables];

export const useInfiniteGetStatusMappingsQuery = <TData = InfiniteData<GetStatusMappingsQuery>, TError = unknown>(
  variables: GetStatusMappingsQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetStatusMappingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetStatusMappingsQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetStatusMappingsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getStatusMappings.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetStatusMappingsQuery, GetStatusMappingsQueryVariables>(GetStatusMappingsDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetStatusMappingsQuery.getKey = (variables: GetStatusMappingsQueryVariables) => [
  'getStatusMappings.infinite',
  variables,
];

useGetStatusMappingsQuery.fetcher = (variables: GetStatusMappingsQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetStatusMappingsQuery, GetStatusMappingsQueryVariables>(GetStatusMappingsDocument, variables, options);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStatusMappingsQuery((req, res, ctx) => {
 *   const { orgType } = req.variables;
 *   return res(
 *     ctx.data({ statusMappingByOrgType })
 *   )
 * })
 */
export const mockGetStatusMappingsQuery = (
  resolver: Parameters<typeof graphql.query<GetStatusMappingsQuery, GetStatusMappingsQueryVariables>>[1],
) => graphql.query<GetStatusMappingsQuery, GetStatusMappingsQueryVariables>('getStatusMappings', resolver);
